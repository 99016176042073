import React from 'react';
import styled from 'styled-components';

import { colors } from 'style/theme';

const Layout = styled.div`
  border: 1px solid #333;
  background-color: ${colors.blueSmalt};
  height: 100vh;

  a {
    text-decoration: none;

    &,
    &:visited,
    &:hover,
    &:active {
      color: ${colors.blueSmalt};
    }
  }

  h2 {
    margin: 0;
  }

  > div {
    padding: 1em;
    text-align: center;
    margin: 2em auto;
    background-color: white;
    width: 480px;
    box-sizing: border-box;
    border: 1px solid ${colors.blueBahama};
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  }

  .goBack {
    font-size: 0.85em;
  }
`;

const ErrorFallback = () => (
  <Layout>
    <div>
      <h2>Oh no! Something went wrong!</h2>
      <div>Please contact customer service</div>
      <div className="goBack">
        <a href="/">Click here</a> to go back
      </div>
    </div>
  </Layout>
);

export default ErrorFallback;
