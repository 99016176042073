import React from 'react';

import { Reports, Tabs } from 'components/Base';

const ReportsLayout = ({ match }) => {
  return (
    <>
      <Tabs />
      <Reports />
    </>
  );
};

export default ReportsLayout;
