import { Tabs } from '@polarislabs/polaris-ui-components';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { translate } from 'utils/translations';

const OrderTabs = ({ match }) => {
  const { accountNumber } = match.params;

  const data = [
    { url: `/accounts/${accountNumber}/order/kits`, label: translate('kits', 'Kits') },
    { url: `/accounts/${accountNumber}/order/jarsMailers`, label: translate('jarsMailers', 'Jars/Mailers') },
    { url: `/accounts/${accountNumber}/order/postage`, label: translate('postage', 'Prepaid Postage') },
    { url: `/accounts/${accountNumber}/order/supplies`, label: translate('supplies', 'Supplies') },
  ];

  return <Tabs data={data} />;
};

export default withRouter(OrderTabs);
