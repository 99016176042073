import React, { Fragment } from 'react';

import DatePicker from 'components/DatePicker';
import Input from 'components/Input';
import ItemManager from 'components/ItemManager/index';
import Loader from 'components/Loader';
import PanelContainer from 'components/Panel/index';
import RowItem from 'components/RowItem';
import Select from 'components/Select';
import { INVALID_ADDRESS } from 'constants/strings';
import { colors } from 'style/theme';
import { changeMethod } from 'utils/common';
import { translate } from 'utils/translations';

import ShippingMethodItem from './ShippingMethodItem';

const ShippingMethods = ({
  carriers,
  currency,
  distributionPoint: { city, region },
  fieldUpdate,
  filterDate,
  methodUpdate,
  selectedShippingCarrier,
  shipping: { carrierAccount, customShipDate, isFreight, isSplitBill, rushOrder, shipDate },
  address: { validity },
  shippingMethod,
  shippingMethods,
  updateCarrierAccount,
}) => {
  const isInvalidRates = carriers.error || (selectedShippingCarrier && selectedShippingCarrier.freight);
  const isIncreasedShipping = isSplitBill || isFreight;

  const renderCarrierMethods = loading => {
    if (loading) return <Loader small />;

    return (
      <Fragment>
        <RowItem center title={translate('shipping.carrier', 'Carrier')}>
          <Select
            clearable={false}
            fullWidth
            onChange={changeMethod(fieldUpdate, 'shipperPreference')}
            options={carriers.data.map(c => ({ label: c.name, value: c.id }))}
            value={selectedShippingCarrier && selectedShippingCarrier.id}
          />
        </RowItem>
        <RowItem center title={translate('shipping.shippingMethod', 'Shipping Method')}>
          {(!shippingMethods || shippingMethods.length === 0) &&
            translate('shipping.noShippingMethod', 'No Methods Found')}
          {shippingMethods && shippingMethods.length > 0 && (
            <ItemManager
              items={shippingMethods}
              component={ShippingMethodItem}
              selectedItem={shippingMethod.id}
              otherOptions={{
                showRates: !isInvalidRates && carrierAccount === '',
                currency: currency,
              }}
              actions={{ onSelect: changeMethod(methodUpdate) }}
            />
          )}
        </RowItem>
        {selectedShippingCarrier && selectedShippingCarrier.show_account_prompt && (
          <RowItem center title={translate('checkout.carrierAccount', 'Carrier Account')}>
            <Input
              name="carrierAccount"
              onChange={changeMethod((_, val) => updateCarrierAccount(selectedShippingCarrier.id, val))}
              value={carrierAccount}
            />
          </RowItem>
        )}
      </Fragment>
    );
  };

  return (
    <PanelContainer title={translate('shipping.shippingFrom', `Shipping (from ${city}, ${region})`, { city, region })}>
      {validity === INVALID_ADDRESS && (
        <div style={{ marginBottom: 10 }}>
          {translate(
            'checkout.invalidShippingAddress',
            // eslint-disable-next-line max-len
            'The shipping address as entered is not a valid. Final shipping costs will be determined at the time of fulfillment and will be included on the invoice for this order.'
          )}
        </div>
      )}
      <RowItem>
        <strong style={{ color: colors.greenApple }}>
          {translate('shipping.estimatedShipDate', 'Estimated ship date')} {isIncreasedShipping ? '3-5' : '1-2'}{' '}
          {'business days'}
        </strong>
        {isFreight && <div>You should consider changing this order to Freight</div>}
      </RowItem>
      <RowItem center title={translate('shipping.onSpecifiedDate', 'OR Select future ship date')}>
        <DatePicker
          filterDate={filterDate}
          minDate={shipDate}
          onChange={changeMethod(fieldUpdate, 'customShipDate')}
          selected={customShipDate}
        />
      </RowItem>
      {renderCarrierMethods(carriers.loading)}
    </PanelContainer>
  );
};

export default ShippingMethods;
