import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import Image from 'components/Image';
import { GALLERY_VIDEO } from 'constants/strings';
import { colors } from 'style/theme';

const StyledLightbox = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 80px;
  overflow: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  .Lightbox__content {
    position: relative;
    max-width: 1150px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .Lightbox__nav {
    display: flex;
    position: absolute;
    top: 50%;
    left: 10px;
    right: 10px;
    transform: translateY(-50%);
    align-items: center;
    justify-content: space-between;

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.2);
      color: ${colors.white};
      padding: 15px;
      border-radius: 999px;
      cursor: pointer;
      transition: background 300ms;

      &:hover {
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }
`;

const Lightbox = ({ data, selected, onBackdropClick = noop }) => {
  const [index, setIndex] = useState(selected);

  const onNext = useCallback(() => setIndex(index => (index + 1) % data.length), [data.length]);
  const onPrevious = useCallback(() => setIndex(index => (index + data.length - 1) % data.length), [data.length]);
  const onKey = useCallback(
    e => {
      if (['ArrowLeft', 'ArrowRight'].includes(e.key)) e.preventDefault();
      if (e.key === 'ArrowLeft') onPrevious();
      if (e.key === 'ArrowRight') onNext();
    },
    [onNext, onPrevious]
  );

  useEffect(() => {
    setIndex(selected);
  }, [data, selected]);

  useEffect(() => {
    document.addEventListener('keyup', onKey);
    return () => document.removeEventListener('keyup', onKey);
  }, [onKey]);

  const selectedItem = data[index];
  if (!selectedItem) return null;

  return (
    <StyledLightbox onClick={onBackdropClick}>
      <div className="Lightbox__content" onClick={e => e.stopPropagation()}>
        {selectedItem.type === GALLERY_VIDEO ? (
          <iframe
            width="600"
            height="425"
            title={selectedItem.alt}
            src={selectedItem.url}
            frameBorder="0"
            allowFullScreen
          />
        ) : (
          <Image alt={selectedItem.alt} src={selectedItem.url} />
        )}
        <div className="Lightbox__nav">
          <div className="Lightbox__nav-item Lightbox__nav-previous" onClick={onPrevious}>
            <i className="fa fa-arrow-left" />
          </div>
          <div className="Lightbox__nav-item Lightbax__nav-next" onClick={onNext}>
            <i className="fa fa-arrow-right" />
          </div>
        </div>
      </div>
    </StyledLightbox>
  );
};

Lightbox.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  selected: PropTypes.number,
  onBackdropClick: PropTypes.func,
};

export default Lightbox;
