/* eslint-disable max-len */
import { get } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import DatePicker from 'components/DatePicker';
import ModalWrapper from 'components/Modals/ModalWrapper';
import { RadioList } from 'components/RadioList/RadioList';
import { RECURRENCE_TYPES } from 'constants/recurrenceTypes';
import changeMethod from 'utils/common/changeMethod';
import { translate } from 'utils/translations';
import { allValid } from 'utils/validation';

const Details = styled.div`
  font-size: 12px;

  .Detail__item {
    margin: 0 0 15px;

    &:last-child {
      margin: 0;
    }
  }
`;

const VALIDATIONS = {
  recurrenceType: { validation: ['exists'] },
  startDate: { validation: ['exists', 'date'] },
};

const excludeEndOfMonth = date => {
  const dayOfMonth = moment(date).date();
  return ![29, 30, 31].includes(dayOfMonth);
};

const RecurringOrderModal = props => {
  const TOMORROW = moment().add(1, 'day').toDate();
  const NEXT_MONTH = moment().add(1, 'month').toDate();
  const INVALID_BY_VOLUME_ORDER_PLPS = ['CHV'];

  const [startDate, setStartDate] = useState(NEXT_MONTH);
  const [recurrenceType, setRecurrenceType] = useState(RECURRENCE_TYPES.monthly);

  const orderPlp = get(props, 'order.account.plp.code');

  const isValid = useMemo(() => {
    return allValid({ recurrenceType, startDate }, VALIDATIONS);
  }, [recurrenceType, startDate]);

  const payload = { startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null, recurrenceType };
  const recurrenceOptions = [
    {
      label: translate('store.recurringOrderMonthlyLabel', 'Every Month'),
      description: translate(
        'store.recurringOrderMonthlyDescription',
        'Will process on the {startDate} of each month',
        { startDate: moment(startDate).format('Do') }
      ),
      value: RECURRENCE_TYPES.monthly,
    },
    {
      label: translate('store.recurringOrderQuarterlyLabel', 'Every Quarter'),
      description: translate(
        'store.recurringOrderQuarterlyDescription',
        'Will process on the {startDate} every 3 months',
        { startDate: moment(startDate).format('Do') }
      ),
      value: RECURRENCE_TYPES.quarterly,
    },
  ];

  if (!INVALID_BY_VOLUME_ORDER_PLPS.includes(orderPlp)) {
    recurrenceOptions.push({
      label: translate('store.recurringOrderVolumeLabel', 'By Volume Received'),
      description: translate(
        'store.recurringOrderVolumeDescription',
        'Will resupply the volume of samples received last month on the {startDate} of each month. Volume rounded to the nearest pack quantity, minimum of half the pack quantity received for resupply order',
        { startDate: moment(startDate).format('Do') }
      ),
      value: RECURRENCE_TYPES.volume,
    });
  }

  return (
    <ModalWrapper payload={payload} valid={isValid} width={500} locked {...props}>
      <Details>
        <p className="Detail__item">
          {translate('store.recurringOrderDescription', 'Please select an option for the recurring order.')}
        </p>
        <RadioList
          name="recurrenceType"
          options={recurrenceOptions}
          onChange={(_, nextRecurrenceType) => setRecurrenceType(nextRecurrenceType)}
          value={recurrenceType}
        />
        <div className="Detail__item">
          <DatePicker
            label={translate('store.nextShipmentDate', 'Next Shipment Date')}
            filterDate={excludeEndOfMonth}
            minDate={TOMORROW}
            selected={startDate}
            onChange={changeMethod((_, nextStartDate) => setStartDate(nextStartDate), 'startDate')}
          />
        </div>
      </Details>
    </ModalWrapper>
  );
};

export default RecurringOrderModal;
