import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import account, { accountSaga } from './account';
import checkout, { checkoutSaga } from './checkout';
import modal from './modal';
import order, { orderSaga } from './order';
import inventory, { inventorySaga } from './order/inventory';
import substitutes, { substitutesSaga } from './order/substitutes';
import orderSearch, { saga as orderSearchSaga } from './orderSearch';
import previousOrder, { previousOrderSaga } from './previousOrder';
import recurringOrderSearch, { saga as recurringOrderSearchSaga } from './recurringOrderSearch';
import reference, { referenceSaga } from './reference';
import rushSamples, { rushSamplesSaga } from './rushSample';
import shipping, { shippingSaga } from './shipping';
import toast from './toast';
import translations, { translationsSaga } from './translations';
import user, { userSaga } from './user';
import userSearch, { userSearchSaga } from './userSearch';

export function* rootSaga() {
  yield all([
    accountSaga(),
    checkoutSaga(),
    inventorySaga(),
    orderSaga(),
    orderSearchSaga(),
    previousOrderSaga(),
    recurringOrderSearchSaga(),
    shippingSaga(),
    substitutesSaga(),
    translationsSaga(),
    userSaga(),
    userSearchSaga(),
    referenceSaga(),
    rushSamplesSaga(),
  ]);
}

export const rootReducer = history => {
  return combineReducers({
    router: connectRouter(history),
    account,
    checkout,
    inventory,
    modal,
    order,
    orderSearch,
    previousOrder,
    recurringOrderSearch,
    shipping,
    substitutes,
    toast,
    translations,
    user,
    userSearch,
    reference,
    rushSamples,
  });
};
