import { handleActions } from 'redux-actions';
import { all, takeLatest, call, select, put } from 'redux-saga/effects';

import * as api from 'lib/api';
import { selectors as orderSelectors } from 'modules/order';

import { actions } from './actions';

const defaultState = {
  data: [],
  loading: false,
  error: false,
};

// sagas
function* fetchShippingRatesSaga() {
  try {
    const orderId = yield select(orderSelectors.id);
    const data = yield call(api.shippingRates, orderId);
    yield put(actions.shippingRatesResponse(data));
  } catch (e) {
    yield put(actions.shippingRatesResponse(e));
  }
}

export function* optionsSaga() {
  yield all([takeLatest(actions.fetchShippingRates, fetchShippingRatesSaga)]);
}

export { actions } from './actions';
export { selectors } from './selectors';

export default handleActions(
  {
    [actions.fetchShippingRates](state) {
      return { ...defaultState, loading: true };
    },
    [actions.shippingRatesResponse]: {
      next(state, { payload }) {
        const { invalidRates, rates } = payload;
        return {
          ...state,
          data: rates,
          error: invalidRates,
          loading: false,
        };
      },
      throw() {
        return { ...defaultState, loading: false, error: true };
      },
    },
  },
  defaultState
);
