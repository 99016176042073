import { createActions } from 'redux-actions';

export const actions = createActions(
  'FETCH_PRODUCT_LIST_ITEM',
  'FETCH_PRODUCT_LIST_ITEM_RESPONSE',
  'FETCH_PRODUCT_LIST_ITEMS',
  'FETCH_PRODUCT_LIST_ITEMS_RESPONSE',
  'FETCH_PRODUCT_LIST_ITEMS_SIMPLE',
  'FETCH_PRODUCT_LIST_ITEMS_SIMPLE_RESPONSE',
  'CLEAR_PRODUCT_LISTS'
);
