import { connect } from 'react-redux';

import { selectors as accountSelectors } from 'modules/account/details';
import selectors from 'modules/checkout/selectors';
import { selectors as userSelectors } from 'modules/user';
import { isEmpty } from 'utils/lodash';

import Confirmation from './Confirmation';

const mapState = state => {
  const data = selectors.confirmationData(state);
  return {
    account: accountSelectors.details(state),
    hasData: !!data && !isEmpty(data),
    order: data,
    isCsr: userSelectors.isCsr(state),
    loading: selectors.confirmationLoading(state),
  };
};

export default connect(mapState)(Confirmation);
