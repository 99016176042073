import noop from 'lodash/noop';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Alert from 'components/Alert';
import Button from 'components/Buttons';
import Input from 'components/Input';
import LabeledInput, { LabeledSelect, LabeledCheckbox } from 'components/LabeledInput';
import Loader from 'components/Loader';
import PanelContainer from 'components/Panel';
import RowItem from 'components/RowItem';
import Select from 'components/Select';
import { COUNTRIES } from 'constants/countryRegions';
import { DEFAULT_BILLING_ADDRESS } from 'constants/defaults';
import { CREDIT_CARD, CC_REQUIRED, PO_REQUIRED, INVOICE_SETTING, INVOICE, NOT_ALLOWED } from 'constants/strings';
import { checkPurchaseOrder, accountSettings } from 'lib/api';
import { selectors as detailsSelectors } from 'modules/account/details';
import { actions as accountActions } from 'modules/account/settings';
import { selectors as checkoutSelectors } from 'modules/checkout';
import { actions as modalActions } from 'modules/modal';
import { selectors as rushSampleSelectors } from 'modules/rushSample';
import { actions as userActions, selectors as userSelectors } from 'modules/user';
import { colors } from 'style/theme';
import { changeMethod } from 'utils/common';
import { translate } from 'utils/translations';
import { allValid } from 'utils/validation';

const DISTRIBUTION_POINT_MAP = { CAD: 'CA', USD: 'US' };
const SETTINGS = [PO_REQUIRED, CC_REQUIRED, INVOICE_SETTING, NOT_ALLOWED];

const AddressErrorMessage = styled.div`
  font-size: 90%;
  margin: 3px;
  color: red;
  position: relative;
  top: -10px;
`;

const PurchaseOrderSuggestion = styled.div`
  margin: 5px 0 15px;
  padding: 8px 15px;
  background: ${colors.blueColumbia};
  border: 1px solid ${colors.blueCuriousBlue};
  border-radius: 3px;
`;

const ErrorInput = styled(Input)`
  ${props => props.invalid && 'border-color: red'};
`;

const ErrorMessage = styled.div`
  font-size: 90%;
  margin: 3px;
  color: red;
`;

const StyledCheckboxLabel = styled.label`
  display: flex;
  align-items: center;

  .CheckboxLabel__input {
    margin-right: 8px;
  }

  .CheckboxLabel__label {
  }
`;

const Columns = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(${props => props.span || 2}, 1fr);
  ${props => props.gridColumns && `grid-template-columns: ${props.gridColumns}`};
`;

const PaymentDetails = styled.div`
  .Details__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

const CheckboxLabel = ({ label, ...props }) => (
  <StyledCheckboxLabel>
    <input className="CheckboxLabel__input" type="radio" {...props} />
    <div className="CheckboxLabel__label">{label}</div>
  </StyledCheckboxLabel>
);

const removeSpaces = val => val.replace(/\s/g, '');

const formatCreditCards = creditCards => {
  const formattedCards = creditCards.map(({ id, cardType, cardNumber }) => ({
    label: `${cardType} - ${cardNumber}`,
    value: id,
  }));
  return [...formattedCards, { label: translate('newCreditCard', 'New Credit Card'), value: 'create' }];
};

const validations = {
  default: {},
  address: {
    firstName: { validation: ['minLength:1'] },
    lastName: { validation: ['minLength:1'] },
    address: { validation: ['minLength:1'] },
    city: { validation: ['minLength:1'] },
    state: { validation: ['minLength:1'] },
    zip: { validation: ['minLength:3'] },
  },
  amexCreditCard: {
    cardNumber: { validation: ['minLength:15'] },
    expirationDate: { validation: ['minLength:5', 'expirationDate:MM/YY:M'] },
    cvv: { validation: ['minLength:4', 'numbersOnly'] },
  },
  creditCard: {
    cardNumber: { validation: ['minLength:16'] },
    expirationDate: {
      validation: ['minLength:5', 'expirationDate:MM/YY:M'],
    },
    cvv: { validation: ['minLength:3', 'numbersOnly'] },
  },
  customerInfo: {
    companyName: { validation: ['minLength:1'] },
    country: { validation: ['minLength:1'] },
    firstName: { validation: ['minLength:1'] },
    lastName: { validation: ['minLength:1'] },
    address: { validation: ['minLength:1'] },
    city: { validation: ['minLength:1'] },
    state: { validation: ['minLength:1'] },
    zip: { validation: ['minLength:3'] },
  },
};

const Payment = ({
  account,
  createCreditCard,
  creditCardDisabled,
  creditCards,
  currency,
  isCsr,
  paymentMethods,
  showModal,
  total,
  value,
  onChange,
  onCreateCreditCard,
  onDeleteCreditCard,
  onValidate,
}) => {
  const [existingPurchaseOrder, setExistingPurchaseOrder] = useState('');
  const [useAccountAddress, setUseAccountAddress] = useState(false);
  const [settings, setSettings] = useState({});

  const { creditCardId, creditCardInfo, customerInfo, paymentMethod } = value;
  const { sample_rush_testing } = settings;
  const distributionPoint = DISTRIBUTION_POINT_MAP[currency];

  const getOrderUrl = order => `/accounts/${order.account.number}/rush-sample/${order.id}`;

  const poRequired =
    sample_rush_testing && (sample_rush_testing === PO_REQUIRED || !SETTINGS.includes(sample_rush_testing));

  const showCreditCardForm = paymentMethod === CREDIT_CARD && distributionPoint && (creditCardId === 'create' || isCsr);
  const showCreditCardList = paymentMethod === CREDIT_CARD && distributionPoint && !isCsr;
  const creditCardRequired = sample_rush_testing === CC_REQUIRED;
  const creditCardOverride = sample_rush_testing === CC_REQUIRED && isCsr && total === 0;

  useEffect(() => {
    const fetchSettings = async () => {
      const { settings } = await accountSettings(account.number, [
        'sample_rush_testing',
        'invoice_level_open',
        'blanket_po',
      ]);

      setSettings(settings);
    };

    fetchSettings();
  }, [account.number]);

  const getValidations = useCallback(
    field => {
      const creditCardFields = ['cardNumber', 'expirationDate', 'cvv'];
      const customerInfoValidations = validations.customerInfo;
      const creditCardInfoValidations = validations.creditCard;
      const amexCreditCardInfoValidations = validations.amexCreditCard;
      let defaultValidations = { ...validations.default };

      if (poRequired && paymentMethod === INVOICE) {
        defaultValidations.purchaseOrderNumber = { validation: ['minLength:1', 'isNotEmpty'] };
      }

      if (paymentMethod === CREDIT_CARD && field && field !== 'purchaseOrderNumber') {
        defaultValidations[field] = customerInfoValidations[field];
      }

      if (creditCardFields.includes(field)) {
        const cardValidation = isAmex(creditCardInfo.cardNumber)
          ? amexCreditCardInfoValidations
          : creditCardInfoValidations;
        defaultValidations[field] = cardValidation[field];
      }

      return defaultValidations;
    },
    [creditCardInfo.cardNumber, paymentMethod, poRequired]
  );

  const isValidCreditCard = useCallback(({ customerInfo, creditCardInfo }) => {
    const [NUMBER_LENGTH, CVV_LENGTH] = isAmex(creditCardInfo.cardNumber) ? [15, 4] : [16, 3];
    const isValidAddress = allValid(customerInfo, validations.address);
    const isValidCard = allValid(creditCardInfo, {
      ...validations.creditCard,
      cardNumber: { validation: [`minLength:${NUMBER_LENGTH}`] },
      cvv: { validation: [`minLength:${CVV_LENGTH}`] },
    });
    return isValidAddress && isValidCard;
  }, []);

  const isValid = useCallback(
    update => {
      const validations = getValidations();

      let valid = allValid(update, validations);
      if (update.paymentMethod === CREDIT_CARD) {
        if (isCsr) {
          valid = valid && isValidCreditCard(update);
        } else {
          valid = valid && update.creditCardId !== 'create';
        }
      }

      return valid;
    },
    [getValidations, isCsr, isValidCreditCard]
  );

  useEffect(() => {
    onValidate(isValid(value));
  }, [value, onValidate, isValid]);

  const onFieldUpdate = useCallback(
    (field, val) => {
      onChange(lastState => {
        const nextState = { ...lastState, [field]: val };

        if (nextState.purchaseOrderNumber && nextState.purchaseOrderNumber.toLowerCase() === 'none') {
          nextState.purchaseOrderNumber = '';
        }

        return nextState;
      });
    },
    [onChange]
  );

  useEffect(() => {
    if (creditCardRequired && !creditCardOverride) {
      onFieldUpdate('paymentMethod', CREDIT_CARD);
    }
  }, [creditCardRequired, creditCardOverride, onFieldUpdate]);

  const toggleUseAccountAddress = (field, val) => {
    setUseAccountAddress(val);
    const newAddress = val
      ? {
          companyName: account.reportAddress.company_name,
          firstName: '',
          lastName: '',
          address: account.reportAddress.address1,
          city: account.reportAddress.city,
          country: account.reportAddress.country,
          state: account.reportAddress.region,
          zip: account.reportAddress.post_code,
        }
      : DEFAULT_BILLING_ADDRESS;

    onChange(lastState => {
      const nextState = {
        ...lastState,
        customerInfo: {
          ...newAddress,
        },
      };

      return {
        ...nextState,
        valid: isValid(nextState),
      };
    });
  };

  const onCreateACreditCard = e => {
    e.preventDefault();
    const { creditCardInfo, customerInfo } = value;
    onCreateCreditCard({
      distPointCode: distributionPoint,
      creditCardInfo,
      customerInfo,
    });
  };

  const onDeleteACreditCard = creditCardId => {
    showModal('confirm', {
      type: 'alert',
      title: translate('deleteCreditCard', 'Delete Credit Card'),
      closeText: translate('yesDeleteCard', 'Yes, Delete Card'),
      cancelText: translate('noCancel', 'No, Cancel'),
      locked: true,
      message: <p>{translate('confirmDelete', "Are you sure you'd like to delete this credit card?")}</p>,
      onClose: () => onDeleteCreditCard({ basePricingLab: currency, creditCardId }),
    });
  };

  const onPurchaseOrderChange = async (_, val) => {
    const setPurchaseOrder = existingPurchaseOrder => {
      setExistingPurchaseOrder(existingPurchaseOrder);
    };

    if (!isCsr) return setPurchaseOrder();
    if (val.length === 0 || val.toLowerCase() === 'none') return setPurchaseOrder();

    const { id: account_id } = account;
    const { orders } = await checkPurchaseOrder({ account_id, poNumber: val });

    if (orders.length !== 1) return setPurchaseOrder();

    const [existingPurchaseOrder] = orders;
    setPurchaseOrder(existingPurchaseOrder);
  };

  const onAddressUpdate = (field, val) => {
    const nextState = {
      ...value,
      customerInfo: {
        ...value.customerInfo,
        [field]: val,
      },
    };

    onChange({
      ...nextState,
      valid: isValid(nextState),
    });
  };

  const onAddressBlurUpdate = field => {
    const { touched } = value;
    onChange({
      ...value,
      touched: { ...touched, [field]: true },
    });
  };

  const onCreditCardBlurUpdate = field => {
    const { touched } = value;
    onChange({
      ...value,
      touched: { ...touched, [field]: true },
    });
  };

  const onCardUpdate = (field, val) => {
    if (field === 'cardNumber') val = removeSpaces(val);

    const nextState = {
      ...value,
      creditCardInfo: {
        ...value.creditCardInfo,
        [field]: val,
      },
    };

    onChange({ ...nextState, valid: isValid(nextState) });
  };

  const isAmex = (number = '') => number.startsWith('34') || number.startsWith('37');

  const isValidField = (field, val) => {
    const { touched } = value;
    const validations = getValidations(field);

    if (!touched[field] && field !== 'purchaseOrderNumber') return true;
    return allValid({ [field]: val }, validations);
  };

  const renderPaymentMethods = () => {
    if (paymentMethods.error) {
      return (
        <Alert alert>
          {translate(
            'store.paymentMethodsError',
            // eslint-disable-next-line max-len
            "We're sorry, we cannot process your order at this time. Please contact us at custserv@eoilreports.com for immediate assistance."
          )}
        </Alert>
      );
    }

    if (paymentMethods.loading) return <Loader small />;

    return (
      <PaymentDetails>
        {(creditCardOverride || !creditCardRequired) && (
          <RowItem
            center
            title={
              <CheckboxLabel
                label={translate('invoice', 'Invoice')}
                name="paymentMethod"
                value="invoice"
                onChange={changeMethod(onFieldUpdate)}
                checked={value.paymentMethod === INVOICE}
              />
            }
          />
        )}
        <Fragment>
          <RowItem
            center
            title={
              <CheckboxLabel
                label={translate('creditCard', 'Credit Card')}
                name="paymentMethod"
                value="credit_card"
                onChange={changeMethod(onFieldUpdate)}
                checked={value.paymentMethod === CREDIT_CARD}
                disabled={creditCardDisabled}
              />
            }
          >
            {showCreditCardList && (
              <Columns style={{ gridTemplateColumns: '1fr auto', alignItems: 'center' }}>
                <Select
                  fullWidth
                  clearable={false}
                  onChange={changeMethod(onFieldUpdate, 'creditCardId')}
                  options={formatCreditCards(creditCards.data)}
                  placeholder={translate('selectCreditCard', 'Select Credit Card')}
                  value={creditCardId}
                />
                <Button
                  alert
                  transparent
                  disabled={creditCardId === 'create'}
                  onClick={() => onDeleteACreditCard(creditCardId)}
                  style={{ flex: '0 0 auto', fontSize: 14 }}
                >
                  <i className="fa fa-close" />
                </Button>
              </Columns>
            )}
          </RowItem>

          {showCreditCardForm && (
            <form onSubmit={isCsr ? noop : onCreateACreditCard}>
              {createCreditCard.error && <Alert alert>{translate(createCreditCard.error)}</Alert>}
              <RowItem>
                <div className="Details__header">
                  <h4>{translate('cardDetails', 'Card Details')}</h4>
                </div>
                <RowItem>
                  <LabeledInput
                    label={translate('cardNumber', 'Card Number')}
                    disabled={createCreditCard.loading}
                    name="cardNumber"
                    required
                    value={creditCardInfo.cardNumber}
                    invalid={!isValidField('cardNumber', creditCardInfo.cardNumber)}
                    onBlur={changeMethod(onCreditCardBlurUpdate, 'cardNumber')}
                    onChange={changeMethod(onCardUpdate)}
                    mask={isAmex(creditCardInfo.cardNumber) ? '1111 111111 11111' : '1111 1111 1111 1111'}
                  />
                  {!isValidField('cardNumber', creditCardInfo.cardNumber) && (
                    <AddressErrorMessage>
                      {translate('checkout.cardNumberRequired', 'Card number is required for this order')}
                    </AddressErrorMessage>
                  )}
                </RowItem>
                <RowItem>
                  <Columns>
                    <LabeledInput
                      label={translate('expiration', 'Expiration (MM/YY)')}
                      disabled={createCreditCard.loading}
                      mask="11/11"
                      name="expirationDate"
                      required
                      value={creditCardInfo.expirationDate}
                      invalid={!isValidField('expirationDate', creditCardInfo.expirationDate)}
                      onBlur={changeMethod(onCreditCardBlurUpdate, 'expirationDate')}
                      onChange={changeMethod(onCardUpdate)}
                    />
                    <LabeledInput
                      label={translate('securityCode', 'Security Code')}
                      disabled={createCreditCard.loading}
                      name="cvv"
                      required
                      value={creditCardInfo.cvv}
                      invalid={!isValidField('cvv', creditCardInfo.cvv)}
                      onBlur={changeMethod(onCreditCardBlurUpdate, 'cvv')}
                      maxLength={isAmex(creditCardInfo.cardNumber) ? 4 : 3}
                      onChange={changeMethod(onCardUpdate)}
                    />
                  </Columns>
                  <RowItem>
                    <Columns>
                      {!isValidField('expirationDate', creditCardInfo.expirationDate) ? (
                        <AddressErrorMessage style={{ display: 'inline', width: '50%' }}>
                          {translate('checkout.expirationDateRequired', 'Valid expiration date required')}
                        </AddressErrorMessage>
                      ) : (
                        <div style={{ width: '50%' }}>&nbsp;</div>
                      )}
                      {!isValidField('cvv', creditCardInfo.cvv) && (
                        <AddressErrorMessage style={{ display: 'inline', width: '50%' }}>
                          {translate('checkout.cvvRequired', 'CVV is required for this order')}
                        </AddressErrorMessage>
                      )}
                    </Columns>
                  </RowItem>
                  <LabeledCheckbox
                    label={translate('useAccountAddress', 'Use Account Address')}
                    name="useAccountAddress"
                    checked={useAccountAddress}
                    onChange={changeMethod(toggleUseAccountAddress)}
                  />
                </RowItem>
                <h4>{translate('billingAddress', 'Billing Address')}</h4>
                <RowItem>
                  <LabeledInput
                    invalid={!isValidField('companyName', customerInfo.companyName)}
                    label={translate('companyName', 'Company Name')}
                    disabled={createCreditCard.loading}
                    name="companyName"
                    required
                    value={customerInfo.companyName || ''}
                    onBlur={changeMethod(onAddressBlurUpdate, 'companyName')}
                    onChange={changeMethod(onAddressUpdate)}
                  />
                  {!isValidField('companyName', customerInfo.companyName) && (
                    <AddressErrorMessage>
                      {translate('checkout.companyNameRequired', 'Company Name is required for this order')}
                    </AddressErrorMessage>
                  )}
                  <Columns>
                    <span>
                      <LabeledInput
                        autoComplete="first-name"
                        invalid={!isValidField('firstName', customerInfo.firstName)}
                        label={translate('firstName', 'First Name')}
                        disabled={createCreditCard.loading}
                        name="firstName"
                        required
                        value={customerInfo.firstName}
                        onBlur={changeMethod(onAddressBlurUpdate, 'firstName')}
                        onChange={changeMethod(onAddressUpdate)}
                      />
                    </span>
                    <span>
                      <LabeledInput
                        autoComplete="last-name"
                        invalid={!isValidField('lastName', customerInfo.lastName)}
                        label={translate('lastName', 'Last Name')}
                        disabled={createCreditCard.loading}
                        name="lastName"
                        required
                        value={customerInfo.lastName}
                        onBlur={changeMethod(onAddressBlurUpdate, 'lastName')}
                        onChange={changeMethod(onAddressUpdate)}
                      />
                    </span>
                  </Columns>
                  {!isValidField('firstName', customerInfo.firstName) && (
                    <AddressErrorMessage>
                      {translate('checkout.firstNameRequired', 'First Name is required for this order')}
                    </AddressErrorMessage>
                  )}
                  {!isValidField('lastName', customerInfo.lastName) && (
                    <AddressErrorMessage>
                      {translate('checkout.lastNameRequired', 'Last Name is required for this order')}
                    </AddressErrorMessage>
                  )}
                  <LabeledInput
                    label={translate('address', 'Address')}
                    invalid={!isValidField('address', customerInfo.address)}
                    disabled={createCreditCard.loading}
                    name="address"
                    required
                    value={customerInfo.address}
                    onBlur={changeMethod(onAddressBlurUpdate, 'address')}
                    onChange={changeMethod(onAddressUpdate)}
                  />
                  {!isValidField('address', customerInfo.address) && (
                    <AddressErrorMessage>
                      {translate('checkout.addressRequired', 'Address is required for this order')}
                    </AddressErrorMessage>
                  )}
                  <LabeledSelect
                    autoComplete="country"
                    invalid={!isValidField('country', customerInfo.country)}
                    label={translate('country')}
                    name="country"
                    disabled={createCreditCard.loading}
                    onBlur={changeMethod(onAddressBlurUpdate, 'country')}
                    onChange={changeMethod(onAddressUpdate, 'country')}
                    style={{ width: '100%', marginBottom: 15 }}
                    options={COUNTRIES}
                    required
                    value={customerInfo.country}
                    width={'100%'}
                  />
                  {!isValidField('country', customerInfo.country) && (
                    <AddressErrorMessage>
                      {translate('checkout.countryRequired', 'Country is required for this order')}
                    </AddressErrorMessage>
                  )}
                  <Columns gridColumns="1fr auto">
                    <span>
                      <LabeledInput
                        autoComplete="city"
                        invalid={!isValidField('city', customerInfo.city)}
                        label={translate('city')}
                        name="city"
                        disabled={createCreditCard.loading}
                        onBlur={changeMethod(onAddressBlurUpdate, 'city')}
                        onChange={changeMethod(onAddressUpdate)}
                        required
                        style={{ width: '100%' }}
                        value={customerInfo.city}
                        width={'100%'}
                      />
                    </span>
                    {!isValidField('city', customerInfo.city) && (
                      <AddressErrorMessage>
                        {translate('checkout.cityRequired', 'City is required for this order')}
                      </AddressErrorMessage>
                    )}
                    <span>
                      <LabeledInput
                        autoComplete="postal-code"
                        invalid={!isValidField('zip', customerInfo.zip)}
                        label={translate('zipPostalCode')}
                        name="zip"
                        disabled={createCreditCard.loading}
                        onBlur={changeMethod(onAddressBlurUpdate, 'zip')}
                        onChange={changeMethod(onAddressUpdate)}
                        required
                        value={customerInfo.zip}
                        width={'96px'}
                      />
                    </span>
                    {!isValidField('zip', customerInfo.zip) && (
                      <AddressErrorMessage>
                        {translate('checkout.zipRequired', 'Zip is required for this order')}
                      </AddressErrorMessage>
                    )}
                  </Columns>

                  <LabeledInput
                    autoComplete="region"
                    country={customerInfo.country}
                    countryValueType="short"
                    invalid={!isValidField('state', customerInfo.state)}
                    name="state"
                    label={translate('stateProvidenceRegion')}
                    onBlur={changeMethod(onAddressBlurUpdate, 'state')}
                    onChange={changeMethod(onAddressUpdate, 'state')}
                    disabled={createCreditCard.loading}
                    regionInput
                    required
                    value={customerInfo.state}
                    valueType="short"
                  />
                  {!isValidField('state', customerInfo.state) && (
                    <AddressErrorMessage>
                      {translate('checkout.stateRequired', 'State is required for this order')}
                    </AddressErrorMessage>
                  )}
                </RowItem>
                {showCreditCardList && (
                  <RowItem>
                    <Button disabled={createCreditCard.loading || !isValidCreditCard(value)} type="submit">
                      {translate('saveCreditCard', 'Save Credit Card')}
                    </Button>
                  </RowItem>
                )}
              </RowItem>
            </form>
          )}
        </Fragment>
      </PaymentDetails>
    );
  };

  return (
    <PanelContainer title={translate('shipping.paymentMethod', 'Payment Method')}>
      <RowItem title={translate('store.purchaseOrderNumber', 'Purchase Order #')} center>
        <ErrorInput
          id="paymentPurchaseOrderNumber"
          name="purchaseOrderNumber"
          invalid={!isValidField('purchaseOrderNumber', value.purchaseOrderNumber)}
          onChange={changeMethod(onFieldUpdate)}
          onBlur={changeMethod(onPurchaseOrderChange)}
          value={value.purchaseOrderNumber || ''}
        />
        {!isValidField('purchaseOrderNumber', value.purchaseOrderNumber) && (
          <ErrorMessage>
            {translate('checkout.purchaseOrderNumberRequired', 'Purchase Order Number is required for this order')}
          </ErrorMessage>
        )}
        {existingPurchaseOrder && (
          <PurchaseOrderSuggestion>
            <strong>NOTE:</strong> Another order was created with this purchase number. You can view it{' '}
            <a href={getOrderUrl(existingPurchaseOrder)} target="_blank" rel="noopener noreferrer">
              here
            </a>
            .
          </PurchaseOrderSuggestion>
        )}
      </RowItem>
      {renderPaymentMethods()}
    </PanelContainer>
  );
};

const mapState = state => ({
  account: detailsSelectors.details(state),
  createCreditCard: userSelectors.createCreditCard(state),
  creditCardDisabled: rushSampleSelectors.isInvoiceOnly(state),
  creditCards: userSelectors.creditCards(state),
  currency: rushSampleSelectors.currency(state),
  isCsr: userSelectors.isCsr(state),
  paymentMethods: checkoutSelectors.paymentMethods(state),
});

const mapDispatch = {
  fetchUserProfile: userActions.fetchUserProfile,
  fetchAccountSettings: accountActions.fetchAccountSettings,
  onCreateCreditCard: userActions.createCreditCard,
  onDeleteCreditCard: userActions.deleteCreditCard,
  showModal: modalActions.showModal,
};

export default connect(mapState, mapDispatch)(Payment);
