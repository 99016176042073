export { default as IMAGE_NOT_FOUND } from 'assets/image_not_found.jpg';
export { default as VIDEO_PLAY } from 'assets/video_play.jpg';

export const CREDIT_CARD = 'credit_card';
export const INVOICE = 'invoice';

export const VALID_ADDRESS = 'valid';
export const INVALID_ADDRESS = 'invalid';
export const UNVALIDATED_ADDRESS = 'unvalidated';
export const BILLING_TYPE = 'BILLING';

export const NO = 'No';
export const YES = 'Yes';
export const CC_REQUIRED = 'CC Required';
export const PO_REQUIRED = 'PO Required';
export const INVOICE_SETTING = 'Invoice';
export const NOT_ALLOWED = 'Not Allowed';
export const TICKET_URL = 'https://eoilreports.atlassian.net/browse';

export const INVENTORY = 'inventory';
export const KITS = 'kits';

export const GALLERY_IMAGE = 'GALLERY_IMAGE';
export const GALLERY_VIDEO = 'GALLERY_VIDEO';

export const PRODUCT_INVOICE_LEVEL = 'Product Invoice Level';

export const SAMPLE_RUSH_TESTING = 'sample_rush_testing';
export const INVOICE_LEVEL_OPEN = 'invoice_level_open';
