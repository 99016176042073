import styled from 'styled-components';

import { colors } from 'style/theme';

const Alert = styled.div`
  color: ${colors.black};
  background: ${colors.grayAthens};
  border: solid 1px ${colors.grayLight};
  border-radius: 3px;
  padding: 7px 14px;
  margin: 0 0 15px;

  &:last-child {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  ${props => props.success && `color: ${colors.greenPear}`};
  ${props => props.success && `background: ${colors.greenLeaf}`};
  ${props => props.success && `border-color: ${colors.greenKale}`};

  ${props => props.alert && `color: ${colors.white}`};
  ${props => props.alert && `background: ${colors.redBrick}`};
  ${props => props.alert && `border-color: ${colors.redCarmine}`};

  ${props => props.warning && `color: ${colors.black}`};
  ${props => props.warning && `background: ${colors.yellow}`};
  ${props => props.warning && `border-color: ${colors.yellowDarker}`};
`;

export default Alert;
