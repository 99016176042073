import React from 'react';

import BaseLayout from 'components/BaseLayout';
import { Breadcrumbs, Cart } from 'components/Cart';

const CartLayout = () => (
  <BaseLayout>
    <Breadcrumbs />
    <div className="Layout__content">
      <div className="Layout__wrapper">
        <Cart />
      </div>
    </div>
  </BaseLayout>
);

export default CartLayout;
