import isBefore from 'date-fns/isBefore';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { compose } from 'redux';

import withReference from 'hoc/withReference';
import { createReportUrl, createKitCountReportUrl } from 'lib/api';
import { omit, isEmptyString } from 'utils/lodash';

import Reports from './Reports';

class ReportsContainer extends PureComponent {
  state = {
    params: {
      selected: 'dateShipped',
    },
    referenceData: {},
    invalidDateRange: false,
  };

  buildCarrierOptions = carriers => {
    return uniqBy(carriers, 'name').map(i => ({ label: i.name, value: i.id }));
  };

  padWithZeros = (name, val) => {
    while (val && val.length < 14) {
      val = val + '0';
    }

    this.setState({ params: { ...this.state.params, [name]: val } });
  };

  updateParam = (name, val, callback) => {
    this.setState(state => {
      if (isEmptyString(val)) return { params: omit(this.state.params, name) };
      return { params: { ...state.params, [name]: val } };
    }, callback);
  };

  validateParams = () => {
    const { invalidDateRange } = this.state;
    const params = pick(this.state.params, ['startDate', 'endDate', 'reportFormat']);

    const validParams = pickBy(params, val => {
      return val !== null;
    });

    if (isEmpty(validParams) || Object.keys(validParams).length < 2 || !validParams.reportFormat || invalidDateRange) {
      return false;
    }

    return true;
  };

  validateDateRange = () => {
    const { startDate, endDate } = this.state.params;

    if (startDate && endDate && isBefore(endDate, startDate)) return this.setState({ invalidDateRange: true });

    return this.setState({ invalidDateRange: false });
  };

  onSubmit = e => {
    if (e) e.preventDefault();

    let startDate;
    let endDate;
    let exportUrl;
    const { params } = this.state;

    // Convert Moment object to formatted date.
    if (params.startDate) startDate = moment(params.startDate).format('MM/DD/YYYY');
    if (params.endDate) endDate = moment(params.endDate).format('MM/DD/YYYY');

    try {
      if (params.reportFormat.includes('kit_count')) {
        exportUrl = createKitCountReportUrl(
          { ...omit(params, ['startDate', 'endDate']), startDate, endDate },
          params.reportFormat
        );
      } else {
        exportUrl = createReportUrl(
          { ...omit(params, ['startDate', 'endDate']), startDate, endDate },
          params.reportFormat
        );
      }

      window.open(exportUrl, '_blank');
    } catch (e) {
      console.log(e);
    }
  };

  getChildProps() {
    return {
      ...this.props,
      ...this.state,
      updateParam: this.updateParam,
      onSubmit: this.onSubmit,
      buildCarrierOptions: this.buildCarrierOptions,
      isValid: this.validateParams(),
      padWithZeros: this.padWithZeros,
      validateDateRange: this.validateDateRange,
    };
  }

  render() {
    return <Reports {...this.getChildProps()} />;
  }
}

const enhance = compose(withReference);

export default enhance(ReportsContainer);
