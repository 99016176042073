import React, { useCallback, useEffect, useState } from 'react';

import Button from 'components/Buttons';
import Errors from 'components/Errors';
import Hider from 'components/Hider';
import Input from 'components/Input';
import { translate } from 'utils/translations';

import SearchForm from './SearchForm';

const INVALID_FORMAT = 'Invalid Lab Code Format';
const MISMATCH_LENGTH = 'Lengths must match';
const MISMATCH_LETTER = 'First letters must match';
const START = 'labNumberStart';
const END = 'labNumberEnd';

const REGEX_FORMAT = /^[a-z]{1}-\d{1,}$/i;

const InputWrapper = ({ errors, touched, ...props }) => (
  <div style={{ position: 'relative' }}>
    <Input type="text" invalid={touched && errors.length > 0} inline {...props} />
    {touched && errors.length > 0 && <Errors data={errors} />}
  </div>
);

const LabcodesSearch = ({ data, loading, selected, onChange, onSearch, onSelect }) => {
  const [touched, setTouched] = useState({ [START]: false, [END]: false });
  const [validation, setValidation] = useState({
    [START]: [],
    [END]: [],
    valid: false,
  });

  useEffect(() => {
    setTouched({ [START]: false, [END]: false });
    setValidation({
      [START]: [],
      [END]: [],
      valid: false,
    });
  }, [selected]);

  const validateSingle = useCallback(labcode => {
    const errors = [];

    if (!labcode.length) return { valid: false, errors: [] };

    if (!REGEX_FORMAT.test(labcode)) errors.push(INVALID_FORMAT);

    return { valid: errors.length === 0, errors };
  }, []);

  const validate = useCallback(
    labcodes => {
      const startLabcode = labcodes[START] || '';
      const endLabcode = labcodes[END] || '';

      const startValidation = validateSingle(startLabcode);
      const endValidation = validateSingle(endLabcode);

      const errors = {
        [START]: startValidation.errors,
        [END]: [],
      };

      if (endLabcode.length > 0) errors[END] = endValidation.errors;

      // Combo Validation
      let validCombo = true;
      if (startLabcode.length > 0 && endLabcode.length > 0) {
        if (startLabcode[0].toLowerCase() !== endLabcode[0].toLowerCase()) {
          errors[END].push(MISMATCH_LETTER);
          validCombo = false;
        }
        if (startLabcode.length !== endLabcode.length) {
          errors[END].push(MISMATCH_LENGTH);
          validCombo = false;
        }
      }

      const startValid = startValidation.valid;
      const endValid = endLabcode.length > 0 ? endValidation.valid : startValid;
      const valid = startValid && endValid && validCombo;
      return { ...errors, valid };
    },
    [validateSingle]
  );

  useEffect(() => {
    const currentValidation = validate(data);
    setValidation(currentValidation);
  }, [data, validate]);

  const onSubmit = useCallback(() => {
    const dataSubmit = {
      [START]: data[START],
      [END]: data[END] || data[START],
    };
    onSearch({ type: 'labcodes', data: dataSubmit });
  }, [data, onSearch]);

  const onBlur = useCallback(
    e => {
      const { name } = e.target;
      const currentValidation = validate(data);
      setValidation(currentValidation);
      setTouched(t => ({ ...t, [name]: true }));
    },
    [data, validate]
  );

  const onLabcodesChange = useCallback(
    e => {
      const { name, value } = e.target;
      onChange({ type: 'labcodes', data: { ...data, [name]: value } });
    },
    [data, onChange]
  );

  return (
    <SearchForm onSubmit={onSubmit} noValidate>
      <label className="Search__label">
        <div className="Search__input">
          <input type="radio" checked={selected === 'labcodes'} onChange={() => onSelect('labcodes')} />
        </div>
        <div className="Search__input-label">{translate('labNumbers', 'Lab #s')}</div>
      </label>
      <Hider hidden={selected !== 'labcodes'}>
        <div className="Search__content">
          <div className="Search__content-label">{translate('from', 'from')}</div>
          <InputWrapper
            name={START}
            errors={validation[START]}
            touched={touched[START]}
            value={data[START] || ''}
            onBlur={onBlur}
            onChange={onLabcodesChange}
          />
          <div className="Search__content-label">{translate('to', 'to')}</div>
          <InputWrapper
            name={END}
            errors={validation[END]}
            touched={touched[END]}
            value={data[END] || ''}
            onBlur={onBlur}
            onChange={onLabcodesChange}
          />
        </div>
        <div className="Search__action">
          <Button success icon="search" disabled={loading || !validation.valid} type="submit" />
        </div>
      </Hider>
    </SearchForm>
  );
};

export default LabcodesSearch;
