import React from 'react';
import styled from 'styled-components';

const OneColumnTd = styled.td`
  div {
    text-align: center;
    font-size: 18px;
    padding: 110px 0;

    ${props => props.condensed && 'padding: 25px 0'};
  }
`;

const ErrorMessageTd = styled(OneColumnTd)`
  div {
    color: darkred;
  }
`;

const OneColumnRow = ({ text, error, colSpan, ...props }) => {
  const TdStyle = error ? ErrorMessageTd : OneColumnTd;
  return (
    <tr>
      <TdStyle colSpan={colSpan} {...props}>
        <div>{text}</div>
      </TdStyle>
    </tr>
  );
};

export default OneColumnRow;
