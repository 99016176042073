import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import { selectors as accountSelectors } from 'modules/account/details';
import { selectors as userSelectors } from 'modules/user/selectors';
import createTheme from 'style/theme';

const { Provider, Consumer: ThemeConsumer } = React.createContext();

class ThemeProvider extends PureComponent {
  getTheme = () => {
    const { accountDetails, userDetails } = this.props;
    if (!isEmpty(accountDetails)) {
      return createTheme({
        plp: get(accountDetails, 'plp.code'),
        program: get(accountDetails, 'program.mask'),
        styleKey: get(accountDetails, 'program.style'),
      });
    }

    return createTheme({
      applicationLogo: userDetails.applicationLogo,
      programLogo: userDetails.programLogo,
      styleKey: userDetails.style,
    });
  };

  render() {
    return <Provider value={this.getTheme()}>{this.props.children}</Provider>;
  }
}

const mapStateToProps = state => ({
  accountDetails: accountSelectors.details(state),
  userDetails: userSelectors.details(state),
});

const enhance = compose(withRouter, connect(mapStateToProps));

export { ThemeConsumer };
export default enhance(ThemeProvider);
