import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { UNSUBMITTED } from 'constants/orderStatuses';
import withAccountSettings from 'hoc/withAccountSettings';
import { checkPurchaseOrder } from 'lib/api';
import { actions as accountActions, selectors as accountSelectors } from 'modules/account/details';
import { selectors as accountSettingsSelectors } from 'modules/account/settings';
import {
  actions as shippingAddressActions,
  selectors as shippingAddressSelectors,
} from 'modules/account/shippingAddresses';
import { actions, selectors as checkoutSelectors } from 'modules/checkout';
import { actions as modalActions } from 'modules/modal';
import { selectors } from 'modules/order';
import { selectors as userSelectors } from 'modules/user';

import Checkout from './Checkout';

class CheckoutContainer extends Component {
  componentDidMount() {
    this.props.fetchBillingInfo(this.props.account.number);
  }

  focusPO = () => {
    document.getElementById('paymentPurchaseOrderNumber').focus();
  };

  placeOrder = async () => {
    const { purchaseOrderNumber } = this.props.payment;
    const { id: account_id } = this.props.account;

    if (purchaseOrderNumber.length) {
      const { orders } = await checkPurchaseOrder({ account_id, poNumber: purchaseOrderNumber });
      if (orders.length === 1) {
        return this.props.showModal('duplicatePO', {
          purchaseOrderNumber,
          onCancel: this.focusPO,
          onClose: this.props.placeOrder,
          locked: true,
        });
      }
    }

    this.props.placeOrder();
  };

  scrollToError = () => {
    window.scrollTo(0, 0);
  };

  checkValidity = () => {
    const { details, payment, paymentMethods, valid } = this.props;
    const isInvalid = !valid;
    const isInvalidStatus = details.status !== UNSUBMITTED;
    const isInvalidPayment = paymentMethods.error || !payment.valid;
    return ![isInvalid, isInvalidStatus, isInvalidPayment].some(v => v);
  };

  componentDidUpdate(prevProps) {
    if (isEqual(prevProps.confirmation, this.props.confirmation)) return;
    if (this.props.confirmation.error) this.scrollToError();
  }

  getChildProps() {
    return {
      ...this.props,
      ...this.state,
      placeOrder: this.placeOrder,
      accountLevel: get(this.props.account, 'accountLevel'),
      accountMask: get(this.props.account, 'program.mask'),
      sampleRushTesting: get(this.props.accountSettings, 'sample_rush_testing'),
    };
  }

  render() {
    return <Checkout {...this.getChildProps()} isValid={this.checkValidity()} />;
  }
}

const mapStateToProps = state => ({
  account: accountSelectors.details(state),
  accountSettings: accountSettingsSelectors.allSettings(state),
  accountLoading: accountSelectors.loading(state),
  address: selectors.address(state),
  billingInfo: accountSelectors.billingInfo(state),
  confirmation: checkoutSelectors.confirmation(state),
  details: selectors.summary(state),
  distributionPoint: selectors.distributionPoint(state),
  hasOrder: selectors.hasOrder(state),
  loading: selectors.loading(state),
  isCsr: userSelectors.isCsr(state),
  isCustomer: userSelectors.isCustomer(state),
  isInvoiceAddressAdd: shippingAddressSelectors.isInvoiceAddressAdd(state),
  items: selectors.items(state),
  payment: checkoutSelectors.payment(state),
  paymentMethod: checkoutSelectors.paymentMethod(state),
  paymentMethods: checkoutSelectors.paymentMethods(state),
  showAddressDialog: shippingAddressSelectors.showDialog(state),
  valid: checkoutSelectors.valid(state),
});

const mapDispatch = {
  closeAddressDialog: shippingAddressActions.closeAddressDialog,
  fetchBillingInfo: accountActions.fetchBillingInfo,
  openAddressDialog: shippingAddressActions.openAddressDialog,
  placeOrder: actions.placeOrder,
  showModal: modalActions.showModal,
};

const enhance = compose(
  withAccountSettings(['billing_option', 'blanket_po', 'shipper_preference', 'carrier_account', 'email_notification']),
  connect(mapStateToProps, mapDispatch)
);
export default enhance(CheckoutContainer);
