import find from 'lodash/find';
import some from 'lodash/some';
import moment from 'moment';
import business from 'moment-business';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import ShippingMethods from 'components/Checkout/ShippingMethods';
import { INVALID_ADDRESS } from 'constants/strings';
import socket from 'lib/socket';
import { selectors as settingsSelectors } from 'modules/account/settings';
import { actions as checkoutActions, selectors as checkoutSelectors } from 'modules/checkout';
import { actions as orderActions, selectors as orderSelectors } from 'modules/order';
import { actions as shippingActions, selectors as shippingSelectors } from 'modules/shipping/options';

class ShippingMethodsContainer extends PureComponent {
  state = {
    carrierAccounts: {},
  };

  componentDidMount() {
    const { address, fetchShippingRates } = this.props;

    if (address && address.country) {
      fetchShippingRates();
    }

    this.calculateEstimatedShipDate();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      address,
      allSettings: { carrier_account, shipper_preference },
      carriers,
      fetchShippingRates,
      shipping: { carrierAccount },
      shippingUpdate,
      shippingMethod,
    } = this.props;
    // TODO - POL-847 We need to move updateShippingCost to an action so we're not firing
    // random socket events from a bunch of containers

    let updateShipping = false;
    let costUpdate = 0;
    let defaultCostUpdate = 0;
    let carrierAccountUpdate = '';

    const setUpdateShipping = (price, carrierAccount, defaultCost) => {
      updateShipping = true;
      costUpdate = price;
      carrierAccountUpdate = carrierAccount;
      defaultCostUpdate = defaultCost;
    };

    if (address.country !== nextProps.address.country) {
      fetchShippingRates();
    }

    if (address.region !== nextProps.address.region) {
      const { address, orderId, shippingPrice } = nextProps.summary;
      const { currency } = nextProps;
      socket.emit('updateTotalSummary', { orderId, address, shippingPrice, currency });
    }

    const carriersUpdated = carriers.data.length === 0 && nextProps.carriers.data.length > 0;
    if (carriersUpdated) {
      let carrier = find(nextProps.carriers.data, { name: shipper_preference });
      if (!carrier) carrier = find(nextProps.carriers.data, { default: true });

      // Set initial carrierAccount on load carriers
      const currentCarrierAccount = carrier_account.toLowerCase() === 'none' ? '' : carrier_account;
      this.updateCarrierAccount(carrier.id, currentCarrierAccount);

      shippingUpdate({ shipperPreference: carrier.id });
      setUpdateShipping(carrier.methods[0].price, currentCarrierAccount, carrier.methods[0].defaultCost);
    }

    if (shippingMethod.id !== nextProps.shippingMethod.id) {
      shippingUpdate({ shippingMethod: nextProps.shippingMethod.id });
      setUpdateShipping(nextProps.shippingMethod.price, carrierAccount, nextProps.shippingMethod.defaultCost);
    }

    if (carrierAccount !== nextProps.shipping.carrierAccount && address.validity !== INVALID_ADDRESS) {
      setUpdateShipping(
        nextProps.shippingMethod.price,
        nextProps.shipping.carrierAccount,
        nextProps.shippingMethod.defaultCost
      );
    }

    if (updateShipping) this.updateShippingCost(costUpdate, carrierAccountUpdate, defaultCostUpdate);
  }

  calculateEstimatedShipDate = () => {
    const { summary } = this.props;
    const { items } = summary;

    const packSizeTotal = items.reduce((sum, item) => sum + item.packSize * item.quantity, 0);
    const isFreight = packSizeTotal > 1000;
    const isSplitBill = some(items, item => item.splitBill === 'Y');
    const isIncreasedShipping = isSplitBill || isFreight;
    this.props.shippingUpdate({
      shipDate: business.addWeekDays(moment(), isIncreasedShipping ? 6 : 3),
      isFreight,
      isSplitBill,
    });
  };

  filterDate = date => {
    const day = moment(date).day();
    return day !== 0 && day !== 6;
  };

  fieldUpdate = (field, val) => {
    let freightSelected = false;
    const {
      carriers: { data: carrierData },
    } = this.props;

    if (field === 'shipperPreference') {
      freightSelected = find(carrierData, { id: val }).freight;
      this.updateCarrierAccount(val, this.state.carrierAccounts[val]);
    }

    this.props.shippingUpdate({ [field]: val, freightSelected });
  };

  methodUpdate = (field, value) => {
    this.props.shippingUpdate({ [field]: parseInt(value, 10) });
  };

  updateCarrierAccount = (carrierId, carrierAccount = '') => {
    if (carrierId) {
      this.setState(state => ({
        carrierAccounts: {
          ...state.carrierAccounts,
          [carrierId]: carrierAccount,
        },
      }));
    }
    this.props.shippingUpdate({ carrierAccount });
  };

  updateShippingCost = (shippingPrice, carrierAccount) => {
    this.props.updateSummaryTotalPrices({ shippingPrice: carrierAccount === '' ? shippingPrice : 0 });
  };

  render() {
    return (
      <ShippingMethods
        {...this.props}
        fieldUpdate={this.fieldUpdate}
        filterDate={this.filterDate}
        methodUpdate={this.methodUpdate}
        updateCarrierAccount={this.updateCarrierAccount}
      />
    );
  }
}

const mapStateToProps = state => ({
  address: orderSelectors.address(state),
  allSettings: settingsSelectors.allSettings(state),
  carriers: shippingSelectors.carriers(state),
  currency: orderSelectors.currency(state),
  distributionPoint: orderSelectors.distributionPoint(state),
  selectedShippingCarrier: checkoutSelectors.selectedShippingCarrier(state),
  shipping: checkoutSelectors.shipping(state),
  shippingAddress: checkoutSelectors.shippingAddress(state),
  shippingMethods: checkoutSelectors.shippingMethods(state),
  shippingMethod: checkoutSelectors.shippingMethod(state),
  summary: orderSelectors.summary(state),
});

const mapDispatch = {
  fetchShippingRates: shippingActions.fetchShippingRates,
  shippingUpdate: checkoutActions.shippingUpdate,
  updateSummaryTotalPrices: orderActions.updateSummaryTotalPrices,
};

export default connect(mapStateToProps, mapDispatch)(ShippingMethodsContainer);
