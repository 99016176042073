import { createActions, handleActions } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { fetchReferenceData } from 'lib/api';
import { get, isFunction, pick } from 'utils/lodash';

import { DEFAULT_REFERENCE } from './selectors';

// State
const defaultState = {
  distributionPoints: [],
  labLocations: [],
  orderStatuses: DEFAULT_REFERENCE,
  privateLabels: [],
  shippingOptions: [],
};

// Selectors

// Actions
export const actions = createActions('FETCH_REFERENCE', 'FETCH_REFERENCE_RESPONSE');

// Sagas
export function* fetchReferenceSaga() {
  try {
    const { success, ...reference } = yield call(fetchReferenceData);
    yield put(actions.fetchReferenceResponse(reference));
  } catch (e) {
    // TODO - Add Toast Errors
    yield put(actions.fetchReferenceResponse(e));
  }
}

// Saga
export function* referenceSaga() {
  yield all([takeLatest(actions.fetchReference, fetchReferenceSaga)]);
}

// Helpers
const getState = (raw = [], key = 'key', label = 'name', additionalFields = []) => {
  return {
    options: raw.map(o => ({
      label: isFunction(label) ? label(o) : get(o, label),
      value: get(o, key),
      ...pick(o, additionalFields),
    })),
    raw,
  };
};

export { selectors } from './selectors';

// Reducer
export default handleActions(
  {
    [actions.fetchReferenceResponse]: {
      next(state, { payload: reference }) {
        return {
          ...state,
          ...reference,
          orderStatuses: getState(reference.orderStatuses),
        };
      },
      throw(state) {
        return state;
      },
    },
  },
  defaultState
);
