import styled from 'styled-components';

import { colors } from 'style/theme';

const Textarea = styled.textarea`
  border-radius: 3px;
  border: 1px solid ${colors.whiteAlto};
  display: ${props => (props.inline ? 'inline-flex' : 'block')};
  font-size: 14px;
  padding: 6px;
  width: ${props => (props.inline ? 'auto' : '100%')};
  min-height: 100px;
  ${props => (props.inline ? 'flex: 1 1 auto' : null)};
  ${props => (props.hasButton ? 'border-top-right-radius: 0' : null)};
  ${props => (props.hasButton ? 'border-bottom-right-radius: 0' : null)};
`;

export default Textarea;
