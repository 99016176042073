import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import SummaryTotal from 'components/Order/SummaryTotal';
import socket, { checkForSocket } from 'lib/socket';
import { actions as checkoutActions } from 'modules/checkout';
import { selectors as orderSelectors, actions as orderActions } from 'modules/order';

class SummaryTotalContainer extends PureComponent {
  componentDidMount() {
    this.registerSocketEvent();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      summary: { address, items, orderId, shippingPrice, total },
      currency,
    } = this.props;
    if (address && items && shippingPrice !== nextProps.summary.shippingPrice && total === nextProps.summary.total) {
      socket.emit('updateTotalSummary', { orderId, address, shippingPrice: nextProps.summary.shippingPrice, currency });
    }
  }

  componentWillUnmount() {
    socket.off('totalSummaryUpdated');
  }

  registerSocketEvent = () => {
    checkForSocket();
    socket.on('totalSummaryUpdated', data => {
      this.props.updateSummaryTotalPrices(data);
    });
  };

  render() {
    return <SummaryTotal {...this.props} />;
  }
}

const mapStateToProps = state => ({
  currency: orderSelectors.currency(state),
  summary: orderSelectors.summary(state),
});

const mapDispatch = {
  updateSummaryTotalPrices: orderActions.updateSummaryTotalPrices,
  placeOrder: checkoutActions.placeOrder,
};

export default connect(mapStateToProps, mapDispatch)(SummaryTotalContainer);
