import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { colors } from 'style/theme';
import { translate } from 'utils/translations';

const Content = styled.div`
  margin: 0 0 15px;
`;

const ToggleButton = styled.a`
  display: flex;
  padding: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: ${colors.blueWater};
`;

class ShowAllContainer extends PureComponent {
  static propTypes = {
    show: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      show: props.show || false,
    };
  }

  toggle = () => {
    this.setState(state => ({
      show: !state.show,
    }));
  };

  render() {
    const toggleText = this.state.show ? (
      <span>
        {translate('collapse')} <i className="fa fa-caret-up" />
      </span>
    ) : (
      <span>
        {translate('expand')} <i className="fa fa-caret-down" />
      </span>
    );

    return (
      <div>
        {this.state.show && <Content>{this.props.children}</Content>}
        <ToggleButton onClick={this.toggle}>{toggleText}</ToggleButton>
      </div>
    );
  }
}

export default ShowAllContainer;
