import React from 'react';

import { Breadcrumbs, PreviousOrders, Tabs } from 'components/Account';

const PreviousOrdersLayout = ({ match }) => {
  return (
    <>
      <Breadcrumbs />
      <Tabs />
      <PreviousOrders />
    </>
  );
};

export default PreviousOrdersLayout;
