import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Provider } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import AuthGate from 'components/AuthGate';
import { References } from 'components/Base';
import ErrorBoundary from 'components/ErrorBoundry';
import PageLayout from 'components/PageLayout';
import BaseLayout from 'layouts/Base.layout';
import store, { history } from 'lib/store';
import ThemeProvider from 'providers/ThemeProvider';
import BoilerplateStyle from 'style/boilerplate';
import GlobalStyle from 'style/global';
import NormalizeStyle from 'style/normalize';

const App = () => (
  <>
    <NormalizeStyle />
    <BoilerplateStyle />
    <GlobalStyle />
    <ErrorBoundary>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AuthGate>
            <ThemeProvider>
              <PageLayout>
                <Switch>
                  <Route path="/accounts" component={BaseLayout} />
                  <Redirect to="/accounts" />
                </Switch>
                <References />
              </PageLayout>
            </ThemeProvider>
          </AuthGate>
        </ConnectedRouter>
      </Provider>
    </ErrorBoundary>
  </>
);

export default App;
