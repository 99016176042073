import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import OverrideDialog from 'components/Checkout/OverrideDialog';
import { actions, selectors } from 'modules/checkout';
import { selectors as orderSelectors } from 'modules/order';

class OverrideDialogContainer extends PureComponent {
  componentDidMount() {
    this.props.createOverrideItems();
  }

  render() {
    return <OverrideDialog {...this.props} />;
  }
}

const mapStateToProps = state => ({
  currency: orderSelectors.currency(state),
  override: selectors.override(state),
});

const mapDispatch = {
  closeDialog: actions.closeOverrideDialog,
  createOverrideItems: actions.createOverrideItems,
  saveOverride: actions.saveOverride,
};

export default connect(mapStateToProps, mapDispatch)(OverrideDialogContainer);
