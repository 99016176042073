import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import ShippingAddress from 'components/Checkout/ShippingAddress';
import { actions as addressActions, selectors as addressSelectors } from 'modules/account/shippingAddresses';
import { actions, selectors } from 'modules/checkout';
import { selectors as orderSelectors } from 'modules/order';

class ShippingAddressContainer extends PureComponent {
  componentDidMount() {
    const { orderAddress } = this.props;
    this.props.shippingAddressUpdate({ phone_number: orderAddress.phone_number });
  }

  fieldUpdate = (field, val) => {
    this.props.shippingAddressUpdate({ [field]: val });
  };

  onEdit = address => this.props.openAddressDialog({ address });

  render() {
    return <ShippingAddress {...this.props} onEdit={this.onEdit} fieldUpdate={this.fieldUpdate} />;
  }
}

const mapStateToProps = state => ({
  orderAddress: orderSelectors.address(state),
  address: selectors.shippingAddress(state),
  showCandidatesDialog: addressSelectors.showCandidatesDialog(state),
  showDialog: addressSelectors.showDialog(state),
});

const mapDispatch = {
  closeAddressDialog: addressActions.closeAddressDialog,
  openAddressDialog: addressActions.openAddressDialog,
  shippingAddressUpdate: actions.shippingAddressUpdate,
};

export default connect(mapStateToProps, mapDispatch)(ShippingAddressContainer);
