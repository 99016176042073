import store from 'lib/store';
import stringf from 'utils/stringf';

const error = (key, defaultString = '', message = '') => {
  if (message.length) {
    console.error(message);
  }

  if (defaultString.length) {
    return `(!) ${defaultString}`;
  }

  return `{ ${key} }`;
};

export const translate = (key, defaultString = '', format = {}) => {
  const { translations } = store.getState();

  if (!translations.data) {
    return error(key, stringf(defaultString, format));
  }

  const keyRegex = key.replace(/[^a-zA-Z0-9._]/g, '');

  const translation = translations.data[`store.${keyRegex}`] || translations.data[keyRegex];

  return translation
    ? stringf(translation, format)
    : error(key, stringf(defaultString, format), `Translations: ${key} could not be found`);
};
