import React from 'react';
import { connect } from 'react-redux';

import AlertInfo from 'components/AlertInfo';
import Loader from 'components/Loader';
import { selectors } from 'modules/order';

const OrderContainer = ({ children, error, loading }) => {
  if (error) return <AlertInfo />;
  if (loading) return <Loader />;
  return children;
};

const mapState = state => ({
  error: selectors.error(state),
  loading: selectors.loading(state),
});

export default connect(mapState)(OrderContainer);
