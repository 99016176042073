const addressValidations = {
  company_name: { touched: false, valid: false, validation: ['minLength:1'] },
  address1: { touched: false, valid: false, validation: ['minLength:1'] },
  city: { touched: false, valid: false, validation: ['minLength:1'] },
  region: { touched: false, valid: false, validation: ['minLength:1'] },
  post_code: { touched: false, valid: false, validation: ['minLength:4'] },
  country: { touched: false, valid: false, validation: ['minLength:2'] },
};

export default addressValidations;
