import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { RadioListItem } from './RadioListItem';

const StyledList = styled.div`
  margin: 0 0 20px;

  &:last-child {
    margin: 0;
  }
`;

const RadioList = ({ name, options, value, onChange }) => {
  const onSelect = useCallback(
    (name, value) => {
      onChange(name, value);
    },
    [onChange]
  );

  return (
    <StyledList>
      {options.map(option => (
        <RadioListItem
          key={option.value}
          checked={option.value === value}
          data={option}
          name={name}
          onChange={onSelect}
        />
      ))}
    </StyledList>
  );
};

RadioList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export { RadioList };
