import get from 'lodash/get';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import LabeledInput from 'components/LabeledInput';
import Loader from 'components/Loader';
import { rushTestingOptions } from 'constants/options';
import { CC_REQUIRED, NOT_ALLOWED } from 'constants/strings';
import * as api from 'lib/api';
import { selectors as detailSelectors } from 'modules/account/details';
import { selectors as settingSelectors } from 'modules/account/settings';
import { selectors } from 'modules/user';
import { colors } from 'style/theme';
import parseTierByLevel from 'utils/parseTierByLevel';
import { translate } from 'utils/translations';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 260px;
  max-width: 325px;
  padding: 15px;
  font-size: 12px;
  background-color: ${colors.blueHawkes};
  border: 1px solid ${colors.blueColumbia};

  .Info__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 15px;

    &:last-child {
      margin: 0;
    }

    &--column {
      flex-flow: column nowrap;
    }
  }

  .Info__title {
    white-space: nowrap;
    color: ${colors.grayWarm};
    margin-right: 15px;
  }

  .Info__content {
    text-align: right;
  }
`;

const CsrPanel = ({ jiraOnly, jiraTicketNumber, updateJiraTicketNumber }) => {
  const [loading, setLoading] = useState(true);
  const [invoiceAccount, setInvoiceAccount] = useState({});
  const [invoiceRushTesting, setInvoiceRushTesting] = useState();
  const accountDetails = useSelector(detailSelectors.details);
  const accountSettings = useSelector(settingSelectors.allSettings);

  const rushTesting = get(accountSettings, 'sample_rush_testing');
  const openInvoiceLevel = get(accountSettings, 'invoice_level_open');
  const accountNumber = get(accountDetails, 'number');
  const accountNumberInvoice = get(invoiceAccount, 'number');
  const tiers = get(accountDetails, 'program.tiers', []);

  useEffect(() => {
    let isSubscribed = true;
    const fetchInvoiceAccount = async () => {
      const { invoiceAccount, sampleRushTesting } = await api.fetchAccountInvoice(accountNumber);

      if (isSubscribed) {
        setInvoiceAccount(invoiceAccount);
        setInvoiceRushTesting(sampleRushTesting);
        setLoading(false);
      }
    };

    if (accountNumber) fetchInvoiceAccount();

    return () => {
      isSubscribed = false;
    };
  }, [accountNumber]);

  const isCustomOption = rushTesting && !rushTestingOptions.includes(rushTesting);
  const isSelfInvoicing = accountNumber === accountNumberInvoice;
  const isApprovalNeeded = rushTesting === NOT_ALLOWED || isCustomOption;

  const showOpenInvoiceLevel = rushTesting && rushTesting !== CC_REQUIRED;
  const showInvoiceLevelRush = isApprovalNeeded && !isSelfInvoicing;

  const accountMask = useMemo(() => {
    if (!accountNumberInvoice || !openInvoiceLevel || !tiers.length) return '';

    const tier = parseTierByLevel(tiers, openInvoiceLevel);
    const maskLength = tier.start - 1 + tier.length;
    return `[${accountNumberInvoice.slice(0, maskLength)}]`;
  }, [accountNumberInvoice, openInvoiceLevel, tiers]);

  if (loading) {
    return (
      <InfoContainer>
        <Loader tiny />
      </InfoContainer>
    );
  }

  const renderRushTestingValue = value => {
    const isCustomOption = value && !rushTestingOptions.includes(value);
    const rushTestingValue = value || 'Setting Not Configured';

    return isCustomOption ? (
      <span className="text--alert">Approval Required From: {rushTestingValue}</span>
    ) : (
      rushTestingValue
    );
  };

  return (
    <InfoContainer>
      {updateJiraTicketNumber && (
        <div className="Info__box">
          <LabeledInput
            label={translate('shipping.jiraTicket', 'JIRA Ticket Number')}
            name="jiraTicketNumber"
            onChange={e => updateJiraTicketNumber(e.target.value)}
            style={{ display: 'block', width: '100%' }}
            value={jiraTicketNumber}
          />
        </div>
      )}
      {!jiraOnly && (
        <>
          <div className="Info__box">
            <div className="Info__title">Rush Testing</div>
            <div className="Info__content">{renderRushTestingValue(rushTesting)}</div>
          </div>
          {showOpenInvoiceLevel && (
            <div className="Info__box">
              <div className="Info__title">Open Invoice Level</div>
              <div className="Info__content">{`${openInvoiceLevel} ${accountMask}`}</div>
            </div>
          )}
          {showInvoiceLevelRush && (
            <div className="Info__box">
              <div className="Info__title">Invoice Level Rush Testing</div>
              <div className="Info__content">{renderRushTestingValue(invoiceRushTesting)}</div>
            </div>
          )}
        </>
      )}
    </InfoContainer>
  );
};

const GatedCsrPanel = props => {
  const isCsr = useSelector(selectors.isCsr);
  if (!isCsr) return null;
  return <CsrPanel {...props} />;
};

export default GatedCsrPanel;
