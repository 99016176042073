import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Button from 'components/Buttons';
import { actions } from 'modules/modal';
import { noop, isFunction } from 'utils/lodash';

import { propTypes, defaultProps } from './ModalWrapper.statics';
import StyledModal, { ModalActions, ModalBackdrop } from './ModalWrapper.styled';

class ModalWrapper extends PureComponent {
  static defaultProps = defaultProps;
  static propTypes = propTypes;

  // Wrap Action with close event
  withClose =
    (action, useReduxCloseAction = false) =>
    () => {
      const { id, close, payload } = this.props;
      const modalAction = isFunction(action) ? action : noop;

      if (useReduxCloseAction) {
        modalAction(payload);
      } else {
        modalAction(payload);
        close(id);
      }
    };

  getCloseEvent = () => {
    const { cancelButton, onClose, onCancel, useCloseAction, useCancelAction } = this.props;
    return cancelButton ? this.withClose(onCancel, useCancelAction) : this.withClose(onClose, useCloseAction);
  };

  handleBackgroundClick = e => {
    if (e.target === e.currentTarget) {
      const closeEvent = this.getCloseEvent();
      closeEvent();
    }
  };

  handleKeyPress = ({ keyCode }) => {
    if (this.props.locked) return;
    if (keyCode === 27) {
      const closeEvent = this.getCloseEvent();
      closeEvent();
    }
  };

  getButtonType = (type = '') => ({
    alert: type === 'alert',
    default: type === 'default',
    info: type === 'info',
    primary: type === 'primary',
    success: type === 'success',
  });

  componentDidMount() {
    document.addEventListener('keyup', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyPress);
  }

  render() {
    const {
      id,
      buttons,
      children,
      cancelIcon,
      closeIcon,
      cancelText,
      closeText,
      cancelButton,
      closeButton,
      cancelType,
      useCloseAction,
      useCancelAction,
      locked,
      title,
      type,
      width,
      valid,
      onCancel,
      onClose,
    } = this.props;

    const modalButtons = [];

    if (cancelButton) {
      modalButtons.push({
        action: onCancel,
        icon: cancelIcon,
        useReduxAction: useCancelAction,
        text: cancelText,
        type: cancelType,
      });
    }

    if (closeButton) {
      modalButtons.push({
        action: onClose,
        icon: closeIcon,
        useReduxAction: useCloseAction,
        text: closeText,
        type: cancelButton ? type || 'primary' : 'default',
        disabled: !valid,
      });
    }

    const modalActions = [...buttons, ...modalButtons];

    return (
      <ModalBackdrop key={id} onClick={!locked ? this.handleBackgroundClick : noop}>
        <StyledModal style={{ width: width ? `${width}px` : '100%' }} type={type}>
          {!locked && (
            <div className="Modal__close" onClick={this.withClose(onCancel, useCancelAction)}>
              <i className="fa fa-times" />
            </div>
          )}
          {title && <div className="Modal__header">{title}</div>}
          <div className="Modal__content">{children}</div>
          <ModalActions length={modalActions.length}>
            {modalActions.map(({ action, type, text, useReduxAction, ...props }, i) => (
              <Button
                className="Modal__action"
                key={i}
                onClick={this.withClose(action, useReduxAction)}
                {...this.getButtonType(type)}
                {...props}
              >
                {text}
              </Button>
            ))}
          </ModalActions>
        </StyledModal>
      </ModalBackdrop>
    );
  }
}

export default connect(null, {
  close: actions.closeModal,
})(ModalWrapper);
