import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Alert from 'components/Alert';
import { ActionButton } from 'components/Buttons';
import Currency from 'components/Currency';
import Image from 'components/Image';
import ListTable from 'components/ListTable';
import OneColumnRow from 'components/OneColumnRow';
import { colors } from 'style/theme';
import { renderDescription } from 'utils/display';
import { translate } from 'utils/translations';

const Item = styled.tr`
  td {
    padding: 8px 15px;
  }

  .Item__content {
    display: flex;
    flex-flow: row nowrap;
  }

  .Item__image {
    display: inline-flex;
    align-items: center;
    flex: 0 0 auto;
    width: 100px;
    min-height: 100px;
    background: ${colors.whiteAlto};
    margin-right: 15px;

    img {
      width: 100px;
      height: auto;
    }
  }

  .Item__title,
  .Item__number,
  .Item__subtitle {
    font-size: 14px;
  }

  .Item__title {
    font-weight: 700;
  }

  .Item__subtitle {
    margin: 0 0 5px;
  }

  .Item__kit-contents,
  .Item__content {
    margin: 10px 0 10px;

    &:last-child {
      margin: 0;
    }
  }
`;

const CartList = ({
  currency,
  orderItemNotes: { itemsMissing, itemsUpdated },
  summary: { items } = {},
  reportAddress,
  language,
  onRemove = noop,
  loading = false,
}) => {
  const renderImage = ({ featuredImage }) => {
    if (!featuredImage) return null;

    return (
      <div className="Item__image">
        <Image src={featuredImage.url} alt={featuredImage.alt} />
      </div>
    );
  };

  return (
    <>
      {itemsMissing.length > 0 && (
        <Alert alert>
          <p>
            {translate(
              'store.missingItemsMessage',
              // eslint-disable-next-line max-len
              'There are some items in the previous order that no longer exist. These items have been removed from the new order.'
            )}
          </p>
          <p>
            <strong>{translate('store.missingItemsTitle', 'Items Removed')}:</strong>
          </p>
          <ul>
            {itemsMissing.map(item => (
              <li key={item.id}>{renderDescription(item, language)}</li>
            ))}
          </ul>
        </Alert>
      )}
      {itemsUpdated.length > 0 && (
        <Alert alert>
          <p>
            {translate(
              'store.updatedItemsMessage',
              // eslint-disable-next-line max-len
              'There are some items in the previous order that have changed. These items have been kept in the order, but you can remove them from the cart below if you no longer want them.'
            )}
          </p>
          <p>
            <strong>{translate('store.updatedItemsTitle', 'Items Updated')}:</strong>
          </p>
          <ul>
            {itemsUpdated.map(item => (
              <li key={item.id}>
                {renderDescription(item, language)} ({translate('store.updatedItemsPreviously', 'Previously')}{' '}
                {renderDescription(item.originalItem, language)})
              </li>
            ))}
          </ul>
        </Alert>
      )}
      <ListTable>
        <thead>
          <tr>
            <th>{translate('cart.description', 'Description')}</th>
            <th>{translate('cart.price', 'Price')}</th>
            <th>{translate('cart.quantity', 'Quantity')}</th>
            <th>{translate('cart.totalPrice', 'Total Price')}</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {loading && <OneColumnRow colSpan="5" text={`${translate('loading')}...`} />}
          {items && !items.length && <OneColumnRow colSpan="5" text={translate('cart.empty', 'Cart is Empty')} />}
          {items &&
            items.length > 0 &&
            items.map((item, i) => (
              <Item key={item.id}>
                <td>
                  <div className="Item__content">
                    {renderImage(item)}
                    <div className="Item__details">
                      {item.items && item.items.length > 0 ? (
                        <>
                          <div className="Item__title">{item.name}</div>
                          <div className="Item__number">{item.actual_item_number}</div>
                          <div className="Item__subtitle">
                            ({item.packSize})&nbsp;{item.actual_description}
                          </div>
                          <div className="Item__kit-contents">
                            {item.items.map(kitItem => (
                              <div key={kitItem.inventory_id}>
                                ({kitItem.quantity}) {renderDescription(kitItem, language)}
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="Item__title">{item.actual_item_number}</div>
                          <div className="Item__subtitle">{item.actual_description}</div>
                          <div className="Item__content">
                            <div key={`${item.productListItemId}-${i}`}>
                              ({item.quantity}) {item.actual_description}
                            </div>
                          </div>
                        </>
                      )}
                      {item.prefillLabel && (
                        <div>
                          <strong>{translate('cart.prefillLabel')}:&nbsp;</strong>
                          {reportAddress.attention && <span>{reportAddress.attention},&nbsp;</span>}
                          <span>{reportAddress.address1},&nbsp;</span>
                          {reportAddress.address2 && <span>{reportAddress.address2},&nbsp;</span>}
                          <span>
                            {reportAddress.city}, {reportAddress.region} {reportAddress.post_code}
                          </span>
                        </div>
                      )}
                      {item.labelsOnly && (
                        <div>
                          <strong>Labels Only</strong>
                        </div>
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  <Currency quantity={item.price} currency={currency} />
                </td>
                <td>{item.quantity}</td>
                <td>
                  <strong>
                    <Currency quantity={item.totalPrice} currency={currency} />
                  </strong>
                </td>
                <td>
                  <ActionButton background={colors.redFlamingo} title="Remove Item" onClick={() => onRemove(item.id)}>
                    <i className="fa fa-times" />
                  </ActionButton>
                </td>
              </Item>
            ))}
        </tbody>
      </ListTable>
    </>
  );
};

CartList.propTypes = {
  summary: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            description: PropTypes.string,
            quantity: PropTypes.number.isRequired,
          })
        ),
        prefillLabel: PropTypes.bool,
        quantity: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        totalPrice: PropTypes.number.isRequired,
      })
    ).isRequired,
  }),
  onRemove: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default CartList;
