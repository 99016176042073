import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import Button from 'components/Buttons';
import Currency from 'components/Currency';
import Loader from 'components/Loader';
import PanelContainer from 'components/Panel';
import RowItem from 'components/RowItem';
import CATEGORIES, { PREPAID_KIT_WITH_JAR } from 'constants/categories';
import { KITS, GALLERY_IMAGE, GALLERY_VIDEO } from 'constants/strings';
import { colors } from 'style/theme';
import { translate } from 'utils/translations';

import ContentsList from './ContentsList';
import ProductGallery from './ProductGallery';

const Details = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;

  .Details__gallery {
    width: 225px;
    margin-right: 30px;
  }

  .Details__content {
    flex: 1 1 auto;
  }

  .Details__title {
    margin: 0;
  }

  .Details__actions {
    width: 225px;
    margin-left: 30px;
  }

  h3,
  .desc,
  .contents {
    margin: 1rem 0 0;
  }

  h3 {
    font-size: 18px;
    font-weight: normal;
  }

  .desc {
    font-size: 12px;
  }
`;

const InputRoot = styled.label`
  font-size: 12px;
  color: ${colors.grayWarm};

  input {
    margin-left: 12px;
    width: 60px;
    border-radius: 3px;
    padding: 8px;
    border: 1px solid ${colors.whiteAlto};
  }
`;

const PrefillLabelLine = styled.div`
  margin-top: 20px;
  display: flex;

  > label {
    flex: none;

    input {
      margin-right: 5px;
    }
  }

  > div {
    display: flex;

    .actInfoLabel {
      color: ${colors.grayWarm};
      margin: 0 10px 0 20px;
      flex: none;
    }

    > div:nth-child(2) {
      flex: 1 1;
    }
  }
`;

const labelsOnlyBtnStyle = {
  display: 'inline-block',
  width: 'auto',
  padding: '5px 8px 4px',
  fontSize: 12,
  marginTop: 20,
};

const QtyInput = ({ value, onChange }) => (
  <InputRoot>
    <input type="number" min="1" value={value} onChange={onChange} />
  </InputRoot>
);

const ProductDetails = ({
  accountNumber,
  currency,
  history,
  labelsOnly,
  language,
  loading,
  prefillLabel,
  product = {},
  quantity = 1,
  reportAddress,
  substituteOptions,
  substitutedItems,
  isCartMessageVisible,
  isCartAddDisabled,
  onAddItem,
  onQuantityChange,
  onSubstituteItemChange,
  onToggleLabelsOnly,
  onTogglePrefillLabel,
}) => {
  if (loading) return <Loader small />;

  const {
    display_item_number: displayItemNumber,
    category,
    images,
    itemUpdate,
    item_number: itemNumber,
    item_type: itemType,
    items,
    name,
    pack_size: packSize,
    prepaidTesting,
    price,
    productListItemPrice,
  } = product;
  const {
    long_description: longDescription,
    rich_description: longDescriptionRaw,
    video_url: videoUrl,
  } = itemUpdate || {};

  const hasDescriptions = product.display_description || product.descriptions;
  const hasLongDescriptions = longDescriptionRaw || longDescription;
  const hasMedia = images && images.length > 0;
  const hasItems = items && items.length > 0;
  const isTaxExempt = category === PREPAID_KIT_WITH_JAR;
  const itemPrice = itemType === KITS ? price : productListItemPrice;

  const getGalleryVideos = (kitVideo, items = []) => {
    const inventoryVideos = items
      .map(item => {
        const { itemUpdate } = item;
        const { video_url } = itemUpdate;
        return video_url;
      })
      .filter(Boolean);

    return kitVideo ? [kitVideo, ...inventoryVideos] : inventoryVideos;
  };

  const getGalleryData = (images, videos) => {
    const parsedImages = images.map(img => ({ ...img, type: GALLERY_IMAGE }));
    const parsedVideos = videos.map(videoUrl => ({
      id: `video:${videoUrl}`,
      type: GALLERY_VIDEO,
      url: videoUrl,
      alt: videoUrl,
    }));

    return parsedVideos.length > 0 ? [...parsedImages, ...parsedVideos] : parsedImages;
  };

  const renderLongDescription = (longDescription, longDescriptionRaw) => {
    if (longDescriptionRaw) return <div dangerouslySetInnerHTML={{ __html: longDescriptionRaw }} />;
    return <p>{longDescription}</p>;
  };

  const renderCartButtons = () => {
    if (isCartMessageVisible) {
      return (
        <>
          <RowItem>
            <Button
              icon="shopping-cart"
              success
              onClick={() => history.push(`/accounts/${accountNumber}/order/cart`)}
              disabled={!quantity || quantity < 1}
            >
              {translate('cart.viewCartAndCheckout', 'View Cart & Checkout')}
            </Button>
          </RowItem>
          <RowItem>
            <Button icon="arrow-right" onClick={() => history.push(`/accounts/${accountNumber}/order/kits`)}>
              {translate('cart.continueShopping', 'Continue Shopping')}
            </Button>
          </RowItem>
        </>
      );
    } else {
      return (
        <>
          <RowItem>
            <Button
              icon="shopping-cart"
              success
              onClick={onAddItem}
              disabled={!quantity || quantity < 1 || isCartAddDisabled}
            >
              {translate('addToCart', 'Add to Cart')}
            </Button>
          </RowItem>
        </>
      );
    }
  };

  const videos = getGalleryVideos(videoUrl, items);

  return (
    <Details>
      {hasMedia && (
        <div className="Details__gallery">
          <ProductGallery data={getGalleryData(images, videos)} />
        </div>
      )}
      <div className="Details__content">
        <h3 className="Details__title">{name}</h3>
        {hasLongDescriptions && renderLongDescription(longDescription, longDescriptionRaw)}
        {hasItems && (
          <div className="contents">
            <p>{translate('willReceive')}:</p>
            <ContentsList
              kitCategory={category}
              contents={items}
              currency={currency}
              isTaxExempt={isTaxExempt}
              productListItemPrice={productListItemPrice}
              itemNumber={itemNumber}
              labelsOnly={labelsOnly}
              language={language}
              hasDescriptions={hasDescriptions}
              packSize={packSize}
              prepaidTesting={prepaidTesting}
              product={product}
              substituteItemChange={onSubstituteItemChange}
              substituteOptions={substituteOptions}
              substitutedItems={substitutedItems}
            />
          </div>
        )}
        {CATEGORIES.kits.includes(category) && (
          <div>
            {!isTaxExempt && (
              <Button key="labels-only-button" style={labelsOnlyBtnStyle} onClick={onToggleLabelsOnly}>
                {labelsOnly
                  ? translate('orderFullKit', 'Order Full Kit')
                  : translate('orderLabelsOnly', 'Order Labels Only')}
              </Button>
            )}
            <PrefillLabelLine key="prefill-label">
              <label>
                <input type="checkbox" onChange={onTogglePrefillLabel} checked={prefillLabel} />{' '}
                {translate('prefillLabel', 'Prefill label')}
              </label>
              {prefillLabel && (
                <div>
                  <div className="actInfoLabel">{translate('accountInfo', 'Account Information')}:</div>
                  <div>
                    {reportAddress.attention && <div>{reportAddress.attention}</div>}
                    {reportAddress.address1 && <div>{reportAddress.address1}</div>}
                    {reportAddress.address2 && <div>{reportAddress.address2}</div>}
                    <div>
                      {reportAddress.city && <span>{reportAddress.city}, &nbsp;</span>}
                      {reportAddress.region && <span>{reportAddress.region}, &nbsp;</span>}
                      {reportAddress.post_code && <span>{reportAddress.post_code}</span>}
                    </div>
                    {reportAddress.country && <div>{reportAddress.country}</div>}
                  </div>
                </div>
              )}
            </PrefillLabelLine>
          </div>
        )}
      </div>
      <div className="Details__actions">
        <PanelContainer>
          <RowItem title={translate('itemNumber')} right>
            {displayItemNumber || itemNumber || ''}
          </RowItem>
          <RowItem title={translate('price')} right>
            <Currency quantity={itemPrice || 0} currency={currency} />
          </RowItem>
          <RowItem title={translate('cart.totalPrice')} right>
            <Currency quantity={itemPrice * quantity} currency={currency} />
          </RowItem>
          <hr />
          <RowItem title={translate('qty')} center right>
            <QtyInput value={quantity} onChange={onQuantityChange} />
          </RowItem>
          {renderCartButtons()}
        </PanelContainer>
      </div>
    </Details>
  );
};

export default withRouter(ProductDetails);
