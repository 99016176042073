import { call, put } from 'redux-saga/effects';

import * as api from 'lib/api';

import { actions } from './actions';

export function* fetchTranslationsSaga() {
  try {
    const { translations } = yield call(api.fetchTranslations);

    if (translations) {
      yield put(actions.translationsResponse(translations));
    }
  } catch (e) {
    yield put(actions.translationsResponse(e));
  }
}
