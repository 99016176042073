import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

// Styles here for Storybook
import 'react-datepicker/dist/react-datepicker.css';

import { colors } from 'style/theme';

const Root = styled.div`
  position: relative;
  width: ${props => (props.width ? `${props.width}px` : '100%')};

  .LabeledDatePicker__label {
    font-size: 12px;
    color: ${colors.grayEmperor};
    margin-bottom: 5px;
    span {
      color: #959595;
    }
  }

  .LabeledDatePicker__input {
    border-radius: 3px;
    border: 1px solid ${colors.whiteAlto};
    display: ${props => (props.inline ? 'flex' : 'block')};
    font-size: 14px;
    padding: 6px;
    width: ${props => (props.inline ? '1%' : '100%')};
    ${props => (props.inline ? 'flex: 1 1 auto' : null)};
    ${props => (props.hasButton ? 'border-top-right-radius: 0' : null)};
    ${props => (props.hasButton ? 'border-bottom-right-radius: 0' : null)};
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }

  .LabeledInput__validation {
    color: darkred;
    font-size: 12px;
    position: absolute;
    bottom: -1.2rem;
  }
`;

const LabeledDatePicker = ({ label, style, width, labelStyle, ...props }) => (
  <Root style={style}>
    <label onClick={e => e.preventDefault()}>
      {label && (
        <div className="LabeledDatePicker__label" style={labelStyle}>
          {label}
        </div>
      )}
      <DatePicker className="LabeledDatePicker__input" {...props} />
    </label>
  </Root>
);

LabeledDatePicker.propTypes = {
  invalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default LabeledDatePicker;
