import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AccountContainer, AccountNotesPopout } from 'components/Account';

import Order from '../Order.layout';

import Details from './Details.layout';
import PreviousOrder from './PreviousOrder.layout';
import PreviousOrders from './PreviousOrders.layout';
import RecurringOrders from './RecurringOrders.layout';
import RushSample from './RushSample.layout';
import RushSampleCheckout from './RushSampleCheckout.layout';
import RushSampleOrder from './RushSampleOrder.layout';

const AccountDetailsLayout = ({ match }) => {
  const { accountNumber } = match.params;

  return (
    <AccountContainer>
      <Switch>
        <Route exact path="/accounts/:accountNumber/details" component={Details} />
        <Route exact path="/accounts/:accountNumber/orders" component={PreviousOrders} />
        <Route exact path="/accounts/:accountNumber/orders/:orderId" component={PreviousOrder} />
        <Route exact path="/accounts/:accountNumber/recurring-orders" component={RecurringOrders} />
        <Route exact path="/accounts/:accountNumber/rush-sample" component={RushSample} />
        <Route exact path="/accounts/:accountNumber/rush-sample/checkout" component={RushSampleCheckout} />
        <Route exact path="/accounts/:accountNumber/rush-sample/confirmation" component={RushSampleOrder} />
        <Route exact path="/accounts/:accountNumber/rush-sample/:orderId" component={RushSampleOrder} />
        <Route path="/accounts/:accountNumber/order" component={Order} />
        <Redirect to={`/accounts/${accountNumber}/details`} />
      </Switch>
      <AccountNotesPopout />
    </AccountContainer>
  );
};

export default AccountDetailsLayout;
