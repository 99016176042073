import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { translate } from 'utils/translations';

const StyledHeader = styled.div`
  display: flex;
  height: 100px;
  align-items: stretch;

  .Header__branding {
    display: inline-flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
    height: auto !important;
    width: auto !important;
  }

  .Header__content {
    flex: 1 1 auto;
    align-self: flex-start;
    font-size: 11px;
    padding: 15px;
    text-align: right;
  }

  .Header__logo {
    height: 80px;
    width: auto;
  }

  .menuItem {
    padding: 0 4px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  i {
    margin-left: 4px;
    margin-right: 4px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const Header = ({ accountNumber, order, itemCount = 0, user = {}, onLogout, applicationLogo, programLogo }) => {
  return (
    <StyledHeader>
      <div className="Header__branding leftCol">
        <img src={programLogo} alt="Brand Logo" className="Header__logo" />
      </div>
      <div className="Header__content">
        <span className="menuItem">
          <Link to="/accounts">{`${translate('welcome')}, ${user.name}`}</Link>
        </span>
        <span className="menuItem">
          <a style={{ cursor: 'pointer ' }} href="mailto:custserv@eoilreports.com">
            {translate('contactUs', 'Contact Us')}
          </a>
        </span>
        {order && (
          <span className="menuItem">
            <Link to={`/accounts/${accountNumber}/order/cart`}>
              <i className="fa fa-shopping-cart" />
              {translate('cart')}
              {itemCount > 0 && ` (${itemCount})`}
            </Link>
          </span>
        )}
      </div>
      <div className="Header__branding">
        <img src={applicationLogo} alt="Brand Logo" className="Header__logo" />
      </div>
    </StyledHeader>
  );
};

export default Header;
