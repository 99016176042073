import React from 'react';
import styled from 'styled-components';

import { colors } from 'style/theme';

const StyledLabel = styled.div`
  display: inline;
  padding: 0.2em 0.6em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  background: ${colors.grayEmperor};
  color: ${colors.white};
  text-align: center;
  vertical-align: baseline;
  border-radius: 3px;
  margin: 0 3px;

  ${props => props.alert && `background: ${colors.redBrick}`};
  ${props => props.info && `background: ${colors.blueCuriousBlue}`};
  ${props => props.success && `background: ${colors.greenMantis}`};
  ${props => props.warning && `background: ${colors.orangeCarmine}`};

  ${props => props.csr && `background: ${colors.blueHawkes}`};
  ${props => props.csr && `color: ${colors.black}`};

  ${props => props.large && 'font-size: 85%'};
  ${props => props.large && 'padding: 0.4em 0.6em'};
`;

const Label = props => <StyledLabel {...props}>{props.children}</StyledLabel>;

export default Label;
