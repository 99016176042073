import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { SortButton } from 'components/Buttons';
import { LabeledSelect } from 'components/LabeledInput';

export const Root = styled.div`
  position: relative;

  .Select-control,
  .Select-input {
    height: 30px;
  }

  .Select-input > input {
    line-height: 15px;
  }

  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    line-height: 30px;
  }
`;

const SearchSortSelect = ({
  label,
  options,
  sortDirection,
  searchHandler = noop,
  searchQuery = '',
  toggleHandler,
  toggleIsActive,
  ...props
}) => (
  <Root>
    <SortButton isActive={toggleIsActive} sortDirection={sortDirection} toggle={toggleHandler} />
    <LabeledSelect label={label} value={searchQuery} options={options} onChange={searchHandler} {...props} />
  </Root>
);

SearchSortSelect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  searchHandler: PropTypes.func,
  searchQuery: PropTypes.string,
  sortDirection: PropTypes.string,
  toggleHandler: PropTypes.func.isRequired,
  toggleIsActive: PropTypes.bool,
};

export default SearchSortSelect;
