import { connect } from 'react-redux';

import { makeNotesSelector, actions } from 'modules/account/notes';

import AddNoteButton from './AddNoteButton';

const makeMapState = () => {
  const notesSelector = makeNotesSelector();

  // mapState
  return (state, props) => {
    const notes = notesSelector(state, props);
    return { disabled: notes.length >= 3 };
  };
};

const mapDispatch = { addNote: actions.addNote };

export default connect(makeMapState, mapDispatch)(AddNoteButton);
