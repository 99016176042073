// TODO - Logic in Here and Labcode Search and shared with Rush Sample
// can be simplified into smaller compoonents
// to prevent duplicating validation logic eventually

import React, { useState, useCallback, useEffect } from 'react';

import Button from 'components/Buttons';
import Errors from 'components/Errors';
import Hider from 'components/Hider';
import Input from 'components/Input';
import { translate } from 'utils/translations';

import SearchForm from './SearchForm';

const VALID_LENGTH = 11;
const VALID_LETTER_POSITIONS = [0, 5];
const INVALID_LETTER = 'Only 1st & 6th can be letters';
const INVALID_FORMAT = 'Invalid Barcode Format';

const REGEX_FORMAT_ONE = /^\d{5}(?![io])[a-z]{1}\d{5}$/i;
const REGEX_FORMAT_TWO = /^\d{11}$/i;
const REGEX_FORMAT_THREE = /^[a-z]{1}\d{10}$/i;

const validFormatOne = (barcode = '') => REGEX_FORMAT_ONE.test(barcode);
const validFormatTwo = (barcode = '') => REGEX_FORMAT_TWO.test(barcode);
const validFormatThree = (barcode = '') => REGEX_FORMAT_THREE.test(barcode);
const validLength = barcode => barcode.length === VALID_LENGTH;
const validLetters = (barcode = '') => {
  return !barcode.split('').some((char, i) => {
    const isNumberPosition = !VALID_LETTER_POSITIONS.includes(i);
    const isLetter = char.match(/[a-z]/i);

    if (isNumberPosition && isLetter) return true;
    return false;
  });
};

const InputWrapper = ({ errors, ...props }) => (
  <div style={{ position: 'relative' }}>
    <Input type="text" invalid={errors.length > 0} inline {...props} />
    {errors.length > 0 && <Errors data={errors} />}
  </div>
);

const BarcodeSearch = ({ selected, setSelected, onSearch }) => {
  const [barcode, setBarcode] = useState('');
  const [touched, setTouched] = useState(false);
  const [validation, setValidation] = useState({ valid: false, errors: [] });

  useEffect(() => {
    setBarcode('');
    setTouched(false);
    setValidation({ valid: false, errors: [] });
  }, [selected]);

  const validate = useCallback((barcode, includeErrors = true) => {
    const errors = [];

    if (!barcode.length) return { valid: false, errors };

    if (!validLetters(barcode)) errors.push(translate('onlyOneAndSixthAreLetters', INVALID_LETTER));

    if (!validLength(barcode)) {
      errors.push(translate('validationMinLength', `${VALID_LENGTH} character minimum`, { len: VALID_LENGTH }));
    }

    const validFormats = validFormatOne(barcode) || validFormatTwo(barcode) || validFormatThree(barcode);
    if (!validFormats) errors.push(translate('invalidBarcodeFormat', INVALID_FORMAT));

    return { valid: errors.length === 0, errors: includeErrors ? errors : [] };
  }, []);

  useEffect(() => {
    const currentValidation = validate(barcode, touched);
    setValidation(currentValidation);
  }, [barcode, touched, validate]);

  const onSubmit = useCallback(() => {
    onSearch({ type: 'barcode', data: barcode });
  }, [barcode, onSearch]);

  const onBlur = useCallback(
    e => {
      const currentValidation = validate(e.target.value);
      setValidation(currentValidation);
      setTouched(true);
    },
    [validate]
  );

  const onChange = useCallback(e => {
    setBarcode(e.target.value);
  }, []);

  return (
    <SearchForm onSubmit={onSubmit} noValidate>
      <div className="Search__input">
        <input type="radio" checked={selected === 'barcode'} onChange={() => setSelected('barcode')} />
      </div>
      <div className="Search__label">Barcode</div>
      <Hider hidden={selected !== 'barcode'}>
        <div className="Search__content">
          <InputWrapper
            errors={validation.errors}
            value={barcode}
            maxLength={VALID_LENGTH}
            onBlur={onBlur}
            onChange={onChange}
          />
        </div>
        <div className="Search__action">
          <Button success icon="search" disabled={!validation.valid} type="submit" />
        </div>
      </Hider>
    </SearchForm>
  );
};

export default BarcodeSearch;
