import React from 'react';
import styled from 'styled-components';

import Error from 'components/Error';

const StyledValidation = styled.div`
  margin: 0 0 15px;
`;

const RushSampleValidation = ({ message }) => {
  if (!message) return null;

  return (
    <StyledValidation>
      <Error>{message}</Error>
    </StyledValidation>
  );
};
export default RushSampleValidation;
