import get from 'lodash/get';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import { handleActions } from 'redux-actions';
import { all, takeLatest, call, select, put } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import * as api from 'lib/api';
import { actions as detailsActions } from 'modules/account/details/actions';
import { selectors as detailsSelectors } from 'modules/account/details/selectors';
import { replaceItem, removeItem } from 'utils/array';

import { actions } from './actions';

// default state
const defaultState = {
  candidates: [],
  data: [],
  dialogAddress: {},
  error: false,
  errorMessage: null,
  loading: true,
  selectedAddressId: null,
  showCandidatesDialog: false,
  showDialog: false,
  showValidationErrors: false,
  unvalidatedAddress: {},
};

// selectors
export const selectors = {
  candidates: state => get(state, 'account.shippingAddresses.candidates', []),
  data: state => get(state, 'account.shippingAddresses.data', []),
  dialogAddress: state => get(state, 'account.shippingAddresses.dialogAddress', {}),
  error: state => get(state, 'account.shippingAddresses.error'),
  errorMessage: state => get(state, 'account.shippingAddresses.errorMessage'),
  isInvoiceAddressAdd: state => get(state, 'account.shippingAddresses.isInvoiceAddressAdd'),
  loading: state => get(state, 'account.shippingAddresses.loading'),
  selectedAddressId: state => get(state, 'account.shippingAddresses.selectedAddressId'),
  showDialog: state => get(state, 'account.shippingAddresses.showDialog'),
  showCandidatesDialog: state => get(state, 'account.shippingAddresses.showCandidatesDialog'),
  showValidationErrors: state => get(state, 'account.shippingAddresses.showValidationErrors'),
  unvalidatedAddress: state => get(state, 'account.shippingAddresses.unvalidatedAddress', {}),
};

selectors.addresses = createSelector(
  [selectors.data, detailsSelectors.defaultAddressId],
  (addresses, defaultAddressId) => {
    const defaultAddress = addresses.filter(address => address.id === defaultAddressId) || [];
    const remainingAddresses = addresses.filter(address => address.id !== defaultAddressId);
    const sortedAddresses = sortBy(remainingAddresses, ['updated_at']).reverse();
    return [...defaultAddress, ...sortedAddresses];
  }
);

// sagas
function* deleteAddressSaga({ payload: id }) {
  try {
    const defaultAddressId = yield select(detailsSelectors.defaultAddressId);
    const selectedAddressId = yield select(selectors.selectedAddressId);

    const { success } = yield call(api.deleteShippingAddress, id);
    if (success) {
      yield put(actions.deleteAddressResponse(id));
      if (id === selectedAddressId) yield put(actions.setSelectedAddress(defaultAddressId));
    }
  } catch (e) {
    yield put(actions.deleteAddressResponse(e));
  }
}

function* fetchShippingAddressesSaga() {
  try {
    const accountNumber = yield select(detailsSelectors.accountNumber);
    const { default_address_id, addresses } = yield call(api.accountShippingAddresses, accountNumber);

    // Tell Account Default Address Id
    yield put(detailsActions.makeDefaultAddressResponse(default_address_id));

    // Set Addresses
    yield put(
      actions.shippingAddressesResponse({
        data: addresses,
        selectedAddressId: default_address_id,
      })
    );
  } catch (e) {
    yield put(actions.shippingAddressesResponse(e));
  }
}

function* saveAddressSaga({ payload: { address: saveAddress } }) {
  const addressToSave = saveAddress.id !== 'unvalidated' ? saveAddress : omit(saveAddress, 'id');
  const endpoint = addressToSave.id ? api.editShippingAddress : api.createAddress;

  try {
    const { address } = yield call(endpoint, addressToSave);
    yield put(actions.saveAddressResponse(address));
    yield put(actions.setSelectedAddress(address.id));
  } catch (e) {
    yield put(actions.saveAddressResponse(e));
  }
}

function* validateAddressSaga({ payload: { address, saveToAccount, skipValidation } }) {
  try {
    const addressToValidate = address.id !== 'unvalidated' ? address : omit(address, 'id');
    const endpoint = addressToValidate.id ? api.validateExistingShippingAddress : api.validateNewShippingAddress;

    const response = yield call(endpoint, addressToValidate, saveToAccount, skipValidation);
    if (response.success) {
      yield put(actions.validateAddressResponse(response));
    } else {
      const errorMessage = response.error || null;
      yield put(
        actions.openAddressDialog({
          address: response.address,
          error: true,
          errorMessage,
          showValidationErrors: errorMessage === null,
        })
      );
    }
  } catch (e) {
    yield put(actions.openAddressDialog(e));
  }
}

export function* shippingAddressesSaga() {
  yield all([
    takeLatest(actions.fetchShippingAddresses, fetchShippingAddressesSaga),
    takeLatest(actions.saveAddress, saveAddressSaga),
    takeLatest(actions.deleteAddress, deleteAddressSaga),
    takeLatest(actions.validateAddress, validateAddressSaga),
  ]);
}

export { actions } from './actions';

// reducer
export default handleActions(
  {
    [actions.deleteAddress](state) {
      return { ...state, loading: true };
    },
    [actions.deleteAddressResponse]: {
      next(state, { payload: id }) {
        return { ...state, data: removeItem(state.data, { id }) };
      },
    },
    [actions.fetchShippingAddresses]() {
      return { ...defaultState, loading: true };
    },
    [actions.shippingAddressesResponse]: {
      next(state, { payload }) {
        return {
          ...state,
          data: payload.data,
          selectedAddressId: payload.selectedAddressId,
          loading: false,
        };
      },
      throw() {
        return { ...defaultState, loading: false, error: true };
      },
    },
    [actions.openAddressDialog](state, { payload = {} }) {
      return {
        ...state,
        dialogAddress: payload.address || {},
        isInvoiceAddressAdd: payload.isInvoiceAddressAdd || false,
        error: payload.error || false,
        errorMessage: payload.errorMessage,
        showCandidatesDialog: false,
        showDialog: true,
        showValidationErrors: payload.showValidationErrors || false,
      };
    },
    [actions.closeAddressDialog](state) {
      return {
        ...state,
        showDialog: false,
        dialogAddress: {},
        error: false,
        errorMessage: '',
        unvalidatedAddress: {},
        showValidationErrors: false,
      };
    },
    [actions.closeAllAddressDialogs](state) {
      return {
        ...state,
        candidates: [],
        dialogAddress: {},
        error: false,
        errorMessage: '',
        showCandidatesDialog: false,
        showDialog: false,
        unvalidatedAddress: {},
        showValidationErrors: false,
      };
    },
    [actions.openCandidatesDialog](state, { payload }) {
      return { ...state, showCandidatesDialog: true };
    },
    [actions.closeCandidatesDialog](state) {
      return { ...state, candidates: [], unvalidatedAddress: {}, showCandidatesDialog: false };
    },
    [actions.setSelectedAddress](state, { payload: selectedAddressId }) {
      return { ...state, selectedAddressId };
    },
    [actions.saveAddress](state) {
      return { ...state, saving: true };
    },
    [actions.saveAddressResponse]: {
      next(state, { payload }) {
        return {
          ...state,
          data: replaceItem(state.data, payload),
          dialogAddress: {},
          saving: false,
          showCandidatesDialog: false,
          showDialog: false,
          unvalidatedAddress: {},
        };
      },
      throw() {
        return { ...defaultState, saving: false, error: true };
      },
    },
    [detailsActions.makeDefaultAddress](state) {
      return { ...state, loading: true };
    },
    [detailsActions.makeDefaultAddressResponse]: {
      next(state, { payload: selectedAddressId }) {
        return { ...state, selectedAddressId, loading: false };
      },
      throw() {
        return { ...defaultState, loading: false, error: true };
      },
    },
    [actions.validateAddressResponse]: {
      next(state, { payload }) {
        const { candidates, showCandidates, unvalidatedAddress } = payload;
        let addressUpdate = { unvalidatedAddress: {}, showCandidatesDialog: false };

        if (showCandidates) {
          addressUpdate = {
            candidates,
            unvalidatedAddress: { id: 'unvalidated', ...unvalidatedAddress },
            showCandidatesDialog: true,
            showDialog: false,
          };
        }

        return {
          ...state,
          ...addressUpdate,
          error: false,
          errorMessage: '',
          showValidationErrors: false,
          loading: false,
        };
      },
      throw(state) {
        return { ...defaultState, error: true };
      },
    },
  },
  defaultState
);
