import isNumber from 'lodash/isNumber';
import React from 'react';
import styled from 'styled-components';

import Button from 'components/Buttons';
import Currency from 'components/Currency';
import { translate } from 'utils/translations';

const StyledTotals = styled.div`
  display: flex;
  justify-content: flex-end;

  .Totals__items {
    width: 230px;
  }

  .Totals__item {
    display: flex;
    font-size: 12px;
    margin: 0 0 10px;

    &-label {
      width: 100px;
      text-align: right;
      margin-right: 15px;
    }

    &-value {
      margin-left: auto;
    }
  }

  .Totals__total {
    font-size: 14px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 25px;
  }
`;

const parseTotals = totals => {
  if (!totals) return { subtotal: null, tax: null, total: null };
  return totals;
};

const TotalsTotal = ({ currency, value }) => {
  return isNumber(value) ? <Currency quantity={value} currency={currency} /> : '--';
};

const RushSampleTotals = ({ currency, disabled, isTaxable, loading, totals, textSubmit, onSubmit }) => {
  const { subtotal, tax, total } = parseTotals(totals);

  return (
    <StyledTotals>
      <div className="Totals__items">
        <div className="Totals__item">
          <div className="Totals__item-label">{translate('subtotal', 'Subtotal')}</div>
          <div className="Totals__item-value">
            <TotalsTotal currency={currency} value={subtotal} />
          </div>
        </div>
        {isTaxable && (
          <div className="Totals__item">
            <div className="Totals__item-label">{translate('tax', 'Tax')}</div>
            <div className="Totals__item-value">
              <TotalsTotal currency={currency} value={tax} />
            </div>
          </div>
        )}
        <div className="Totals__item Totals__total">
          <div className="Totals__item-label">{translate('total', 'Total')}</div>
          <div className="Totals__item-value">
            <TotalsTotal currency={currency} value={total} />
          </div>
        </div>
        {onSubmit && (
          <Button icon="check" disabled={disabled || loading} onClick={onSubmit} success>
            {textSubmit}
          </Button>
        )}
      </div>
    </StyledTotals>
  );
};

export default RushSampleTotals;
