import React from 'react';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import Loader from 'components/Loader';
import { OrderActions, OrderShippingMaterials, SummaryTablePanel } from 'components/Order';

import OrderCSR from './OrderCSR.container';
import OrderDetails from './OrderDetails.container';
import OrderHeader from './OrderHeader.container';
import SummaryTable from './SummaryTable.container';
import SummaryTotals from './SummaryTotals.container';

const LayoutRoot = styled.div`
  .Layout__header {
    display: flex;
    align-items: center;
    margin: 0 0 25px;

    &-left {
      margin-right: 25px;
    }

    &-right {
      margin-left: auto;
    }
  }

  .Layout__body {
    display: flex;
  }

  .Layout__main {
    margin-right: 25px;
    flex: 1 1 auto;
  }

  .Layout__sidebar {
    width: 250px;
    flex: 0 0 auto;
  }
`;

const ConfirmationLayout = ({ account, isCsr, loading, hasData, order }) => {
  if (!hasData && !account) return <Redirect to={'/accounts'} />;
  if (!hasData) return <Redirect to={`/accounts/${account.number}`} />;
  if (loading) return <Loader />;

  return (
    <LayoutRoot>
      <div className="Layout__header">
        <div className="Layout__header-left">
          <OrderHeader />
        </div>
        <div className="Layout__header-right">
          <OrderActions order={order} />
        </div>
      </div>
      <div className="Layout__body">
        <div className="Layout__main">
          <SummaryTablePanel table={<SummaryTable />} totals={<SummaryTotals />} />
        </div>
        <div className="Layout__sidebar">
          <OrderDetails isConfirmation style={{ marginBottom: 25 }} />
          {isCsr && <OrderCSR />}
          {isCsr && <OrderShippingMaterials shippingMaterials={order.shippingMaterials} />}
        </div>
      </div>
    </LayoutRoot>
  );
};

export default withRouter(ConfirmationLayout);
