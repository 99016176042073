import { get } from 'lodash';

// selectors
export const selectors = {
  item: state => get(state, 'inventory.item', {}),
  productListItems: state => get(state, 'inventory.productsMap', {}),
  products: state => get(state, 'inventory.products', []),
  itemNumber: (_, props) => props.itemNumber,
  type: (_, props) => props.type,
};
