import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { Radio } from 'components/Input/Radio';
import { colors } from 'style/theme';

const StyledListItem = styled.div`
  display: flex;
  border: 2px solid ${colors.whiteAlto};
  border-radius: 12px;
  cursor: pointer;
  padding: 12px;
  margin: 0 0 12px;
  transition: border-color 300ms;

  ${props => props.checked && `border-color: ${colors.blueDenim}`};

  &:hover {
    border-color: ${colors.blueDenim};
  }

  &:last-child {
    margin: 0;
  }

  .Item__left {
    margin-right: 5px;
  }

  .Item__right {
  }

  .Item__label {
    font-weight: 700;
    color: ${colors.grayMineShaft};
    line-height: 1.3;
  }

  .Item__description {
    font-weight: 300;
    color: ${colors.grayWarm};
    line-height: 1.3;
  }
`;

const RadioListItem = ({ checked, data, name, onChange }) => {
  const onSelect = useCallback(() => {
    onChange(name, data.value);
  }, [data, name, onChange]);

  return (
    <StyledListItem checked={checked} onClick={onSelect}>
      <div className="Item__left">
        <Radio checked={checked} name={name} onChange={onSelect} />
      </div>
      <div className="Item__right">
        <div className="Item__label">{data.label}</div>
        <div className="Item__description">{data.description}</div>
      </div>
    </StyledListItem>
  );
};

RadioListItem.propTypes = {
  checked: PropTypes.bool,
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { RadioListItem };
