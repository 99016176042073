import React from 'react';
import styled from 'styled-components';

import { LabeledTextarea } from 'components/LabeledInput';
import ModalWrapper from 'components/Modals/ModalWrapper';
import changeMethod from 'utils/common/changeMethod';
import { translate } from 'utils/translations';

const Details = styled.div`
  font-size: 12px;

  .Detail__item {
    margin: 0 0 15px;
    &:first-child {
      font-weight: bold;
    }

    &:last-child {
      margin: 0;
    }
  }
`;

const CancelOrderModal = props => {
  const { reason, onChange, validation, ...rest } = props;

  return (
    <ModalWrapper valid={validation()} payload={reason} title={props.title} width={400} locked {...rest}>
      <Details>
        <p className="Detail__item">
          {translate('store.cancelOrderMessage', 'Are you sure you want to cancel this order?')}
        </p>
        <div className="Detail__item">
          <LabeledTextarea
            label={translate('store.cancelOrderReason', 'Reason')}
            name="reason"
            onChange={changeMethod(onChange)}
            style={{ display: 'block' }}
            value={reason}
          />
        </div>
      </Details>
    </ModalWrapper>
  );
};

export default CancelOrderModal;
