import PropTypes from 'prop-types';
import React from 'react';

import Dialog from 'components/Dialog';
import { translate } from 'utils/translations';

import NoteForm from './NoteForm.container';

const NoteFormDialog = ({ showDialog, newNote, closeDialog, accountNumber, type }) => {
  if (!showDialog) return null;

  const noteTitle =
    type === 'SHIP'
      ? {
          edit: translate('editShippingNote', 'Edit Shipping Note'),
          create: translate('newShippingNote', 'Create Shipping Note'),
        }
      : {
          edit: translate('editOrderNote', 'Edit Order Note'),
          create: translate('newOrderNote', 'Create Order Note'),
        };

  return (
    <Dialog
      header={newNote ? noteTitle.edit : noteTitle.create}
      onClose={closeDialog}
      modalStyle={{ width: 750, maxWidth: '90vw' }}
      locked
    >
      <NoteForm accountNumber={accountNumber} />
    </Dialog>
  );
};

NoteFormDialog.propTypes = {
  showDialog: PropTypes.bool,
  newNote: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  accountNumber: PropTypes.string.isRequired,
};

export default NoteFormDialog;
