import React, { useCallback } from 'react';
import styled from 'styled-components';

import Currency from 'components/Currency';
import ListTable from 'components/ListTable';
import OneColumnRow from 'components/OneColumnRow';
import { SortLabel } from 'components/SearchSortLabel';
import { colors } from 'style/theme';
import { isNumberLike } from 'utils/helpers';
import { translate } from 'utils/translations';

import RushSampleValidation from './RushSampleValidation';

const INACTIVE = 'inactive';

const StyledResults = styled.div`
  margin: 0 0 25px;
`;

const TableRow = styled.tr`
  opacity: ${props => props.disabled && 0.65};

  td:last-child {
    background-color: ${props => (props.isCsr ? colors.blueHawkes : 'inherit')};
  }

  &:nth-child(odd) {
    td:last-child {
      background-color: ${props => (props.isCsr ? colors.blueHawkes : 'inherit')};
      opacity: 0.85;
    }
  }
`;

const CheckBox = styled.input`
  opacity: ${props => props.disabled && 0.65};
`;

const RushSampleResultsTable = ({
  errorMessage,
  hasSearched,
  isCsr,
  getSortDirection,
  updateSort,
  rushSamples,
  loading,
  onCheck,
}) => {
  const onCheckAll = useCallback(e => onCheck({ id: 'all', key: 'checked', value: e.target.checked }), [onCheck]);
  const onCheckSingle = useCallback((id, key) => e => onCheck({ id, key, value: e.target.checked }), [onCheck]);
  const availableSamples = rushSamples.filter(i => i.isRushable);
  const isAllChecked = availableSamples.length > 0 && availableSamples.every(rs => rs.checked);

  const rushTicket = item =>
    !isNumberLike(item.rushTicket) ? (
      item.rushTicket
    ) : (
      <a
        href={`/accounts/${item.accountNumber}/rush-sample/${item.rushTicket}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {item.rushTicket}
      </a>
    );

  const orderNumber = item =>
    !isNumberLike(item.orderNumber) ? (
      item.orderNumber
    ) : (
      <a href={`/accounts/${item.accountNumber}/orders/${item.orderNumber}`} target="_blank" rel="noopener noreferrer">
        {item.orderNumber}
      </a>
    );

  const renderResults = () => {
    if (loading) return <OneColumnRow colSpan="99" text={`${translate('loading')}...`} />;

    const isEmptyResults = rushSamples.length === 0;
    if (hasSearched && isEmptyResults) {
      return <OneColumnRow colSpan="99" text={translate('noResultsFound', 'No Results Found')} />;
    }
    if (!hasSearched && isEmptyResults) {
      return (
        <OneColumnRow
          colSpan="99"
          text={translate('searchRushSamples', 'Search for Rush Samples using one of the options above')}
        />
      );
    }

    return rushSamples.map(item => (
      <TableRow key={item.id} disabled={!item.isRushable} isCsr={isCsr}>
        <td style={{ textAlign: 'center' }}>
          <CheckBox
            type="checkbox"
            checked={item.checked}
            onChange={onCheckSingle(item.id, 'checked')}
            disabled={!item.isRushable}
          />
        </td>
        <td>{item.barcode}</td>
        <td>{item.lab}</td>
        <td>{orderNumber(item)}</td>
        <td>{item.accountNumber}</td>
        <td>{item.testPackageName}</td>
        <td>{item.status}</td>
        <td>{item.rushCharge}</td>
        <td>{rushTicket(item)}</td>
        <td>
          <Currency currency={item.currency} quantity={!item.waived ? item.rushFee : 0} />
        </td>
        {isCsr && (
          <td style={{ textAlign: 'center' }}>
            <CheckBox
              type="checkbox"
              checked={item.waived}
              onChange={onCheckSingle(item.id, 'waived')}
              disabled={!item.isWaivable}
            />
          </td>
        )}
      </TableRow>
    ));
  };

  return (
    <StyledResults>
      <RushSampleValidation message={errorMessage} />
      <ListTable>
        <thead>
          <tr>
            <th style={{ textAlign: 'center' }}>
              <CheckBox
                type="checkbox"
                checked={isAllChecked}
                onChange={onCheckAll}
                disabled={!availableSamples.length}
              />
            </th>
            <th>
              <SortLabel
                label={translate('barcode', 'Barcode')}
                toggleIsActive={getSortDirection('barcode') !== INACTIVE}
                sortDirection={getSortDirection('barcode')}
                toggleHandler={direction => updateSort('barcode', direction)}
              />
            </th>
            <th>
              <SortLabel
                label={translate('lab', 'Lab')}
                toggleIsActive={getSortDirection('labNumber') !== INACTIVE}
                sortDirection={getSortDirection('labNumber')}
                toggleHandler={direction => updateSort('labNumber', direction)}
              />
            </th>
            <th>
              <SortLabel
                label={translate('orderNumber', 'Order #')}
                toggleIsActive={getSortDirection('orderNumber') !== INACTIVE}
                sortDirection={getSortDirection('orderNumber')}
                toggleHandler={direction => updateSort('orderNumber', direction)}
              />
            </th>
            <th>
              <SortLabel
                label={translate('account', 'Account')}
                toggleIsActive={getSortDirection('accountNumber') !== INACTIVE}
                sortDirection={getSortDirection('accountNumber')}
                toggleHandler={direction => updateSort('accountNumber', direction)}
              />
            </th>
            <th>
              <SortLabel
                label={translate('testing', 'Testing')}
                toggleIsActive={getSortDirection('testing') !== INACTIVE}
                sortDirection={getSortDirection('testing')}
                toggleHandler={direction => updateSort('testing', direction)}
              />
            </th>
            <th>{translate('statusDateReceived', 'Status: Date Received')}</th>
            <th>
              <SortLabel
                label={translate('rushCapitalized', 'Rush')}
                toggleIsActive={getSortDirection('rushCharge') !== INACTIVE}
                sortDirection={getSortDirection('rushCharge')}
                toggleHandler={direction => updateSort('rushCharge', direction)}
              />
            </th>
            <th>{translate('rushOrderNumber', 'Rush Order #')}</th>
            <th>{translate('fee', 'Fee')}</th>
            {isCsr && <th style={{ textAlign: 'center', backgroundColor: colors.blueHawkes }}>Waive</th>}
          </tr>
        </thead>
        <tbody>{renderResults()}</tbody>
      </ListTable>
    </StyledResults>
  );
};

export default RushSampleResultsTable;
