import React from 'react';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

// Components
import Alert from 'components/Alert';
import Button from 'components/Buttons';
import Loader from 'components/Loader';
import SummaryWithTotalLayout from 'components/Order/SummaryWithTotal.layout';
import { translate } from 'utils/translations';

import CsrPanel from './CsrPanel.container';
import Payment from './Payment.container';
import ReferenceInfo from './ReferenceInfo.container';
import ShippingAddress from './ShippingAddress.container';
import ShippingMethods from './ShippingMethods.container';

const TAX_ADDED_LATER_COUNTRIES = ['GT', 'PL'];

const LayoutRoot = styled.div`
  .Layout__flex {
    display: flex;
    flex-flow: row nowrap;

    .Layout__main {
      margin-right: ${props => (props.isCsr ? '25px' : '')};
      flex: 1 1 auto;
    }

    .Layout__sidebar {
      width: 250px;
      flex: 0 0 auto;
      margin-bottom: 15px;
    }
  }

  .Layout__pushRight {
    display: flex;
    align-items: flex-end;
    flex-flow: column;
    padding: 15px;
    .TaxAddedLater {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  .PlaceOrderButton {
    width: 230px;
  }
`;

const LayoutColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.span || 2}, 1fr);
  grid-gap: 25px;
`;

const Checkout = ({
  account,
  accountLevel,
  accountMask,
  accountLoading,
  confirmation,
  billingInfo,
  details,
  distributionPoint,
  openAddressDialog,
  hasOrder,
  loading,
  paymentMethod,
  placeOrder,
  sampleRushTesting,
  isCsr,
  isCustomer,
  isValid,
}) => {
  const { hasValidInvoiceAddress } = billingInfo;

  if (accountLoading || loading || confirmation.loading) return <Loader />;
  if (!hasOrder && !account) return <Redirect to={'/accounts'} />;
  if (!hasOrder) return <Redirect to={`/accounts/${account.number}`} />;

  const verifyOrderIsPlaceable = () => {
    if (isCustomer && !hasValidInvoiceAddress && paymentMethod === 'invoice') {
      openAddressDialog({ isInvoiceAddressAdd: true });
    } else {
      placeOrder();
    }
  };

  return (
    <LayoutRoot isCsr={isCsr}>
      {confirmation.error && <Alert alert>{translate(confirmation.error)}</Alert>}
      <div className="Layout__flex">
        <div className="Layout__main">
          <LayoutColumns span="2" style={{ margin: '0 0 25px' }}>
            <div className="Layout__left">
              <ShippingAddress style={{ margin: '0 0 25px' }} />
              <ShippingMethods />
            </div>
            <div className="Layout__right">
              <ReferenceInfo />
              <Payment />
            </div>
          </LayoutColumns>
        </div>
        {isCsr && (
          <div className="Layout__sidebar">
            <CsrPanel accountLevel={accountLevel} accountMask={accountMask} sampleRushTesting={sampleRushTesting} />
          </div>
        )}
      </div>
      <SummaryWithTotalLayout />
      <div className="Layout__pushRight">
        {TAX_ADDED_LATER_COUNTRIES.includes(distributionPoint.country) && (
          <div className="TaxAddedLater">
            {translate('checkout.taxAddedLater', 'Tax for this order will be added at the time of invoice.')}
          </div>
        )}
        <Button
          icon={'check'}
          onClick={verifyOrderIsPlaceable}
          disabled={!isValid}
          success
          className="PlaceOrderButton"
        >
          {translate('store.placeOrder', 'Place Order')}
        </Button>
      </div>
    </LayoutRoot>
  );
};

export default withRouter(Checkout);
