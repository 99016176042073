import React, { useCallback, useEffect, useState } from 'react';

import Button from 'components/Buttons';
import Errors from 'components/Errors';
import Hider from 'components/Hider';
import Input from 'components/Input';

import SearchForm from './SearchForm';

const INVALID_FORMAT = 'Invalid Lab Code Format';

const REGEX_FORMAT = /^[a-z]{1}-\d{1,}$/i;

const InputWrapper = ({ errors, ...props }) => (
  <div style={{ position: 'relative' }}>
    <Input type="text" invalid={errors.length > 0} inline {...props} />
    {errors.length > 0 && <Errors data={errors} />}
  </div>
);

const LabcodesSearch = ({ selected, setSelected, onSearch }) => {
  const [labcode, setLabcode] = useState('');
  const [touched, setTouched] = useState(false);
  const [validation, setValidation] = useState({ valid: false, errors: [] });

  useEffect(() => {
    setLabcode('');
    setTouched(false);
    setValidation({ valid: false, errors: [] });
  }, [selected]);

  const validate = useCallback((labcode, includeErrors = true) => {
    const errors = [];

    if (!labcode.length) return { valid: false, errors };

    if (!REGEX_FORMAT.test(labcode)) errors.push(INVALID_FORMAT);

    return { valid: errors.length === 0, errors: includeErrors ? errors : [] };
  }, []);

  useEffect(() => {
    const currentValidation = validate(labcode, touched);
    setValidation(currentValidation);
  }, [labcode, touched, validate]);

  const onSubmit = useCallback(() => {
    onSearch({ type: 'labcode', data: labcode });
  }, [labcode, onSearch]);

  const onBlur = useCallback(
    e => {
      const currentValidation = validate(e.target.value);
      setValidation(currentValidation);
      setTouched(true);
    },
    [validate]
  );

  const onChange = useCallback(e => {
    setLabcode(e.target.value);
  }, []);

  return (
    <SearchForm onSubmit={onSubmit} noValidate>
      <div className="Search__input">
        <input type="radio" checked={selected === 'labcode'} onChange={() => setSelected('labcode')} />
      </div>
      <div className="Search__label">Lab #</div>
      <Hider hidden={selected !== 'labcode'}>
        <div className="Search__content">
          <InputWrapper errors={validation.errors} name="labcode" value={labcode} onBlur={onBlur} onChange={onChange} />
        </div>
        <div className="Search__action">
          <Button success icon="search" disabled={!validation.valid} type="submit" />
        </div>
      </Hider>
    </SearchForm>
  );
};

export default LabcodesSearch;
