import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { actions, selectors } from 'modules/account/search';
import { selectors as userSelectors } from 'modules/user';

import AccountList from './AccountList';

class AccountListContainer extends PureComponent {
  constructor(props) {
    super(props);

    const { params } = props;

    this.state = {
      params,
      saveToAccount: true,
      emailSearch: false,
      showEmail: true,
      validInputLength: {
        name: 3,
        number: 3,
        city: 3,
        state: 2,
        country: 2,
        any: 3,
      },
    };
  }

  resetSearch = () => {
    const { params } = this.state;
    const { pageSize, sort, sortDirection } = params;
    this.setState(
      () => ({
        params: { pageSize, sort, sortDirection },
      }),
      () => {
        this.props.clearResults();
      }
    );
  };

  searchSubmit = e => {
    if (e) e.preventDefault();

    if (this.validateParams()) {
      this.props.fetchAccounts(this.state.params);
    } else {
      this.props.invalidSearch();
    }
  };

  setShowNumber = num => this.setState(() => ({ showNumber: String(num) }));

  toggleEmailSearch = show =>
    this.setState(state => ({
      emailSearch: typeof show === 'boolean' ? show : !state.emailSearch,
    }));

  updateParams = (field, value, submitOnUpdate = false) => {
    let { params: newParams } = this.state;

    if (typeof value === 'string' && value.length === 0) {
      newParams = omit(this.state.params, ['page', field]);
    } else {
      newParams = { ...omit(this.state.params, 'page'), [field]: value };
    }

    this.setState(
      () => ({ params: newParams }),
      () => {
        if (submitOnUpdate) {
          this.searchSubmit();
        }
      }
    );
  };

  updateSort = (field, direction) => {
    this.setState(
      state => ({
        params: {
          ...state.params,
          sort: field,
          sortDirection: direction,
        },
      }),
      () => this.searchSubmit()
    );
  };

  validateParams = () => {
    const { isCsr } = this.props;
    const validInputLength = this.state.validInputLength;
    const params = omit(this.state.params, ['pageSize', 'sort', 'sortDirection']);

    if (isCsr && isEmpty(params)) return false;

    const failedParams = pickBy(params, (val, key) => {
      return val.length < validInputLength[key];
    });

    return isEmpty(failedParams);
  };

  componentDidMount() {
    const { isCsr, fetchAccounts } = this.props;
    if (!isCsr) fetchAccounts(this.state.params);
  }

  getChildProps() {
    return {
      ...this.props,
      ...this.state,
      showEmail: this.props.isCsr,
      resetSearch: this.resetSearch,
      searchSubmit: this.searchSubmit,
      setShowNumber: this.setShowNumber,
      toggleEmailSearch: this.toggleEmailSearch,
      updateParams: this.updateParams,
      updateSort: this.updateSort,
      isValid: this.validateParams(),
    };
  }

  render() {
    return <AccountList {...this.getChildProps()} />;
  }
}

const mapState = state => ({
  accounts: selectors.accounts(state),
  error: selectors.error(state),
  isCsr: userSelectors.isCsr(state),
  loading: selectors.loading(state),
  params: selectors.params(state),
  hasSearched: selectors.hasSearched(state),
  searchData: selectors.searchData(state),
  validSearch: selectors.validSearch(state),
});

const mapDispatch = {
  clearResults: actions.clearResults,
  fetchAccounts: actions.fetchAccounts,
  invalidSearch: actions.invalidSearch,
};

export default connect(mapState, mapDispatch)(AccountListContainer);
