import { createActions } from 'redux-actions';

export const actions = createActions(
  'CLOSE_OVERRIDE_DIALOG',
  'CREATE_OVERRIDE_ITEMS',
  'CSR_UPDATE',
  'DELETE_EMAIL',
  'DELETE_EMAIL_RESPONSE',
  'EDIT_EMAIL',
  'EMAIL_INVOICE_ADDRESS',
  'FETCH_NOTIFICATION_EMAILS',
  'NOTIFICATION_EMAILS_RESPONSE',
  'FETCH_SETTINGS_OPTIONS',
  'MODIFY_CONFIRMATION_ORDER',
  'MODIFY_CONFIRMATION_ORDER_RESPONSE',
  'OPEN_OVERRIDE_DIALOG',
  'OVERRIDE_UPDATE',
  'PAYMENT_UPDATE',
  'PLACE_ORDER',
  'PLACE_ORDER_RESPONSE',
  'REFERENCE_INFO_UPDATE',
  'SAVE_EMAIL',
  'SAVE_EMAIL_RESPONSE',
  'SAVE_OVERRIDE',
  'SAVE_OVERRIDE_RESPONSE',
  'SET_OVERRIDE_ITEMS',
  'SETTINGS_OPTIONS_RESPONSE',
  'SHIPPING_ADDRESS_UPDATE',
  'SHIPPING_UPDATE',
  'UPDATE_CONFIRMATION_ORDER_ADDRESS',
  'UPDATE_CONFIRMATION_ORDER_ADDRESS_RESPONSE',
  'UPDATE_OVERRIDE_SUMMARY_PRICES'
);
