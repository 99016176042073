import noop from 'lodash/noop';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { ActionButton } from 'components/Buttons';
import JiraLink from 'components/JiraLink';
import ListTableDefault from 'components/ListTable';
import { colors } from 'style/theme';
import { renderMask } from 'utils/display';
import { translate } from 'utils/translations';

const ListTable = styled(ListTableDefault)`
  background: transparent;
  background-color: ${colors.bluePajamas} !important;
  vertical-align: top;

  tr:nth-child(odd) {
    background-color: ${colors.bluePajamas} !important;
  }

  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    td {
      padding: 15px;
    }

    &:last-child {
      border: none;
    }
  }

  td {
    vertical-align: top;
    min-width: 50px;

    &:first-child {
      width: 190px;
    }

    &.noStretch {
      width: 1%;
      white-space: nowrap;
    }
  }

  p {
    margin: 0 0 10px;

    &:last-child {
      margin: 0;
    }
  }
`;

const Detail = styled.div`
  margin: 0 0 10px;

  &:last-child {
    margin: 0;
  }

  label {
    display: block;
    margin: 0;
  }

  p {
    margin: 0;
    font-weight: 700;
  }
`;

const Note = styled.div`
  p {
    margin: 0;
  }
`;

const renderContent = (htmlContent = '') => ({ __html: htmlContent });

const NotesList = ({ notes = [], locked = false, type, editNote = noop, deleteNote = noop }) => (
  <ListTable>
    <tbody>
      {notes.map(note => (
        <tr key={note.id}>
          <td>{renderMask(note.noteMask)}</td>
          <td>
            <Note dangerouslySetInnerHTML={renderContent(note.htmlContent)} />
          </td>
          <td className="noStretch">
            {note.ticketNumber && (
              <Detail>
                <label>{translate('ticketNumber')}:</label>
                <p>
                  <JiraLink issueNumber={note.ticketNumber} />
                </p>
              </Detail>
            )}
            <Detail>
              <label>{translate('lastEdited')}:</label>
              <p>{moment(note.updatedAt).format('MM/DD/YYYY')}</p>
            </Detail>
            {note.lastEditedBy && (
              <Detail>
                <label>{translate('user')}:</label>
                {note.lastEditedBy.name && <p>{note.lastEditedBy.name}</p>}
                <p>{note.lastEditedBy.email}</p>
              </Detail>
            )}
          </td>
          <td className="noStretch">
            {!locked && (
              <Fragment>
                <ActionButton onClick={() => editNote({ id: note.id, type })} title={translate('editNote')}>
                  <i className="fa fa-pencil" />
                </ActionButton>
                <ActionButton
                  background={colors.redFlamingo}
                  onClick={() => deleteNote(note, type)}
                  title={translate('deleteNote')}
                >
                  <i className="fa fa-times" />
                </ActionButton>
              </Fragment>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  </ListTable>
);

NotesList.propTypes = {
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      noteMask: PropTypes.string.isRequired,
      htmlContent: PropTypes.string,
      ticketNumber: PropTypes.string,
      updatedAt: PropTypes.string.isRequired,
    })
  ).isRequired,
  editNote: PropTypes.func,
  deleteNote: PropTypes.func,
};

export default NotesList;
