import { Tabs } from '@polarislabs/polaris-ui-components';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { SAMPLE_RUSH_TESTING, INVOICE_LEVEL_OPEN } from 'constants/strings';
import { actions, selectors } from 'modules/account/settings';
import { translate } from 'utils/translations';

const NOT_ALLOWED = 'Not Allowed';

const AccountTabs = ({ fetchAccountSettings, match, settings }) => {
  const { accountNumber } = match.params;

  const displayRushSampleTab = settings.sampleRushTesting && settings.sampleRushTesting !== NOT_ALLOWED;

  useEffect(() => {
    fetchAccountSettings([SAMPLE_RUSH_TESTING, INVOICE_LEVEL_OPEN]);
  }, [fetchAccountSettings]);

  const data = [
    { url: `/accounts/${accountNumber}/details`, label: translate('accountDetails', 'Account Details'), exact: true },
    { url: `/accounts/${accountNumber}/orders`, label: translate('previousOrders', 'Previous Orders') },
    {
      url: `/accounts/${accountNumber}/recurring-orders`,
      label: translate('store.recurringOrders', 'Recurring Orders'),
      exact: true,
    },
  ];

  if (displayRushSampleTab) {
    data.push({
      url: `/accounts/${accountNumber}/rush-sample`,
      label: translate('rushSample', 'Rush Sample'),
    });
  }

  return <Tabs data={data} />;
};

const mapState = state => ({
  settings: selectors.decorated(state),
});

const mapDispatch = { fetchAccountSettings: actions.fetchAccountSettings };

export default connect(mapState, mapDispatch)(withRouter(AccountTabs));
