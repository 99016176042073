import * as config from 'config';

const localEnv = ['test', 'local'];

const rollbarConfig = {
  enabled: !localEnv.includes(config.ENVIRONMENT),
  accessToken: 'c86af4f2ce9c4b4692816288ec2b3a80',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: config.ROLLBAR_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: config.ROLLBAR_SHA,
        guess_uncaught_frames: false,
      },
    },
    server: { root: '/' },
  },
};

export default rollbarConfig;
