import noop from 'lodash/noop';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import Alert from 'components/Alert';
import Button from 'components/Buttons';
import Input from 'components/Input';
import LabeledInput, { LabeledSelect, LabeledCheckbox } from 'components/LabeledInput';
import Loader from 'components/Loader';
import PanelContainer from 'components/Panel';
import RowItem from 'components/RowItem';
import Select from 'components/Select';
import { COUNTRIES } from 'constants/countryRegions';
import { DEFAULT_BILLING_ADDRESS } from 'constants/defaults';
import { CREDIT_CARD, INVOICE } from 'constants/strings';
import { colors } from 'style/theme';
import { changeMethod } from 'utils/common';
import { translate } from 'utils/translations';

const PurchaseOrderSuggestion = styled.div`
  margin: 5px 0 15px;
  padding: 8px 15px;
  background: ${colors.blueColumbia};
  border: 1px solid ${colors.blueCuriousBlue};
  border-radius: 3px;
`;

const ErrorInput = styled(Input)`
  ${props => props.invalid && 'border-color: red'};
`;

const ErrorMessage = styled.div`
  font-size: 90%;
  margin: 3px;
  color: red;
`;

const AddressErrorMessage = styled.div`
  font-size: 90%;
  margin: 3px;
  color: red;
  position: relative;
  top: -10px;
`;

const StyledCheckboxLabel = styled.label`
  display: flex;
  align-items: center;

  .CheckboxLabel__input {
    margin-right: 8px;
  }

  .CheckboxLabel__label {
  }
`;

const Columns = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(${props => props.span || 2}, 1fr);
  ${props => props.gridColumns && `grid-template-columns: ${props.gridColumns}`};
`;

const CheckboxLabel = ({ label, ...props }) => (
  <StyledCheckboxLabel>
    <input className="CheckboxLabel__input" type="radio" {...props} />
    <div className="CheckboxLabel__label">{label}</div>
  </StyledCheckboxLabel>
);

const formatCreditCards = creditCards => {
  const formattedCards = creditCards.map(({ id, cardType, cardNumber }) => ({
    label: `${cardType} - ${cardNumber}`,
    value: id,
  }));
  return [...formattedCards, { label: translate('newCreditCard', 'New Credit Card'), value: 'create' }];
};

const PaymentDetails = styled.div`
  .Details__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

const Payment = ({
  createCreditCard,
  creditCards,
  existingPurchaseOrder,
  orderAddress,
  payment,
  paymentMethod,
  paymentMethods,
  purchaseOrderNumber,
  onAddressBlurUpdate,
  onCreditCardBlurUpdate,
  onAddressUpdate,
  onCardUpdate,
  onCreateCreditCard,
  onDeleteCreditCard,
  onFieldUpdate,
  onPaymentUpdate,
  onPurchaseOrderChange,
  isAmex,
  isCsr,
  isValidCreditCard,
  isValidField,
}) => {
  const [useShippingAddress, setUseShippingAddress] = useState(false);

  const { creditCardId, creditCardInfo, customerInfo } = payment;

  const getOrderUrl = order => `/accounts/${order.account.number}/orders/${order.id}`;
  const showCreditCardForm = paymentMethod === CREDIT_CARD && (creditCardId === 'create' || isCsr);
  const showCreditCardList = paymentMethod === CREDIT_CARD && !isCsr;

  const toggleUseShippingAddress = (field, val) => {
    setUseShippingAddress(val);
    const newAddress = val
      ? {
          companyName: orderAddress.company_name,
          address: orderAddress.address1,
          city: orderAddress.city,
          country: orderAddress.country,
          state: orderAddress.region,
          zip: orderAddress.post_code,
        }
      : DEFAULT_BILLING_ADDRESS;

    onPaymentUpdate({
      customerInfo: {
        ...customerInfo,
        ...newAddress,
      },
    });
  };

  const renderPaymentMethods = () => {
    if (paymentMethods.error) {
      return (
        <Alert alert>
          {translate(
            'store.paymentMethodsError',
            // eslint-disable-next-line max-len
            "We're sorry, we cannot process your order at this time. Please contact us at custserv@eoilreports.com for immediate assistance."
          )}
        </Alert>
      );
    }

    if (paymentMethods.loading) return <Loader small />;

    return (
      <PaymentDetails>
        {paymentMethods.data.includes(INVOICE) && (
          <RowItem
            center
            title={
              <CheckboxLabel
                label={translate('invoice', 'Invoice')}
                name="paymentMethod"
                value="invoice"
                onChange={changeMethod(onFieldUpdate)}
                checked={paymentMethod === 'invoice'}
              />
            }
          />
        )}
        {paymentMethods.data.includes(CREDIT_CARD) && (
          <Fragment>
            <RowItem
              center
              title={
                <CheckboxLabel
                  label={translate('creditCard', 'Credit Card')}
                  name="paymentMethod"
                  value="credit_card"
                  onChange={changeMethod(onFieldUpdate)}
                  checked={paymentMethod === CREDIT_CARD}
                />
              }
            >
              {showCreditCardList && (
                <Columns style={{ gridTemplateColumns: '1fr auto', alignItems: 'center' }}>
                  <Select
                    fullWidth
                    clearable={false}
                    onChange={changeMethod(onFieldUpdate, 'creditCardId')}
                    options={formatCreditCards(creditCards.data)}
                    placeholder={translate('selectCreditCard', 'Select Credit Card')}
                    value={creditCardId}
                  />
                  <Button
                    alert
                    transparent
                    disabled={creditCardId === 'create'}
                    onClick={() => onDeleteCreditCard(creditCardId)}
                    style={{ flex: '0 0 auto', fontSize: 14 }}
                  >
                    <i className="fa fa-close" />
                  </Button>
                </Columns>
              )}
            </RowItem>

            {showCreditCardForm && (
              <form onSubmit={isCsr ? noop : onCreateCreditCard}>
                {createCreditCard.error && <Alert alert>{translate(createCreditCard.error)}</Alert>}
                <RowItem>
                  <div className="Details__header">
                    <h4>{translate('cardDetails', 'Card Details')}</h4>
                  </div>
                  <RowItem>
                    <LabeledInput
                      label={translate('cardNumber', 'Card Number')}
                      disabled={createCreditCard.loading}
                      name="cardNumber"
                      required
                      mask={isAmex(creditCardInfo.cardNumber) ? '1111 111111 11111' : '1111 1111 1111 1111'}
                      value={creditCardInfo.cardNumber}
                      invalid={!isValidField('cardNumber', creditCardInfo.cardNumber)}
                      onBlur={changeMethod(onCreditCardBlurUpdate, 'cardNumber')}
                      onChange={changeMethod(onCardUpdate)}
                    />
                    {!isValidField('cardNumber', creditCardInfo.cardNumber) && (
                      <AddressErrorMessage>
                        {translate('checkout.cardNumberRequired', 'Card number is required for this order')}
                      </AddressErrorMessage>
                    )}
                  </RowItem>
                  <RowItem>
                    <Columns>
                      <LabeledInput
                        label={translate('expiration', 'Expiration (MM/YY)')}
                        disabled={createCreditCard.loading}
                        mask="11/11"
                        required
                        name="expirationDate"
                        value={creditCardInfo.expirationDate}
                        invalid={!isValidField('expirationDate', creditCardInfo.expirationDate)}
                        onBlur={changeMethod(onCreditCardBlurUpdate, 'expirationDate')}
                        onChange={changeMethod(onCardUpdate)}
                      />
                      <LabeledInput
                        label={translate('securityCode', 'Security Code')}
                        disabled={createCreditCard.loading}
                        name="cvv"
                        required
                        value={creditCardInfo.cvv}
                        invalid={!isValidField('cvv', creditCardInfo.cvv)}
                        onBlur={changeMethod(onCreditCardBlurUpdate, 'cvv')}
                        maxLength={isAmex(creditCardInfo.cardNumber) ? 4 : 3}
                        onChange={changeMethod(onCardUpdate)}
                      />
                    </Columns>
                    <RowItem>
                      <Columns>
                        {!isValidField('expirationDate', creditCardInfo.expirationDate) ? (
                          <AddressErrorMessage>
                            {translate('checkout.expirationDateRequired', 'Valid expiration date required')}
                          </AddressErrorMessage>
                        ) : (
                          <div style={{ width: '50%' }}>&nbsp;</div>
                        )}
                        {!isValidField('cvv', creditCardInfo.cvv) && (
                          <AddressErrorMessage>
                            {translate('checkout.cvvRequired', 'CVV is required for this order')}
                          </AddressErrorMessage>
                        )}
                      </Columns>
                    </RowItem>
                    <LabeledCheckbox
                      label={translate('useShippingAddress', 'Use Shipping Address')}
                      name="useShippingAddress"
                      checked={useShippingAddress}
                      onChange={changeMethod(toggleUseShippingAddress)}
                    />
                  </RowItem>
                  <h4>{translate('billingAddress', 'Billing Address')}</h4>
                  <RowItem>
                    <LabeledInput
                      invalid={!isValidField('companyName', customerInfo.companyName)}
                      label={translate('companyName', 'Company Name')}
                      disabled={createCreditCard.loading || useShippingAddress}
                      name="companyName"
                      required
                      value={customerInfo.companyName}
                      onBlur={changeMethod(onAddressBlurUpdate, 'companyName')}
                      onChange={changeMethod(onAddressUpdate)}
                    />
                    {!isValidField('companyName', customerInfo.companyName) && (
                      <AddressErrorMessage>
                        {translate('checkout.companyNameRequired', 'Company Name is required for this order')}
                      </AddressErrorMessage>
                    )}
                    <Columns>
                      <span>
                        <LabeledInput
                          autoComplete="first-name"
                          invalid={!isValidField('firstName', customerInfo.firstName)}
                          label={translate('firstName', 'First Name')}
                          disabled={createCreditCard.loading}
                          name="firstName"
                          required
                          value={customerInfo.firstName}
                          onBlur={changeMethod(onAddressBlurUpdate, 'firstName')}
                          onChange={changeMethod(onAddressUpdate)}
                        />
                        {!isValidField('firstName', customerInfo.firstName) && (
                          <AddressErrorMessage>
                            {translate('checkout.firstNameRequired', 'First Name is required for this order')}
                          </AddressErrorMessage>
                        )}
                      </span>
                      <span>
                        <LabeledInput
                          autoComplete="last-name"
                          invalid={!isValidField('lastName', customerInfo.lastName)}
                          label={translate('lastName', 'Last Name')}
                          disabled={createCreditCard.loading}
                          name="lastName"
                          required
                          value={customerInfo.lastName}
                          onBlur={changeMethod(onAddressBlurUpdate, 'lastName')}
                          onChange={changeMethod(onAddressUpdate)}
                        />
                        {!isValidField('lastName', customerInfo.lastName) && (
                          <AddressErrorMessage>
                            {translate('checkout.lastNameRequired', 'Last Name is required for this order')}
                          </AddressErrorMessage>
                        )}
                      </span>
                    </Columns>
                    <LabeledInput
                      label={translate('address', 'Address')}
                      invalid={!isValidField('address', customerInfo.address)}
                      disabled={createCreditCard.loading || useShippingAddress}
                      name="address"
                      value={customerInfo.address}
                      required
                      onBlur={changeMethod(onAddressBlurUpdate, 'address')}
                      onChange={changeMethod(onAddressUpdate)}
                    />
                    {!isValidField('address', customerInfo.address) && (
                      <AddressErrorMessage>
                        {translate('checkout.addressRequired', 'Address is required for this order')}
                      </AddressErrorMessage>
                    )}
                    <LabeledSelect
                      autoComplete="country"
                      invalid={!isValidField('country', customerInfo.country)}
                      label={translate('country')}
                      name="country"
                      disabled={createCreditCard.loading || useShippingAddress}
                      onBlur={changeMethod(onAddressBlurUpdate, 'country')}
                      onChange={changeMethod(onAddressUpdate, 'country')}
                      required
                      style={{ width: '100%', marginBottom: 15 }}
                      options={COUNTRIES}
                      value={customerInfo.country}
                      width={'100%'}
                    />
                    {!isValidField('country', customerInfo.country) && (
                      <AddressErrorMessage>
                        {translate('checkout.countryRequired', 'Country is required for this order')}
                      </AddressErrorMessage>
                    )}
                    <Columns gridColumns="1fr auto">
                      <span>
                        <LabeledInput
                          autoComplete="city"
                          invalid={!isValidField('city', customerInfo.city)}
                          label={translate('city')}
                          name="city"
                          disabled={createCreditCard.loading || useShippingAddress}
                          onBlur={changeMethod(onAddressBlurUpdate, 'city')}
                          onChange={changeMethod(onAddressUpdate)}
                          required
                          style={{ width: '100%' }}
                          value={customerInfo.city}
                          width={'100%'}
                        />
                        {!isValidField('city', customerInfo.city) && (
                          <AddressErrorMessage>
                            {translate('checkout.cityRequired', 'City is required for this order')}
                          </AddressErrorMessage>
                        )}
                      </span>
                      <span>
                        <LabeledInput
                          autoComplete="postal-code"
                          invalid={!isValidField('zip', customerInfo.zip)}
                          label={translate('zipPostalCode')}
                          name="zip"
                          disabled={createCreditCard.loading || useShippingAddress}
                          onBlur={changeMethod(onAddressBlurUpdate, 'zip')}
                          onChange={changeMethod(onAddressUpdate)}
                          required
                          value={customerInfo.zip}
                          width={'96px'}
                        />
                        {!isValidField('zip', customerInfo.zip) && (
                          <AddressErrorMessage>
                            {translate('checkout.zipRequired', 'Zip is required for this order')}
                          </AddressErrorMessage>
                        )}
                      </span>
                    </Columns>

                    <LabeledInput
                      autoComplete="region"
                      country={customerInfo.country}
                      countryValueType="short"
                      invalid={!isValidField('state', customerInfo.state)}
                      name="state"
                      label={translate('stateProvidenceRegion')}
                      onBlur={changeMethod(onAddressBlurUpdate, 'state')}
                      onChange={changeMethod(onAddressUpdate, 'state')}
                      disabled={createCreditCard.loading || useShippingAddress}
                      regionInput
                      required
                      value={customerInfo.state}
                      valueType="short"
                    />
                    {!isValidField('state', customerInfo.state) && (
                      <AddressErrorMessage>
                        {translate('checkout.stateRequired', 'State is required for this order')}
                      </AddressErrorMessage>
                    )}
                  </RowItem>
                  {showCreditCardList && (
                    <RowItem>
                      <Button disabled={createCreditCard.loading || !isValidCreditCard(payment)} type="submit">
                        {translate('saveCreditCard', 'Save Credit Card')}
                      </Button>
                    </RowItem>
                  )}
                </RowItem>
              </form>
            )}
          </Fragment>
        )}
      </PaymentDetails>
    );
  };

  return (
    <PanelContainer title={translate('shipping.paymentMethod', 'Payment Method')}>
      <RowItem title={translate('store.purchaseOrderNumber', 'Purchase Order #')} center>
        <ErrorInput
          id="paymentPurchaseOrderNumber"
          name="purchaseOrderNumber"
          invalid={!isValidField('purchaseOrderNumber', purchaseOrderNumber)}
          onChange={changeMethod(onFieldUpdate)}
          onBlur={changeMethod(onPurchaseOrderChange)}
          value={purchaseOrderNumber || ''}
        />
        {!isValidField('purchaseOrderNumber', purchaseOrderNumber) && (
          <ErrorMessage>
            {translate('checkout.purchaseOrderNumberRequired', 'Purchase Order Number is required for this order')}
          </ErrorMessage>
        )}
        {existingPurchaseOrder && (
          <PurchaseOrderSuggestion>
            <strong>NOTE:</strong> Another order was created with this purchase number. You can view it{' '}
            <a href={getOrderUrl(existingPurchaseOrder)} target="_blank" rel="noopener noreferrer">
              here
            </a>
            .
          </PurchaseOrderSuggestion>
        )}
      </RowItem>
      {renderPaymentMethods()}
    </PanelContainer>
  );
};

export default Payment;
