import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { actions as addressActions, selectors as addressSelectors } from 'modules/account/billingAddress';
import { actions, selectors } from 'modules/account/details';
import { actions as modalActions } from 'modules/modal';
import { selectors as userSelectors } from 'modules/user';

import BillingInfo from './BillingInfo';

class BillingInfoContainer extends PureComponent {
  componentDidMount() {
    const { accountNumber, fetchBillingInfo } = this.props;
    fetchBillingInfo(accountNumber);
  }

  warningModal = closeAction => {
    this.props.showModal('confirm', {
      type: 'alert',
      title: 'Alert',
      message: 'Billing Info changes will affect more than just ONE account. Are you sure?',
      closeText: 'Continue',
      locked: true,
      onClose: data => closeAction({ data }),
    });
  };

  onEditAddress = address => {
    const { isInvoiceAccount } = this.props;

    if (isInvoiceAccount) {
      this.props.openAddressDialog({ address });
    } else {
      this.warningModal(() => this.props.openAddressDialog({ address }));
    }
  };

  onSaveBilling = data => this.props.updateBillingInfo(data);

  render() {
    return (
      <BillingInfo
        {...this.props}
        onEditAddress={this.onEditAddress}
        warningModal={this.warningModal}
        onSaveBilling={this.onSaveBilling}
      />
    );
  }
}

const mapState = state => ({
  billingInfo: selectors.billingInfo(state),
  program: selectors.program(state),
  showDialog: addressSelectors.showDialog(state),
  isInvoiceAccount: selectors.isInvoiceAccount(state),
  isCsr: userSelectors.isCsr(state),
});

export default connect(mapState, {
  fetchBillingInfo: actions.fetchBillingInfo,
  openAddressDialog: addressActions.openBillingAddressDialog,
  updateBillingInfo: actions.updateBillingInfo,
  showModal: modalActions.showModal,
})(BillingInfoContainer);
