import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Currency from 'components/Currency';
import { colors } from 'style/theme';
import { translate } from 'utils/translations';

const Subtotal = styled.div`
  font-size: 18px;
  font-weight: 700;

  .Subtotal__price {
    color: ${colors.redBrick};
  }
`;

const CartSubtotal = ({ currency, subtotal = 0 }) => {
  return (
    <Subtotal>
      {`${translate('cart.subtotal', 'Subtotal')}: `}
      <span className="Subtotal__price">
        <Currency quantity={subtotal} currency={currency} />
      </span>
    </Subtotal>
  );
};

CartSubtotal.propTypes = {
  subtotal: PropTypes.number.isRequired,
};

export default CartSubtotal;
