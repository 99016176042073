import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import Loader from 'components/Loader';
import { actions, selectors } from 'modules/account/details';
import orderSelectors from 'modules/order/selectors';

const AccountContainer = ({
  children,
  accountNumber,
  loading,
  loadingOrder,
  fetchAccountBilling,
  fetchAccountDetails,
}) => {
  useEffect(() => {
    if (isEmpty(accountNumber)) return;
    fetchAccountDetails(accountNumber);
    fetchAccountBilling(accountNumber);
  }, [accountNumber, fetchAccountDetails, fetchAccountBilling]);

  if (loading || loadingOrder) return <Loader />;
  return children;
};

const mapState = (state, { match }) => ({
  accountNumber: match.params.accountNumber,
  loading: selectors.loading(state),
  loadingOrder: orderSelectors.loading(state),
});

const mapDispatch = {
  fetchAccountBilling: actions.fetchBillingInfo,
  fetchAccountDetails: actions.fetchAccountDetails,
};

const enhance = compose(withRouter, connect(mapState, mapDispatch));

export default enhance(AccountContainer);
