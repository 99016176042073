import styled from 'styled-components';

import Button from './Button';

const ClearButton = styled(Button)`
  margin: 0;
  width: 30px;
  padding-left: 8px;
  padding-top: 3px;
  font-size: 18px;
`;

export default ClearButton;
