import find from 'lodash/find';
import padEnd from 'lodash/padEnd';

export const renderMask = _noteMask => {
  const noteMask = padEnd(_noteMask, 14, '*');

  return [noteMask.slice(0, 6), noteMask.slice(6, 10), noteMask.slice(10, 14)].join('-');
};

export const computeTableWidth = (width = 100) => {
  return { flex: `${width} 0 auto`, minWidth: `${width}px`, width: `${width}px` };
};

export const findDescription = (itemOrDesc, language) => {
  let descriptions;

  if (itemOrDesc.display_description) return itemOrDesc.display_description;

  if (itemOrDesc.descriptions) {
    descriptions = itemOrDesc.descriptions;
  } else {
    descriptions = itemOrDesc;
  }

  let description = find(descriptions, { language }) || find(descriptions, { language: 'en' });
  return description ? description.text : '';
};

export const renderDescription = (itemOrDesc, language) => {
  let description = findDescription(itemOrDesc, language);
  return description;
};
