import React from 'react';
import styled from 'styled-components';

import { TICKET_URL } from 'constants/strings';
import { colors } from 'style/theme';

const StyledLink = styled.a`
  &,
  &:visited,
  &:hover,
  &:active {
    color: ${colors.blueWater};
    text-decoration: none;
  }
`;

const JiraLink = ({ issueNumber }) => (
  <StyledLink href={`${TICKET_URL}/${issueNumber}`} target="_blank">
    {issueNumber}
  </StyledLink>
);

export default JiraLink;
