import { createActions } from 'redux-actions';

export const actions = createActions(
  'FETCH_SHIPPING_ADDRESSES',
  'SHIPPING_ADDRESSES_RESPONSE',
  'DELETE_ADDRESS',
  'DELETE_ADDRESS_RESPONSE',
  'OPEN_ADDRESS_DIALOG',
  'CLOSE_ALL_ADDRESS_DIALOGS',
  'CLOSE_ADDRESS_DIALOG',
  'OPEN_CANDIDATES_DIALOG',
  'CLOSE_CANDIDATES_DIALOG',
  'SAVE_ADDRESS',
  'SAVE_ADDRESS_RESPONSE',
  'SET_SELECTED_ADDRESS',
  'VALIDATE_ADDRESS',
  'VALIDATE_ADDRESS_RESPONSE'
);
