import get from 'lodash/get';
import { handleActions } from 'redux-actions';
import { all, takeEvery } from 'redux-saga/effects';

import { actions } from './actions';
import { fetchTranslationsSaga } from './fetchTranslationsSaga';

// state
const defaultState = {
  data: null,
  error: false,
};

// selectors
export const selectors = {
  translations: state => get(state, 'translations.data'),
};

export function* translationsSaga() {
  yield all([takeEvery(actions.fetchTranslations, fetchTranslationsSaga)]);
}

export { actions } from './actions';

// reducer
export default handleActions(
  {
    [actions.translationsResponse]: {
      next(state, { payload: data }) {
        return { ...state, data };
      },
      throw(state) {
        return { ...state, error: true };
      },
    },
  },
  defaultState
);
