import get from 'lodash/get';
import moment from 'moment';

const NOT_RUSHED = 'NO';

const buildStatus = result => {
  if (!result.dateReceived) return result.status;
  const formattedDate = moment(result.dateReceived).format('DD-MMM');
  return `${result.status}: ${formattedDate}`;
};

const checkIfRushable = result => {
  const isRushableCode = result.rushChargeCode === NOT_RUSHED;
  return isRushableCode && result.rushable;
};

const checkIfWaivable = result => {
  const isRushableCode = result.rushChargeCode === NOT_RUSHED;
  return isRushableCode && result.rushable;
};

const formatResult = result => {
  const accountId = get(result, 'loggingAccount.id');
  const accountNumber = get(result, 'loggingAccount.number');
  const basePricingLab = get(result, 'basePricingLabLocation.countryCode');
  const currencyId = get(result, 'currency.id');
  const currency = get(result, 'currency.code');
  const rushFee = Number(get(result, 'priceList.rushFee'));
  const testPackageName = get(result, 'testPackage.name');
  const rushTicket = get(result, 'rushTicket');

  return {
    checked: false,
    id: result.barcodeId,
    barcode: result.barcode,
    countryId: result.countryId,
    fee: rushFee,
    lab: result.labNumber,
    orderNumber: result.orderNumber,
    rushCharge: result.rushCharge,
    waived: false,
    accountId,
    accountNumber,
    basePricingLab,
    currency,
    currencyId,
    rushFee,
    rushTicket,
    testPackageName,
    status: buildStatus(result),
    isRushable: checkIfRushable(result),
    isWaivable: checkIfWaivable(result),
  };
};

const rushSampleSearchInboundMapper = results => {
  return results.map(formatResult);
};

export default rushSampleSearchInboundMapper;
