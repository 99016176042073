import get from 'lodash/get';
import isNumber from 'lodash/isNumber';

const getTotals = order => {
  const totals = {};
  if (isNumber(order.subtotal)) totals.subtotal = Number(order.subtotal);
  if (isNumber(order.tax)) totals.tax = Number(order.tax);
  if (isNumber(order.total)) totals.total = Number(order.total);
  return totals;
};

const rushSamplePlaceInboundMapper = order => {
  const items = get(order, 'rushOrder.rushOrderItems', []);

  return {
    ...order,
    csrInformation: {
      jiraTicketNumber: get(order, 'csrInformation.jira_ticket_number', ''),
    },
    currency: get(order, 'rushOrder.currency.code'),
    notificationEmails: get(order, 'notificationEmails', []),
    orderDate: get(order, 'ordered_at', 'N/A'),
    orderPlacedBy: get(order, 'order_placed_by', ''),
    orderPlacedFor: get(order, 'order_placed_for', ''),
    payment: {
      cardNumber: get(order, 'paymentDetails.cardNumber'),
      method: get(order, 'payment_method'),
    },
    items,
    orderNumber: get(order, 'id', 'N/A'),
    referencePO: get(order, 'reference_po'),
    totals: getTotals(order),
    isTaxable: get(order, 'taxable'),
    purchaseOrderNumber: get(order, 'purchase_order_number'),
  };
};

export default rushSamplePlaceInboundMapper;
