import { connect } from 'react-redux';

import SummaryTotals from 'components/Order/SummaryTotal';
import { selectors } from 'modules/previousOrder';

const mapState = state => {
  const data = selectors.data(state);

  return {
    currency: data.productList ? data.productList.currency : 'USD',
    summary: {
      discount: data.discount,
      shippingPrice: data.shippingPreference ? data.shippingPreference.customer_cost : 0,
      subtotal: data.subtotal,
      tax: data.tax,
      total: data.total,
      taxMessage: data.taxMessage,
    },
    address: data.address,
  };
};

export default connect(mapState)(SummaryTotals);
