import React, { Component } from 'react';

import Select from 'components/Select';

export default class AccountShowNumberSelectContainer extends Component {
  state = {
    options: [
      { value: '25', label: '25' },
      { value: '50', label: '50' },
      { value: '75', label: '75' },
      { value: '100', label: '100' },
    ],
  };

  componentDidMount() {
    if (this.props.onChange) {
      this.props.onChange(this.props.value || this.props.defaultValue);
    }
  }

  getChildProps() {
    return { ...this.props, ...this.state };
  }

  render() {
    return <Select inline {...this.getChildProps()} />;
  }
}
