import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { SearchButton } from 'components/Buttons';
import LabeledInput, { validateLength } from 'components/LabeledInput';

export const Root = styled.div`
  position: relative;
  display: flex;
  width: ${props => (props.width ? `${props.width}px` : '300px')};
`;

const SearchSubmit = ({ disabled, invalidLength, label, searchHandler, searchQuery = '', ...props }) => (
  <Root>
    <LabeledInput
      inline
      invalid={invalidLength && validateLength(searchQuery, invalidLength)}
      label={label}
      value={searchQuery}
      onChange={e => {
        const val = e.target.value;
        searchHandler(val, val >= invalidLength);
      }}
      hasButton
      {...props}
    />
    <SearchButton style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} type="submit" disabled={disabled} />
  </Root>
);

SearchSubmit.propTypes = {
  label: PropTypes.node,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  searchHandler: PropTypes.func.isRequired,
  searchQuery: PropTypes.string,
};

export default SearchSubmit;
