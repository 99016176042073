import React, { useState } from 'react';
import { connect } from 'react-redux';

import OrderDetails from 'components/Order/OrderDetails';
import { actions, selectors } from 'modules/rushSample';

const OrderDetailsContainer = ({ modifyRushOrder, order }) => {
  const {
    data: {
      notificationEmails: emails,
      orderPlacedBy,
      orderPlacedFor: placedFor,
      payment,
      referencePO,
      status,
      purchaseOrderNumber,
      id,
    },
  } = order;

  const initialData = {
    placedFor,
    referencePo: referencePO,
    purchaseOrderNumber,
  };

  const [edits, setEdits] = useState(initialData);
  const [editing, setEditing] = useState(false);

  const onChange = (key, value) => {
    setEdits(edits => ({ ...edits, [key]: value }));
  };

  const onToggleEdit = () => {
    setEditing(isEditing => {
      if (isEditing) setEdits(initialData);
      return !isEditing;
    });
  };

  const onSave = () => {
    const { placedFor, purchaseOrderNumber, referencePo } = edits;

    modifyRushOrder({
      purchaseOrderNumber,
      referencePo,
      placedFor,
      isDetails: true,
      orderId: id,
    });

    setEditing(false);
  };

  const props = {
    noShippingInfo: true,
    placedFor,
    referencePo: referencePO,
    purchaseOrderNumber,
    summary: {
      payment: {
        method: payment.method,
        number: payment.cardNumber,
      },
      placedBy: {
        name: orderPlacedBy,
      },
      emails,
    },
    status,
    ...edits,
  };

  return (
    <OrderDetails editable={editing} fieldUpdate={onChange} onEditingToggle={onToggleEdit} save={onSave} {...props} />
  );
};

const mapState = state => ({
  order: selectors.currentOrder(state),
});

const mapDispatch = {
  modifyRushOrder: actions.modifyRushOrder,
};

export default connect(mapState, mapDispatch)(OrderDetailsContainer);
