import { get } from 'lodash';
import { createSelector } from 'reselect';

// selectors
export const selectors = {
  createCreditCard: state => get(state, 'user.createCreditCard', {}),
  creditCards: state => get(state, 'user.creditCards', {}),
  details: state => get(state, 'user.details'),
  loading: state => get(state, 'user.loading'),
  invalid: state => get(state, 'user.invalid'),
};

selectors.isCsr = createSelector([selectors.details], details => {
  if (!details) return false;
  const { roles = [] } = details || {};
  return roles.includes('store_order_admin');
});

selectors.isCustomer = createSelector([selectors.details], details => {
  if (!details) return false;
  const { roles = [] } = details || {};
  return roles.includes('store');
});

selectors.language = createSelector([selectors.details], details => {
  if (!details) return 'en';
  return details.language;
});
