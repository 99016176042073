import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import Currency from 'components/Currency';
import PanelContainer from 'components/Panel';
import { colors } from 'style/theme';
import { translate } from 'utils/translations';

const Divider = styled.div`
  margin: 15px 0;
`;

const RowItem = styled.div`
  display: flex;
  flex-flow: ${props => (props.column ? 'column nowrap' : 'row nowrap')};

  .RowItem__label {
    text-align: right;
    margin-right: auto;
    width: 150px;
  }

  .RowItem__price {
    font-weight: 700;
  }

  &.total {
    margin-top: 15px;
    .RowItem__label,
    .RowItem__price {
      font-size: 14px;
      font-weight: 800;
    }
  }
`;

const SummaryTotal = ({ currency, summary }) => {
  const { discount, shippingPrice, subtotal, tax, taxMessage, total } = summary;

  return (
    <PanelContainer style={{ backgroundColor: colors.white }} width={300}>
      <RowItem>
        <div className="RowItem__label">{translate('cart.subtotal', 'Subtotal')}</div>
        <div className="RowItem__price">
          <Currency quantity={subtotal || 0} currency={currency} />
        </div>
      </RowItem>
      {shippingPrice !== undefined && (
        <div>
          <RowItem>
            <div className="RowItem__label">{translate('cart.shipping', 'Est. Shipping & Handling')}</div>
            <div className="RowItem__price">
              <Currency quantity={shippingPrice} currency={currency} />
            </div>
          </RowItem>
          <Divider />
        </div>
      )}
      {discount > 0 && (
        <Fragment>
          <RowItem>
            <div className="RowItem__label">{translate('checkout.discountProvided', 'Discount Provided')}</div>
            <div className="RowItem__price">
              <Currency quantity={discount} currency={currency} />
            </div>
          </RowItem>
          <Divider />
        </Fragment>
      )}
      {tax > 0 && (
        <RowItem>
          <div className="RowItem__label">{translate(taxMessage, 'Tax')}</div>
          <div className="RowItem__price">
            <Currency quantity={tax} currency={currency} />
          </div>
        </RowItem>
      )}
      <Divider />
      <RowItem className="total">
        <div className="RowItem__label">{translate('cart.total', 'Total')}</div>
        <div className="RowItem__price">
          <Currency quantity={total} currency={currency} />
        </div>
      </RowItem>
    </PanelContainer>
  );
};

SummaryTotal.propTypes = {
  summary: PropTypes.shape({
    discount: PropTypes.number,
    shippingPrice: PropTypes.number,
    subtotal: PropTypes.number.isRequired,
    tax: PropTypes.number.isRequired,
    taxMessage: PropTypes.string,
    total: PropTypes.number.isRequired,
  }).isRequired,
};

export default SummaryTotal;
