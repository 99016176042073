import find from 'lodash/find';
import get from 'lodash/get';
import { createActions, handleActions } from 'redux-actions';
import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import * as api from 'lib/api';
import { selectors as ordersSelectors } from 'modules/order/selectors';

// default state
const defaultState = {
  data: [],
  loading: false,
  error: false,
};

// actions
export const actions = createActions('FETCH_SUBSTITUTE_ITEMS', 'FETCH_SUBSTITUTE_ITEMS_RESPONSE');

// selectors
export const selectors = {
  data: state => get(state, 'substitutes.data', []),
  error: state => get(state, 'substitutes.error'),
  loading: state => get(state, 'substitutes.loading'),
  itemNumber: (_, props) => props.itemNumber,
  type: (_, props) => props.type,
};

selectors.currentItem = createSelector([selectors.data, selectors.itemNumber], (items, itemNumber) => {
  return find(items, item => {
    return item['item_number'] === itemNumber;
  });
});

// sagas
function* fetchSubstituteItemsSaga() {
  try {
    const orderId = yield select(ordersSelectors.id);
    const data = yield call(api.fetchSubstituteItems, orderId);
    if (data) {
      yield put(actions.fetchSubstituteItemsResponse(data));
    }
  } catch (e) {
    yield put(actions.fetchSubstituteItemsResponse(e));
  }
}

export function* substitutesSaga() {
  yield all([takeLatest(actions.fetchSubstituteItems, fetchSubstituteItemsSaga)]);
}

// reducers
export default handleActions(
  {
    [actions.fetchSubstituteItems](state) {
      return { ...defaultState, loading: true };
    },
    [actions.fetchSubstituteItemsResponse]: {
      next(state, { payload: data }) {
        return { ...state, data, loading: false };
      },
      throw(state) {
        return { ...state, data: [], loading: false };
      },
    },
  },
  defaultState
);
