import React, { Component } from 'react';

import rollbar from 'lib/rollbar';

import ErrorFallback from './ErrorBoundary';

export default class ErrorBoundary extends Component {
  state = { hasError: false };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    rollbar.error('Error caught in boundary', error, info);
  }

  render() {
    if (this.state.hasError) return <ErrorFallback />;
    return this.props.children;
  }
}
