import hoistStatics from 'hoist-non-react-statics';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getDisplayName } from 'utils/components';
import { omit, isEmptyString } from 'utils/lodash';

const DATE_FMT = moment.HTML5_FMT.DATE;

const withOrderSearch = (mapState, mapDispatch) => WrappedComponent => {
  class UnconnectedContainer extends PureComponent {
    state = {
      params: {
        pageSize: '100',
        sort: 'orderNumber',
        sortDirection: 'desc',
      },
      validInputLengths: {
        accountNumber: 3,
        city: 3,
        country: 2,
        poNumber: 3,
        shipTo: 3,
        state: 2,
        trackingNumber: 3,
      },
    };

    componentDidMount() {
      this.fetchResults();
    }

    updateParam = (name, val, callback) =>
      this.setState(state => {
        if (isEmptyString(val)) return { params: omit(this.state.params, name) };
        return { params: { ...state.params, [name]: val } };
      }, callback);

    updatePageSize = val =>
      this.updateParam('pageSize', val, () => {
        if (this.props.hasSearched) this.fetchResults();
      });

    updateSort = (sort, sortDirection) =>
      this.setState(
        state => ({
          params: { ...state.params, sort, sortDirection },
        }),
        this.fetchResults
      );

    validateParams = () => {
      const validInputLength = this.state.validInputLengths;
      const params = omit(this.state.params, ['pageSize', 'sort', 'sortDirection']);

      if (isEmpty(params)) return false;

      const failedParams = pickBy(params, (val, key) => {
        return val.length < validInputLength[key];
      });

      return isEmpty(failedParams);
    };

    fetchResults() {
      const { accountId } = this.props;
      const {
        params: { startDate, endDate, ...params },
      } = this.state;

      const fetchParams = { ...params };
      if (startDate) fetchParams.startDate = moment(startDate).format(DATE_FMT);
      if (endDate) fetchParams.endDate = moment(endDate).format(DATE_FMT);

      this.props.fetchResults({ params: fetchParams, accountId });
    }

    resetSearch = () => {
      const { params } = this.state;
      const { pageSize, sort, sortDirection } = params;
      this.setState(
        () => ({
          params: { pageSize, sort, sortDirection },
        }),
        () => {
          this.fetchResults();
        }
      );
    };

    searchSubmit = e => {
      e.preventDefault();

      if (this.validateParams()) {
        this.fetchResults();
      }
    };

    getChildProps() {
      const {
        resetSearch,
        searchSubmit,
        updateParam,
        updatePageSize,
        updateSort,
        validInputLengths,
        validateParams,
        state: { params },
      } = this;

      const { error, loading, orders, searchData, hasSearched, accountId } = this.props;

      const validSearch = validateParams(params);

      return {
        error,
        hasSearched,
        loading,
        orders,
        resetSearch,
        searchData,
        searchSubmit,
        updateParam,
        updatePageSize,
        updateSort,
        validInputLengths,
        validateParams,
        validSearch,
        params,
        accountId,
      };
    }

    render() {
      return <WrappedComponent {...this.getChildProps()} />;
    }
  }

  UnconnectedContainer.displayName = `withOrderSearch(${getDisplayName(WrappedComponent)})`;
  hoistStatics(UnconnectedContainer, WrappedComponent);

  return connect(mapState, mapDispatch)(UnconnectedContainer);
};

export default withOrderSearch;
