import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';

import Currency from 'components/Currency';
import { JAR_INVENTORY, PREPAID_LABEL } from 'constants/categories';
import { colors } from 'style/theme';
import { renderDescription } from 'utils/display';
import { translate } from 'utils/translations';

import SubstituteItemsSelect from './SubstituteItemsSelect';

const substitutedCategories = [JAR_INVENTORY, PREPAID_LABEL];

const determineIfSubstitutedCategory = category => {
  return substitutedCategories.includes(category);
};

const Table = styled.table`
  font-size: 12px;
  width: 100%;

  th,
  td {
    line-height: 2.5em;
  }

  th {
    color: ${colors.grayWarm};
    text-align: left;
    font-weight: normal;
  }

  td {
    &.price {
      font-weight: bold;
    }
  }
`;

const ListRow = ({
  allow_substitutions: allowSubstitutions,
  category,
  currency,
  display_item_number: displayItemNumber,
  display_description: displayDescription,
  descriptions,
  id,
  itemUpdate,
  item_number: itemNumber,
  isTaxExempt,
  labelsOnly,
  language,
  price,
  productListItemPrice,
  quantity,
  substituteOptions,
  substituteItemChange,
  substitutedItems,
  contentsItemIndex,
}) => {
  let disabled = labelsOnly;
  if (labelsOnly && category === 59) disabled = false;

  const isSubstitutedCategory = determineIfSubstitutedCategory(category);
  const displaySelectField = !isTaxExempt && isSubstitutedCategory && allowSubstitutions;

  const jarQuantity = get(itemUpdate, 'jar_quantity');

  return (
    <tr style={{ opacity: disabled ? 0.4 : 1 }}>
      <td>{displayItemNumber || itemNumber}</td>

      <td style={{ paddingRight: 15 }}>
        {displaySelectField ? (
          <SubstituteItemsSelect
            clearable={false}
            disabled={disabled}
            onChange={val => substituteItemChange(val, id, itemNumber, contentsItemIndex)}
            category={category}
            allowedQuantity={jarQuantity}
            substituteItems={substituteOptions}
            value={itemNumber}
            width={300}
          />
        ) : (
          displayDescription || renderDescription(descriptions, language)
        )}
      </td>

      {!isTaxExempt && (
        <td className="price">
          <Currency quantity={productListItemPrice >= 0 ? productListItemPrice : price} currency={currency} />
        </td>
      )}

      <td>{quantity}</td>
    </tr>
  );
};

const ContentsList = ({
  contents,
  currency,
  hasDescriptions,
  kitCategory,
  labelsOnly,
  language,
  isTaxExempt,
  itemNumber,
  prepaidTesting,
  product,
  substituteOptions,
  substituteItemChange,
  substitutedItems,
}) => (
  <Table>
    <thead>
      <tr>
        <th>{translate('partNumber')}</th>
        <th>{translate('kitContents')}</th>
        {!isTaxExempt && <th>{translate('priceEach')}</th>}
        <th>{isTaxExempt ? translate('kitQty') : translate('qty')}</th>
      </tr>
    </thead>
    <tbody>
      {prepaidTesting && (
        <tr>
          <td>{itemNumber}</td>
          <td>{hasDescriptions && renderDescription(product, language)}</td>
          {!isTaxExempt && (
            <td className="price">{<Currency quantity={get(prepaidTesting, 'price')} currency={currency} />}</td>
          )}
          <td>{get(prepaidTesting, 'quantity')}</td>
        </tr>
      )}
      {contents.map((item, i) => {
        return (
          <ListRow
            kitCategory={kitCategory}
            currency={currency}
            key={`${i}-${item.item_number}`}
            contentsItemIndex={i}
            {...item}
            language={language}
            substituteOptions={substituteOptions}
            substituteItemChange={substituteItemChange}
            substitutedItems={substitutedItems}
            labelsOnly={labelsOnly}
            isTaxExempt={isTaxExempt}
          />
        );
      })}
    </tbody>
  </Table>
);

export default ContentsList;
