import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import styled from 'styled-components';

import AlertInfo from 'components/AlertInfo';
import Currency from 'components/Currency';
import LinkRow from 'components/LinkRow';
import ListTable from 'components/ListTable';
import Loader from 'components/Loader';
import CATEGORIES from 'constants/categories';
import { actions, selectors } from 'modules/order/inventory';
import orderSelectors from 'modules/order/selectors';
import { selectors as userSelectors } from 'modules/user/selectors';
import { computeTableWidth, renderDescription } from 'utils/display';
import { translate } from 'utils/translations';

const ListTitle = styled.div`
  font-weight: 700;
  margin: 0 0 10px;
`;

const Section = styled.div`
  margin: 0 0 25px;

  &:last-child {
    margin: 0;
  }
`;

const SuppliesList = ({ currency, language, products, productType, ...props }) => {
  const { fetchProducts } = props;

  useEffect(() => {
    const productCategories = CATEGORIES[productType];
    fetchProducts(productCategories);
  }, [fetchProducts, productType]);

  const { data, loading, error } = products;

  if (error) return <AlertInfo />;
  if (loading) return <Loader small />;

  const renderItems = (items = []) => {
    if (!items.length) {
      return (
        <tr>
          <td colspan="3">No items found</td>
        </tr>
      );
    }

    return items.map((item, i) => {
      const itemId = item.product_list_item_id;

      return (
        <LinkRow key={i} to={`${productType}/${itemId}`} enabled>
          <td style={computeTableWidth(100)}>{item.display_item_number || item.item_number || ''}</td>
          <td style={computeTableWidth(200)}>{renderDescription(item, language)}</td>
          <td style={computeTableWidth(100)}>
            <strong>
              <Currency quantity={item.productListItemPrice || item.price} currency={currency} />
            </strong>
          </td>
        </LinkRow>
      );
    });
  };

  if (isEmpty(data)) return <AlertInfo>No Items Found</AlertInfo>;

  const sections = sortBy(Object.keys(data));
  return sections.map(section => (
    <Section key={section}>
      <ListTitle>{section}</ListTitle>
      <ListTable>
        <thead>
          <tr>
            <th style={computeTableWidth(100)}>{translate('itemNumber', 'Item Number')}</th>
            <th style={computeTableWidth(300)}>{translate('description', 'Description')}</th>
            <th style={computeTableWidth(100)}>{translate('price', 'Price')}</th>
          </tr>
        </thead>
        <tbody>{renderItems(data[section])}</tbody>
      </ListTable>
    </Section>
  ));
};

const mapState = (state, { match }) => ({
  currency: orderSelectors.currency(state),
  language: userSelectors.language(state),
  products: selectors.products(state),
  productType: match.params.productType,
});

const mapDispatch = {
  fetchProducts: actions.fetchProductListItems,
};

const enhance = compose(withRouter, connect(mapState, mapDispatch));
export default enhance(SuppliesList);
