import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';

import { makeNotesSelector, actions } from 'modules/account/notes';
import { actions as modalActions } from 'modules/modal';
import { colors } from 'style/theme';

import NotesList from './NotesList';

const renderContent = (htmlContent = '') => ({ __html: htmlContent });

class NotesListContainer extends PureComponent {
  deleteNote = (note, type) => {
    const { deleteNote, showModal } = this.props;

    showModal('confirm', {
      title: 'Delete Note',
      type: 'alert',
      locked: true,
      message: (
        <Fragment>
          <p>
            Are you sure you want to delete the following <strong>{type}</strong> note?
          </p>
          <div
            style={{ border: `1px solid ${colors.grayLight}`, borderRadius: 5, padding: 8 }}
            dangerouslySetInnerHTML={renderContent(note.htmlContent)}
          />
        </Fragment>
      ),
      onClose: () => deleteNote(note.id),
    });
  };

  render() {
    return <NotesList {...this.props} deleteNote={this.deleteNote} />;
  }
}

const makeMapState = () => {
  const notesSelector = makeNotesSelector();
  return (state, ownProps) => ({ type: ownProps.notebook, notes: notesSelector(state, ownProps) });
};

const mapDispatch = {
  deleteNote: actions.deleteNote,
  editNote: actions.openAccountNoteDialog,
  showModal: modalActions.showModal,
};

export default connect(makeMapState, mapDispatch)(NotesListContainer);
