import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import map from 'lodash/map';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { selectors, actions, notebookTypes } from 'modules/account/notes';
import { translate } from 'utils/translations';

import NoteForm from './NoteForm';

const rawStringToState = rawString => EditorState.createWithContent(convertFromRaw(JSON.parse(rawString)));

class NoteFormContainer extends PureComponent {
  static propTypes = {
    accountNumber: PropTypes.string.isRequired,
    existingNotes: PropTypes.arrayOf(PropTypes.string).isRequired,
    note: PropTypes.object,
    notebookTypes: PropTypes.oneOf(values(notebookTypes)),
    saveNote: PropTypes.func.isRequired,
    saving: PropTypes.bool,
    saveError: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const { note } = props;

    this.state = {
      editorState: note ? rawStringToState(note.rawContent) : EditorState.createEmpty(),
      noteMask: note ? note.noteMask : '',
      ticketNumber: note ? note.ticketNumber : '',
      validation: [],
    };
  }

  updateField = (field, value) => this.setState(() => ({ [field]: value }));

  valid = () => {
    let validation = [];

    if (!this.state.noteMask || this.state.noteMask.length === 0) {
      validation.push({
        field: 'noteMask',
        reason: translate('noteMaskRequired'),
      });
    }

    if (validation.length) {
      this.setState(() => ({ validation }));
      return false;
    }

    return true;
  };

  saveForm = () => {
    if (!this.valid()) return;

    const content = this.state.editorState.getCurrentContent();

    const { note = {} } = this.props;

    this.props.saveNote({
      noteId: this.props.note ? this.props.note.id : null,
      params: {
        htmlContent: stateToHTML(content),
        noteMask: this.state.noteMask,
        rawContent: JSON.stringify(convertToRaw(content)),
        ticketNumber: this.state.ticketNumber,
        notebook: note.notebook,
      },
    });
  };

  render() {
    return (
      <NoteForm
        {...this.state}
        accountNumber={this.props.accountNumber}
        editing={!!this.props.note}
        existingNotes={this.props.existingNotes}
        notebookType={this.props.notebookType || this.props.note.notebook}
        saveHandler={this.saveForm}
        saving={this.props.saving}
        saveError={this.props.saveError}
        updateField={this.updateField}
      />
    );
  }
}

const mapState = state => ({
  notebookType: selectors.dialogNotebook(state),
  existingNotes: map(selectors.dialogNotes(state), 'noteMask'),
  note: selectors.note(state),
  saving: selectors.saving(state),
  saveError: selectors.saveError(state),
});

const mapDispatch = { saveNote: actions.saveNote };

export default connect(mapState, mapDispatch)(NoteFormContainer);
