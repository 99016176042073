import find from 'lodash/find';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { selectors as detailsSelectors } from 'modules/account/details';
import { actions, selectors } from 'modules/account/shippingAddresses';
import { actions as confirmationActions } from 'modules/checkout';
import orderActions from 'modules/order/actions';
import { actions as previousOrderActions } from 'modules/previousOrder';

import AddressCandidatesForm from './AddressCandidatesForm';

class AddressCandidatesFormContainer extends PureComponent {
  state = {
    selectedItem: this.props.unvalidatedAddress ? this.props.unvalidatedAddress.id : 0,
  };

  editAddress = () => {
    const { address } = this.getSelectedAddress();
    this.props.openAddressDialog({ address });
  };

  actions = {
    onEdit: () => this.editAddress(),
    onSelect: (_, value) => this.setState({ selectedItem: value }),
  };

  getSelectedAddress = () => {
    const { selectedItem } = this.state;
    const { account, candidates, unvalidatedAddress } = this.props;

    const candidateAddress = find(candidates, c => c.id === selectedItem);
    if (candidateAddress) {
      // Set our candidates final "id" to our unvalidated id
      // so we can reference the original
      return {
        type: 'candidate',
        address: {
          ...candidateAddress,
          id: unvalidatedAddress.id,
          account_id: account.id,
        },
      };
    }

    return { type: 'unvalidated', address: { ...unvalidatedAddress, account_id: account.id } };
  };

  placeNewOrder = () => {
    let { address, type } = this.getSelectedAddress();

    // Only skip validation if we choose to keep our invalid address
    this.props.stubOrder({ address, skipValidation: type === 'unvalidated' });
  };

  saveAddress = () => {
    const { address } = this.getSelectedAddress();
    const {
      isCheckout,
      isPreviousOrder,
      isConfirmation,
      saveAddress,
      updateConfirmationOrderAddress,
      updateOrderAddress,
      updatePreviousOrderAddress,
    } = this.props;

    if (isCheckout) return updateOrderAddress({ address, skipValidation: true });
    if (isConfirmation) return updateConfirmationOrderAddress({ address, skipValidation: true });
    if (isPreviousOrder) return updatePreviousOrderAddress({ address, skipValidation: true });

    return saveAddress({ address });
  };

  render() {
    return (
      <AddressCandidatesForm
        {...this.state}
        {...this.props}
        actions={this.actions}
        placeNewOrder={this.placeNewOrder}
        saveAddress={this.saveAddress}
      />
    );
  }
}

const mapState = state => ({
  account: detailsSelectors.details(state),
  candidates: selectors.candidates(state),
  error: selectors.error(state),
  loading: selectors.loading(state),
  unvalidatedAddress: selectors.unvalidatedAddress(state),
});

const mapDispatch = {
  closeCandidatesDialog: actions.closeCandidatesDialog,
  openAddressDialog: actions.openAddressDialog,
  saveAddress: actions.saveAddress,
  stubOrder: orderActions.stubOrder,
  updateOrderAddress: orderActions.updateOrderAddress,
  updatePreviousOrderAddress: previousOrderActions.modifyAddress,
  updateConfirmationOrderAddress: confirmationActions.updateConfirmationOrderAddress,
};

export default connect(mapState, mapDispatch)(AddressCandidatesFormContainer);
