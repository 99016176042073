import React from 'react';
import styled from 'styled-components';

import { ExportButton } from 'components/Buttons';
import DateRangePicker from 'components/DateRangePicker';
import Input from 'components/Input';
import { LabeledSelect } from 'components/LabeledInput';
import PanelContainer from 'components/Panel';
import RowItem from 'components/RowItem';
import Select from 'components/Select';
import { yesNoOptions, reportFormatOptions } from 'constants/options';
import { changeMethod } from 'utils/common';
import { translate } from 'utils/translations';

const ReportsContent = styled.div`
  display: flex;
`;

const OrdersFilter = styled.div`
  flex: 50%;
  margin-right: 1em;
`;

const ShippingFilter = styled.div`
  flex: 50%;
`;

const StyledContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const StyledRadioLabel = styled.div`
  padding-top: 12px;
  color: #898989;

  .RadioLabel__input {
    margin-right: 8px;
    font-size: 12px;
  }
`;

const StyledValueRange = styled.div`
  display: inline-block;
  float: right;

  label {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
  }

  .ValueRange__input {
    border-radius: 3px;
    border: 1px solid #cfcfcf;
    font-size: 14px;
    padding: 6px 4px;
    width: 138px;
    height: 36px;
    margin-left: 8px;
    margin-right: 8px;
  }
`;

const ReportFormat = styled.div`
  display: flex;
  margin-top: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 20px;
`;

const Reports = ({
  params,
  updateParam,
  onSubmit,
  privateLabels,
  distributionPoints,
  labLocations,
  shippingOptions,
  buildCarrierOptions,
  isValid,
  padWithZeros,
  validateDateRange,
  invalidDateRange,
}) => {
  return (
    <form onSubmit={onSubmit}>
      <ReportsContent>
        <OrdersFilter>
          <PanelContainer title="Order Filters">
            <StyledContentContainer>
              <StyledRadioLabel>
                <input
                  className="RadioLabel__input"
                  type="radio"
                  name="selected"
                  value="dateShipped"
                  defaultChecked
                  onChange={changeMethod(updateParam)}
                />
                <label>{translate('dateShipped')}</label>
              </StyledRadioLabel>
              <DateRangePicker
                startDate={params.selected === 'dateOrdered' ? null : params.startDate}
                endDate={params.selected === 'dateOrdered' ? null : params.endDate}
                handleStartDateChange={changeMethod(updateParam, 'startDate')}
                handleEndDateChange={changeMethod(updateParam, 'endDate')}
                disabled={params.selected === 'dateOrdered'}
                width={138}
                onBlur={validateDateRange}
                invalid={params.selected === 'dateShipped' && invalidDateRange}
                invalidMsg="Invalid date range"
              />
            </StyledContentContainer>
            <StyledContentContainer>
              <StyledRadioLabel>
                <input
                  className="RadioLabel__input"
                  type="radio"
                  name="selected"
                  value="dateOrdered"
                  onChange={changeMethod(updateParam)}
                />
                <label>{translate('dateOrdered')}</label>
              </StyledRadioLabel>
              <DateRangePicker
                startDate={params.selected === 'dateShipped' ? null : params.startDate}
                endDate={params.selected === 'dateShipped' ? null : params.endDate}
                handleStartDateChange={changeMethod(updateParam, 'startDate')}
                handleEndDateChange={changeMethod(updateParam, 'endDate')}
                disabled={params.selected === 'dateShipped'}
                width={138}
                onBlur={validateDateRange}
                invalid={params.selected === 'dateOrdered' && invalidDateRange}
                invalidMsg="Invalid date range"
              />
            </StyledContentContainer>
            <RowItem center title={translate('plp')} titleStyle={{ marginRight: 53 }}>
              <Select
                style={{ width: 138 }}
                containerStyle={{ alignItems: 'start' }}
                options={privateLabels.map(p => ({ label: p.code, value: p.id }))}
                onChange={changeMethod(updateParam, 'plp')}
                value={params.plp}
              />
            </RowItem>
            <RowItem center title={translate('accountNumber')} titleStyle={{ paddingTop: 5 }}>
              <StyledValueRange>
                <label>
                  <span>From</span>
                  <Input
                    type="text"
                    className="ValueRange__input"
                    name="accountFrom"
                    onChange={changeMethod(updateParam)}
                    onBlur={() => padWithZeros('accountFrom', params.accountFrom)}
                    value={params.accountFrom || ''}
                    maxLength={14}
                  />
                </label>
                <label>
                  <span>To</span>
                  <Input
                    type="text"
                    className="ValueRange__input"
                    name="accountTo"
                    onChange={changeMethod(updateParam)}
                    onBlur={() => padWithZeros('accountTo', params.accountTo)}
                    value={params.accountTo || ''}
                    maxLength={14}
                  />
                </label>
              </StyledValueRange>
            </RowItem>
            <RowItem center title={translate('orderNumber')} titleStyle={{ paddingTop: 5 }}>
              <StyledValueRange>
                <label>
                  <span>From</span>
                  <Input
                    type="text"
                    className="ValueRange__input"
                    name="orderFrom"
                    onChange={changeMethod(updateParam)}
                  />
                </label>
                <label>
                  <span>To</span>
                  <Input
                    type="text"
                    className="ValueRange__input"
                    name="orderTo"
                    onChange={changeMethod(updateParam)}
                  />
                </label>
              </StyledValueRange>
            </RowItem>
          </PanelContainer>
        </OrdersFilter>
        <ShippingFilter>
          <PanelContainer height={270} title="Shipping Filters">
            <RowItem center title={translate('shipped')}>
              <Select
                style={{ width: 230 }}
                containerStyle={{ width: 230 }}
                options={yesNoOptions}
                onChange={changeMethod(updateParam, 'shipped')}
                value={params.shipped}
              />
            </RowItem>
            <RowItem center title={translate('shippedFrom')}>
              <Select
                style={{ width: 230 }}
                containerStyle={{ width: 230 }}
                options={distributionPoints.map(d => ({ label: d.code, value: d.id }))}
                onChange={changeMethod(updateParam, 'shipped_from')}
                value={params.shipped_from}
              />
            </RowItem>
            <RowItem center title={translate('shipping.carrier')}>
              <Select
                style={{ width: 230 }}
                containerStyle={{ width: 230 }}
                onChange={changeMethod(updateParam, 'shipping_carrier')}
                value={params.shipping_carrier}
                options={buildCarrierOptions(shippingOptions)}
              />
            </RowItem>
            <RowItem center title={translate('packedBy')}>
              <Input type="text" name="packed_by" onChange={changeMethod(updateParam)} style={{ width: 230 }} />
            </RowItem>
            <RowItem center title={translate('lab')}>
              <Select
                style={{ width: 230 }}
                containerStyle={{ width: 230 }}
                options={labLocations.map(l => ({ label: l.name, value: l.id }))}
                onChange={changeMethod(updateParam, 'lab')}
                value={params.lab}
              />
            </RowItem>
          </PanelContainer>
        </ShippingFilter>
      </ReportsContent>
      <ReportFormat>
        <LabeledSelect
          label={translate('reportFormat')}
          width={260}
          style={{ width: 260 }}
          options={reportFormatOptions}
          onChange={changeMethod(updateParam, 'reportFormat')}
          value={params.reportFormat}
        />
        <ButtonContainer>
          <ExportButton title="Generate CSV" disabled={!isValid} type="submit" />
        </ButtonContainer>
      </ReportFormat>
    </form>
  );
};

export default Reports;
