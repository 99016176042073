import get from 'lodash/get';

import { INVENTORY, KITS } from 'constants/strings';

export const selectors = {
  isAddingToCart: state => get(state, 'order.isAddingToCart'),
  address: state => get(state, 'order.address'),
  country: state => get(state, 'order.address.country'),
  currency: state => get(state, 'order.productList.currency', 'USD'),
  distributionPoint: state => get(state, 'order.productList.distributionPoint'),
  error: state => get(state, 'order.error'),
  hasOrder: state => {
    const {
      order: { id, address, items },
      account: {
        details: { data },
      },
    } = state;
    if (!address || !items || !data) {
      return false;
    }

    return !!id && data.id === address.account_id;
  },
  id: state => get(state, 'order.id'),
  items: state => get(state, 'order.items', []),
  kits: state => get(state, 'order.items', []).filter(i => i.item_type === KITS),
  inventory: state => get(state, 'order.items', []).filter(i => i.item_type === INVENTORY),
  itemCount: state => get(state, 'order.items', []).length,
  loading: state => get(state, 'order.loading'),
  productList: state => get(state, 'order.productList'),
  orderItemNotes: state => {
    const {
      order: { itemsMissing, itemsUpdated },
    } = state;
    return { itemsMissing, itemsUpdated };
  },
  labLocationCode: state => get(state, 'order.labLocation.code'),
  labelLanguageCode: state => get(state, 'order.labelLanguage.code'),
  summary: state => {
    const { address, discount, id, items, shippingPrice, subtotal, status, tax, total, taxMessage, useExistingOrder } =
      state.order;

    return {
      accountId: address.account_id,
      address,
      discount,
      items,
      orderId: id,
      shippingPrice,
      subtotal,
      status,
      tax,
      total,
      taxMessage,
      useExistingOrder,
    };
  },
};

export default selectors;
