// #region imports
import { createAction } from 'redux-actions';

import { createOrderSearch } from './helpers/createOrderSearch';
// #endregion

const { actions, reducer, saga, selectors } = createOrderSearch({
  requestAction: createAction('ORDER_SEARCH_REQUEST'),
  responseAction: createAction('ORDER_SEARCH_RESPONSE'),
  baseSelector: state => state.orderSearch,
});

export { actions, selectors, saga };
export default reducer;
