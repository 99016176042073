import { get } from 'lodash';

export const DEFAULT_REFERENCE = { options: [], raw: [] };

export const selectors = {
  all: state => get(state, 'reference'),
  accountInvoiceDeliveryMethods: state => get(state, 'reference.accountInvoiceDeliveryMethods', []),
  distributionPoints: state => get(state, 'reference.distributionPoints', []),
  labLocations: state => get(state, 'reference.labLocations', []),
  orderStatuses: state => get(state, 'reference.orderStatuses', DEFAULT_REFERENCE),
  privateLabels: state => get(state, 'reference.privateLabels', []),
  shippingOptions: state => get(state, 'reference.shippingOptions', []),
};
