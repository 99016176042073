import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  margin: 0 0 15px;

  > label {
    display: inline-block;
    margin-left: 15px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 5px;
`;

const NoteMaskSelector = ({ accountNumber, disabled, existingNotes, noteMask, onSelect }) => {
  const tier1Mask = accountNumber.slice(0, 6);
  const tier2Mask = accountNumber.slice(0, 10);
  const tier3Mask = accountNumber;
  const allTiers = [tier1Mask, tier2Mask, tier3Mask];

  return (
    <Root>
      <label>
        <Checkbox
          checked={allTiers.indexOf(noteMask) !== -1}
          onChange={() => onSelect(tier1Mask)}
          disabled={disabled || existingNotes.indexOf(tier1Mask) > -1}
        />
        {tier1Mask}
      </label>
      <label>
        <Checkbox
          checked={allTiers.slice(1).indexOf(noteMask) !== -1}
          onChange={() => onSelect(tier2Mask)}
          disabled={disabled || existingNotes.indexOf(tier2Mask) > -1}
        />
        {tier2Mask.slice(tier1Mask.length)}
      </label>
      <label>
        <Checkbox
          checked={noteMask === tier3Mask}
          onChange={() => onSelect(accountNumber)}
          disabled={disabled || existingNotes.indexOf(tier3Mask) > -1}
        />
        {tier3Mask.slice(tier2Mask.length)}
      </label>
    </Root>
  );
};

NoteMaskSelector.propTypes = {
  accountNumber: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  existingNotes: PropTypes.arrayOf(PropTypes.string),
  noteMask: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default NoteMaskSelector;
