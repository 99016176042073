import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { colors } from 'style/theme';

const ItemManager = styled.div``;

const Item = styled.div`
  border: 1px solid transparent;
  cursor: pointer;
  border-color: ${props => (props.selected ? colors.blueColumbia : 'transparent')};
  background: ${props => (props.selected ? colors.grayAthens : 'transparent')};
`;

const AddItem = styled.a`
  color: ${colors.blueWater};
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
  padding: 15px 34px;
`;

const ItemManagerContainer = ({
  actions = {},
  addItem,
  addItemText = '',
  children,
  component,
  defaultAddressId,
  editable = true,
  items = [],
  otherOptions = {},
  selectedItem,
  showAddItem = true,
}) => {
  const selected = item => item.id === selectedItem;
  return (
    <ItemManager>
      {items.map(item => (
        <Item key={item.id} selected={selected(item)}>
          {React.createElement(component, {
            selected: selected(item),
            editable,
            defaultAddressId,
            ...otherOptions,
            ...actions,
            ...item,
          })}
        </Item>
      ))}
      {children}
      {addItem && showAddItem && <AddItem onClick={addItem}>{addItemText}</AddItem>}
    </ItemManager>
  );
};

ItemManagerContainer.propTypes = {
  component: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  actions: PropTypes.object,
  addItem: PropTypes.func,
  selectedItem: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ItemManagerContainer.defaultProps = {
  addItemText: 'Add new item',
};

export default ItemManagerContainer;
