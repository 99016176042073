import get from 'lodash/get';
import { createActions, handleActions } from 'redux-actions';
import { all, takeLatest, call, put } from 'redux-saga/effects';

import * as api from 'lib/api';

// default state
const defaultState = {
  accounts: [],
  error: false,
  loading: false,
  hasSearched: false,
  validSearch: true,
  searchData: null,
  params: {
    page: '1',
    pageSize: '100',
    sortDirection: 'asc',
    sort: 'number',
  },
};

// actions
export const actions = createActions('CLEAR_RESULTS', 'FETCH_ACCOUNTS', 'ACCOUNTS_RESPONSE', 'INVALID_SEARCH');

// selectors
export const selectors = {
  accounts: state => get(state, 'account.search.accounts'),
  error: state => get(state, 'account.search.error'),
  loading: state => get(state, 'account.search.loading'),
  hasSearched: state => get(state, 'account.search.hasSearched'),
  params: state => get(state, 'account.search.params'),
  searchData: state => get(state, 'account.search.searchData'),
  validSearch: state => get(state, 'account.search.validSearch'),
};

// sagas
function* fetchAccountsSaga({ payload: params }) {
  try {
    const data = yield call(api.searchAccounts, { params });
    if (data) {
      yield put(actions.accountsResponse(data));
    }
  } catch (e) {
    yield put(actions.accountsResponse(e));
  }
}

export function* searchSaga() {
  yield all([takeLatest(actions.fetchAccounts, fetchAccountsSaga)]);
}

// reducer
export default handleActions(
  {
    [actions.clearResults]() {
      return { ...defaultState };
    },
    [actions.fetchAccounts](state, { payload }) {
      return { ...defaultState, params: payload, loading: true };
    },
    [actions.accountsResponse]: {
      next(state, { payload }) {
        const { accounts, page, pageSize, totalResults } = payload;
        return {
          ...state,
          accounts,
          searchData: { page, pageSize, totalResults },
          loading: false,
          hasSearched: true,
        };
      },
      throw(state) {
        return { ...defaultState, error: true };
      },
    },
    [actions.invalidSearch](state) {
      return { accounts: [], hasSearched: true, validSearch: false };
    },
  },
  defaultState
);
