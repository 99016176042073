import styled from 'styled-components';

import { colors } from 'style/theme';

const Error = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  font-size: 12px;
  background-color: ${colors.redLightSalmon};
  border: 1px solid ${colors.redFlamingo};
  margin-bottom: 10px;
`;

export default Error;
