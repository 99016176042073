import { PREPAID_LABEL } from 'constants/categories';
import { findDescription } from 'utils/display';

// utility functions for reducer
// simplifyItem takes a returned item and reduces it down to what the UI needs
export default function simplifyItem(orderItem, language = 'en') {
  const { csrOverride, id, item, label_only, prefill_label, price, private_label, quantity, totalPrice } = orderItem;

  const {
    category,
    display_description,
    display_item_number,
    images = [],
    item_number,
    items,
    label_language_id,
    lab_location_id,
    listable_type,
    name,
    pack_size,
    product_list_item_id,
    split_bill,
  } = item;

  const [featuredImage] = images;
  const description = findDescription(item, language);

  return {
    actual_description: display_description || description,
    actual_item_number: display_item_number || item_number,
    category,
    csrOverride,
    description,
    display_item_number,
    featuredImage,
    id,
    images,
    item_number,
    item_type: listable_type,
    items: label_only ? items.filter(item => item.category === PREPAID_LABEL) : items,
    labelsOnly: label_only,
    label_language_id,
    lab_location_id,
    name,
    packSize: pack_size,
    prefillLabel: prefill_label,
    price,
    private_label,
    productListItemId: product_list_item_id,
    quantity,
    splitBill: split_bill,
    totalPrice,
  };
}
