export const DEFAULT_BILLING_ADDRESS = {
  firstName: '',
  lastName: '',
  companyName: '',
  address: '',
  city: '',
  country: '',
  state: '',
  zip: '',
};

export const DEFAULT_PAYMENT = {
  creditCardId: 'create',
  paymentMethod: 'invoice',
  purchaseOrderNumber: '',
  customerInfo: {
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  creditCardInfo: {
    cardNumber: '',
    expirationDate: '',
    cvv: '',
  },
  touched: {},
  valid: false,
};
