import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import styled from 'styled-components';

import Loader from 'components/Loader';
import { selectors } from 'modules/account/details';
import { selectors as userSelectors } from 'modules/user';
import { translate } from 'utils/translations';

import AccountInfo from './AccountInfo';
import { AccountNotes } from './AccountNotes';
import BillingInfo from './BillingInfo.container';
import ShippingInfo from './ShippingInfo.container';

const Error = styled.div`
  color: darkred;
  text-align: center;
`;

const PanelsContainer = styled.div`
  display: flex;
  margin-bottom: 30px;

  > div {
    flex: 1 1 auto;
    margin-bottom: 0;

    &:first-child {
      margin-right: 15px;
    }

    &:last-child {
      margin-left: 15px;
    }
  }
`;

const Root = styled.div`
  position: static;
`;

const AccountDetails = ({ details, error, loading, isCsr }) => {
  if (error) return <Error>{translate('anErrorOccurred')}</Error>;
  if (loading) return <Loader />;

  const accountNumber = get(details, 'number');
  if (!accountNumber) return null;

  return (
    <Root>
      <PanelsContainer>
        <AccountInfo details={details} isCsr={isCsr} />
        <BillingInfo accountNumber={accountNumber} />
      </PanelsContainer>
      <ShippingInfo accountNumber={accountNumber} />
      {isCsr && <AccountNotes accountNumber={accountNumber} />}
    </Root>
  );
};

const mapState = state => ({
  details: selectors.details(state),
  error: selectors.error(state),
  loading: selectors.loading(state),
  isCsr: userSelectors.isCsr(state),
});

const enhance = compose(withRouter, connect(mapState));

export default enhance(AccountDetails);
