// #region imports
import { noop } from 'lodash';
import React from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import { translate } from 'utils/translations';
// #endregion

const Root = styled.div`
  display: inline-block;

  label {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;

    > div {
      display: inline-block;
      margin: 0 8px;
    }
  }

  .DateRangePicker__input {
    border-radius: 3px;
    border: 1px solid #cfcfcf;
    font-size: 14px;
    padding: 6px 4px;
    width: ${props => (props.width ? `${props.width}px` : '82px')};
    height: 36px;
    opacity: ${props => props.disabled && 0.5};
    ${props =>
      props.invalid &&
      `
        transition: border-color 333ms, outline-color 333ms;
        border-color: red;
        outline-color: red;
    `};
  }

  .DateRangePicker__label {
    opacity: ${props => props.disabled && 0.5};
  }

  .DateRangePicker__validation {
    font-size: 12px;
    position: absolute;
    background: darkred;
    margin-top: -1px;
    white-space: nowrap;
    color: white;
    padding: 0 8px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 100;
    margin-left: 37px;
  }
`;

const DateRangePicker = ({
  endDate = null,
  handleStartDateChange = noop,
  handleEndDateChange = noop,
  startDate = null,
  disabled = false,
  width,
  invalid,
  invalidMsg,
  ...props
}) => (
  <Root {...props} disabled={disabled} width={width} invalid={invalid}>
    <label onClick={e => e.preventDefault()}>
      <span className="DateRangePicker__label">{translate('from', 'From')}</span>
      <DatePicker
        className="DateRangePicker__input"
        selectsStart
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        maxDate={endDate}
        onChange={handleStartDateChange}
        disabled={disabled}
        disabledKeyboardNavigation
      />
    </label>
    <label onClick={e => e.preventDefault()}>
      <span className="DateRangePicker__label">{translate('to', 'To')}</span>
      <DatePicker
        className="DateRangePicker__input"
        selectsEnd
        selected={endDate}
        startDate={startDate}
        minDate={startDate}
        endDate={endDate}
        onChange={handleEndDateChange}
        disabled={disabled}
        disabledKeyboardNavigation
      />
    </label>
    {invalid && <div className="DateRangePicker__validation">{invalidMsg}</div>}
  </Root>
);

export default DateRangePicker;
