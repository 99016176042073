import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { selectors as accountSelectors } from 'modules/account/details';
import { actions as modalActions } from 'modules/modal';
import { actions as orderActions } from 'modules/order';
import { actions, selectors } from 'modules/recurringOrderSearch';
import { translate } from 'utils/translations';

import RecurringOrders from './RecurringOrders';

export class UnconnectedContainer extends PureComponent {
  componentDidMount() {
    const { id } = this.props.account;
    this.props.fetchResults(id);
  }

  cancelRecurringOrder = order => {
    const { cancelRecurringOrder, showModal } = this.props;
    const { id } = order;

    showModal('confirm', {
      type: 'alert',
      locked: true,
      title: `${translate('store.cancelRecurOrderTitle', 'Cancel Recurring Order')} #${id}`,
      message: translate('store.cancelRecurOrderMessage', 'Are you sure you want to cancel this recurring order?'),
      closeText: translate('store.cancelOrderClose', 'Yes, Cancel Order'),
      cancelText: translate('store.cancelOrderCancel', 'No, Nevermind'),
      onClose: () => cancelRecurringOrder(id),
    });
  };

  deleteRecurringOrder = order => {
    const { deleteRecurringOrder, showModal } = this.props;
    const { id } = order;

    showModal('confirm', {
      type: 'alert',
      locked: true,
      title: `${translate('store.deleteRecurOrderTitle')} #${id}`,
      message: translate('store.deleteRecurOrderMessage'),
      closeText: translate('store.deleteOrderClose'),
      cancelText: translate('store.deleteOrderCancel'),
      onClose: () => deleteRecurringOrder(id),
    });
  };

  getChildProps() {
    return {
      ...this.props,
      onCancel: this.cancelRecurringOrder,
      onDelete: this.deleteRecurringOrder,
    };
  }

  render() {
    return <RecurringOrders {...this.getChildProps()} />;
  }
}

const mapState = state => ({
  account: accountSelectors.details(state),
  hasSearched: selectors.hasSearched(state),
  orders: selectors.orders(state),
  error: selectors.error(state),
});

const mapDispatch = {
  fetchResults: actions.requestAction,
  cancelRecurringOrder: orderActions.cancelRecurringOrder,
  deleteRecurringOrder: orderActions.deleteRecurringOrder,
  showModal: modalActions.showModal,
};

export default connect(mapState, mapDispatch)(UnconnectedContainer);
