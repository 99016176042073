import get from 'lodash/get';
import noop from 'lodash/noop';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import AddressCandidatesForm from 'components/AddressCandidatesForm';
import AddressForm from 'components/AddressForm';
import Button from 'components/Buttons';
import Currency from 'components/Currency';
import Input from 'components/Input';
import { PACKED, SHIPPED } from 'constants/orderStatuses';
import { CREDIT_CARD } from 'constants/strings';
import { selectors as userSelectors } from 'modules/user';
import { colors } from 'style/theme';
import { changeMethod } from 'utils/common';
import { translate } from 'utils/translations';

const StyledDetails = styled.div`
  padding: 15px;
  background: ${colors.whiteAlabaster};
  border: 1px solid ${colors.grayWhisper};
  font-size: 12px;

  .Details__section {
    margin: 0 0 15px;

    &:last-child {
      margin: 0;
    }

    &-label {
      font-weight: 700;
    }
  }

  .Details__edit {
    display: inline-block;
    color: ${colors.blueWater};
    cursor: pointer;
    margin-left: 5px;
  }

  .Details__buttons {
    display: flex;
    justify-content: space-between;
  }
  .Details__inline {
    display: flex;
    align-items: center;
    .Details__inline-label {
      margin-left: 5px;
    }
  }
`;

const OrderDetails = ({
  editable,
  editingTracking,
  fieldUpdate,
  isConfirmation,
  isPreviousOrder,
  noShippingInfo,
  order = {},
  placedFor,
  purchaseOrderNumber,
  referencePo,
  save,
  showCandidatesDialog,
  showDialog,
  status,
  summary = {},
  toggleEditingTracking,
  trackingNumber,
  onEditingToggle = noop,
  onEditShipping = noop,
  ...props
}) => {
  const isCsr = useSelector(userSelectors.isCsr);
  const { address = {}, payment = {}, placedBy = {}, emails = [], shipping = {} } = summary;

  const isCreditCard = payment.method === CREDIT_CARD;
  const isValidStatus = status !== SHIPPED;
  const isShippedStatus = status === SHIPPED;

  return (
    <StyledDetails {...props}>
      {!noShippingInfo && (
        <div className="Details__section">
          <div className="Details__section-label">
            {translate('store.shippingAddress', 'Shipping Address')}{' '}
            {!isCreditCard && isValidStatus && (
              <span className="Details__edit" onClick={() => onEditShipping(address)}>
                <i className="fa fa-pencil" />
              </span>
            )}
          </div>
          <div>{address.company_name}</div>
          {address.attention && <div>Attn: {address.attention}</div>}
          <div>{address.address1}</div>
          <div>{address.address2}</div>
          <div>
            {address.city}, {address.region} {address.post_code}
          </div>
          <div>{address.country}</div>
          <div>{address.phone_number}</div>
        </div>
      )}
      <div className="Details__section">
        <div className="Details__section-label">
          {translate('shipping.paymentMethod', 'Payment Method')}{' '}
          {!editable && isValidStatus && (
            <div className="Details__edit" onClick={onEditingToggle}>
              <i className="fa fa-pencil" />
            </div>
          )}
        </div>
        {editable ? (
          <div className="Details__inline">
            <div>PO#: </div>
            <Input
              className="Details__inline-label"
              name="purchaseOrderNumber"
              onChange={changeMethod(fieldUpdate)}
              value={purchaseOrderNumber}
            />
          </div>
        ) : (
          purchaseOrderNumber && <div>PO#: {purchaseOrderNumber}</div>
        )}
        {payment.method === 'invoice' ? (
          <div>{translate('store.invoice', 'Invoice')}</div>
        ) : (
          <div>
            {translate('creditCard', 'Credit Card')}: {payment.number}
          </div>
        )}
      </div>
      {!noShippingInfo && [PACKED, SHIPPED].includes(order.status) && (
        <div className="Details__section">
          <div className="Details__section-label">
            {translate('store.shippingDetails', 'Shipping Details')}
            {isShippedStatus && isCsr && (
              <span className="Details__edit" onClick={() => toggleEditingTracking()}>
                <i className="fa fa-pencil" />
              </span>
            )}
          </div>
          {editingTracking ? (
            <div>
              Tracking #:
              <Input
                className="Details__inline-label"
                name="trackingNumber"
                onChange={changeMethod(fieldUpdate)}
                value={trackingNumber}
              />
            </div>
          ) : (
            <div>
              {' '}
              {translate('trackingNumber', 'Tracking #')}: {shipping.trackingNumber}
            </div>
          )}
          <div>
            {translate('shippingCost', 'Shipping Cost')}:{' '}
            {shipping.shippingCost ? <Currency quantity={shipping.shippingCost} /> : 'N/A'}
          </div>
          {!shipping.shippingCost && (
            <div>
              {translate('carrierAccountNumber', 'Carrier Account Number')}: {shipping.shippingAccountNumber}
            </div>
          )}
        </div>
      )}
      <div className="Details__section">
        <div className="Details__section-label">{translate('shipping.orderPlacedBy', 'Order Placed By')}</div>
        <div>{get(placedBy, 'name', '')}</div>
      </div>
      <div className="Details__section">
        <div className="Details__section-label">
          {translate('shipping.orderPlacedFor', 'Order Placed For')}
          {!editable && isValidStatus && (
            <div className="Details__edit" onClick={onEditingToggle}>
              <i className="fa fa-pencil" />
            </div>
          )}
        </div>
        {editable ? (
          <div className="Details__inline">
            <Input name="placedFor" onChange={changeMethod(fieldUpdate)} value={placedFor} />
          </div>
        ) : (
          placedFor
        )}
        <div>{get(placedFor, 'name', '')}</div>
        {editable ? (
          <div className="Details__inline">
            <div>Reference PO#: </div>
            <Input
              className="Details__inline-label"
              name="referencePo"
              onChange={changeMethod(fieldUpdate)}
              value={referencePo}
            />
          </div>
        ) : (
          referencePo && <div>Reference PO#: {referencePo}</div>
        )}
      </div>
      <div className="Details__section">
        <div className="Details__section-label">
          {translate('order.confirmationEmails', 'Confirmation Emails Sent to')}
        </div>
        <div>
          {emails.length === 0 && <div>None</div>}
          {emails.map(({ id, email }) => (
            <div key={id}>{email}</div>
          ))}
        </div>
      </div>
      <div className="Details__section">
        <div className="Details__section-label">
          {translate('order.carrierAccountNumber', 'Carrier Account Number')}
        </div>
        <div>{get(shipping, 'shippingAccountNumber', '')}</div>
      </div>
      {(editable || editingTracking) && (
        <div className="Details__buttons">
          <Button onClick={editingTracking ? toggleEditingTracking : onEditingToggle} icon="times" alert inline>
            {translate('shipping.cancel', 'Cancel')}
          </Button>
          <Button onClick={save} icon="print" inline success>
            {translate('store.save', 'Save')}
          </Button>
        </div>
      )}
      {showDialog && <AddressForm isConfirmation={isConfirmation} isPreviousOrder={isPreviousOrder} />}
      {showCandidatesDialog && (
        <AddressCandidatesForm isConfirmation={isConfirmation} isPreviousOrder={isPreviousOrder} />
      )}
    </StyledDetails>
  );
};

export default OrderDetails;
