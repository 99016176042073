import React, { useCallback } from 'react';
import styled from 'styled-components';

import { colors } from 'style/theme';

const StyledRadio = styled.label`
  display: flex;
  cursor: pointer;
  padding: 2px;

  ${props => props.inline && 'display: inline-flex'};
  ${props => props.hasLabel && 'align-items: center'};

  input {
    display: none;
  }

  .Radio__element {
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50px;
    border: 2px solid ${colors.whiteAlto};
    transition: background 300ms, border-color 300ms;
  }

  .Radio__check {
    position: absolute;
    top: 2px;
    bottom: 2px;
    right: 2px;
    left: 2px;
    border-radius: 50px;
    background: ${colors.blueDenim};
    opacity: 0;
    transition: opacity 300ms, background 300ms;
  }

  .Radio__label {
    margin-left: 10px;
  }

  &:hover .Radio__check {
    opacity: 0.8;
  }

  &:hover .Radio__element {
    border-color: ${colors.blueDenim};
  }

  input:checked + .Radio__element {
    background: ${colors.blueDenim};
    border-color: ${colors.blueDenim};
  }

  input:checked + .Radio__element .Radio__check {
    opacity: 1;
    background: ${colors.white};
  }
`;

const Radio = ({ checked, inline, label, name, onChange }) => {
  const onRadioCheck = useCallback(
    e => {
      const { checked } = e.target;
      onChange(name, checked);
    },
    [name, onChange]
  );

  return (
    <StyledRadio hasLabel={!!label} inline={inline}>
      <input type="radio" checked={checked} onChange={onRadioCheck} />
      <div className="Radio__element">
        <div className="Radio__check" />
      </div>
      {label && <div className="Radio__label">{label}</div>}
    </StyledRadio>
  );
};

export { Radio };
