import React from 'react';
import styled from 'styled-components';

import PanelContainer from 'components/Panel';
import { translate } from 'utils/translations';

const LayoutRoot = styled.div`
  width: 100%;

  .Summary__title {
    display: flex;
    align-items: center;
    padding: 5px 15px;
  }

  .Summary__titleName {
    font-size: 14px;
    font-weight: 700;
    margin-right: 10px;
  }

  .Summary__totals {
    display: flex;
    justify-content: flex-end;
  }
`;

const SummaryTablePanel = ({ table, totals }) => (
  <LayoutRoot>
    <PanelContainer
      title={
        <div className="Summary__title">
          <div className="Summary__titleName">{translate('checkout.orderSummary', 'Order Summary')}</div>
        </div>
      }
      style={{ margin: '0 0 15px' }}
      collapse
    >
      {table}
    </PanelContainer>
    <div className="Summary__totals">{totals}</div>
  </LayoutRoot>
);

export default SummaryTablePanel;
