import * as config from 'config';
const { THEME_URL } = config;

export const colors = {
  black: '#000',
  blueBahama: '#1b417d',
  blueColumbia: '#bbdefb',
  blueCuriousBlue: '#1E88E5',
  blueDenim: '#1565c0',
  blueEndeavour: '#0060B9',
  blueHawkes: '#E3F2FD',
  bluePajamas: '#e5f2fc',
  blueSalt: '#d0e6fa',
  blueSmalt: '#003893',
  blueWater: '#1976d2',
  grayBenjamin: '#b3b9ca',
  grayHeather: '#b0bec5',
  grayMineShaft: '#333',
  grayEmperor: '#555',
  grayWarm: '#898989',
  grayLight: '#D1D1D1',
  grayMercury: '#E1E1E1',
  grayWhisper: '#e6e6e6',
  grayAthens: '#eff1f3',
  greenPear: '#1b5e20',
  greenApple: '#36a334',
  greenMantis: '#6fc86e',
  greenSpinach: '#50a84f',
  greenKale: '#c8e6c9',
  greenLeaf: '#e8f5e9',
  orangeCarmine: '#e8af5f',
  redBrick: '#b71c1c',
  redCarmine: '#9e0b0f',
  redCinnabar: '#e53935',
  redFlamingo: '#df5d56',
  redLightSalmon: '#ffcdd2',
  white: '#fff',
  whiteAlabaster: '#f8f8f8',
  whiteAlto: '#cfcfcf',
  whiteGallery: '#efefef',
  whiteLight: '#fdfdfd',
  yellow: '#ffcd07',
  yellowDarker: '#b59207',
};

const getCodes = (plpCode, programMask) => {
  const plp = plpCode || 'POL';
  const program = plp === 'POL' ? 'POLARI' : programMask;
  return { plp, program };
};

const getApplicationLogo = (plpCode, programMask) => {
  const { plp, program } = getCodes(plpCode, programMask);
  return `${THEME_URL}/rs/logo/name/App_${plp}_${program}`;
};

const getProgramLogo = (plpCode, programMask) => {
  const { plp, program } = getCodes(plpCode, programMask);
  return `${THEME_URL}/rs/logo/name/Program_${plp}_${program}`;
};

const getStylesheet = (styleKey = 'POLARIS') => {
  if (!styleKey) styleKey = 'POLARIS';
  return `${THEME_URL}/resources/com.polarislabs.horizon.HorizonApp/css/style_${styleKey}.css`;
};

const theme = styleOptions => {
  let { plp, program, applicationLogo, programLogo, styleKey } = { ...styleOptions };

  const stylesheetUrl = getStylesheet(styleKey);
  const stylesheetPolarisUrl = getStylesheet('POLARIS');
  if (!applicationLogo) applicationLogo = getApplicationLogo(plp, program);
  if (!programLogo) programLogo = getProgramLogo(plp, program);

  return {
    ...colors,
    primary: colors.blueSmalt,
    primaryText: colors.white,
    accent: colors.blueSmalt,
    accentText: colors.white,
    textColor: colors.black,
    applicationLogo,
    programLogo,
    stylesheetPolarisUrl,
    stylesheetUrl,
  };
};

export default theme;
