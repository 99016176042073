import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { SortButton } from 'components/Buttons';
import LabeledInput, { validateLength } from 'components/LabeledInput';

export const Root = styled.div`
  position: relative;

  .SearchSortLabel__label {
    display: block;
    margin-bottom: 10px;
  }
`;

const SearchSortLabel = ({
  label,
  invalidLength,
  sortDirection,
  searchHandler = noop,
  searchQuery = '',
  toggleHandler,
  toggleIsActive,
  ...props
}) => (
  <Root>
    <SortButton isActive={toggleIsActive} sortDirection={sortDirection} toggle={toggleHandler} />
    <LabeledInput
      label={label}
      value={searchQuery}
      onChange={e => searchHandler(e.target.value)}
      invalid={invalidLength && validateLength(searchQuery, invalidLength)}
      {...props}
    />
  </Root>
);

SearchSortLabel.propTypes = {
  invalidLength: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  searchHandler: PropTypes.func,
  searchQuery: PropTypes.string,
  sortDirection: PropTypes.string,
  toggleHandler: PropTypes.func.isRequired,
  toggleIsActive: PropTypes.bool,
};

export default SearchSortLabel;
