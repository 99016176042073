import React from 'react';

import { translate } from 'utils/translations';

import Button from './Button';

const PlaceNewOrderButton = props => (
  <Button {...props} icon="plus" success inline>
    {props.isInvoiceAddressAdd ? translate('placeOrder') : translate('placeNewOrder')}
  </Button>
);

export default PlaceNewOrderButton;
