import React, { Fragment, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import AddressForm from 'components/AddressForm';
import { ActionButton } from 'components/Buttons';
import EditableField from 'components/EditableField';
import Input from 'components/Input';
import Label from 'components/Label';
import PanelContainer from 'components/Panel';
import RowItem from 'components/RowItem';
import { PRODUCT_INVOICE_LEVEL } from 'constants/strings';
import { translate } from 'utils/translations';

import BillingInfoCSR from './BillingInfoCSR';

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  .Title__action {
    margin-left: 10px;
  }
`;

const Address = ({ address, invoiceLevelProduct }) => (
  <Fragment>
    {address.attention && invoiceLevelProduct && <RowItem title={translate('attention')}>{address.attention}</RowItem>}
    <RowItem title={translate('invoiceTo')}>
      <div>{address.address1}</div>
      {address.address2 && <div>{address.address2}</div>}
      <div>
        {address.city}, {address.region} {address.post_code}
      </div>
    </RowItem>
  </Fragment>
);

const title = (invoiceLevelProduct, accountMask, isCsr) => {
  const billingInfoTitle = translate('billingInfo');
  if (!isCsr || !invoiceLevelProduct) return billingInfoTitle;
  return (
    <>
      {billingInfoTitle}
      <Label style={{ marginLeft: 8, fontSize: 13 }} large csr>
        {PRODUCT_INVOICE_LEVEL}: {invoiceLevelProduct} [{accountMask}]
      </Label>
    </>
  );
};

const BillingInfo = ({
  billingInfo,
  showDialog,
  isInvoiceAccount,
  isCsr,
  onEditAddress,
  onSaveBilling,
  program,
  warningModal,
}) => {
  const { invoiceAddress, invoiceLevelProduct, email_address, invoiceAccount } = billingInfo;

  const [email, setEmail] = useState(email_address);

  useEffect(() => {
    setEmail(email_address);
  }, [email_address]);

  const billingAccountMask = useMemo(() => {
    if (!isCsr || !invoiceAccount || !program || !program.tiers || !program.tiers.length) {
      return '';
    }

    const tiers = program.tiers.sort((a, b) => a.start - b.start);
    const invoiceTier = tiers[invoiceLevelProduct - 1];
    const maskLength = invoiceTier.start - 1 + invoiceTier.length;
    return invoiceAccount.number.slice(0, maskLength);
  }, [program, invoiceAccount, invoiceLevelProduct, isCsr]);

  return (
    <Container>
      <PanelContainer
        title={
          <Title>
            <div className="Title__base">{title(invoiceLevelProduct, billingAccountMask, isCsr)}</div>
            {isCsr && (
              <div className="Title__action">
                <ActionButton onClick={() => onEditAddress(invoiceAddress)}>
                  <i className="fa fa-pencil" />
                </ActionButton>
              </div>
            )}
          </Title>
        }
        style={{ marginBottom: 0, flex: '1 1 auto' }}
      >
        <RowItem title={translate('companyName')}>{billingInfo.name && billingInfo.name}</RowItem>
        {billingInfo.invoiceAddress && (
          <Address address={billingInfo.invoiceAddress} invoiceLevelProduct={invoiceLevelProduct} />
        )}
        <RowItem title={translate('phone')}>{billingInfo.phone}</RowItem>
        <RowItem title={translate('email')}>
          {isCsr ? (
            <EditableField
              editConfirmation={!isInvoiceAccount ? warningModal : false}
              value={billingInfo}
              onSave={() => onSaveBilling({ email_address: email })}
            >
              {({ editing }) => {
                if (editing) {
                  return <Input name="email" onChange={e => setEmail(e.target.value)} value={email || ''} />;
                }
                return email;
              }}
            </EditableField>
          ) : (
            billingInfo.email_address
          )}
        </RowItem>
        <RowItem title={translate('invoiceDeliveryPreference')}>{billingInfo.invoiceDeliveryMethod}</RowItem>
        <RowItem title={translate('billingRequirements')}>
          {billingInfo.billingOption && billingInfo.billingOption}
          {billingInfo.blanketPo && (
            <span>
              <br />
              {`Blanket Order PO: ${billingInfo.blanketPo}`}
            </span>
          )}
        </RowItem>
      </PanelContainer>
      {isCsr && (
        <BillingInfoCSR
          data={billingInfo}
          onSave={onSaveBilling}
          isInvoiceAccount={isInvoiceAccount}
          warningModal={warningModal}
        />
      )}
      {showDialog && (
        <AddressForm
          address={{ ...billingInfo.invoiceAddress, company_name: billingInfo.name, phone_number: billingInfo.phone }}
          isBilling
        />
      )}
    </Container>
  );
};

export default BillingInfo;
