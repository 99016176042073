import styled from 'styled-components';

import { colors } from 'style/theme';

const ListTable = styled.table`
  color: ${colors.grayMineShaft};
  background: ${colors.whiteAlabaster};
  border-radius: 3px;
  font-size: 12px;
  width: 100%;

  thead {
    vertical-align: top;
  }

  th {
    padding: 10px 15px;
    font-weight: normal;
    color: ${colors.grayEmperor};
    text-align: left;
  }

  td {
    padding: 5px 15px;
  }

  tbody tr:nth-child(odd) {
    background-color: ${colors.whiteGallery};
  }
`;

export default ListTable;
