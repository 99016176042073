import { Breadcrumbs } from '@polarislabs/polaris-ui-components';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { renderMask } from 'utils/display';
import { translate } from 'utils/translations';

const OrderBreadcrumbs = ({ match }) => {
  const { accountNumber, itemNumber, productType } = match.params;

  const routes = [
    { url: '/accounts', label: translate('accounts', 'Accounts') },
    { url: `/accounts/${accountNumber}/details`, label: renderMask(accountNumber) },
  ];

  if (itemNumber) {
    routes.push({ url: `/accounts/${accountNumber}/order/${productType}`, label: translate(productType) });
    routes.push({ label: itemNumber });
  } else {
    routes.push({ label: translate(productType) });
  }

  return <Breadcrumbs routes={routes} />;
};

export default withRouter(OrderBreadcrumbs);
