export const yesNoOptions = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];
export const reportFormatOptions = [
  { label: 'Inventory Ordered', value: 'inventory_ordered' },
  { label: 'Jar Count', value: 'jar_count' },
  { label: 'Kit Count by PLP', value: 'kit_count_plp' },
  { label: 'Kit Count by Program', value: 'kit_count_program' },
  { label: 'Kit Count by Account', value: 'kit_count_account' },
  { label: 'Order Audit', value: 'order_audit' },
];
export const rushTestingOptions = ['Inherit', 'CC Required', 'Invoice', 'Not Allowed', 'PO Required'];
