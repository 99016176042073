import React from 'react';

import ModalWrapper from './ModalWrapper';

const AlertModal = ({ ...props }) => {
  return (
    <ModalWrapper {...props} cancelButton={false} title={props.title === undefined ? 'Alert' : null} width={600}>
      <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{props.message}</div>
    </ModalWrapper>
  );
};

export default AlertModal;
