import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Textarea } from 'components/Input';
import { colors } from 'style/theme';
import { translate } from 'utils/translations';

export const validateLength = (text = '', len) =>
  !!text.length && text.length < len && translate('validationMinLength', '{len} character minimum', { len });

const Root = styled.div`
  display: block;
  position: relative;

  .labelText {
    font-size: 12px;
    color: ${colors.grayEmperor};
    margin-bottom: 5px;
    span {
      color: #959595;
    }
  }

  .LabeledTextarea__input {
    ${props =>
      props.invalid &&
      `
      transition: border-color 333ms, outline-color 333ms;
      border-color: darkred;
      outline-color: darkred;
    `};
  }

  .LabeledTextarea__validation {
    color: darkred;
    font-size: 12px;
    position: absolute;
    bottom: -1.2rem;
  }
`;

const InlineLabel = styled.label`
  display: flex;
  width: 100%;
  align-items: center;

  .labelText {
    margin-right: 11px;
    margin-bottom: 0;
  }
`;

const LabeledTextarea = ({ invalid, inline, label, secondary, style, width, labelStyle, ...props }) => {
  const Label = inline ? InlineLabel : 'label';

  return (
    <Root invalid={invalid} inline={inline} style={style}>
      <Label onClick={e => e.stopPropagation()}>
        <div className="labelText">
          {label}
          {secondary && <span>&nbsp;{secondary}</span>}
        </div>
        <Textarea className="LabeledTextarea__input" style={{ width }} inline={inline} {...props} />
        {invalid && typeof invalid === 'string' && <div className="LabeledTextarea__validation">{invalid}</div>}
      </Label>
    </Root>
  );
};

LabeledTextarea.propTypes = {
  invalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.node.isRequired,
  style: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default LabeledTextarea;
