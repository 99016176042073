import React from 'react';

import { translate } from 'utils/translations';

import ToastWrapper from './ToastWrapper';

const AlertToast = ({ translation, ...props }) => {
  const getMessage = (message, shouldTranslate) => (shouldTranslate ? translate(message) : message);
  return (
    <ToastWrapper {...props} title={getMessage(props.title, translation)} type="alert">
      {getMessage(props.message, translation)}
    </ToastWrapper>
  );
};

export default AlertToast;
