import React from 'react';
import styled from 'styled-components';

import { colors } from 'style/theme';

const StyledButton = styled.button`
  background-image: linear-gradient(to top, ${colors.grayWhisper}, ${colors.white});
  border: solid 1px ${colors.grayLight};
  border-radius: 3px;
  display: block;
  font-size: 12px;
  height: 30px;
  outline: none;
  padding: 5px 10px;
  transition: opacity 0.3s;
  width: 100%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
    &:hover {
      opacity: 0.4;
    }
  }

  &:hover {
    opacity: 0.8;
  }

  i {
    margin-right: 5px;
    ${props => props.iconOnly && 'margin: 0'};
  }

  /* Success */
  ${props =>
    props.success && `background-image: linear-gradient(to top, ${colors.greenSpinach}, ${colors.greenMantis});`}
  ${props => props.success && `border: solid 1px ${colors.greenApple};`}
  ${props => props.success && `color: ${colors.white}`};

  /* Alert */
  ${props => props.alert && `background-image: linear-gradient(to top, ${colors.redCarmine}, ${colors.redBrick});`}
  ${props => props.alert && `border: solid 1px ${colors.redCarmine}`};
  ${props => props.alert && `color: ${colors.white}`};

  /* Info */
  ${props => props.info && `background-image: linear-gradient(to top, ${colors.blueDenim}, ${colors.blueCuriousBlue});`}
  ${props => props.info && `border: solid 1px ${colors.blueEndeavour};`}
  ${props => props.info && `color: ${colors.white};`}

  /* Inline */
  ${props => props.inline && 'display: inline-block;'}
  ${props => props.inline && 'width: auto;'}

  /* Transparent */
  ${props => props.transparent && 'background: transparent;'}
  ${props => props.transparent && 'border-color: transparent;'}
  ${props => props.transparent && props.alert && `color: ${colors.redCarmine};`}
`;

const Button = props => (
  <StyledButton type="button" iconOnly={props.icon && !props.children} {...props}>
    {props.icon && <i className={`fa fa-${props.icon}`} />} {props.children}
  </StyledButton>
);

export default Button;
