import { connect } from 'react-redux';

import SummaryTable from 'components/Order/SummaryTable';
import { selectors as accountSelectors } from 'modules/account/details';
import { selectors } from 'modules/previousOrder';
import { selectors as userSelectors } from 'modules/user';

const mapState = state => ({
  address: selectors.address(state),
  currency: selectors.currency(state),
  hasOverrides: selectors.hasOverrides(state),
  items: selectors.items(state),
  language: userSelectors.language(state),
  reportAddress: accountSelectors.reportAddress(state),
});

export default connect(mapState)(SummaryTable);
