import get from 'lodash/get';
import { createAction } from 'redux-actions';

import { createOrderSearch } from 'modules/helpers/createOrderSearch';

const { actions, reducer, saga, selectors } = createOrderSearch({
  requestAction: createAction('ACCOUNT_ORDER_SEARCH_REQUEST'),
  responseAction: createAction('ACCOUNT_ORDER_SEARCH_RESPONSE'),
  baseSelector: state => get(state, 'account.orderSearch'),
});

export { actions, selectors, saga };
export default reducer;
