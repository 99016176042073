import PropTypes from 'prop-types';
import React from 'react';

const SortIcon = ({ active = false, direction = 'asc' }) => {
  const icon = active ? (direction === 'asc' ? 'sort-up' : 'sort-down') : 'sort';
  return <i className={`SortIcon__icon fa fa-${icon}`} />;
};

SortIcon.propTypes = {
  active: PropTypes.bool,
  direction: PropTypes.string,
};

export default SortIcon;
