import unionBy from 'lodash/unionBy';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import Input from 'components/Input';
import PanelContainer from 'components/Panel';
import RowItem from 'components/RowItem';
import { colors } from 'style/theme';
import { translate } from 'utils/translations';

import EmailNotificationItem from './EmailNotificationItem';

const Title = styled.div`
  display: flex;
  align-items: center;

  .Title__action {
    margin-left: auto;
  }
`;

const AddItem = styled.a`
  color: ${colors.blueWater};
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
  padding: 15px 34px;
`;

const DEFAULT_REFERENCE_INFO = {
  notificationEmails: [],
  orderPlacedFor: '',
  referencePO: '',
};

// Use Callback
const ReferenceInfo = ({ orderPlacedBy, referenceInfo, onChange }) => {
  const [data, setData] = useState({ ...referenceInfo, ...DEFAULT_REFERENCE_INFO });

  useEffect(() => {
    setData({ ...referenceInfo, ...DEFAULT_REFERENCE_INFO });
  }, [referenceInfo]);

  const onInputChange = e => {
    const { name, value } = e.target;
    setData(referenceInfo => {
      const updatedData = {
        ...referenceInfo,
        [name]: value,
      };
      onChange(updatedData);
      return updatedData;
    });
  };

  const onDeleteEmail = id => {
    setData(referenceInfo => {
      const updatedData = {
        ...referenceInfo,
        notificationEmails: referenceInfo.notificationEmails.filter(e => e.id !== id),
      };
      onChange(updatedData);
      return updatedData;
    });
  };

  const onSaveEmail = (id, value) => {
    setData(referenceInfo => {
      const updatedData = {
        ...referenceInfo,
        notificationEmails: unionBy([{ id, value }], referenceInfo.notificationEmails, 'id'),
      };
      onChange(updatedData);
      return updatedData;
    });
  };

  const onAddEmail = () => {
    setData(referenceInfo => ({
      ...referenceInfo,
      notificationEmails: [...referenceInfo.notificationEmails, { id: uuid(), value: '' }],
    }));
  };

  return (
    <PanelContainer
      title={
        <Title>
          <div className="Title__base">{translate('checkout.referenceInformation', 'Reference Information')}</div>
        </Title>
      }
    >
      {orderPlacedBy && (
        <RowItem center title={translate('shipping.orderPlacedBy', 'Order Placed By')}>
          <span>{orderPlacedBy}</span>
        </RowItem>
      )}
      <RowItem center title={translate('shipping.orderPlacedFor', 'Order Placed For')}>
        <Input name="orderPlacedFor" onChange={onInputChange} value={data.orderPlacedFor} />
      </RowItem>
      <RowItem center title={translate('checkout.referencePO', 'Reference PO')}>
        <Input name="referencePO" onChange={onInputChange} value={data.referencePO} />
      </RowItem>

      <RowItem titleStyle={{ marginTop: '5px' }} title={translate('shipping.sendNotification', 'Send Notification')}>
        {data.notificationEmails.map(({ id, value }) => (
          <EmailNotificationItem key={id} id={id} value={value} onSave={onSaveEmail} onDelete={onDeleteEmail} />
        ))}
        {data.notificationEmails.length < 3 && (
          <AddItem onClick={onAddEmail}>{translate('addEmailAddress', 'Add email address')}</AddItem>
        )}
      </RowItem>
    </PanelContainer>
  );
};

ReferenceInfo.propTypes = {
  notificationEmails: PropTypes.array,
  orderPlacedBy: PropTypes.string,
  orderPlacedFor: PropTypes.string,
  referencePO: PropTypes.string,
};

export default ReferenceInfo;
