import styled from 'styled-components';

import { colors } from 'style/theme';

const ActionButton = styled.button`
  align-items: center;
  background: ${props => props.background || colors.blueWater};
  border-radius: 3px;
  border: none;
  box-shadow: none;
  color: ${colors.white};
  display: inline-flex;
  font-size: 10px;
  height: 15px;
  justify-content: center;
  margin-right: 5px;
  outline: none;
  padding: 0;
  width: 15px;

  &:last-child {
    margin: 0;
  }

  .fa {
    line-height: 5px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
    &:hover {
      opacity: 0.4;
    }
  }
`;

export default ActionButton;
