import get from 'lodash/get';

const CAD = 'CAD';
const USD = 'USD';

const rushSampleStubInboundMapper = (order, totals = {}) => {
  const currency = get(order, 'currency.code');

  const isCanadaOrder = currency === CAD;
  const isUSOrder = currency === USD;

  return {
    ...order,
    currency: get(order, 'currency.code'),
    items: get(order, 'rushOrderItems', []),
    isInvoiceOnly: !isCanadaOrder && !isUSOrder,
    isTaxable: isCanadaOrder,
    totals,
  };
};

export default rushSampleStubInboundMapper;
