import { Breadcrumbs } from '@polarislabs/polaris-ui-components';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { renderMask } from 'utils/display';
import { isEmpty } from 'utils/lodash';
import { translate } from 'utils/translations';

const BaseBreadcrumbs = ({ match }) => {
  const { accountNumber, orderId } = match.params;
  const accountMask = renderMask(accountNumber);

  const routes = [{ url: '/accounts', label: translate('accounts', 'Accounts') }];

  if (!isEmpty(orderId)) {
    routes.push({ url: `/accounts/${accountNumber}/`, label: accountMask });
    routes.push({ label: orderId });
  } else {
    routes.push({ label: accountMask });
  }

  return <Breadcrumbs routes={routes} />;
};

export default withRouter(BaseBreadcrumbs);
