import PreviousOrders from 'components/PreviousOrders';
import withOrderSearch from 'hoc/withOrderSearch';
import { selectors as accountSelectors } from 'modules/account/details';
import { actions, selectors } from 'modules/account/orderSearch';

const mapState = state => ({
  hasSearched: selectors.hasSearched(state),
  orders: selectors.orders(state),
  error: selectors.error(state),
  loading: selectors.loading(state),
  accountId: accountSelectors.id(state),
});

const mapDispatch = {
  fetchResults: actions.requestAction,
};

export default withOrderSearch(mapState, mapDispatch)(PreviousOrders);
