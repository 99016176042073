import find from 'lodash/find';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import { actions as accountActions, selectors as accountSelectors } from 'modules/account/details';
import { actions, selectors } from 'modules/account/shippingAddresses';
import { actions as modalActions } from 'modules/modal';
import orderActions from 'modules/order/actions';
import orderSelectors from 'modules/order/selectors';
import { actions as toastActions } from 'modules/toast';
import { selectors as userSelectors } from 'modules/user';
import { translate } from 'utils/translations';

import ShippingInfo from './ShippingInfo';

const VALID_ADDRESS_REQUIRED_TOAST = 'VALID_ADDRESS_REQUIRED_TOAST';

class ShippingInfoContainer extends PureComponent {
  componentDidMount() {
    this.props.fetchShippingAddresses();
  }

  deleteAddress = id => {
    if (window.confirm(translate('confirmAddressDelete'))) this.props.deleteAddress(id);
  };

  editAddress = id => {
    const { addresses } = this.props;
    const address = find(addresses, { id });

    this.props.openAddressDialog({ address });
  };

  continueOrder = () => {
    const { accountNumber } = this.props;
    this.props.history.push(`/accounts/${accountNumber}/order/cart`);
  };

  placeNewOrder = () => {
    this.props.stubOrder({ address: { id: this.props.selectedAddressId } });
  };

  noValidAddressToast = () => {
    this.props.createToast('alert', {
      id: VALID_ADDRESS_REQUIRED_TOAST,
      translation: false,
      message: translate('validInvoicingAddress', 'A valid invoicing address is required'),
      timeout: 6000,
      title: 'Invoice Address Error',
    });
  };

  actions = {
    onDefault: id => !this.props.defaultAddressLoading && this.props.makeDefaultAddress(id),
    onEdit: id => this.editAddress(id),
    onDelete: id => this.deleteAddress(id),
    onSelect: (_, value) => this.props.setSelectedAddress(parseInt(value, 10)),
  };

  render() {
    return (
      <ShippingInfo
        {...this.props}
        actions={this.actions}
        deleteAddress={this.deleteAddress}
        noValidAddressToast={this.noValidAddressToast}
        placeNewOrder={this.placeNewOrder}
        hasOrder={this.props.hasOrder}
        continueOrder={this.continueOrder}
      />
    );
  }
}

const mapState = state => ({
  accountNumber: accountSelectors.accountNumber(state),
  addresses: selectors.addresses(state),
  billingInfo: accountSelectors.billingInfo(state),
  hasOrder: orderSelectors.hasOrder(state),
  defaultAddressId: accountSelectors.defaultAddressId(state),
  defaultAddressLoading: accountSelectors.defaultAddressLoading(state),
  error: selectors.error(state),
  isInvoiceAccount: accountSelectors.isInvoiceAccount(state),
  isOrderEnabled: accountSelectors.isOrderEnabled(state),
  isCsr: userSelectors.isCsr(state),
  isCustomer: userSelectors.isCustomer(state),
  loading: selectors.loading(state),
  selectedAddressId: selectors.selectedAddressId(state),
  showCandidatesDialog: selectors.showCandidatesDialog(state),
  showDialog: selectors.showDialog(state),
});

const mapDispatch = {
  closeAddressDialog: actions.closeAddressDialog,
  deleteAddress: actions.deleteAddress,
  fetchShippingAddresses: actions.fetchShippingAddresses,
  makeDefaultAddress: accountActions.makeDefaultAddress,
  createToast: toastActions.createToast,
  openAddressDialog: actions.openAddressDialog,
  setSelectedAddress: actions.setSelectedAddress,
  showModal: modalActions.showModal,
  stubOrder: orderActions.stubOrder,
};

export default compose(withRouter, connect(mapState, mapDispatch))(ShippingInfoContainer);
