import { handleActions } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as api from 'lib/api';

export const createOrderSearch = opts => {
  const { requestAction, responseAction, baseSelector } = opts;

  const defaultState = {
    loading: false,
    error: false,
    params: {},
    orders: [],
    hasSearched: false,
  };

  const actions = { requestAction, responseAction };

  const selectors = {
    error: state => baseSelector(state).error,
    loading: state => baseSelector(state).loading,
    orders: state => baseSelector(state).orders,
    hasSearched: state => baseSelector(state).hasSearched,
  };

  function* requestSaga({ payload }) {
    try {
      const { orders } = yield call(api.orderSearch, payload);
      yield put(actions.responseAction(orders));
    } catch (e) {
      console.error(e);
      yield put(actions.responseAction(e));
    }
  }

  const saga = function* () {
    yield all([takeLatest(requestAction, requestSaga)]);
  };

  const reducer = handleActions(
    {
      [requestAction](state) {
        return { ...defaultState, loading: true };
      },
      [responseAction]: {
        next(state, { payload: orders }) {
          return { ...state, loading: false, hasSearched: true, orders };
        },
        throw(state) {
          return { ...defaultState, error: true };
        },
      },
    },
    defaultState
  );

  return { actions, reducer, saga, selectors };
};
