import React from 'react';

import { Breadcrumbs, Tabs } from 'components/Account';
import { RushSampleOrder } from 'components/Account/RushSample';

const RushSampleOrderLayout = () => {
  return (
    <>
      <Breadcrumbs />
      <Tabs />
      <RushSampleOrder />
    </>
  );
};

export default RushSampleOrderLayout;
