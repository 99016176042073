import React, { Fragment } from 'react';
import styled from 'styled-components';

import Button from 'components/Buttons';
import Dialog from 'components/Dialog';
import ItemManager from 'components/ItemManager';
import { translate } from 'utils/translations';

import AddressCandidatesItem from './AddressCandidatesItem';

const Message = styled.div`
  font-size: 14px;
  padding: 3px 0;
  margin-top: 1rem;
  ${props => props.color && `color: ${props.color};`};
`;

const SpaceBetweenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledHr = styled.hr`
  margin: 25px 0;
`;

const SubHeader = styled.div`
  margin: 15px 0;
  h4 {
    margin-bottom: 10px;
  }
`;

const AddressCandidatesForm = ({
  actions,
  candidates,
  closeCandidatesDialog,
  error,
  isCheckout,
  isConfirmation,
  isPreviousOrder,
  loading,
  placeNewOrder,
  saveAddress,
  selectedItem,
  unvalidatedAddress,
}) => {
  const showPlaceOrder = !isCheckout && !isPreviousOrder && !isConfirmation;

  return (
    <Dialog
      header={translate('addNewAddress', 'Add New Address')}
      modalStyle={{ width: 430, maxWidth: '90vw' }}
      onClose={closeCandidatesDialog}
      locked
    >
      <SubHeader>
        <h4>Address Entered:</h4>
        <ItemManager
          actions={actions}
          component={AddressCandidatesItem}
          editable={true}
          items={[unvalidatedAddress]}
          selectedItem={selectedItem}
        />
        <p>
          <strong>{translate('invalidAddressNote', 'NOTE:')}</strong>{' '}
          {translate(
            'invalidAddressCCOnly',
            `If you choose to save the address as entered, we will not be able to estimate
          shipping costs or accept credit cards for payment for orders to this address.`
          )}
        </p>
      </SubHeader>
      <StyledHr />
      <SubHeader>
        {candidates.length === 0 && (
          <h4>{translate('noValidAddresses', 'We were unable to find any additional valid addresses')}</h4>
        )}
        {candidates.length > 0 && (
          <Fragment>
            <h4>
              {translate('addressCandidates', 'We found {candidatesCount} valid {candidatesAddressString}:', {
                candidatesCount: candidates.length,
                candidatesAddressString: candidates.length === 1 ? 'address' : 'addresses',
              })}
            </h4>
            <ItemManager
              actions={actions}
              component={AddressCandidatesItem}
              editable={false}
              items={candidates}
              selectedItem={selectedItem}
            />
          </Fragment>
        )}
      </SubHeader>

      <StyledHr />
      <SpaceBetweenWrapper>
        <Button inline onClick={closeCandidatesDialog}>
          {translate('shipping.cancel', 'Cancel')}
        </Button>
        <Button icon="save" inline success onClick={saveAddress}>
          {translate('save', 'Save')}
        </Button>
        {showPlaceOrder && (
          <Button icon="plus" inline success onClick={placeNewOrder}>
            {translate('saveAndPlaceNewOrder', 'Save and place new order')}
          </Button>
        )}
      </SpaceBetweenWrapper>
      {error && <Message color="darkred">{translate('anErrorOccurred')}</Message>}
      {loading && <Message>{translate('searching')}...</Message>}
    </Dialog>
  );
};

export default AddressCandidatesForm;
