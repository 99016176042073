import { LOCATION_CHANGE } from 'connected-react-router';
import get from 'lodash/get';
import { handleActions } from 'redux-actions';
import { all, takeLatest, call, put, select } from 'redux-saga/effects';

import * as api from 'lib/api';
import { actions as orderActions } from 'modules/order/actions';
import { selectors as referenceSelectors } from 'modules/reference/selectors';
import { actions as toastActions } from 'modules/toast/actions';

import { actions } from './actions';
import { selectors } from './selectors';

// Constants
const ERROR_TOAST = 'ERROR_TOAST';
const RESET_LOCATION_PATTERN = /^\/(?:accounts)?\/?$/;

// default state
const defaultState = {
  billingInfo: {},
  data: {},
  defaultAddressId: null,
  defaultAddressLoading: false,
  error: false,
  loading: true,
  reportAddress: {},
};

const mapBillingInfo = (data, accountInvoiceDeliveryMethods) => ({
  ...data,
  invoiceDeliveryMethod: get(
    accountInvoiceDeliveryMethods.find(i => i.key === data.invoiceDeliveryMethod),
    'name',
    ''
  ),
});

// sagas
function* fetchAccountDetailsSaga({ payload: accountNumber }) {
  try {
    const data = yield call(api.accountDetails, accountNumber);
    yield put(actions.accountDetailsResponse(data));
    yield put(orderActions.checkForExistingOrder());
  } catch (e) {
    yield put(actions.accountDetailsResponse(e));
  }
}

function* fetchBillingInfoSaga({ payload: accountNumber }) {
  try {
    const { billingInfo } = yield call(api.accountBillingInfo, accountNumber);
    const accountInvoiceDeliveryMethods = yield select(referenceSelectors.accountInvoiceDeliveryMethods);
    const mappedBillingInfo = mapBillingInfo(billingInfo, accountInvoiceDeliveryMethods);
    yield put(actions.fetchBillingInfoResponse(mappedBillingInfo));
  } catch (e) {
    yield put(actions.fetchBillingInfoResponse(e));
  }
}

function* makeDefaultAddressSaga({ payload: id }) {
  try {
    const { default_address_id } = yield call(api.makeDefaultShippingAddress, id);
    yield put(actions.makeDefaultAddressResponse(default_address_id));
  } catch (e) {
    yield put(actions.makeDefaultAddressResponse(e));
  }
}

function* updateBillingInfoSaga({ payload: data }) {
  try {
    const { number } = yield select(selectors.details);

    yield call(api.updateBillingInfo, { id: number, ...data });
    yield put(actions.fetchBillingInfo(number));
  } catch (e) {
    yield put(
      toastActions.createToast('alert', {
        id: ERROR_TOAST,
        message: e.message,
        timeout: false,
        title: 'store.error',
      })
    );
  }
}
export function* detailsSaga() {
  yield all([
    takeLatest(actions.fetchAccountDetails, fetchAccountDetailsSaga),
    takeLatest(actions.fetchBillingInfo, fetchBillingInfoSaga),
    takeLatest(actions.makeDefaultAddress, makeDefaultAddressSaga),
    takeLatest(actions.updateBillingInfo, updateBillingInfoSaga),
  ]);
}

export { actions } from './actions';
export { selectors } from './selectors';

// reducer
export default handleActions(
  {
    [LOCATION_CHANGE](state, { payload }) {
      const { pathname } = payload;
      if (RESET_LOCATION_PATTERN.test(pathname)) return defaultState;
      return state;
    },
    [actions.fetchAccountDetails]() {
      return { ...defaultState, loading: true };
    },
    [actions.accountDetailsResponse]: {
      next(state, { payload }) {
        return {
          ...state,
          data: payload,
          defaultAddressId: payload.default_address_id,
          reportAddress: payload.reportAddress,
          loading: false,
        };
      },
      throw() {
        return { ...defaultState, loading: false, error: true };
      },
    },
    [actions.fetchBillingInfoResponse]: {
      next(state, { payload }) {
        return {
          ...state,
          billingInfo: payload,
          loading: false,
        };
      },
      throw() {
        return { ...defaultState, loading: false, error: true };
      },
    },
    [actions.makeDefaultAddress](state) {
      return { ...state, defaultAddressLoading: true };
    },
    [actions.makeDefaultAddressResponse]: {
      next(state, { payload: defaultAddressId }) {
        return { ...state, defaultAddressId, defaultAddressLoading: false };
      },
      throw() {
        return { ...defaultState, defaultAddressLoading: false, error: true };
      },
    },
  },
  defaultState
);
