import get from 'lodash/get';
import { createActions, handleActions } from 'redux-actions';
import { all, takeLatest, call, put } from 'redux-saga/effects';

import * as api from 'lib/api';

export const defaultState = {
  loading: false,
  error: false,
  showResults: false,
  users: [],
};

export const actions = createActions('USER_SEARCH', 'USER_SEARCH_RESPONSE', 'RESET_USER_SEARCH');

export const selectors = {
  error: state => get(state, 'userSearch.error'),
  loading: state => get(state, 'userSearch.loading'),
  showResults: state => get(state, 'userSearch.showResults'),
  users: state => get(state, 'userSearch.users'),
};

export function* userSearchRequestSaga({ payload: searchText }) {
  try {
    const users = yield call(api.userSearch, searchText);
    yield put(actions.userSearchResponse(users));
  } catch (e) {
    yield put(actions.userSearchResponse(e));
  }
}

export function* userSearchSaga() {
  yield all([takeLatest(actions.userSearch, userSearchRequestSaga)]);
}

export default handleActions(
  {
    [actions.resetUserSearch](state) {
      return { ...defaultState };
    },
    [actions.userSearch](state) {
      return { ...defaultState, loading: true };
    },
    [actions.userSearchResponse]: {
      next(state, { payload }) {
        return { ...state, loading: false, showResults: true, users: payload };
      },
      throw(state) {
        return { ...defaultState, error: true };
      },
    },
  },
  defaultState
);
