import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import Button from 'components/Buttons';
import { translate } from 'utils/translations';

import CartList from './CartList.container';
import CartSubtotal from './CartSubtotal';
import OrderSummary from './OrderSummary';

const LayoutRoot = styled.div`
  display: flex;

  .Layout__main {
    margin-right: 15px;
    flex: 1 1 auto;
  }

  .Layout__sidebar {
    width: 250px;
    flex: 0 0 auto;
  }

  .Button {
    margin: 0 0 10px;

    &:last-child {
      margin: 0;
    }
  }
`;

const Cart = ({ account, currency, items = [], summary, history }) => (
  <LayoutRoot>
    <div className="Layout__main">
      <CartList />
      <div style={{ textAlign: 'right', padding: '10px' }}>
        <CartSubtotal currency={currency} subtotal={summary.subtotal} />
      </div>
    </div>
    <div className="Layout__sidebar">
      <OrderSummary currency={currency} details={summary}>
        <Button
          className="Button"
          disabled={items.length < 1}
          icon={'check'}
          onClick={() => history.push(`/accounts/${account.number}/order/checkout`)}
          success
        >
          {translate('cart.proceedToCheckout')}
        </Button>
        <Button
          className="Button"
          icon={'arrow-right'}
          onClick={() => history.push(`/accounts/${account.number}/order/kits`)}
        >
          {translate('cart.continueShopping')}
        </Button>
      </OrderSummary>
    </div>
  </LayoutRoot>
);

export default withRouter(Cart);
