import React from 'react';
import styled from 'styled-components';

import Label from 'components/Label';
import { CANCELLED, PENDING, PREPPED, PACKED, SHIPPED } from 'constants/orderStatuses';
import { colors } from 'style/theme';
import getOrderStatus from 'utils/getOrderStatus';
import { translate } from 'utils/translations';

const StyledHeader = styled.div`
  .Header__title {
    margin: 0;
    font-size: 18px;
  }

  .Header__subtitle {
    margin: 10px 0 0;
    font-size: 14px;
    font-weight: 700;
  }
`;

const STATUS_MAP = {
  canceled: { alert: true },
  pending: { warning: true },
  rush: { success: true },
  shipped: { success: true },
};

const OrderHeader = ({ cancelledReason, cancelledUser, orderDate, orderNumber, shipDate, shipMethod, ...order }) => {
  const orderStatus = getOrderStatus(order);
  let statusType = STATUS_MAP[orderStatus] || {};
  let statusText = orderStatus.toUpperCase();

  return (
    <StyledHeader>
      <div className="Header__title">
        {translate('orderNumber', 'Order Number')}: <strong>{orderNumber || 'N/A'}</strong>{' '}
        {orderStatus && <Label {...statusType}>{statusText}</Label>}
      </div>
      <div className="Header__subtitle">
        {translate('orderDate', 'Order Date')}: <span style={{ color: colors.greenSpinach }}>{orderDate || 'N/A'}</span>
      </div>
      {[CANCELLED].includes(orderStatus) && (
        <>
          <div className="Header__subtitle">
            Cancelled By: <span style={{ fontWeight: 'normal' }}>{cancelledUser}</span>
          </div>
          <div className="Header__subtitle">
            Reason: <span style={{ fontWeight: 'normal' }}>{cancelledReason}</span>
          </div>
        </>
      )}
      {[PENDING, PREPPED, PACKED].includes(orderStatus) && (
        <div className="Header__subtitle">
          {translate('estimatedShipDate', 'Estimated Ship Date')}:{' '}
          <span style={{ color: colors.greenSpinach }}>
            {shipDate || 'N/A'} - {shipMethod || 'N/A'}
          </span>
        </div>
      )}
      {[SHIPPED].includes(orderStatus) && (
        <div className="Header__subtitle">
          Ship Date:{' '}
          <span style={{ color: colors.greenSpinach }}>
            {shipDate || 'N/A'} - {shipMethod || 'N/A'}
          </span>
        </div>
      )}
    </StyledHeader>
  );
};

export default OrderHeader;
