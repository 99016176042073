import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { colors } from 'style/theme';

const RowItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0 15px;
  ${props => props.center && 'align-items: center'};

  &:last-child {
    margin: 0;
  }
`;

const RowTitle = styled.div`
  color: ${colors.grayWarm};
  width: 33%;
  margin-right: 10px;
  min-width: 100px;
  max-width: 250px;
`;

const RowContent = styled.div`
  flex: 1 1 auto;
  ${props => props.right && 'text-align: right'};
`;

const RowItemContainer = ({ title, center, right, children, titleStyle }) => (
  <RowItem center={center}>
    {title && <RowTitle style={titleStyle}>{title}</RowTitle>}
    <RowContent right={right}>{children}</RowContent>
  </RowItem>
);

RowItemContainer.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default RowItemContainer;
