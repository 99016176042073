import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Backdrop = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 80px;
  overflow: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const Container = styled.div`
  display: inline-block;
  max-width: 1150px;
`;

const Lightbox = ({ children, onBackdropClick = noop }) => (
  <Backdrop onClick={() => onBackdropClick()}>
    <Container onClick={e => e.stopPropagation()}>{children}</Container>
  </Backdrop>
);

Lightbox.propTypes = {
  children: PropTypes.node.isRequired,
  onBackdropClick: PropTypes.func,
};

export default Lightbox;
