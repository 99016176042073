import React from 'react';

import BaseLayout from 'components/BaseLayout';
import { Breadcrumbs, Checkout } from 'components/Checkout';

const CheckoutLayout = () => (
  <BaseLayout>
    <Breadcrumbs />
    <div className="Layout__content">
      <div className="Layout__wrapper">
        <Checkout />
      </div>
    </div>
  </BaseLayout>
);

export default CheckoutLayout;
