import React, { PureComponent } from 'react';

import { allValid } from 'utils/validation';

import CancelOrderModal from './CancelOrderModal';

class CancelOrderModalContainer extends PureComponent {
  state = {
    reason: '',
  };

  validations = {
    reason: { validation: ['minLength:5'] },
  };

  validate = () => {
    return allValid(this.state, this.validations);
  };

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  getChildProps = () => ({
    ...this.props,
    ...this.state,
    onChange: this.onChange,
    validation: this.validate,
  });

  render() {
    return <CancelOrderModal {...this.getChildProps()} />;
  }
}

export default CancelOrderModalContainer;
