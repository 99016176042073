import { combineActions, handleActions } from 'redux-actions';
import { all, takeLatest, call, put, select } from 'redux-saga/effects';

import * as api from 'lib/api';
import { selectors as accountSelectors } from 'modules/account/details/selectors';
import { actions as shippingAddressesActions } from 'modules/account/shippingAddresses/actions';
import { actions as toastActions } from 'modules/toast/actions';
import simplifyItem from 'utils/simplifyItem';

import { actions } from './actions';
import { selectors } from './selectors';

const PREVIOUS_ORDER_ADDRESS_TOAST = 'PREVIOUS_ORDER_ADDRESS_TOAST';
const PREVIOUS_ORDER_UPDATE_TOAST = 'PREVIOUS_ORDER_UPDATE_TOAST';

const defaultState = { loading: true, invalid: false, data: null };

const requestSaga = function* ({ payload: orderId }) {
  try {
    const { language } = yield select(accountSelectors.details);
    const { order } = yield call(api.loadOrder, orderId);
    yield put(actions.fetchResponse({ response: order, language }));
  } catch (e) {
    console.log(e);
    yield put(actions.fetchResponse(e));
  }
};

const modifyOrderSaga = function* ({ payload: { isDetails = false, orderId, ...payload } }) {
  try {
    const { language } = yield select(accountSelectors.details);
    const { order } = yield call(isDetails ? api.modifyOrder : api.modifyCsrInfo, orderId, payload);
    yield put(actions.modifyOrderResponse({ response: order, language }));
  } catch (e) {
    console.log(e.message);
    yield put(
      toastActions.createToast('alert', {
        id: PREVIOUS_ORDER_UPDATE_TOAST,
        translation: true,
        message: e.message,
        timeout: false,
        title: 'store.error',
      })
    );
    yield put(actions.modifyOrderResponse(e));
  }
};

const modifyShippedOrderSaga = function* ({ payload: { orderId, trackingNumber } }) {
  try {
    const { order } = yield call(api.modifyShippedOrder, { orderId, trackingNumber });
    const { language } = yield select(accountSelectors.details);
    yield put(actions.modifyOrderResponse({ response: order, language }));
  } catch (e) {
    console.log(e.message);
    yield put(
      toastActions.createToast('alert', {
        id: PREVIOUS_ORDER_UPDATE_TOAST,
        translation: true,
        message: e.message,
        timeout: false,
        title: 'store.error',
      })
    );
    yield put(actions.modifyOrderResponse(e));
  }
};

function* modifyAddressSaga({ payload: { address, skipValidation } }) {
  try {
    const { id: orderId } = yield select(selectors.data);
    const { language } = yield select(accountSelectors.details);

    const response = yield call(api.updateOrderAddress, orderId, address.id, address, skipValidation);
    if (!response.showCandidates) {
      yield put(actions.modifyAddressResponse({ response: response.order, language }));
      yield put(shippingAddressesActions.closeAllAddressDialogs());
    } else {
      yield put(shippingAddressesActions.validateAddressResponse(response));
    }
  } catch (e) {
    console.error(e.message);
    yield put(
      toastActions.createToast('alert', {
        id: PREVIOUS_ORDER_ADDRESS_TOAST,
        translation: true,
        message: e.message,
        timeout: false,
        title: 'store.error',
      })
    );
    yield put(shippingAddressesActions.closeAllAddressDialogs());
  }
}

export function* previousOrderSaga() {
  yield all([
    takeLatest(actions.fetchRequest, requestSaga),
    takeLatest(actions.modifyOrder, modifyOrderSaga),
    takeLatest(actions.modifyAddress, modifyAddressSaga),
    takeLatest(actions.updateTrackingNumber, modifyShippedOrderSaga),
  ]);
}

export { actions } from './actions';
export { selectors } from './selectors';

export default handleActions(
  {
    [actions.fetchRequest](state) {
      return { ...defaultState, loading: true };
    },
    [combineActions(actions.modifyOrder, actions.modifyAddress)](state) {
      return { ...state, loading: true };
    },
    [combineActions(actions.fetchResponse, actions.modifyOrderResponse, actions.modifyAddressResponse)]: {
      next(state, { payload }) {
        const { items, productList } = payload.response;
        const data = {
          ...payload.response,
          items: items && items.map(item => simplifyItem(item, payload.language, productList)),
        };

        return { ...defaultState, data, loading: false };
      },
      throw() {
        return { ...defaultState, loading: false, invalid: true };
      },
    },
  },
  defaultState
);
