import hoistStatics from 'hoist-non-react-statics';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { actions, selectors } from 'modules/account/settings';
import { getDisplayName } from 'utils/components';

const mapState = state => ({
  settings: selectors.decorated(state),
  loading: selectors.loading(state),
  loadedSettings: selectors.loadedSettings(state),
});

const mapDispatch = { fetchAccountSettings: actions.fetchAccountSettings };

const withAccountSettings = requestedSettings => WrappedComponent => {
  class WithAccountSettings extends PureComponent {
    state = { loaded: false };

    componentDidMount() {
      const { loadedSettings } = this.props;
      const settingsToFetch = requestedSettings.filter(setting => !loadedSettings.includes(setting));

      if (settingsToFetch.length) {
        this.props.fetchAccountSettings(requestedSettings);
      } else {
        this.setState({ loaded: true });
      }
    }

    componentDidUpdate(prevProps) {
      const { loaded } = this.state;
      const { loading } = this.props;
      if (!loaded && prevProps.loading && !loading) this.setState({ loaded: true });
    }

    render() {
      const { loaded } = this.state;
      const { fetchAccountSettings, loading, innerRef, ...props } = this.props;
      if (!loaded || loading) return null;
      return <WrappedComponent ref={innerRef} {...props} />;
    }
  }

  WithAccountSettings.displayName = `WithAccountSettings(${getDisplayName(WrappedComponent)})`;
  hoistStatics(WithAccountSettings, WrappedComponent);

  return connect(mapState, mapDispatch)(WithAccountSettings);
};

export default withAccountSettings;
