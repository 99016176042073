import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import CATEGORIES from 'constants/categories';
import { actions, selectors } from 'modules/order/inventory';

import ProductListNavigation from './ProductListNavigation';

const ProductListNavigationContainer = ({ productListItems, productType, fetchProductListItems }) => {
  const { data, error, loading } = productListItems;

  useEffect(() => {
    const productCategories = CATEGORIES[productType];
    if (productCategories) fetchProductListItems(productCategories);
  }, [productType, fetchProductListItems]);

  return <ProductListNavigation data={data} productType={productType} error={error} loading={loading} />;
};

const mapState = (state, { match }) => ({
  productListItems: selectors.productListItems(state),
  productType: match.params.productType,
});

const mapDispatch = {
  fetchProductListItems: actions.fetchProductListItemsSimple,
};

const enhance = compose(withRouter, connect(mapState, mapDispatch));
export default enhance(ProductListNavigationContainer);
