import noop from 'lodash/noop';
import React from 'react';
import styled from 'styled-components';

import Button from 'components/Buttons';
import { CANCELLED, SHIPPED, UNSUBMITTED, RUSH } from 'constants/orderStatuses';
import getOrderStatus from 'utils/getOrderStatus';
import { translate } from 'utils/translations';

const StyledActions = styled.div`
  display: inline-flex;
  align-self: flex-end;

  button {
    margin-right: 15px;

    &:last-child {
      margin: 0;
    }
  }
`;

const OrderActions = ({
  order,
  onCancel = noop,
  onDuplicate = noop,
  onPackingSlip = noop,
  onPrint = noop,
  onReoccuring = noop,
}) => {
  const status = getOrderStatus(order);
  return (
    <StyledActions>
      {![UNSUBMITTED, RUSH].includes(status) && (
        <Button onClick={onPackingSlip} icon="barcode" inline>
          {translate('store.packingSlip', 'Packing Slip')}
        </Button>
      )}
      <Button onClick={onPrint} icon="print" inline>
        {translate('store.print', 'Print')}
      </Button>
      {status !== RUSH && (
        <Button onClick={onDuplicate} icon="copy" success inline>
          {translate('store.duplicateOrder', 'Duplicate Order')}
        </Button>
      )}
      {![CANCELLED, RUSH].includes(status) && (
        <Button onClick={onReoccuring} icon="repeat" success inline>
          {translate('store.scheduleRecurringOrder', 'Schedule Reccuring Order')}
        </Button>
      )}
      {![SHIPPED, CANCELLED, RUSH].includes(status) && (
        <Button onClick={onCancel} icon="times" alert inline>
          {translate('store.cancelOrder', 'Cancel Order')}
        </Button>
      )}
    </StyledActions>
  );
};

export default OrderActions;
