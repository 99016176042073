import React, { Component } from 'react';
import { connect } from 'react-redux';

import Summary from 'components/Order/Summary';
import { selectors as accountSelectors } from 'modules/account/details';
import { actions, selectors } from 'modules/checkout';
import { selectors as userSelectors } from 'modules/user';

class SummaryContainer extends Component {
  getChildProps = () => ({
    ...this.state,
    ...this.props,
  });

  render() {
    return <Summary {...this.getChildProps()} />;
  }
}

const mapStateToProps = state => ({
  isCsr: userSelectors.isCsr(state),
  reportAddress: accountSelectors.reportAddress(state),
  showOverrideDialog: selectors.showOverrideDialog(state),
});

const mapDispatch = {
  closeOverrideDialog: actions.closeOverrideDialog,
  openOverrideDialog: actions.openOverrideDialog,
};

export default connect(mapStateToProps, mapDispatch)(SummaryContainer);
