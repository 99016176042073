import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { actions, selectors } from 'modules/previousOrder';
import { selectors as userSelectors } from 'modules/user';

import PreviousOrder from './PreviousOrder';

class PreviousOrderContainer extends PureComponent {
  componentDidMount() {
    this.props.fetchOrder(this.props.orderId);
  }

  render() {
    const { loading, invalid, isCsr, order } = this.props;
    return <PreviousOrder loading={loading} invalid={invalid} isCsr={isCsr} order={order} />;
  }
}

const mapState = (state, { match }) => ({
  orderId: match.params.orderId,
  order: selectors.data(state),
  loading: selectors.loading(state),
  invalid: selectors.invalid(state),
  isCsr: userSelectors.isCsr(state),
});

const mapDispatch = {
  fetchOrder: actions.fetchRequest,
};

const enhance = compose(withRouter, connect(mapState, mapDispatch));

export default enhance(PreviousOrderContainer);
