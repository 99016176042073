import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Loader from 'components/Loader';
import { KITS } from 'constants/strings';
import { colors } from 'style/theme';
import { translate } from 'utils/translations';

import NavItem from './NavItem';

const NavRoot = styled.div`
  background-color: ${colors.whiteAlabaster};
  min-width: 165px;
  font-size: 12px;
  display: inline-block;
  vertical-align: top;

  .Nav__header {
    font-size: 14px;
    font-weight: bold;
    padding: 8px 16px;
  }
`;

const Item = ({ label, header, children, match, depth, loading, ...props }) => {
  const labelEl = header ? <b>{label}</b> : label;

  return (
    <NavItem baseUrl={match.baseUrl} depth={depth} title={labelEl} {...props}>
      {children && renderItems(children, loading, match, depth + 1)}
    </NavItem>
  );
};

const renderItems = (items, loading, match, depth = 1) => {
  if (loading) return <Loader tiny />;
  return items.map((item, i) => <Item key={i} depth={depth} loading={loading} match={match} {...item} />);
};

const childItemNumberMapper = match => item => {
  const productListItemId = item.product_list_item_id;
  const itemNumber = item.display_item_number || item.item_number;
  const isItemMatch = match.params.productListItemId && match.params.productListItemId === productListItemId;
  return { active: isItemMatch, label: itemNumber, to: `/${productListItemId}` };
};

const createNavigation = (data, match, type) => {
  const types = sortBy(Object.keys(data));
  const navigation = types.map(label => {
    const categories = groupBy(data[label], item => {
      return !!item.open;
    });

    const createChild = (label, arr = []) => {
      if (!arr.length) return [];

      return [
        {
          label,
          children: arr.map(childItemNumberMapper(match)),
        },
      ];
    };

    const children =
      type === KITS
        ? [...createChild('Open Testing', categories['true']), ...createChild('Prepaid Testing', categories['false'])]
        : data[label].map(childItemNumberMapper(match));

    const hasActiveChild = children.some(child => {
      if (child.active) return true;
      return child.children && child.children.some(child => child.active);
    });

    return {
      label,
      collapsed: !hasActiveChild,
      header: true,
      children,
    };
  });

  const all = { label: 'All', active: !match.params.itemNumber, to: '/', header: true };
  return [all, ...navigation];
};

const ProductsNav = ({ loading, match, productType, data }) => {
  const items = createNavigation(data, match, productType);
  const baseUrl = `/accounts/${match.params.accountNumber}/order/${match.params.productType}`;

  return (
    <NavRoot>
      <div className="Nav__header">{translate(productType)}</div>
      <div className="Nav__items">{renderItems(items, loading, { ...match, baseUrl })}</div>
    </NavRoot>
  );
};

export default withRouter(ProductsNav);
