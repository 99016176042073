import isFunction from 'lodash/isFunction';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import IconButton from 'components/Common/IconButton';
import { translate } from 'utils/translations';

const StyledField = styled.div`
  display: flex;
  align-items: center;

  .Field__content {
    margin-right: 15px;
  }

  .Field__actions {
  }
`;

const EditableField = ({ children, disabled, editConfirmation, disabledAll, value, ...props }) => {
  const [editing, setEditing] = useState(false);

  const onCancel = () => {
    if (isFunction(props.onCancel)) props.onCancel();
    setEditing(false);
  };

  const onEdit = () => {
    if (isFunction(props.onEdit)) props.onEdit();

    if (isFunction(editConfirmation)) {
      editConfirmation(() => setEditing(true));
      return;
    }

    setEditing(true);
  };

  const onSave = () => {
    if (isFunction(props.onSave)) props.onSave();
  };

  useEffect(() => {
    setEditing(false);
  }, [value]);

  const renderActions = () => {
    if (editing) {
      return (
        <div className="Column__actions">
          <IconButton
            className="Column__button"
            icon="fa-save"
            color="#43a047"
            disabled={disabledAll || disabled}
            title="Save"
            onClick={onSave}
          />{' '}
          <IconButton
            className="Column__button"
            icon="fa-ban"
            color="#e53935"
            disabled={disabledAll}
            title="Cancel"
            onClick={onCancel}
          />
        </div>
      );
    }

    return (
      <div className="Column__actions">
        <IconButton className="Column__button" icon="fa-pencil" title="Edit" disabled={disabledAll} onClick={onEdit} />
        {isFunction(props.onDelete) && (
          <IconButton
            className="Column__button"
            icon="fa-trash"
            color="#e53935"
            title={translate('delete', 'Delete')}
            disabled={disabled}
            onClick={props.onDelete}
          />
        )}
      </div>
    );
  };

  return (
    <StyledField>
      <div className="Field__content">{children({ editing })}</div>
      <div className="Field__actions">{renderActions()}</div>
    </StyledField>
  );
};

export default EditableField;
