import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { KITS } from 'constants/strings';

import KitList from './KitList';
import SuppliesList from './SuppliesList';

const ProductsListContainer = ({ productType }) => {
  return productType === KITS ? <KitList /> : <SuppliesList />;
};

const mapState = (state, { match }) => ({
  productType: match.params.productType,
});

const enhance = compose(withRouter, connect(mapState));
export default enhance(ProductsListContainer);
