import { Editor, EditorState, RichUtils } from 'draft-js';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

const INLINE_STYLES = [
  {
    icon: <i className="fa fa-bold" />,
    style: 'BOLD',
  },
  {
    icon: <i className="fa fa-italic" />,
    style: 'ITALIC',
  },
  {
    icon: <i className="fa fa-underline" />,
    style: 'UNDERLINE',
  },
];

const BLOCK_TYPES = [
  {
    icon: <i className="fa fa-list-ul" />,
    type: 'unordered-list-item',
  },
  {
    icon: <i className="fa fa-list-ol" />,
    type: 'ordered-list-item',
  },
];

const EditorRoot = styled.div`
  border: solid 1px #cfcfcf;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  flex-direction: column;

  .public-DraftEditor-content {
    font-size: 14px;
    min-height: 200px;
    padding: 5px;
  }
`;

const ControlBar = styled.div`
  background-color: #f0f0f0;
  border-bottom: solid 1px #cfcfcf;
  padding: 2px;

  > .section {
    display: inline-block;

    &:not(:first-child) {
      border-left: 1px solid #cfcfcf;
    }
  }
`;

const Control = styled.button.attrs({ type: 'button' })`
  background: transparent;
  border: none;
  height: 35px;
  width: 40px;
  text-align: center;
  line-height: 35px;
  padding: 0;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  color: ${props => (props.active ? '#0064ff' : '#333')};

  &:hover {
    background: rgba(210, 210, 210, 0.3);
  }
`;

export default class TextEditor extends PureComponent {
  state = {
    editorState: this.props.editorState ? undefined : EditorState.createEmpty(),
  };

  getEditorState = this.props.editorState ? () => this.props.editorState : () => this.state.editorState;

  onChange =
    this.props.onChange && this.props.editorState
      ? editorState => this.props.onChange(editorState)
      : editorState => this.setState(() => ({ editorState }));

  focus = () => this.editor.focus();
  saveEditorRef = node => (this.editor = node);

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    return newState && this.onChange(newState) ? true : false;
  };

  toggleBlockType = blockType => e => {
    e.preventDefault();
    this.onChange(RichUtils.toggleBlockType(this.getEditorState(), blockType));
  };

  toggleInlineStyle = inlineStyle => e => {
    e.preventDefault();
    this.onChange(RichUtils.toggleInlineStyle(this.getEditorState(), inlineStyle));
  };

  render() {
    const editorState = this.getEditorState();
    const currentStyle = editorState.getCurrentInlineStyle();
    const selection = editorState.getSelection();
    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

    return (
      <EditorRoot>
        <ControlBar>
          <div className="section">
            {INLINE_STYLES.map(({ icon, style }) => (
              <Control key={style} active={currentStyle.has(style)} onMouseDown={this.toggleInlineStyle(style)}>
                {icon}
              </Control>
            ))}
          </div>
          <div className="section">
            {BLOCK_TYPES.map(({ icon, type }) => (
              <Control key={type} active={blockType === type} onMouseDown={this.toggleBlockType(type)}>
                {icon}
              </Control>
            ))}
          </div>
        </ControlBar>
        <Editor
          editorState={editorState}
          onChange={this.onChange}
          ref={this.saveEditorRef}
          handleKeyCommand={this.handleKeyCommand}
        />
      </EditorRoot>
    );
  }
}
