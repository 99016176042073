import React from 'react';
import styled from 'styled-components';

import Label from 'components/Label';
import { VALID_ADDRESS, INVALID_ADDRESS } from 'constants/strings';
import { colors } from 'style/theme';
import { changeMethod } from 'utils/common';
import { translate } from 'utils/translations';

const TemplateContent = styled.label`
  display: flex;
  padding: 10px;
  font-size: 12px;
`;

const Cell = styled.div`
  flex: 0 0 auto;
  margin-right: 10px;

  &:last-child {
    margin: 0;
  }
`;

const Action = styled(Cell)`
  cursor: pointer;

  ${props => props.link && `color: ${colors.blueWater}`};
  ${props => props.link && 'text-decoration: underline'};
`;

const Icon = styled.i`
  &.fa-pencil {
    color: #1976d2;
  }
  &.fa-times {
    color: #e53935;
  }
`;

const ShippingInfoItem = ({
  address1,
  address2,
  attention,
  city,
  company_name,
  country,
  id,
  defaultAddressId,
  onEdit,
  onDefault,
  onDelete,
  onSelect,
  phone_number,
  post_code,
  region,
  selected,
  validity,
}) => {
  const isDefault = defaultAddressId === id;
  return (
    <TemplateContent>
      <Cell>
        <input type="radio" name="selected" onChange={changeMethod(onSelect)} checked={selected} value={id} />
      </Cell>
      <Cell>
        {company_name && <span>{company_name},&nbsp;</span>}
        {attention && <span>{attention},&nbsp;</span>}
        {address1 && <span>{address1},&nbsp;</span>}
        {address2 && <span>{address2},&nbsp;</span>}
        {city && <span>{city},&nbsp;</span>}
        {region && <span>{region}&nbsp;</span>}
        {post_code && <span>{post_code},&nbsp;</span>}
        {country && <span>{country}</span>}
        {phone_number && <span>,&nbsp;{phone_number}</span>}
      </Cell>
      <Cell>
        {validity === VALID_ADDRESS && <Label success>{translate('store.valid').toUpperCase()}</Label>}
        {validity === INVALID_ADDRESS && <Label alert>{translate('store.invalid').toUpperCase()}</Label>}
        {![VALID_ADDRESS, INVALID_ADDRESS].includes(validity) && (
          <Label>{translate('store.unvalidated').toUpperCase()}</Label>
        )}
      </Cell>
      {!isDefault && (
        <Action onClick={e => e.preventDefault() || onDefault(id)} link>
          {translate('makeDefault')}
        </Action>
      )}
      {isDefault && (
        <Cell>
          <strong>{translate('default')}</strong>
        </Cell>
      )}
      <Action onClick={e => e.preventDefault() || onEdit(id)}>
        <Icon className="fa fa-pencil" />
      </Action>
      {!isDefault && (
        <Action onClick={e => e.preventDefault() || onDelete(id)}>
          <Icon className="fa fa-times" />
        </Action>
      )}
    </TemplateContent>
  );
};

export default ShippingInfoItem;
