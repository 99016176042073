import React from 'react';
import styled from 'styled-components';

import { Summary, SummaryTotal } from 'components/Order';

const LayoutRoot = styled.div`
  width: 100%;

  .Summary__totals {
    display: flex;
    justify-content: flex-end;
  }
`;

const SummaryWithTotalLayout = () => (
  <LayoutRoot>
    <Summary />
    <div className="Summary__totals">
      <SummaryTotal />
    </div>
  </LayoutRoot>
);

export default SummaryWithTotalLayout;
