import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Currency from 'components/Currency';
import PanelContainer from 'components/Panel';
import { colors } from 'style/theme';
import { translate } from 'utils/translations';

const Divider = styled.div`
  height: 1px;
  background: ${colors.whiteGallery};
  margin: 15px 0;
`;

const RowItem = styled.div`
  display: flex;
  flex-flow: ${props => (props.column ? 'column nowrap' : 'row nowrap')};

  .RowItem__label {
    color: ${colors.grayWarm};
  }

  .RowItem__price {
    margin-left: auto;
    font-weight: 700;
  }
`;

const OrderSummary = ({ currency, details, children }) => {
  const { address, shipping, subtotal, tax, total, taxMessage } = details;
  const showTaxInfo = tax > 0;

  return (
    <PanelContainer title={translate('checkout.orderSummary', 'Order Summary')}>
      {address && (
        <div>
          <RowItem column>
            <div className="RowItem__label">{translate('cart.shippingTo', 'Shipping To')}</div>
            {address.attention && <div>{address.attention}</div>}
            <div>{address.address1}</div>
            {address.address2 && <div>{address.address2}</div>}
            <div>
              {address.city}, {address.region} {address.post_code}
            </div>
          </RowItem>
        </div>
      )}
      {(showTaxInfo || shipping) && <Divider />}
      {showTaxInfo && (
        <RowItem>
          <div className="RowItem__label">{translate('cart.subtotal', 'Subtotal')}</div>
          <div className="RowItem__price">
            <Currency quantity={subtotal || 0} currency={currency} />
          </div>
        </RowItem>
      )}
      {shipping && (
        <RowItem>
          <div className="RowItem__label">{translate('cart.shipping', 'Est. Shipping & Handling')}</div>
          <div className="RowItem__price">
            <Currency quantity={shipping || 0} currency={currency} />
          </div>
        </RowItem>
      )}
      {showTaxInfo && (
        <div>
          <Divider />
          <RowItem>
            <div className="RowItem__label">{translate(taxMessage, 'Tax')}</div>
            <div className="RowItem__price">
              <Currency quantity={tax || 0} currency={currency} />
            </div>
          </RowItem>
        </div>
      )}
      <Divider />
      <RowItem>
        <div className="RowItem__label">{translate('cart.estOrderTotal', 'Estimated Order Total')}</div>
        <div className="RowItem__price">
          <Currency quantity={total || 0} currency={currency} />
        </div>
      </RowItem>
      {children && (
        <div>
          <Divider />
          {children}
        </div>
      )}
    </PanelContainer>
  );
};

OrderSummary.propTypes = {
  details: PropTypes.shape({
    address: PropTypes.object.isRequired,
    shipping: PropTypes.number,
    subtotal: PropTypes.number.isRequired,
    tax: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
};

export default OrderSummary;
