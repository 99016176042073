import React from 'react';
import styled from 'styled-components';

import { colors } from 'style/theme';

const StyledExportButton = styled.button`
  background-image: linear-gradient(to top, ${colors.greenSpinach}, ${colors.greenMantis});
  border-radius: 3px;
  border: solid 1px ${colors.greenApple};
  color: ${colors.white};
  height: 35px;
  text-shadow: -0px -1px 0 rgba(0, 0, 0, 0.3);
  width: 35px;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }
`;

const ExportButton = props => {
  return (
    <StyledExportButton {...props} title={props.disabled ? '' : props.title}>
      <i className="fa fa-share" />
    </StyledExportButton>
  );
};

export default ExportButton;
