import filter from 'lodash/filter';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { SaveButton } from 'components/Buttons';
import LabeledInput from 'components/LabeledInput';
import TextEditor from 'components/TextEditor';
import { translate } from 'utils/translations';

import NoteMaskSelector from './NoteMaskSelector';

const Root = styled.div`
  font-size: 12px;

  .bottomControls {
    margin-top: 20px;

    label {
      display: inline-block;
      &:first-child {
        margin-right: 20px;
      }
    }

    input[type='radio'] {
      margin-right: 5px;
    }
  }

  .validation {
    color: darkred;
  }
`;

const validationFor = (validation, field) => filter(validation, { field }).map(({ reason }) => reason);

const Validation = ({ validation, field }) => {
  const reasons = validationFor(validation, field);
  if (reasons.length === 0) return null;
  return (
    <ul className="validation">
      {reasons.map(r => (
        <li key={r}>{r}</li>
      ))}
    </ul>
  );
};

const NoteForm = ({
  accountNumber,
  editing,
  editorState,
  existingNotes,
  notebookType,
  noteMask,
  saveError,
  saveHandler,
  saving,
  ticketNumber,
  updateField,
  validation,
}) => (
  <Root>
    <p>{translate('applyNoteTo')}:</p>
    <NoteMaskSelector
      accountNumber={accountNumber}
      disabled={editing}
      existingNotes={existingNotes}
      noteMask={noteMask}
      onSelect={sel => updateField('noteMask', sel)}
    />
    <Validation validation={validation} field="noteMask" />
    <LabeledInput
      label={translate('jiraTicketNumber')}
      labelStyle={{ color: '#000' }}
      width={250}
      value={ticketNumber}
      onChange={e => updateField('ticketNumber', e.target.value)}
    />
    <TextEditor editorState={editorState} onChange={editorState => updateField('editorState', editorState)} />
    <div className="bottomControls">
      <SaveButton onClick={saveHandler} disabled={saving} />
      {saveError && <div className="validation">{translate('noteNotSaved')}</div>}
    </div>
  </Root>
);

NoteForm.propTypes = {
  accountNumber: PropTypes.string.isRequired,
  content: PropTypes.object,
  editing: PropTypes.bool,
  noteMask: PropTypes.string,
  saveHandler: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  ticketNumber: PropTypes.string,
  updateField: PropTypes.func.isRequired,
  validation: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      reason: PropTypes.string.isRequired,
    })
  ),
};

export default NoteForm;
