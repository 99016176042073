import React from 'react';

import { Breadcrumbs, Details, Tabs } from 'components/Account';

const DetailsLayout = ({ match }) => {
  return (
    <>
      <Breadcrumbs />
      <Tabs />
      <Details />
    </>
  );
};

export default DetailsLayout;
