import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Select from 'components/Select';
import { colors } from 'style/theme';
import { translate } from 'utils/translations';

export const validateLength = (text = '', len) =>
  !!text.length && text.length < len && translate('validationMinLength', '{len} character minimum', { len });

const Root = styled.div`
  display: block;
  position: relative;

  .labelText {
    font-size: 12px;
    color: ${colors.grayEmperor};
    margin-bottom: 5px;
    span {
      color: #959595;
    }
  }

  .LabeledSelect__input,
  .LabeledSelect__input .Select-control {
    width: 100%;
    ${props => props.invalid && 'transition: border-color 333ms;'};
    ${props => props.invalid && 'border-color: red;'};
  }

  .LabeledSelect__validation {
    color: darkred;
    font-size: 12px;
    position: absolute;
    bottom: -1.2rem;
  }
`;

const InlineLabel = styled.label`
  display: flex;
  width: 100%;
  align-items: center;

  .labelText {
    margin-right: 11px;
    margin-bottom: 0;
  }
`;

const Required = styled.span`
  color: red !important;
`;

const LabeledSelect = ({ invalid, inline, label, secondary, style, width, labelStyle, required, ...props }) => {
  const Label = inline ? InlineLabel : 'label';

  return (
    <Root invalid={invalid} inline={inline} style={style}>
      <Label onClick={e => e.stopPropagation()}>
        <div className="labelText LabeledSelect__label">
          {label}
          {secondary && <span>&nbsp;{secondary}</span>}
          {required && <Required>&nbsp;*</Required>}
        </div>
        <Select className="LabeledSelect__input" style={{ width }} {...props} />
        {invalid && typeof invalid === 'string' && <div className="LabeledSelect__validation">{invalid}</div>}
      </Label>
    </Root>
  );
};

LabeledSelect.propTypes = {
  invalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.node,
  style: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default LabeledSelect;
