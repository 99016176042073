import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import Loader from 'components/Loader';
import { selectors as accountSelectors } from 'modules/account/details';
import { actions, selectors } from 'modules/rushSample';

import RushOrderDetails from './RushOrderDetails';

const RushSampleConfirmation = ({ accountNumber, fetchRushOrder, order }) => {
  const { data, loading } = order;
  const { orderId } = useParams();

  useEffect(() => {
    fetchRushOrder(orderId);
  }, [orderId, fetchRushOrder]);

  const renderOrder = () => {
    if (loading) return <Loader />;
    if (isEmpty(data)) return <Redirect to={`/accounts/${accountNumber}/rush-sample`} />;
    return <RushOrderDetails order={data} />;
  };

  return renderOrder();
};

const mapState = state => ({
  accountNumber: accountSelectors.accountNumber(state),
  order: selectors.currentOrder(state),
});

const mapDispatch = {
  fetchRushOrder: actions.fetchRushOrder,
};

export default connect(mapState, mapDispatch)(RushSampleConfirmation);
