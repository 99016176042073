import get from 'lodash/get';
import { createSelector } from 'reselect';

export const selectors = {
  loading: state => get(state, 'previousOrder.loading'),
  invalid: state => get(state, 'previousOrder.invalid'),
  data: state => get(state, 'previousOrder.data'),
  address: state => get(state, 'previousOrder.data.address', {}),
};

const withData = getter => createSelector([selectors.data], getter);

selectors.currency = withData(data => get(data, 'productList.currency', 'USD'));
selectors.items = withData(data => get(data, 'items', []));
selectors.hasOverrides = createSelector([selectors.items], items => {
  return items.some(item => item.csrOverride && item.csrOverride.price);
});

export default selectors;
