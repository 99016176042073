import React from 'react';
import styled from 'styled-components';

import Currency from 'components/Currency';
import ListTable from 'components/ListTable';
import PanelContainer from 'components/Panel';
import { translate } from 'utils/translations';

import RushSampleTotals from './RushSampleTotals';

const StyledSummary = styled.div`
  .Summary__item {
    margin: 0 0 3px;
  }
`;

const RushOrderSummary = ({ currency, data = [], loading, validPayment, ...props }) => (
  <StyledSummary>
    <PanelContainer title={translate('checkout.orderSummary', 'Order Summary')} collapse>
      <ListTable>
        <thead>
          <tr>
            <th>{translate('description', 'Description')}</th>
            <th style={{ width: 90, textAlign: 'center' }}>{translate('quantity', 'Quantity')}</th>
            <th style={{ width: 90, textAlign: 'right' }}>{translate('total', 'Total')}</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={item.id}>
              <td>
                <div className="Summary__item">
                  <strong>{translate('rushSampleTesting', 'Rush Sample Testing')}</strong>
                </div>
                {item.barcode}
              </td>
              <td style={{ textAlign: 'center' }}>{item.quantity}</td>
              <td style={{ textAlign: 'right' }}>
                <strong>
                  <Currency quantity={!item.waived ? item.price : 0} currency={currency} />
                </strong>
              </td>
            </tr>
          ))}
        </tbody>
      </ListTable>
    </PanelContainer>
    <RushSampleTotals disabled={!validPayment} loading={loading} currency={currency} {...props} />
  </StyledSummary>
);

export default RushOrderSummary;
