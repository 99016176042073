import React from 'react';
import styled from 'styled-components';

import Icon from 'components/Icon';
import theme from 'style/theme';

const StyledAlert = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin: 45px 0;

  .Alert__icon {
    font-size: 50px;
    margin: 0 0 15px;
    ${props => props.color && `color: ${props.color}`};
  }

  .Alert__text {
    font-weight: 700;
    font-size: 14px;
    text-align: center;
  }
`;

const TYPES = {
  error: { icon: 'exclamation-circle', color: theme.redBrick },
  info: { icon: 'info-circle', color: theme.blueBahama },
  warning: { icon: 'exclamation-triangle', color: theme.yellowLemon },
};

const AlertInfo = ({ type = 'error', children, text = 'An error has occurred' }) => {
  const selectedType = TYPES[type] || TYPES['error'];
  return (
    <StyledAlert color={selectedType.color}>
      <Icon className="Alert__icon" type={selectedType.icon} />
      <div className="Alert__text">{children || text}</div>
    </StyledAlert>
  );
};

export default AlertInfo;
