import { createActions } from 'redux-actions';

export const actions = createActions(
  'FETCH_ACCOUNT_DETAILS',
  'ACCOUNT_DETAILS_RESPONSE',
  'FETCH_ACCOUNT_ORDER',
  'ACCOUNT_ORDER_RESPONSE',
  'MAKE_DEFAULT_ADDRESS',
  'MAKE_DEFAULT_ADDRESS_RESPONSE',
  'FETCH_BILLING_INFO',
  'FETCH_BILLING_INFO_RESPONSE',
  'UPDATE_BILLING_INFO',
  'UPDATE_BILLING_INFO_RESPONSE'
);
