import React from 'react';
import BaseMaskedInput from 'react-maskedinput';
import styled, { css } from 'styled-components';

import { colors } from 'style/theme';

const BaseStyles = css`
  border-radius: 3px;
  border: 1px solid ${props => (props.invalid ? colors.redBrick : colors.whiteAlto)};
  font-size: 14px;
  padding: 6px;
  width: ${props => (props.inline ? 'auto' : '100%')};
  max-width: 100%;
  ${props => (props.disabled ? 'opacity: 0.5' : null)};
  ${props => (props.inline ? 'flex: 1 1 auto' : null)};
  ${props => (props.hasButton ? 'border-top-right-radius: 0' : null)};
  ${props => (props.hasButton ? 'border-bottom-right-radius: 0' : null)};
  ${props => props.invisible && 'visibility: hidden'};
`;

const MaskedInput = styled(BaseMaskedInput)`
  ${BaseStyles};
`;
const BaseInput = styled.input.attrs({ type: 'text' })`
  ${BaseStyles};
`;

const Input = ({ mask, hasButton, ...props }) => {
  return mask ? (
    <MaskedInput mask={mask} placeholderChar=" " {...props} />
  ) : (
    <BaseInput hasButton={hasButton} {...props} />
  );
};

export default Input;
