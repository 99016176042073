import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { selectors as accountSelectors } from 'modules/account/details';
import { actions, selectors } from 'modules/account/notes';
import { selectors as userSelectors } from 'modules/user';

import NotesPopout from './NotesPopout';

class NotesPopupContainer extends PureComponent {
  componentDidMount() {
    if (this.props.accountNumber && this.props.isCsr) {
      this.props.fetchNotes(this.props.accountNumber);
    }
  }

  render() {
    return <NotesPopout {...this.props} />;
  }
}
const mapState = state => ({
  accountNumber: accountSelectors.accountNumber(state),
  isCsr: userSelectors.isCsr(state),
  notes: selectors.orderNotes(state),
});

const mapDispatch = { fetchNotes: actions.fetchNotes };

export default connect(mapState, mapDispatch)(NotesPopupContainer);
