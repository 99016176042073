const createMap = (categoryIds = [], title = '') => {
  const obj = {};
  categoryIds.forEach(category => {
    obj[category] = title;
  });
  return obj;
};

export const OPEN_ANALYSIS_KIT = 40;
export const PREPAID_KIT_WITHOUT_JAR = 41;
export const PREPAID_KIT_WITH_JAR = 42;
export const PREPAID_POSTAGE = 43;
export const JAR_INVENTORY = 45;
export const PREPAID_LABEL = 59;

const CATEGORIES = {
  kits: [OPEN_ANALYSIS_KIT, PREPAID_KIT_WITHOUT_JAR, PREPAID_KIT_WITH_JAR],
  jarsMailers: [JAR_INVENTORY],
  postage: [PREPAID_POSTAGE],
  supplies: [56, 57, 58, 44, 55],
  tubing: [56],
  vacuum: [57],
  valves: [58],
  other: [44, 55],
};

export const CATEGORY_TITLES = {
  ...createMap(CATEGORIES['kits'], 'Kits'),
  ...createMap(CATEGORIES['jarsMailers'], 'Jars/Mailers'),
  ...createMap(CATEGORIES['postage'], 'Prepaid Postage'),
  ...createMap(CATEGORIES['supplies'], 'Supplies'),
  ...createMap(CATEGORIES['tubing'], 'Tubing'),
  ...createMap(CATEGORIES['vacuum'], 'Vacuum'),
  ...createMap(CATEGORIES['valves'], 'Valves'),
  ...createMap(CATEGORIES['other'], 'Other'),
};

export default CATEGORIES;
