import React from 'react';
import styled from 'styled-components';

import { colors } from 'style/theme';
import { translate } from 'utils/translations';

const StyledSaveButton = styled.button.attrs({ type: 'button' })`
  padding: 5px 10px;
  border-radius: 3px;
  background-image: linear-gradient(to top, ${colors.grayWhisper}, ${colors.white});
  border: solid 1px ${colors.grayLight};
  ${props =>
    props.disabled &&
    `
    opacity: 0.4;
  `};
`;

const SaveButton = props => (
  <StyledSaveButton {...props}>
    <span>
      <i className="fa fa-save" /> {translate('save')}
    </span>
  </StyledSaveButton>
);

export default SaveButton;
