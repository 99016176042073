import isEmpty from 'lodash/isEmpty';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { selectors as accountSelectors } from 'modules/account/details';
import { actions, selectors } from 'modules/rushSample';

import Checkout from './Checkout';

const RushSampleCheckout = ({ accountNumber, order, onPlaceOrder }) => {
  const { data } = order;

  const onPlace = useCallback(
    payload => {
      onPlaceOrder({ ...payload, accountNumber });
    },
    [onPlaceOrder, accountNumber]
  );

  const props = {
    order: data,
    loading: order.loading,
    onPlace,
  };

  return <Checkout {...props} />;
};

const RushSampleCheckoutWithRedirect = props => {
  const { order, accountNumber } = props;
  const { data } = order;

  if (isEmpty(data)) return <Redirect to={`/accounts/${accountNumber}/rush-sample`} />;

  return <RushSampleCheckout {...props} />;
};

const mapState = state => ({
  accountNumber: accountSelectors.accountNumber(state),
  order: selectors.currentOrder(state),
});

const mapDispatch = {
  onPlaceOrder: actions.placeRushSampleOrder,
};

export default connect(mapState, mapDispatch)(RushSampleCheckoutWithRedirect);
