import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { actions } from 'modules/reference';

class ReferencesContainer extends PureComponent {
  componentDidMount() {
    this.props.fetchReference();
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = {
  fetchReference: actions.fetchReference,
};

export default connect(null, mapDispatchToProps)(ReferencesContainer);
