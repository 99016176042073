import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import RushOrderSummary from 'components/Account/RushSample/RushOrderSummary';
import { OrderActions } from 'components/Order';
import OrderHeader from 'components/Order/OrderHeader';
import { selectors as userSelectors } from 'modules/user';

import OrderCSR from './OrderCSR.container';
import OrderDetails from './OrderDetails.container';

const StyledConfirmation = styled.div`
  display: flex;
  flex-flow: column nowrap;

  .Confirmation__header {
    display: flex;
    align-items: center;
    margin: 0 0 20px;

    &-left {
      margin-right: 20px;
    }

    &-right {
      margin-left: auto;
    }

    .Confirmation__details {
      flex: 1 1 auto;
      margin-right: 15px;
    }

    .Confirmation__detail {
      font-size: 12px;
    }

    .Confirmation__number {
      font-size: 18px;
    }

    .Confirmation__actions {
      flex: 0 0 auto;
    }
  }

  .Confirmation__breakdown {
    display: flex;
  }

  .Confirmation__summary {
    flex: 1 1 auto;
    margin-right: 30px;
    min-width: 400px;
  }

  .Confirmation__options {
    flex: 0 0 auto;
    width: 275px;
  }
`;

const RushOrderDetails = ({ order }) => {
  const isCsr = useSelector(userSelectors.isCsr);
  const { orderNumber, orderDate, items, currency, totals, isTaxable } = order;

  return (
    <StyledConfirmation>
      <div className="Confirmation__header">
        <div className="Confirmation__header-left">
          <OrderHeader
            orderDate={moment(orderDate).format('MM/DD/YYYY, h:mm:ss a')}
            orderNumber={orderNumber}
            status="rush"
          />
        </div>
        <div className="Confirmation__header-right">
          <OrderActions order={order} />
        </div>
      </div>
      <div className="Confirmation__breakdown">
        <div className="Confirmation__summary">
          <RushOrderSummary data={items} currency={currency} isTaxable={isTaxable} totals={totals} />
        </div>
        <div className="Confirmation__options">
          <div style={{ marginBottom: 15 }}>
            <OrderDetails />
          </div>
          {isCsr && <OrderCSR />}
        </div>
      </div>
    </StyledConfirmation>
  );
};

export default RushOrderDetails;
