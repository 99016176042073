import { push, LOCATION_CHANGE } from 'connected-react-router';
import get from 'lodash/get';
import { createActions, handleActions } from 'redux-actions';
import { all, call, takeLatest, put, select } from 'redux-saga/effects';

import { SAMPLE_RUSH_TESTING } from 'constants/strings';
import * as api from 'lib/api';
import { placeInbound, searchInbound, stubInbound, outboundJarLabels } from 'mappers/rushSample';
import { selectors as accountSelectors } from 'modules/account/details/selectors';
import { actions as toastActions } from 'modules/toast/actions';
import { selectors as userSelectors } from 'modules/user/selectors';

const RUSH_SAMPLE_ORDER_ERROR = 'RUSH_SAMPLE_ORDER_ERROR';
const RUSH_ORDER_UPDATE_ERROR = 'RUSH_ORDER_UPDATE_ERROR';
const RUSH_SEARCH_ROUTES = {
  barcodes: api.searchRushByBarcodes,
  labcodes: api.searchRushByLabcodes,
  orderNumber: api.searchRushByOrderNumber,
};

const RESET_ACCOUNT_PATTERN = /^\/accounts\/[A-Z0-9]{14}?/i;

const defaultState = {
  order: {
    data: {},
    loading: true,
  },
  params: {},
  search: {
    data: {},
    type: 'barcodes',
  },
  rushSampleLookup: {
    data: {},
    hasSearched: false,
    loading: false,
  },
  rushSamples: {
    data: [],
    hasSearched: false,
    loading: false,
  },
  stub: { loading: false },
  totals: {
    data: {
      subtotal: null,
      tax: null,
      total: null,
    },
    loading: false,
  },
};

export const actions = createActions(
  'FETCH_RUSH_ORDER',
  'FETCH_RUSH_ORDER_RESPONSE',
  'FETCH_RUSH_SAMPLES',
  'FETCH_RUSH_SAMPLES_RESPONSE',
  'FETCH_RUSH_SAMPLES_TOTAL',
  'FETCH_RUSH_SAMPLES_TOTAL_RESPONSE',
  'FETCH_RUSH_SAMPLE_LOOKUP',
  'FETCH_RUSH_SAMPLE_LOOKUP_RESPONSE',
  'MODIFY_RUSH_ORDER',
  'MODIFY_RUSH_ORDER_RESPONSE',
  'PLACE_RUSH_SAMPLE_ORDER',
  'PLACE_RUSH_SAMPLE_ORDER_RESPONSE',
  'STUB_RUSH_SAMPLE_ORDER',
  'STUB_RUSH_SAMPLE_ORDER_RESPONSE',
  'TOGGLE_RUSH_SELECTION',
  'UPDATE_RUSH_SELECTED_SEARCH',
  'UPDATE_RUSH_SEARCH'
);

export const selectors = {
  currency: state => get(state, 'rushSamples.order.data.currency'),
  currentOrder: state => get(state, 'rushSamples.order'),
  stubOrder: state => get(state, 'rushSamples.stub'),
  isInvoiceOnly: state => get(state, 'rushSamples.order.data.isInvoiceOnly'),
  rushSamples: state => get(state, 'rushSamples.rushSamples'),
  rushSampleLookup: state => get(state, 'rushSamples.rushSampleLookup'),
  rushSamplesTotal: state => get(state, 'rushSamples.totals'),
  search: state => get(state, 'rushSamples.search'),
};

function* fetchRushOrderSaga({ payload }) {
  const { order: response } = yield call(api.loadOrder, payload);
  const order = yield call(placeInbound, response);
  yield put(actions.fetchRushOrderResponse(order));
}

function* fetchRushSampleLookupSaga({ payload }) {
  try {
    const { data, type } = payload;

    let barcodesResponse = [];
    let formattedData;
    if (type === 'labcode') {
      yield put(actions.updateRushSelectedSearch('labcodes'));
      yield put(actions.updateRushSearch({ type: 'labcodes', data: { labNumberStart: data } }));

      formattedData = { labcodes: { labNumberStart: data, labNumberEnd: data } };
      const { barcodes } = yield call(api.searchRushByLabcodes, formattedData);
      barcodesResponse = barcodes;
    } else if (type === 'barcode') {
      yield put(actions.updateRushSelectedSearch('barcodes'));
      yield put(actions.updateRushSearch({ type: 'barcodes', data: { barcodeStart: data } }));

      formattedData = { barcodes: { barcodeStart: data, barcodeEnd: data } };
      const { barcodes } = yield call(api.searchRushByBarcodes, formattedData);
      barcodesResponse = barcodes;
    }

    // Lookup Rush Testing Setting
    const accountNumber = get(barcodesResponse, '[0].loggingAccount.number');
    const { settings } = yield call(api.accountSettings, accountNumber, [SAMPLE_RUSH_TESTING]);
    const { sample_rush_testing: settingRushTesting } = settings;

    yield put(actions.fetchRushSampleLookupResponse({ accountNumber, settingRushTesting }));
  } catch (e) {
    console.log(e);
    yield put(actions.fetchRushSampleLookupResponse({}));
  }
}

function* fetchRushSamplesSaga({ payload }) {
  try {
    const accountNumber = yield select(accountSelectors.accountNumber);
    const search = yield select(selectors.search);

    const { type, data } = search;
    const { sortFields } = payload;

    const apiRoute = RUSH_SEARCH_ROUTES[type];

    if (apiRoute) {
      const { barcodes: response } = yield call(apiRoute, data, accountNumber, sortFields);
      const barcodes = yield call(searchInbound, response);
      yield put(actions.fetchRushSamplesResponse(barcodes));
    }
  } catch (e) {
    yield put(actions.fetchRushSamplesResponse([]));
  }
}

function* fetchRushSamplesTotalSaga({ payload }) {
  try {
    const accountId = get(payload, '[0].accountId');
    const currencyId = get(payload, '[0].currencyId');

    const jarLabels = outboundJarLabels(payload);

    const { subtotal, tax, total } = yield call(api.fetchRushTotal, {
      accountId,
      currencyId,
      jarLabels,
    });

    yield put(actions.fetchRushSamplesTotalResponse({ subtotal, tax, total }));
  } catch (e) {
    console.log(e);
    yield put(actions.fetchRushSamplesTotalResponse(defaultState.totals));
  }
}

const modifyRushOrderSaga = function* ({ payload: { isDetails = false, orderId, ...payload } }) {
  try {
    const { order } = yield call(isDetails ? api.modifyOrder : api.modifyCsrInfo, orderId, payload);
    const formattedOrder = yield call(placeInbound, order);
    yield put(actions.modifyRushOrderResponse(formattedOrder));
  } catch (e) {
    console.error(e);
    yield put(
      toastActions.createToast('alert', {
        id: RUSH_ORDER_UPDATE_ERROR,
        translation: true,
        message: e.message,
        timeout: false,
        title: 'store.error',
      })
    );
    yield put(actions.modifyRushOrderResponse(e));
  }
};

function* stubRushSampleOrderSaga({ payload }) {
  try {
    const accountId = yield select(accountSelectors.id);
    const currencyId = get(payload, '[0].currencyId');
    const jarLabels = outboundJarLabels(payload);

    const { rushOrder: response, totals } = yield call(api.stubRushOrder, {
      accountId,
      currencyId,
      jarLabels,
    });
    const rushOrder = yield call(stubInbound, response, totals);
    yield put(actions.stubRushSampleOrderResponse(rushOrder));

    const accountNumber = yield select(accountSelectors.accountNumber);
    yield put(push(`/accounts/${accountNumber}/rush-sample/checkout`));
  } catch (e) {
    console.error(e);
    yield put(actions.stubRushSampleOrderResponse(e));
  }
}

function* placeRushSampleOrderSaga({ payload }) {
  try {
    const { csr, payment, referenceInfo } = payload;
    const isCsr = yield select(userSelectors.isCsr);
    const user = yield select(userSelectors.details);

    const { data: currentOrder } = yield select(selectors.currentOrder);

    const notificationEmails = get(referenceInfo, 'notificationEmails', []).map(({ value }) => value);

    const data = {
      isCsr,
      payment: {
        paymentMethod: payment.paymentMethod,
        purchaseOrderNumber: payment.purchaseOrderNumber || null,
        customerInfo: payment.paymentMethod === 'credit_card' ? payment.customerInfo : null,
        creditCardInfo: payment.paymentMethod === 'credit_card' ? payment.creditCardInfo : null,
      },
      referenceInfo: { ...referenceInfo, notificationEmails, orderPlacedBy: get(user, 'name') },
      csr,
    };

    const { order: response, totals } = yield call(api.placeRushOrder, currentOrder.id, data);
    const order = yield call(placeInbound, response, totals);
    yield put(actions.placeRushSampleOrderResponse(order));

    const accountNumber = yield select(accountSelectors.accountNumber);
    yield put(push(`/accounts/${accountNumber}/rush-sample/${order.id}`));
  } catch (e) {
    console.log(e.message);
    yield put(
      toastActions.createToast('alert', {
        id: RUSH_SAMPLE_ORDER_ERROR,
        translation: true,
        message: e.message,
        timeout: false,
        title: 'store.error',
      })
    );
  }
}

export function* rushSamplesSaga() {
  yield all([
    takeLatest(actions.fetchRushOrder, fetchRushOrderSaga),
    takeLatest(actions.fetchRushSamples, fetchRushSamplesSaga),
    takeLatest(actions.fetchRushSampleLookup, fetchRushSampleLookupSaga),
    takeLatest(actions.fetchRushSamplesTotal, fetchRushSamplesTotalSaga),
    takeLatest(actions.modifyRushOrder, modifyRushOrderSaga),
    takeLatest(actions.placeRushSampleOrder, placeRushSampleOrderSaga),
    takeLatest(actions.stubRushSampleOrder, stubRushSampleOrderSaga),
  ]);
}

export default handleActions(
  {
    [LOCATION_CHANGE](state, { payload }) {
      const { location } = payload;
      if (!RESET_ACCOUNT_PATTERN.test(location.pathname)) return defaultState;
      return state;
    },
    [actions.fetchRushOrder]() {
      return { ...defaultState, order: { ...defaultState.order, loading: true } };
    },
    [actions.fetchRushOrderResponse](state, { payload }) {
      return { ...state, order: { data: payload, loading: false } };
    },
    [actions.fetchRushSampleLookup](state) {
      return {
        ...state,
        rushSampleLookup: {
          ...defaultState.rushSampleLookup,
          loading: true,
        },
      };
    },
    [actions.fetchRushSampleLookupResponse](state, { payload }) {
      return {
        ...state,
        rushSampleLookup: {
          ...state.rushSampleLookup,
          data: payload,
          hasSearched: true,
          loading: false,
        },
      };
    },
    [actions.fetchRushSamples](state) {
      return {
        ...state,
        rushSamples: {
          ...defaultState.rushSamples,
          hasSearched: true,
          loading: true,
        },
      };
    },
    [actions.fetchRushSamplesResponse](state, { payload }) {
      return {
        ...state,
        rushSamples: {
          ...state.rushSamples,
          data: payload,
          loading: false,
        },
      };
    },
    [actions.fetchRushSamplesTotal](state) {
      return {
        ...state,
        totals: {
          ...defaultState.totals,
          loading: true,
        },
      };
    },
    [actions.fetchRushSamplesTotalResponse](state, { payload }) {
      return {
        ...state,
        totals: {
          data: payload,
          loading: false,
        },
      };
    },
    [actions.modifyRushOrder](state) {
      return { ...state, order: { ...state.order, loading: true } };
    },
    [actions.modifyRushOrderResponse](state, { payload }) {
      return { ...state, order: { data: payload, loading: false } };
    },
    [actions.placeRushSampleOrder](state) {
      return {
        ...state,
        order: {
          ...state.order,
          loading: true,
        },
      };
    },
    [actions.placeRushSampleOrderResponse](state, { payload }) {
      return {
        ...state,
        order: {
          data: payload,
          loading: false,
        },
      };
    },
    [actions.stubRushSampleOrder](state) {
      return {
        ...state,
        order: {
          ...state.order,
          loading: true,
        },
        stub: {
          loading: true,
        },
      };
    },
    [actions.stubRushSampleOrderResponse](_, { payload }) {
      return {
        ...defaultState,
        order: {
          data: payload,
          loading: false,
        },
      };
    },
    [actions.toggleRushSelection](state, { payload }) {
      const { id, key, value } = payload;
      const data = state.rushSamples.data.map(sample => {
        if (!sample.isRushable) return sample;

        if (id === 'all' || sample.id === id) {
          const update = { [key]: value };

          // Check Sample when Waived Checked
          if (value && key === 'waived') update.checked = true;

          // Uncheck Waived when Sample Unchecked
          if (!value && key === 'checked') update.waived = false;

          return { ...sample, ...update };
        }

        return sample;
      });

      return {
        ...state,
        rushSamples: {
          ...state.rushSamples,
          data,
        },
      };
    },
    [actions.updateRushSelectedSearch](state, { payload }) {
      return {
        ...state,
        search: {
          ...state.search,
          type: payload,
        },
      };
    },
    [actions.updateRushSearch](state, { payload }) {
      return {
        ...state,
        search: {
          ...state.search,
          data: {
            ...state.search.data,
            [payload.type]: payload.data,
          },
        },
      };
    },
  },
  defaultState
);
