import React from 'react';
import styled from 'styled-components';

import Header from 'components/Header';
import ModalContainer from 'components/Modal.container';
import ToastContainer from 'components/Toast.container';
import { ThemeConsumer } from 'providers/ThemeProvider';
import { colors } from 'style/theme';
import { translate } from 'utils/translations';

const Layout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;

  .Root__header,
  .Root__footer {
    color: ${props => props.theme.primaryText};
  }

  .Root__header {
    text-align: right;
    padding: 0 15px;
  }

  .Root__body {
    display: flex;
    background: ${colors.white};
    position: relative;
    padding: 15px;
    flex: 1 1 auto;
  }

  .Root__footer {
    flex: 0 0 auto;
    text-align: right;
    padding: 15px 15px 90px;
    text-align: center;
    font-size: 12px;

    .menuItem {
      padding: 0 4px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  @media print {
    .Root__header,
    .Root__footer {
      display: none;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
  padding: ${props => props.padding || 0}px;
`;

const PageLayout = ({ children }) => (
  <ThemeConsumer>
    {theme => (
      <Layout theme={theme}>
        <div className="Root__header" id="menus">
          <Container>
            <Header {...theme} />
          </Container>
        </div>
        <div className="Root__body">
          <Container>{children}</Container>
          <ToastContainer />
        </div>
        <div id="footer" className="Root__footer">
          <span className="menuItem">
            {translate('questions')} <a href="mailto:custserv@eoilreports.com">custserv@eoilreports.com</a>
          </span>
          <span className="menuItem">
            <a href="https://www.eoilreports.com/legal/termsofservice.pdf" target="_blank" rel="noopener noreferrer">
              {translate('termsOfService')}
            </a>
          </span>
          <span className="menuItem">
            <a href="https://www.eoilreports.com/legal/privacynotice.pdf" target="_blank" rel="noopener noreferrer">
              {translate('privacyPolicy')}
            </a>
          </span>
        </div>
        <ModalContainer />
        <link rel="stylesheet" href={theme.stylesheetPolarisUrl} />
        <link rel="stylesheet" href={theme.stylesheetUrl} />
      </Layout>
    )}
  </ThemeConsumer>
);

export default PageLayout;
