import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import BaseLayout from 'components/BaseLayout';
import OrderContainer from 'components/Order';

import { CartLayout, CheckoutLayout, ConfirmationLayout, ProductListLayout } from './Order';

const productTypeRoute = ':productType(kits|jarsMailers|postage|supplies)';

const OrderLayout = ({ match }) => (
  <BaseLayout>
    <div className="Layout__content">
      <div className="Layout__wrapper">
        <OrderContainer>
          <Switch>
            <Route path={`${match.path}/${productTypeRoute}`} component={ProductListLayout} />
            <Route path={`${match.path}/cart`} component={CartLayout} />
            <Route path={`${match.path}/checkout`} component={CheckoutLayout} />
            <Route path={`${match.path}/confirmation`} component={ConfirmationLayout} />
            <Redirect to={`${match.path}/kits`} />
          </Switch>
        </OrderContainer>
      </div>
    </div>
  </BaseLayout>
);

export default OrderLayout;
