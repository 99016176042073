import get from 'lodash/get';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { SAMPLE_RUSH_TESTING, INVOICE_LEVEL_OPEN } from 'constants/strings';
import { selectors as accountDetailsSelectors } from 'modules/account/details';
import { actions as accountSettingsActions } from 'modules/account/settings';
import { actions, selectors } from 'modules/rushSample';
import { selectors as userSelectors } from 'modules/user';
import { updateItem } from 'utils/array';
import { translate } from 'utils/translations';

import CsrPanel from './CsrPanel';
import RushSampleResultsTable from './RushSampleResultsTable';
import RushSampleSearch from './RushSampleSearch';
import RushSampleTotals from './RushSampleTotals';

const StyledRushSample = styled.div`
  .Sample__header {
    display: flex;
  }

  .Sample__search {
    margin-right: 15px;
  }

  .Sample__csr {
    margin-left: auto;
  }
`;

const CAD = 'CAD';
const INACTIVE = 'inactive';

const RUSH_SAMPLE_DEFAULT_SORT = [];

const RushSample = ({
  isCsr,
  fetchTotals,
  getRushSamples,
  refreshAccountSettings,
  rushSamples,
  search,
  stubOrder,
  stubRushSampleOrder,
  totals,
  onSearchSelect,
  onSearchUpdate,
  onSelect,
}) => {
  const { data, hasSearched, loading } = rushSamples;
  const [error, setError] = useState();
  const [selected, setSelected] = useState([]);
  const [sortFields, setSortFields] = useState(RUSH_SAMPLE_DEFAULT_SORT);

  const currency = get(selected, '[0].currency');
  const isCanadaOnly = selected.every(i => i.currency === CAD);

  useEffect(() => {
    if (sortFields.length > 0 && hasSearched) {
      getRushSamples({ type: search.type, data: search.data.orderNumber, sortFields });
    }
  }, [sortFields, getRushSamples, hasSearched, search.data.orderNumber, search.type]);

  useEffect(() => {
    refreshAccountSettings([SAMPLE_RUSH_TESTING, INVOICE_LEVEL_OPEN]);
  }, [refreshAccountSettings]);

  const validate = useCallback(data => {
    const isValidAccounts = data.every((sample, _, arr) => sample.accountId === arr[0].accountId);
    const isValidCurrency = data.every((sample, _, arr) => sample.currencyId === arr[0].currencyId);

    if (!isValidAccounts && !isValidCurrency) {
      return 'Barcodes in different currencies or different accounts cannot be rushed in the same transaction';
    } else if (!isValidAccounts) {
      return 'Barcodes in different accounts cannot be rushed in the same transaction';
    } else if (!isValidCurrency) {
      return 'Barcodes in different currencies cannot be rushed in the same transaction';
    }
  }, []);

  useEffect(() => {
    const nextSelected = data.filter(i => i.checked);
    const error = validate(nextSelected);
    setError(error);
    setSelected(nextSelected);

    if (error || !nextSelected.length) return;
    fetchTotals(nextSelected);
  }, [data, fetchTotals, validate]);

  const onStubOrder = useCallback(() => {
    stubRushSampleOrder(selected);
  }, [selected, stubRushSampleOrder]);

  const onChange = useCallback(
    ({ type, data }) => {
      onSearchUpdate({ type, data, sortFields });
    },
    [onSearchUpdate, sortFields]
  );

  const onSearch = useCallback(
    ({ type, data }) => {
      getRushSamples({ type, data, sortFields });
    },
    [getRushSamples, sortFields]
  );

  const getSortDirection = useCallback(
    name => {
      const sort = sortFields.find(sort => sort.fieldName === name);
      return sort ? sort.sortDirection : INACTIVE;
    },
    [sortFields]
  );

  const updateSort = useCallback(
    (fieldName, sortDirection) => {
      const newSortFields = updateItem(sortFields, { fieldName, sortDirection }, 'fieldName');
      setSortFields(newSortFields);
    },
    [sortFields]
  );

  return (
    <StyledRushSample>
      <div className="Sample__header">
        <div className="Sample__search">
          <RushSampleSearch
            data={search.data}
            isCsr={isCsr}
            loading={loading}
            selected={search.type}
            onChange={onChange}
            onSearch={onSearch}
            onSelect={onSearchSelect}
          />
        </div>
        {isCsr && (
          <div className="Sample__csr">
            <CsrPanel />
          </div>
        )}
      </div>
      <RushSampleResultsTable
        hasSearched={hasSearched}
        isCsr={isCsr}
        loading={loading}
        selected={selected}
        rushSamples={data}
        getSortDirection={getSortDirection}
        updateSort={updateSort}
        errorMessage={error}
        onCheck={onSelect}
      />
      <RushSampleTotals
        currency={currency}
        disabled={error || !selected.length || stubOrder.loading}
        isTaxable={isCanadaOnly}
        loading={totals.loading}
        textSubmit={translate('proceedToCheckout', 'Proceed To Checkout')}
        totals={totals.data}
        onSubmit={onStubOrder}
      />
    </StyledRushSample>
  );
};

const mapState = state => ({
  accountDetails: accountDetailsSelectors.details(state),
  isCsr: userSelectors.isCsr(state),
  rushSamples: selectors.rushSamples(state),
  search: selectors.search(state),
  stubOrder: selectors.stubOrder(state),
  totals: selectors.rushSamplesTotal(state),
});

const mapDispatch = {
  fetchTotals: actions.fetchRushSamplesTotal,
  getRushSamples: actions.fetchRushSamples,
  refreshAccountSettings: accountSettingsActions.fetchAccountSettings,
  stubRushSampleOrder: actions.stubRushSampleOrder,
  onSearchSelect: actions.updateRushSelectedSearch,
  onSearchUpdate: actions.updateRushSearch,
  onSelect: actions.toggleRushSelection,
};

export default connect(mapState, mapDispatch)(RushSample);
