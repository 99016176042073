/* eslint no-unused-expressions: 0 */
import { createGlobalStyle } from 'styled-components';

import { colors } from 'style/theme';

export default createGlobalStyle`
  html {
    background: ${colors.white};
  }

  body {
    font-family: Arial;
    min-height: 100vh;
    * {
      box-sizing: border-box;
    }
  }

  input {
    outline: none;
    box-shadow: none;
  }

  .text--alert { color: ${colors.redBrick}; }
`;
