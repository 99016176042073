import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Panel from './Panel';

class PanelContainer extends PureComponent {
  state = {
    toggled: this.props.toggleable ? (this.props.toggled !== undefined ? this.props.toggled : true) : true,
  };

  onToggle = () => {
    const { toggleable } = this.props;
    if (!toggleable) return;
    this.setState(state => ({
      toggled: !state.toggled,
    }));
  };

  getChildProps = () => ({
    ...this.props,
    ...this.state,
    onToggle: this.onToggle,
  });

  render() {
    return <Panel {...this.getChildProps()} />;
  }
}

PanelContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  toggleable: PropTypes.bool,
  toggled: PropTypes.bool,
};

export default PanelContainer;
