import get from 'lodash/get';
import queryString from 'query-string';

import fetch, { urlWithParams } from 'utils/apiFetch';

const { REACT_APP_ENV } = process.env;
const ENVIRONMENT = REACT_APP_ENV || 'local';

const REPORTS_API_URL = '/api/v1/reports';

// Account Search
export const searchAccounts = ({ params }) => fetch('/accounts/search', { params });

// Account Details
export const accountBillingInfo = accountNumber => fetch(`/accounts/${accountNumber}/billing`);
export const fetchAccountInvoice = accountNumber => fetch(`/accounts/${accountNumber}/invoice`);
export const accountCurrentOrder = accountNumber => fetch(`/accounts/${accountNumber}/current_order`);
export const accountDetails = accountNumber => fetch(`/accounts/${accountNumber}`);
export const accountShippingAddresses = accountNumber => fetch(`/accounts/${accountNumber}/shipping_addresses`);
export const accountNotes = accountNumber => fetch(`/accounts/${accountNumber}/notes`);

export const accountSettings = (accountNumber, types) => {
  const qs = queryString.stringify({ types }, { arrayFormat: 'bracket' });
  return fetch(`/accounts/${accountNumber}/settings?${qs}`);
};

// Account Notes
export const createNote = params => fetch('/account_notes', { method: 'POST', body: params });
export const updateNote = (params, id) => fetch(`/account_notes/${id}`, { method: 'PUT', body: params });
export const deleteNote = id => fetch(`/account_notes/${id}`, { method: 'DELETE' });

// Checkout
export const addCreditCard = (customerId, body) => {
  return fetch(`/customer_profiles/${customerId}/payment_profiles`, {
    method: 'POST',
    body,
  });
};

export const deleteCreditCard = (customerId, paymentProfileId, distPointCode) => {
  return fetch(`/customer_profiles/${customerId}/payment_profiles/${paymentProfileId}?distPointCode=${distPointCode}`, {
    method: 'DELETE',
  });
};

export const createUserProfile = body => fetch('/customer_profiles', { method: 'POST', body });

export const fetchUserProfile = (customerId, distPointCode) => {
  return fetch(`/customer_profiles/${customerId}?distPointCode=${distPointCode}`);
};

export const fetchSettingsOptions = () => fetch('/settings/options');

export const saveOverride = (accountNumber, orderId, params) => {
  return fetch(`/checkout/${accountNumber}/${orderId}/override`, {
    method: 'POST',
    body: params,
  });
};

export const updateOrderAddress = (orderId, addressId, params, skipValidation = false) => {
  return fetch(`/checkout/${orderId}/${addressId}`, {
    method: 'PUT',
    body: { params, skipValidation },
  });
};

// Address
export const updateAddress = ({ id, ...address }) => fetch(`/addresses/${id}`, { method: 'PUT', body: address });
export const createAddress = address => fetch('/addresses', { method: 'POST', body: address });

// Billing Information
export const updateBillingInfo = ({ id, ...billingInfo }) =>
  fetch(`/accounts/${id}/billing`, { method: 'PUT', body: billingInfo });

// Shipping Addresses
export const shippingOptions = country => fetch(`/shipping_options/${country}`);
export const editShippingAddress = ({ id, ...address }) => fetch(`/addresses/${id}`, { method: 'PUT', body: address });
export const deleteShippingAddress = id => fetch(`/addresses/${id}`, { method: 'DELETE' });
export const makeDefaultShippingAddress = id => fetch(`/addresses/${id}/default`, { method: 'PUT' });
export const fetchCityRegion = payload => fetch('/addresses/lookup', { method: 'POST', body: payload });

export const validateNewShippingAddress = (params, saveToAccount, skipValidation = false) => {
  return fetch('/addresses/validate', {
    method: 'POST',
    body: { params, saveToAccount, skipValidation },
  });
};

export const validateExistingShippingAddress = ({ id, ...params }, saveToAccount, skipValidation = false) => {
  return fetch(`/addresses/validate/${id}`, {
    method: 'PUT',
    body: { params, saveToAccount, skipValidation },
  });
};

// Orders
export const addItem = (orderId, productListItemId, quantity, prefillLabel, labelsOnly, substitutions) => {
  return fetch(`/orders/${orderId}/items`, {
    method: 'POST',
    body: {
      productListItemId,
      quantity,
      prefillLabel,
      labelsOnly,
      substitutions,
    },
  });
};

export const checkPurchaseOrder = ({ account_id, poNumber }) => {
  return orderSearch({
    accountId: account_id,
    params: {
      poNumber,
      sort: 'ordered_at',
      sortDirection: 'desc',
      exact: true,
      cancelled: false,
    },
  });
};

export const shippingRates = orderId => fetch(`/easy_post/rates/${orderId}`);
export const cancelOrder = (orderId, reason) =>
  fetch(`/orders/${orderId}/cancel`, { method: 'POST', body: { reason } });
export const duplicateOrder = orderId => fetch(`/orders/${orderId}/duplicate`, { method: 'POST' });
export const loadOrder = orderId => fetch(`/orders/${orderId}`);
export const emailInvoiceAddress = (orderId, body) => {
  return fetch(`/orders/${orderId}/email_invoice_address`, { method: 'POST', body });
};

export const orderSearch = ({ accountId, params }) => {
  return fetch('/orders/search', {
    method: 'POST',
    body: { accountId, ...params },
  });
};

export const recurringOrderCancel = orderId => fetch(`/order_recurrences/${orderId}/cancel`, { method: 'POST' });
export const recurringOrderDelete = orderId => fetch(`/order_recurrences/${orderId}`, { method: 'DELETE' });

export const recurringOrderCreate = (orderId, body) => {
  return fetch(`/orders/${orderId}/recur`, { method: 'POST', body });
};

export const recurringOrderSearch = accountId => {
  return fetch('/order_recurrences/search', { method: 'POST', body: { accountId } });
};

export const modifyCsrInfo = (orderId, data) => {
  return fetch(`/orders/${orderId}/modifyCsrInfo`, {
    method: 'PUT',
    body: { data },
  });
};

export const modifyOrder = (orderId, data) => {
  return fetch(`/orders/${orderId}/modify`, {
    method: 'PUT',
    body: { data },
  });
};

export const modifyShippedOrder = ({ orderId, trackingNumber }) => {
  return fetch(`/orders/${orderId}/modify_shipped_order`, {
    method: 'PUT',
    body: { trackingNumber },
  });
};

export const placeOrder = (orderId, data) => {
  return fetch(`/orders/${orderId}/place`, {
    method: 'PUT',
    body: { data },
  });
};

export const removeItem = (orderId, itemId) => {
  return fetch(`/orders/${orderId}/items/${itemId}`, {
    method: 'DELETE',
  });
};

export const stubOrder = address => {
  return fetch('/orders/stub', {
    method: 'POST',
    body: { address },
  });
};

// Order Notification Emails
export const createEmailNotification = params => fetch('/order_notification_emails', { method: 'POST', body: params });
export const deleteEmailNotification = id => fetch(`/order_notification_emails/${id}`, { method: 'DELETE' });
export const fetchNotificationEmails = orderId => fetch(`orders/${orderId}/emails`);

export const editEmailNotification = (params, id) => {
  return fetch(`/order_notification_emails/${id}`, { method: 'PUT', body: params });
};

// Product List Items
export const fetchProductListItems = (orderId, categoryIds) => {
  return fetch('/store/product_list_items/search', {
    method: 'POST',
    body: { orderId, categories: categoryIds },
  });
};

export const fetchProductListItemsSimple = (orderId, categoryIds) => {
  return fetch('/store/product_list_items/search_simple', {
    method: 'POST',
    body: { orderId, categories: categoryIds },
  });
};

export const fetchProductListItem = (id, orderId) => fetch(`/store/product_list_items/${id}?orderId=${orderId}`);

// Rush Order
export const searchRushByBarcodes = (data, accountNumber, sortFields) => {
  const barcodeStart = get(data, 'barcodes.barcodeStart');
  const barcodeEnd = get(data, 'barcodes.barcodeEnd') || barcodeStart;

  return fetch('/rush/get_barcodes_by_barcode_range', {
    params: { accountNumber, barcodeStart, barcodeEnd, sortFields },
  });
};

export const searchRushByLabcodes = (data, accountNumber, sortFields) => {
  const labNumberStart = get(data, 'labcodes.labNumberStart');
  const labNumberEnd = get(data, 'labcodes.labNumberEnd') || labNumberStart;

  return fetch('/rush/get_barcodes_by_lab_number_range', {
    params: { accountNumber, labNumberStart, labNumberEnd, sortFields },
  });
};

export const searchRushByOrderNumber = (data, accountNumber, sortFields) => {
  return fetch(`/rush/get_barcodes_by_order_number/${data.orderNumber}`, { params: { accountNumber, sortFields } });
};

export const fetchRushTotal = body => fetch('/rush/totals', { method: 'POST', body });
export const stubRushOrder = body => fetch('/rush/stub', { method: 'POST', body });
export const placeRushOrder = (rushOrderId, data) =>
  fetch(`/rush/${rushOrderId}/place`, { method: 'PUT', body: { data } });

// Substitute Items
export const fetchSubstituteItems = orderId => fetch(`/orders/${orderId}/substitutions`);

// Session
export const fetchSession = () => fetch('/session');
export const verifyToken = token => fetch('/session/verify', { method: 'POST', body: { token } });

// Translations
export const fetchTranslations = () => fetch('/translations');

// User Search
export const userSearch = searchText => fetch('/users/search', { params: { searchText } });

// Reference
export const fetchReferenceData = () => fetch('/product_management/reference');

// Reports
const createApiFileUrl = (url, params) => {
  let hostname = '';
  if (ENVIRONMENT === 'local') {
    hostname = 'http://localhost:17100';
  }

  return urlWithParams(`${hostname}${REPORTS_API_URL}${url}`, params);
};

export const createReportUrl = (params, reportName) => createApiFileUrl(`/${reportName}/export`, { ...params });

export const createKitCountReportUrl = (params, reportType) => {
  return createApiFileUrl(`/kit_count/${reportType}/export`, { ...params });
};
