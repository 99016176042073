import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { selectors } from 'modules/reference';

const withReference = Component => {
  class WrappedComponent extends PureComponent {
    getChildProps = () => ({
      ...this.props,
      ...this.state,
    });

    render() {
      return <Component {...this.getChildProps()} />;
    }
  }

  const mapState = state => ({
    ...selectors.all(state),
  });

  return connect(mapState)(WrappedComponent);
};

export default withReference;
