// #region imports
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { SearchButton, ClearButton } from 'components/Buttons';
import DateRangePicker from 'components/DateRangePicker';
import ListTable from 'components/ListTable';
import OneColumnRow from 'components/OneColumnRow';
import { SearchSortInput, SearchSortSelect } from 'components/SearchSortLabel';
import ShowSelect from 'components/ShowNumberSelect';
import { PACKED, RUSH, SHIPPED } from 'constants/orderStatuses';
import { selectors } from 'modules/reference';
import { colors } from 'style/theme';
import { changeMethod } from 'utils/common';
import getOrderStatus from 'utils/getOrderStatus';
import { get, isEmpty, noop } from 'utils/lodash';
import { translate } from 'utils/translations';
// #endregion

const Root = styled.div`
  position: static;

  .PreviousOrders__controls {
    display: flex;
    margin-bottom: 16px;
  }

  .PreviousOrders__result {
    td {
      vertical-align: top;
    }

    a {
      &,
      :visited,
      :hover,
      :active,
      :focus {
        color: ${colors.blueWater};
        text-decoration: none;
      }
    }
  }
`;

const PreviousOrders = ({
  accountId,
  error,
  hasSearched,
  loading,
  orders,
  params = {},
  resetSearch = noop,
  searchSubmit = noop,
  updateParam = noop,
  updatePageSize = noop,
  updateSort = noop,
  validSearch,
}) => {
  const orderStatuses = useSelector(selectors.orderStatuses);

  return (
    <Root>
      <form onSubmit={searchSubmit}>
        <div className="PreviousOrders__controls">
          <ShowSelect
            label={translate('show')}
            value={params.pageSize || '100'}
            clearable={false}
            onChange={updatePageSize}
            width={200}
          />
          <DateRangePicker
            style={{ marginLeft: 25 }}
            startDate={params.startDate}
            endDate={params.endDate}
            handleStartDateChange={changeMethod(updateParam, 'startDate')}
            handleEndDateChange={changeMethod(updateParam, 'endDate')}
          />
        </div>
        <ListTable>
          <thead>
            <tr>
              <th>
                <SearchSortInput
                  size={8}
                  label={translate('orderNumber', 'Order #')}
                  searchQuery={params.orderNumber}
                  searchHandler={changeMethod(updateParam, 'orderNumber')}
                  toggleHandler={changeMethod(updateSort, 'orderNumber')}
                  toggleIsActive={params.sort === 'orderNumber'}
                  autoFocus
                />
              </th>
              {!accountId && (
                <th>
                  <SearchSortInput
                    invalidLength={3}
                    label={translate('accountNumber', 'Account Number')}
                    searchQuery={params.accountNumber}
                    searchHandler={changeMethod(updateParam, 'accountNumber')}
                    toggleHandler={changeMethod(updateSort, 'accountNumber')}
                    toggleIsActive={params.sort === 'accountNumber'}
                  />
                </th>
              )}
              <th>
                {accountId ? (
                  <SearchSortInput
                    invalidLength={3}
                    label={translate('PO', 'PO #')}
                    searchQuery={params.poNumber}
                    searchHandler={changeMethod(updateParam, 'poNumber')}
                    toggleHandler={changeMethod(updateSort, 'poNumber')}
                    toggleIsActive={params.sort === 'poNumber'}
                  />
                ) : (
                  <SearchSortInput
                    invalidLength={3}
                    label={translate('shipTo', 'Ship to')}
                    searchQuery={params.shipTo}
                    searchHandler={changeMethod(updateParam, 'shipTo')}
                    toggleHandler={changeMethod(updateSort, 'shipTo')}
                    toggleIsActive={params.sort === 'shipTo'}
                  />
                )}
              </th>
              <th>
                <SearchSortInput
                  invalidLength={3}
                  label={translate('city')}
                  searchQuery={params.city}
                  searchHandler={changeMethod(updateParam, 'city')}
                  toggleHandler={changeMethod(updateSort, 'city')}
                  toggleIsActive={params.sort === 'city'}
                />
              </th>
              <th>
                <SearchSortInput
                  maxLength={2}
                  size={8}
                  invalidLength={2}
                  label={translate('state', 'State')}
                  searchQuery={params.state}
                  searchHandler={changeMethod(updateParam, 'state')}
                  toggleHandler={changeMethod(updateSort, 'state')}
                  toggleIsActive={params.sort === 'state'}
                />
              </th>
              <th>
                <SearchSortInput
                  maxLength={2}
                  size={8}
                  invalidLength={2}
                  label={translate('country', 'Country')}
                  searchQuery={params.country}
                  searchHandler={changeMethod(updateParam, 'country')}
                  toggleHandler={changeMethod(updateSort, 'country')}
                  toggleIsActive={params.sort === 'country'}
                />
              </th>
              <th>
                {accountId ? (
                  translate('trackingNumber', 'Tracking #')
                ) : (
                  <SearchSortInput
                    invalidLength={3}
                    label={translate('PO', 'PO #')}
                    searchQuery={params.poNumber}
                    searchHandler={changeMethod(updateParam, 'poNumber')}
                    toggleHandler={changeMethod(updateSort, 'poNumber')}
                    toggleIsActive={params.sort === 'poNumber'}
                  />
                )}
              </th>
              <th>
                <SearchSortInput
                  size={8}
                  invalidLength={3}
                  label={translate('ordered', 'Ordered')}
                  toggleHandler={changeMethod(updateSort, 'ordered')}
                  toggleIsActive={params.sort === 'ordered'}
                />
              </th>
              <th>
                <SearchSortSelect
                  label={translate('status', 'Status')}
                  options={orderStatuses.options}
                  searchQuery={params.status}
                  searchHandler={changeMethod(updateParam, 'status')}
                  toggleHandler={changeMethod(updateSort, 'status')}
                  toggleIsActive={params.sort === 'status'}
                />
              </th>
              <th>{translate('shipping.orderPlacedBy', 'Order Placed By')}</th>
              <th style={{ width: 30, verticalAlign: 'bottom', padding: '10px 5px' }}>
                <SearchButton type="submit" disabled={!validSearch} onClick={searchSubmit} />
              </th>
              <th style={{ width: 30, verticalAlign: 'bottom', padding: '10px 15px 10px 5px' }}>
                <ClearButton onClick={() => resetSearch()} icon="close" disabled={!hasSearched} />
              </th>
            </tr>
          </thead>
          <tbody>
            {error && <OneColumnRow colSpan="12" error text={translate('anErrorOccurred')} />}
            {loading && <OneColumnRow colSpan="12" text={`${translate('loading')}...`} />}
            {orders &&
              orders.length > 0 &&
              orders.map(order => {
                const accountNumber = get(order, 'account.number', '');
                const address = get(order, 'address', {});
                const orderId = get(order, 'id', '');
                const orderStatus = getOrderStatus(order);
                const translatedOrderStatus = translate(orderStatus, orderStatus);

                let orderUrl = `/accounts/${accountNumber}/orders/${orderId}`;

                if (get(order, 'status', '') === RUSH) {
                  orderUrl = `/accounts/${accountNumber}/rush-sample/${orderId}`;
                }

                return (
                  <tr key={orderId} className="PreviousOrders__result">
                    <td>
                      <Link to={orderUrl}>{orderId}</Link>
                    </td>
                    {!accountId && (
                      <td>
                        <Link to={`/accounts/${accountNumber}`} onClick={e => e.stopPropagation()}>
                          {accountNumber}
                        </Link>
                      </td>
                    )}
                    <td>{accountId ? order.purchase_order_number : address.company_name}</td>
                    <td>{address.city}</td>
                    <td>{address.region}</td>
                    <td>{address.country}</td>
                    <td>
                      {accountId
                        ? [PACKED, SHIPPED].includes(order.status)
                          ? order.tracking_number
                          : 'N/A'
                        : order.purchase_order_number}
                    </td>
                    <td>{moment(order.ordered_at).format('MM/DD/YYYY')}</td>
                    <td>
                      {order.shipped_at
                        ? `${translatedOrderStatus} - ${moment(order.shipped_at).format('MM/DD/YYYY')}`
                        : translatedOrderStatus}
                    </td>
                    <td>{order.order_placed_by}</td>
                    <td />
                    <td />
                  </tr>
                );
              })}
            {!error && validSearch && hasSearched && orders.length === 0 && (
              <OneColumnRow colSpan="12" text={translate('noRecordsFound')} />
            )}
            {!error && !loading && isEmpty(orders) && !hasSearched && (
              <OneColumnRow colSpan="12" text={translate('previousOrderSearchNoSearchYet')} />
            )}
          </tbody>
        </ListTable>
      </form>
    </Root>
  );
};

export default PreviousOrders;
