import { Breadcrumbs } from '@polarislabs/polaris-ui-components';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import selectors from 'modules/checkout/selectors';
import { renderMask } from 'utils/display';
import { translate } from 'utils/translations';

const ConfirmationBreadcrumbs = ({ match, orderId }) => {
  const { accountNumber } = match.params;

  const routes = [
    { url: '/accounts', label: translate('accounts', 'Accounts') },
    { url: `/accounts/${accountNumber}/details`, label: renderMask(accountNumber) },
    { label: `Order #${orderId}`.toUpperCase() },
  ];

  return <Breadcrumbs routes={routes} />;
};

const mapState = state => ({
  orderId: selectors.confirmationData(state).id,
});

const enhance = compose(withRouter, connect(mapState));

export default enhance(ConfirmationBreadcrumbs);
