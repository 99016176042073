import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import React, { useCallback, useEffect, useState } from 'react';

import Select from 'components/Select';
import { PREPAID_LABEL } from 'constants/categories';
import { findDescription } from 'utils/display';

const getAllowedItems = (allItems, category, allowedQuantity) => {
  let filteredItems;

  if (category === PREPAID_LABEL) {
    filteredItems = allItems.filter(item => item.category === PREPAID_LABEL);
  } else {
    filteredItems = allItems.filter(item => {
      const jarQuantity = get(item, 'itemUpdate.jar_quantity');
      return jarQuantity === allowedQuantity;
    });
  }

  return filteredItems.map(item => ({
    label: findDescription(item),
    value: item.item_number,
  }));
};

const SubstituteItemsSelect = ({ category, substituteItems, ...props }) => {
  const { onChange, value, allowedQuantity } = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [onChange, value]);

  const setSelectOptions = useCallback(
    items => {
      const allowedItems = getAllowedItems(items, category, allowedQuantity);

      const sortedItems = sortBy(allowedItems, 'label');
      setOptions(sortedItems);
    },
    [category, allowedQuantity]
  );

  useEffect(() => {
    setSelectOptions(substituteItems);
  }, [substituteItems, setSelectOptions]);

  const getChildProps = () => ({
    ...props,
    options,
  });

  return <Select inline {...getChildProps()} />;
};

export default SubstituteItemsSelect;
