const createStateObject = (key, initialValue, defaults = {}) => ({
  [key]: {
    data: initialValue,
    loading: true,
    error: false,
    ...defaults,
  },
});

export default createStateObject;
