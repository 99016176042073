import React from 'react';
import styled from 'styled-components';

import { colors } from 'style/theme';

const StyledSearchButton = styled.button`
  background-image: linear-gradient(to top, ${colors.greenSpinach}, ${colors.greenMantis});
  border-radius: 3px;
  border: solid 1px ${colors.greenApple};
  color: ${colors.white};
  font-size: 14px;
  height: 30px;
  padding: 0;
  text-shadow: -0px -1px 0 rgba(0, 0, 0, 0.3);
  width: 30px;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }
`;

const SearchButton = props => (
  <StyledSearchButton type="button" {...props}>
    <i className="fa fa-search" />
  </StyledSearchButton>
);

export default SearchButton;
