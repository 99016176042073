import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import OrderActions from 'components/Order/OrderActions';
import { ENVIRONMENT } from 'config';
import { actions as modalActions } from 'modules/modal';
import { actions } from 'modules/order';
import { translate } from 'utils/translations';

class OrderActionsContainer extends PureComponent {
  cancelOrder = () => {
    const { cancelOrder, showModal } = this.props;
    const { id } = this.props.order;

    showModal('cancelOrder', {
      type: 'alert',
      title: `${translate('store.cancelOrderTitle', 'Cancel Order')} #${id}`,
      message: translate('store.cancelOrderMessage', 'Are you sure you want to cancel this order?'),
      closeText: translate('store.cancelOrderClose', 'Yes, Cancel Order'),
      cancelText: translate('store.cancelOrderCancel', 'No, Nevermind'),
      locked: true,
      onClose: reason => cancelOrder({ id, reason }),
    });
  };

  duplicateOrder = () => {
    const { duplicateOrder, showModal } = this.props;
    const { id } = this.props.order;

    showModal('confirm', {
      type: 'success',
      locked: true,
      title: `${translate('store.duplicateOrderTitle', 'Duplicate Order')} #${id}`,
      message: translate('store.duplicateOrderMessage', 'Are you sure you want to duplicate this order?'),
      closeText: translate('store.duplicateOrderClose', 'Yes, Duplicate Order'),
      cancelText: translate('store.duplicateOrderCancel', 'No, Nevermind'),
      onClose: () => duplicateOrder(id),
    });
  };

  packingSlip = () => {
    const { id } = this.props.order;

    let hostname = '';
    if (ENVIRONMENT === 'local') {
      hostname = 'http://localhost:17100';
    }

    window.open(`${hostname}/api/v1/orders/${id}/packing_slip.pdf`, '_blank');
  };

  printOrder = () => {
    window.print();
  };

  recurringOrder = () => {
    const { order, recurringOrder, showModal } = this.props;
    const { id } = order;

    showModal('recurringOrder', {
      order,
      type: 'info',
      locked: true,
      title: `${translate('store.recurringOrderTitle', 'Schedule Reoccuring Order')} #${id}`,
      closeText: translate('store.recurringOrderClose', 'Schedule Order'),
      cancelText: translate('store.recurringOrderCancel', 'Cancel'),
      onClose: ({ recurrenceType, startDate }) => recurringOrder({ id, recurrenceType, startDate }),
    });
  };

  render() {
    return (
      <OrderActions
        order={this.props.order}
        onCancel={this.cancelOrder}
        onDuplicate={this.duplicateOrder}
        onPackingSlip={this.packingSlip}
        onPrint={this.printOrder}
        onReoccuring={this.recurringOrder}
      />
    );
  }
}

const mapDispatch = {
  cancelOrder: actions.cancelOrder,
  duplicateOrder: actions.duplicateOrder,
  recurringOrder: actions.recurringOrder,
  showModal: modalActions.showModal,
};

export default connect(null, mapDispatch)(OrderActionsContainer);
