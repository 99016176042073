import { connect } from 'react-redux';

import SummaryTable from 'components/Order/SummaryTable';
import { selectors as accountSelectors } from 'modules/account/details';
import selectors from 'modules/checkout/selectors';
import { selectors as userSelectors } from 'modules/user';

const mapState = state => ({
  currency: selectors.confirmationCurrency(state),
  items: selectors.confirmationItems(state),
  language: userSelectors.language(state),
  address: selectors.confirmationAddress(state),
  hasOverrides: selectors.confirmationOverrides(state),
  reportAddress: accountSelectors.reportAddress(state),
});

export default connect(mapState)(SummaryTable);
