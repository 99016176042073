import pick from 'lodash/pick';
import React, { useEffect, useState } from 'react';

import EditableField from 'components/EditableField';
import Input from 'components/Input';
import JiraLink from 'components/JiraLink';
import PanelContainer from 'components/Panel';
import RowItem from 'components/RowItem';
import { colors } from 'style/theme';
import { translate } from 'utils/translations';

const BillingInfoCSR = ({ data, isInvoiceAccount, warningModal, ...props }) => {
  const [edits, setEdits] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const editableData = pick(data, ['jira_ticket_number', 'nit_number']);
    setEdits(editableData);
    setLoading(false);
  }, [data]);

  const onChange = (key, value) => {
    setEdits(edits => ({
      ...edits,
      [key]: value,
    }));
  };

  const onInputChange = e => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  const onCancel = () => {
    setEdits({ ...data });
  };

  const onSave = () => {
    setLoading(true);
    props.onSave(edits);
  };

  return (
    <PanelContainer style={{ background: colors.blueHawkes }}>
      <RowItem title={translate('jiraTicketNumber')} center>
        <EditableField
          disabledAll={loading}
          value={data}
          editConfirmation={!isInvoiceAccount ? warningModal : false}
          onCancel={onCancel}
          onSave={onSave}
        >
          {({ editing }) => {
            if (editing) {
              return (
                <Input name="jira_ticket_number" onChange={onInputChange} value={edits.jira_ticket_number || ''} />
              );
            }
            return edits.jira_ticket_number ? <JiraLink issueNumber={edits.jira_ticket_number} /> : 'None';
          }}
        </EditableField>
      </RowItem>
      <RowItem title={translate('nitNumber')} center>
        <EditableField
          disabledAll={loading}
          editConfirmation={!isInvoiceAccount ? warningModal : false}
          value={data}
          onCancel={onCancel}
          onSave={onSave}
        >
          {({ editing }) => {
            if (editing) {
              return <Input name="nit_number" onChange={onInputChange} value={edits.nit_number || ''} />;
            }
            return edits.nit_number || 'None';
          }}
        </EditableField>
      </RowItem>
    </PanelContainer>
  );
};

export default BillingInfoCSR;
