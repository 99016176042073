import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';

export const isEmptyString = val => isString(val) && isEmpty(val);

export { isEmpty, isFunction, isString };
export { default as get } from 'lodash/get';
export { default as omit } from 'lodash/omit';
export { default as pick } from 'lodash/pick';
export { default as noop } from 'lodash/noop';
export { default as find } from 'lodash/find';
