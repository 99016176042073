import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Lightbox from 'components/Lightbox';
import { colors } from 'style/theme';

const Modal = styled.div`
  position: relative;
  background-color: white;
  padding: ${props => (props.error ? '120px 40px 40px 40px' : '40px')};
`;

const Header = styled.div`
  font-weight: bold;
  margin-top: -7px;
  margin-bottom: 1em;
  font-size: 14px;
`;

const CloseButton = styled.i.attrs({ className: 'fa fa-close' })`
  color: ${props => (props.error ? colors.white : colors.grayHeather)};
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 16px;
  cursor: pointer;
`;

const Dialog = ({ children, onClose, locked, modalStyle, header, ...props }) => (
  <Lightbox onBackdropClick={!locked ? onClose : noop}>
    <Modal {...props} style={modalStyle}>
      {header && <Header>{header}</Header>}
      {children}
      <CloseButton {...props} onClick={() => onClose()} />
    </Modal>
  </Lightbox>
);

Dialog.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Dialog;
