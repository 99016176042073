import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import options, { optionsSaga } from './options';

export function* shippingSaga() {
  yield all([optionsSaga()]);
}

export default combineReducers({
  options,
});
