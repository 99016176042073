import React from 'react';
import styled from 'styled-components';

import Button from 'components/Buttons/Button';
import Currency from 'components/Currency/index';
import Dialog from 'components/Dialog/index';
import Loader from 'components/Loader';
import { OverrideTable } from 'components/Order';
import { translate } from 'utils/translations';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 25px;
`;

const Table = styled.div`
  margin: 0 0 10px;
`;

const FooterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  font-size: 12px;
  padding: 0 15px;
  margin: 0 0 10px;

  &:last-child {
    margin: 0;
  }

  .Item__title {
    margin-right: 25px;
  }

  .Item__price {
    width: 125px;
  }
`;

const OverrideDialog = ({
  closeDialog,
  currency,
  override: { discount, loading, shippingPrice, subtotal, tax, total },
  saveOverride,
}) => {
  if (loading) return <Loader />;
  return (
    <Dialog
      onClose={closeDialog}
      modalStyle={{ width: 900, maxWidth: '90vw' }}
      header={translate('checkout.costOverride', 'Cost Override')}
      locked
    >
      <Table>
        <OverrideTable showOverridePrice editable />
      </Table>
      <FooterItem>
        <div className="Item__title">{translate('shipping.subtotal', 'Subtotal')}:</div>
        <div className="Item__price">
          <Currency quantity={subtotal} currency={currency} />
        </div>
      </FooterItem>
      <FooterItem>
        <div className="Item__title">{translate('shipping.shipping', 'Shipping & Handling')}:</div>
        <div className="Item__price">
          <Currency quantity={shippingPrice} currency={currency} />
        </div>
      </FooterItem>
      <FooterItem>
        <div className="Item__title">{translate('shipping.tax', 'Tax')}:</div>
        <div className="Item__price">
          <Currency quantity={tax || 0} currency={currency} />
        </div>
      </FooterItem>
      <FooterItem>
        <div className="Item__title">{translate('checkout.discountProvided', 'Discount Provided')}:</div>
        <div className="Item__price">
          <Currency quantity={discount} currency={currency} />
        </div>
      </FooterItem>
      <FooterItem>
        <div className="Item__title">{translate('shipping.total', 'Order Total')}:</div>
        <div className="Item__price">
          <Currency quantity={total || 0} currency={currency} />
        </div>
      </FooterItem>
      <ButtonContainer>
        <Button inline style={{ marginRight: 10 }} icon="times" onClick={() => closeDialog()}>
          {translate('shipping.cancel', 'Cancel')}
        </Button>
        <Button inline success icon="check" onClick={() => saveOverride()}>
          {translate('shipping.update', 'Update')}
        </Button>
      </ButtonContainer>
    </Dialog>
  );
};

export default OverrideDialog;
