import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { SortButton } from 'components/Buttons';

export const Root = styled.div`
  position: relative;

  .SearchSortLabel__label {
    display: block;
    margin-bottom: 10px;
  }
`;

const SortLabel = ({ label, sortDirection, toggleHandler, toggleIsActive }) => (
  <Root>
    {label}
    <SortButton isActive={toggleIsActive} sortDirection={sortDirection} toggle={toggleHandler} />
  </Root>
);

SortLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  sortDirection: PropTypes.string,
  toggleHandler: PropTypes.func.isRequired,
};

export default SortLabel;
