import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import AlertModal from 'components/Modals/AlertModal';
import CancelOrderModal from 'components/Modals/CancelOrderModal';
import ConfirmModal from 'components/Modals/ConfirmModal';
import DuplicatePOModal from 'components/Modals/DuplicatePOModal';
import RecurringOrderModal from 'components/Modals/RecurringOrderModal';

const types = {
  alert: <AlertModal />,
  cancelOrder: <CancelOrderModal />,
  confirm: <ConfirmModal />,
  duplicatePO: <DuplicatePOModal />,
  recurringOrder: <RecurringOrderModal />,
};

const getModalComponent = type => types[type] || null;

const ModalConductor = ({ modals = [] }) => (
  <Fragment>
    <ReactCSSTransitionGroup transitionName="modal" transitionEnterTimeout={300} transitionLeaveTimeout={200}>
      {modals.map(modal => {
        const Modal = getModalComponent(modal.type);

        return Modal
          ? React.cloneElement(Modal, {
              key: modal.id,
              id: modal.id,
              ...modal.options,
            })
          : null;
      })}
    </ReactCSSTransitionGroup>
  </Fragment>
);

const stateToProps = state => state.modal;

export default connect(stateToProps)(ModalConductor);
