import React from 'react';
import styled from 'styled-components';

import PanelContainer from 'components/Panel';
import { notebookTypes } from 'modules/account/notes';

import NotesList from './NotesList.container';

const StyledPopout = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1100px;
  max-width: 100%;
  padding: 0 15px;
`;

const NotesPopout = ({ isCsr, notes }) => {
  if (!isCsr) return null;
  if (notes.length === 0) return null;

  return (
    <StyledPopout>
      <PanelContainer
        title={
          <span>
            <i className="fa fa-file-text" style={{ marginRight: 10 }} />
            Order Notes
          </span>
        }
        maxHeight={500}
        toggleable
        raised
        csrDark
      >
        <NotesList locked notebook={notebookTypes.ORDER} />
      </PanelContainer>
    </StyledPopout>
  );
};

export default NotesPopout;
