import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import billingAddress, { billingAddressSaga } from './billingAddress';
import details, { detailsSaga } from './details';
import notes, { notesSaga } from './notes';
import orderSearch, { saga as orderSearchSaga } from './orderSearch';
import search, { searchSaga } from './search';
import settings, { accountSettingsSaga } from './settings';
import shippingAddresses, { shippingAddressesSaga } from './shippingAddresses';

export function* accountSaga() {
  yield all([
    accountSettingsSaga(),
    billingAddressSaga(),
    detailsSaga(),
    notesSaga(),
    orderSearchSaga(),
    searchSaga(),
    shippingAddressesSaga(),
  ]);
}

export default combineReducers({ billingAddress, details, settings, notes, search, shippingAddresses, orderSearch });
