import styled from 'styled-components';

const IconButton = styled.button.attrs(props => ({
  className: `${props.icon} fa`,
}))`
  padding: 8px 10px;
  color: ${props => props.color || '#4380ce'};
  background: transparent;
  border: none;
  outline: none;

  ${props => props.compact && 'padding: 3px 5px'}

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
    color: #ccc;
  }
`;

export default IconButton;
