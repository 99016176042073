import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import CartList from 'components/Cart/CartList';
import { selectors as accountSelectors } from 'modules/account/details';
import { actions, selectors } from 'modules/order';
import { selectors as userSelectors } from 'modules/user';

class CartListContainer extends PureComponent {
  onRemove = itemId => {
    this.props.removeItem({ itemId });
  };

  render() {
    return <CartList onRemove={this.onRemove} {...this.props} />;
  }
}

const mapStateToProps = state => ({
  address: selectors.address(state),
  currency: selectors.currency(state),
  language: userSelectors.language(state),
  orderItemNotes: selectors.orderItemNotes(state),
  reportAddress: accountSelectors.reportAddress(state),
  summary: selectors.summary(state),
});

const mapDispatch = {
  removeItem: actions.removeItem,
};

export default connect(mapStateToProps, mapDispatch)(CartListContainer);
