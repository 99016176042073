import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import PanelContainer from 'components/Panel';
import { notebookTypes } from 'modules/account/notes';
import { translate } from 'utils/translations';

import AddNoteButton from './AddNoteButton.container';
import NoteFormDialog from './NoteFormDialog.container';
import NotesList from './NotesList.container';

const Title = styled.span`
  button {
    margin-left: 10px;
  }
`;

class AccountNotes extends PureComponent {
  static propTypes = {
    fetchNotes: PropTypes.func.isRequired,
    accountNumber: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.props.fetchNotes(this.props.accountNumber);
  }

  render() {
    const { accountNumber } = this.props;

    const orderNotesTitle = (
      <Title>
        {translate('orderNotes')}
        <AddNoteButton notebook={notebookTypes.ORDER} />
      </Title>
    );

    const shipNotesTitle = (
      <Title>
        {translate('shipNotes')}
        <AddNoteButton notebook={notebookTypes.SHIP} />
      </Title>
    );

    return [
      <PanelContainer key="order-notes-panel" title={orderNotesTitle} csr collapse>
        <NotesList notebook={notebookTypes.ORDER} />
      </PanelContainer>,
      <PanelContainer key="ship-notes-panel" title={shipNotesTitle} csr collapse>
        <NotesList notebook={notebookTypes.SHIP} />
      </PanelContainer>,
      <NoteFormDialog key="notes-form-dialog" accountNumber={accountNumber} />,
    ];
  }
}

export default AccountNotes;
