import React from 'react';
import styled from 'styled-components';

import Input from 'components/Input';
import { changeMethod } from 'utils/common';

import Button from '../Button';

const AddEmailInput = styled.div`
  display: flex;
  width: 100%;
  input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
  }
  button {
    padding: 2px 10px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;

const TemplateContent = styled.label`
  display: flex;
  padding: 10px;
  font-size: 12px;
`;

const Thing = styled.div`
  flex: 0 0 auto;
  margin-right: 10px;

  &:last-child {
    margin: 0;
  }
`;

const Action = styled(Thing)`
  cursor: pointer;
`;

const Icon = styled.i`
  &.fa-pencil {
    color: #1976d2;
  }
  &.fa-times {
    color: #e53935;
  }
`;

const ShippingInfoItem = ({
  editedEmail,
  editedEmailId,
  email,
  fieldUpdate,
  id,
  onEdit,
  onDelete,
  primaryEmail,
  saveEmail,
}) => {
  const isPrimary = primaryEmail === email;
  const isEditing = editedEmailId === id;

  return (
    <div>
      {isEditing ? (
        <AddEmailInput>
          <Input name="editedEmail" inline onChange={changeMethod(fieldUpdate)} value={editedEmail || ''} />
          <Button icon="save" onClick={() => saveEmail(editedEmail, id)} />
        </AddEmailInput>
      ) : (
        <TemplateContent>
          <Thing>{email && <span>{email}</span>}</Thing>
          {!isPrimary && (
            <Action onClick={e => e.preventDefault() || onEdit(id)}>
              <Icon className="fa fa-pencil" />
            </Action>
          )}
          {!isPrimary && (
            <Action onClick={e => e.preventDefault() || onDelete(id)}>
              <Icon className="fa fa-times" />
            </Action>
          )}
        </TemplateContent>
      )}
    </div>
  );
};

export default ShippingInfoItem;
