import { push, LOCATION_CHANGE } from 'connected-react-router';
import unionBy from 'lodash/unionBy';
import { handleActions } from 'redux-actions';
import { all, takeLatest, call, put, select, take } from 'redux-saga/effects';

import * as api from 'lib/api';
import { selectors as accountSelectors } from 'modules/account/details/selectors';
import { actions as shippingAddressActions } from 'modules/account/shippingAddresses/actions';
import { actions as checkoutActions } from 'modules/checkout/actions';
import { actions as inventoryActions } from 'modules/order/inventory/actions';
import { actions as previousOrderActions } from 'modules/previousOrder/actions';
import { actions as recurringOrderActions } from 'modules/recurringOrderSearch/actions';
import { actions as shippingActions } from 'modules/shipping/options/actions';
import { actions as toastActions } from 'modules/toast/actions';
import simplifyItem from 'utils/simplifyItem';

import { actions } from './actions';
import { selectors } from './selectors';

export const CANCEL_ORDER_TOAST = 'CANCEL_ORDER_TOAST';
export const DUPLICATE_ORDER_TOAST = 'DUPLICATE_ORDER_TOAST';
export const RECURRING_ORDER_TOAST = 'RECURRING_ORDER_TOAST';
export const STUB_ORDER_TOAST = 'STUB_ORDER_TOAST';
const CHECKOUT_ADDRESS_TOAST = 'CHECKOUT_ADDRESS_TOAST';
const ADD_ITEM_TOAST = 'ADD_ITEM_TOAST';

const RESET_LOCATION_PATTERN = /^\/(?:accounts)?\/?$/;

// default state
const defaultState = {
  address: {},
  discount: 0,
  error: false,
  id: null,
  items: [],
  loading: false,
  previousOrders: [],
  productList: {},
  shippingPrice: 0,
  status: '',
  subtotal: 0,
  tax: 0,
  taxMessage: '',
  total: 0,
  itemsMissing: [],
  itemsUpdated: [],
  isAddingToCart: false,
};

// sagas
function* checkForExistingOrderSaga() {
  try {
    const accountNumber = yield select(accountSelectors.accountNumber);
    if (!accountNumber) return;

    const payload = yield call(api.accountCurrentOrder, accountNumber);
    const { language } = yield select(accountSelectors.details);

    if (payload.success) {
      yield put(actions.loadOrderResponse({ response: payload.order, language }));
    } else {
      yield put(actions.noExistingOrder());
    }
  } catch (e) {
    console.error(e.message);
  }
}

function* stubOrderSaga({ payload: orderParams }) {
  const accountNumber = yield select(accountSelectors.accountNumber);
  const { language } = yield select(accountSelectors.details);

  try {
    yield put(shippingAddressActions.validateAddress(orderParams));

    const { payload: validateResponse } = yield take(shippingAddressActions.validateAddressResponse);
    const { success, showCandidates, address = null } = validateResponse;

    if (success && !showCandidates) {
      const { order } = yield call(api.stubOrder, address);
      yield put(actions.loadOrderResponse({ response: order, language }));
      yield put(shippingAddressActions.closeAllAddressDialogs());
      yield put(inventoryActions.clearProductLists());
      yield put(push(`/accounts/${accountNumber}/order`));
    }
  } catch (e) {
    yield put(
      toastActions.createToast('alert', {
        id: STUB_ORDER_TOAST,
        translation: true,
        message: e.message,
        timeout: false,
        title: 'store.error',
      })
    );
    yield put(shippingAddressActions.closeAllAddressDialogs());
    yield put(actions.stubOrderResponse(e));
  }
}

function* cancelOrderSaga({ payload: { id, reason } }) {
  try {
    const accountNumber = yield select(accountSelectors.accountNumber);
    yield call(api.cancelOrder, id, reason);
    yield put(previousOrderActions.fetchRequest(id));
    yield put(push(`/accounts/${accountNumber}/orders/${id}`));
  } catch (e) {
    console.error(e.message);
    yield put(
      toastActions.createToast('alert', {
        id: CANCEL_ORDER_TOAST,
        translation: true,
        message: e.message,
        timeout: false,
        title: 'store.error',
      })
    );
  }
}

function* cancelRecurringOrderSaga({ payload: id }) {
  try {
    const { id: accountId } = yield select(accountSelectors.details);
    yield call(api.recurringOrderCancel, id);
    yield put(recurringOrderActions.requestAction(accountId));
  } catch (e) {
    console.error(e.message);
    yield put(
      toastActions.createToast('alert', {
        id: CANCEL_ORDER_TOAST,
        translation: true,
        message: e.message,
        timeout: false,
        title: 'store.error',
      })
    );
  }
}

function* deleteRecurringOrderSaga({ payload: id }) {
  try {
    const { id: accountId } = yield select(accountSelectors.details);
    yield call(api.recurringOrderDelete, id);
    yield put(recurringOrderActions.requestAction(accountId));
  } catch (e) {
    console.error(e.message);
    yield put(
      toastActions.createToast('alert', {
        id: CANCEL_ORDER_TOAST,
        translation: true,
        message: e.message,
        timeout: false,
        title: 'store.error',
      })
    );
  }
}

function* duplicateOrderSaga({ payload: id }) {
  try {
    const accountNumber = yield select(accountSelectors.accountNumber);
    const { language } = yield select(accountSelectors.details);
    const { order, itemsMissing, itemsUpdated } = yield call(api.duplicateOrder, id);
    yield put(actions.loadOrderResponse({ response: order, language }));
    yield put(actions.duplicateOrderResponse({ itemsMissing, itemsUpdated }));
    yield put(push(`/accounts/${accountNumber}/order/cart`));
  } catch (e) {
    console.error(e.message);
    yield put(
      toastActions.createToast('alert', {
        id: DUPLICATE_ORDER_TOAST,
        translation: true,
        message: e.message,
        timeout: false,
        title: 'store.error',
      })
    );
  }
}

function* recurringOrderSaga({ payload: { id, recurrenceType, startDate } }) {
  try {
    const data = yield call(api.recurringOrderCreate, id, { recurrenceType, startDate });
    yield put(actions.recurringOrderResponse(data));
    yield put(
      toastActions.createToast('success', {
        id: RECURRING_ORDER_TOAST,
        translation: true,
        message: 'store.recurringOrderSuccess',
        title: 'store.success',
      })
    );
  } catch (e) {
    console.error(e.message);
    yield put(
      toastActions.createToast('alert', {
        id: RECURRING_ORDER_TOAST,
        translation: true,
        message: e.message,
        timeout: false,
        title: 'store.error',
      })
    );
  }
}

const mapSubstitutedItemsToSubstitutions = substitutedItems => {
  let substitutions;

  const substitutedItemArr = Object.values(substitutedItems).filter(sub => sub.original_item_id !== sub.id);
  if (substitutedItemArr.length) {
    substitutions = substitutedItemArr.map(sub => ({
      replacedItemId: sub.original_item_id,
      substitutedItemId: sub.id,
    }));
  }

  return substitutions;
};

function* addItemSaga({
  payload: { orderId, productListItemId, prefillLabel = false, labelsOnly = false, quantity = 1, substitutedItems },
}) {
  try {
    const { order } = yield call(
      api.addItem,
      orderId,
      productListItemId,
      quantity,
      prefillLabel,
      labelsOnly,
      mapSubstitutedItemsToSubstitutions(substitutedItems)
    );
    const { language } = yield select(accountSelectors.details);
    yield put(actions.loadOrderResponse({ response: order, language }));
  } catch (e) {
    console.error(e.message);
    yield put(
      toastActions.createToast('alert', {
        id: ADD_ITEM_TOAST,
        translation: true,
        message: e.message,
        timeout: false,
        title: 'store.error',
      })
    );
  }
}

function* removeItemSaga({ payload: { itemId } }) {
  try {
    const orderId = yield select(selectors.id);
    const { order } = yield call(api.removeItem, orderId, itemId);
    const { language } = yield select(accountSelectors.details);
    yield put(actions.loadOrderResponse({ response: order, language }));
  } catch (e) {
    yield put(actions.loadOrderResponse(e));
  }
}

function* loadOrderSaga({ payload: orderId }) {
  try {
    const { order } = yield call(api.loadOrder, orderId);
    const { language } = yield select(accountSelectors.details);
    yield put(actions.loadOrderResponse({ response: order, language }));
  } catch (e) {
    yield put(actions.loadOrderResponse(e));
  }
}

function* updateOrderAddressSaga({ payload: { address, skipValidation } }) {
  try {
    const orderId = yield select(selectors.id);

    const response = yield call(api.updateOrderAddress, orderId, address.id, address, skipValidation);
    if (!response.showCandidates) {
      yield put(actions.updateOrderAddressResponse(response.address));
      if (response.labLocation) yield put(checkoutActions.csrUpdate({ returnLabLocation: response.labLocation }));
      yield put(shippingAddressActions.closeAllAddressDialogs());
      yield put(shippingActions.fetchShippingRates());
    } else {
      yield put(shippingAddressActions.validateAddressResponse(response));
    }
  } catch (e) {
    console.error(e.message);
    yield put(
      toastActions.createToast('alert', {
        id: CHECKOUT_ADDRESS_TOAST,
        translation: true,
        message: e.message,
        timeout: false,
        title: 'store.error',
      })
    );
    yield put(shippingAddressActions.closeAllAddressDialogs());
  }
}

export function* orderSaga() {
  yield all([
    takeLatest(actions.addItem, addItemSaga),
    takeLatest(actions.cancelOrder, cancelOrderSaga),
    takeLatest(actions.cancelRecurringOrder, cancelRecurringOrderSaga),
    takeLatest(actions.deleteRecurringOrder, deleteRecurringOrderSaga),
    takeLatest(actions.checkForExistingOrder, checkForExistingOrderSaga),
    takeLatest(actions.duplicateOrder, duplicateOrderSaga),
    takeLatest(actions.loadOrder, loadOrderSaga),
    takeLatest(actions.recurringOrder, recurringOrderSaga),
    takeLatest(actions.removeItem, removeItemSaga),
    takeLatest(actions.stubOrder, stubOrderSaga),
    takeLatest(actions.updateOrderAddress, updateOrderAddressSaga),
  ]);
}

export { actions } from './actions';
export { selectors } from './selectors';

// reducer
export default handleActions(
  {
    [actions.csrOverrideResponse]: {
      next(state, { payload }) {
        const { discount, items, subtotal, tax, total } = payload;

        let simpleItems = [];
        if (items && items.length > 0) {
          simpleItems = items.map(item => simplifyItem(item, payload.language));
        }

        return {
          ...state,
          discount,
          items: unionBy(simpleItems, [...state.items], 'id'),
          subtotal,
          tax,
          total,
        };
      },
      throw(state) {
        return { ...defaultState, loading: false, error: true };
      },
    },
    [actions.stubOrder]() {
      return defaultState;
    },
    [LOCATION_CHANGE](state, { payload }) {
      const { pathname } = payload;
      if (RESET_LOCATION_PATTERN.test(pathname)) return defaultState;
      return state;
    },
    [actions.addItem](state) {
      return {
        ...state,
        isAddingToCart: true,
      };
    },
    [actions.checkForExistingOrder](state) {
      return { ...defaultState, loading: true };
    },
    [actions.loadOrder](state) {
      return { ...defaultState, loading: true };
    },
    [actions.noExistingOrder](state) {
      return { ...defaultState, loading: false };
    },
    [actions.loadOrderResponse]: {
      next(state, { payload }) {
        const {
          id,
          address,
          discount,
          items,
          productList,
          shippingMaterials,
          status,
          subtotal,
          tax,
          total,
          taxMessage,
          labLocation,
          labelLanguage,
        } = payload.response;

        const simpleItems = items.map(item => simplifyItem(item, payload.language, productList));

        return {
          ...state,
          isAddingToCart: false,
          address,
          discount,
          id,
          items: simpleItems,
          loading: false,
          labLocation,
          labelLanguage,
          productList,
          shippingMaterials,
          status,
          subtotal,
          tax,
          total,
          taxMessage,
        };
      },
      throw(state) {
        return { ...defaultState, loading: false, error: true };
      },
    },
    [actions.duplicateOrder](state) {
      return { ...defaultState, loading: true };
    },
    [actions.duplicateOrderResponse]: {
      next(state, { payload }) {
        const { itemsMissing, itemsUpdated } = payload;
        return {
          ...state,
          itemsMissing,
          itemsUpdated,
        };
      },
      throw(state) {
        return { ...defaultState, loading: false, error: true };
      },
    },
    [actions.updateOrderAddress](state) {
      return { ...state, loading: true };
    },
    [actions.updateOrderAddressResponse]: {
      next(state, { payload: address }) {
        return { ...state, address, loading: false };
      },
      throw(state) {
        return { ...state, error: true, loading: false };
      },
    },
    [actions.updateSummaryTotalPrices]: {
      next(state, { payload }) {
        return {
          ...state,
          ...payload,
        };
      },
      throw(state) {
        return { ...defaultState, loading: false, error: true };
      },
    },
  },
  defaultState
);
