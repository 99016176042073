import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import SortIcon from 'components/SortIcon';
import { colors } from 'style/theme';

const ToggleButton = styled.a`
  position: absolute;
  top: 0;
  right: 1px;
  z-index: 1;
  color: ${props => (props.isActive ? colors.blueWater : colors.grayEmperor)};
  cursor: pointer;
`;

class SortButton extends PureComponent {
  state = {
    sortDirection: 'asc',
  };

  static propTypes = {
    toggle: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
  };

  toggle = e => {
    const { isActive } = this.props;

    e.preventDefault();
    this.setState(
      ({ sortDirection: currentSort }) => {
        const sortDirection = isActive && currentSort === 'asc' ? 'desc' : 'asc';
        return { sortDirection };
      },
      () => this.props.toggle(this.state.sortDirection)
    );
  };

  render() {
    const { sortDirection } = this.state;
    const { isActive } = this.props;

    return (
      <ToggleButton {...this.props} onClick={this.toggle}>
        <SortIcon active={isActive} direction={sortDirection} />
      </ToggleButton>
    );
  }
}

export default SortButton;
