import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Alert from 'components/Alert';
import LabeledInput, { LabeledTextarea, LabeledSelect } from 'components/LabeledInput';
import PanelContainer from 'components/Panel';
import { rushTestingOptions } from 'constants/options';
import { colors } from 'style/theme';
import { changeMethod } from 'utils/common';
import { translate } from 'utils/translations';

const Block = styled.div`
  margin: 0 0 10px;

  &:last-child {
    margin: 0;
  }
`;

const ShipToday = styled.div`
  background: ${colors.blueWater};
  padding: 14px 12px;
  color: ${colors.white};
  margin: 0 0 10px;
  input {
    margin-right: 8px;
  }
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;

  & :first-child {
    color: ${colors.grayEmperor};
  }
`;

const CsrPanel = ({
  accountLevel,
  accountMask,
  labelLanguage: initialLabelLanguage,
  returnLabLocation: initialReturnLabLocation,
  csr: {
    labelLanguage,
    jiraTicketNumber,
    languages,
    locations,
    prefillComponentId,
    returnLabLocation,
    mustShipToday,
    specialInstructions,
  },
  fieldUpdate,
  sampleRushTesting,
  isLangOverridden,
  isLabLocationOverridden,
}) => {
  const isStandardOption = rushTestingOptions.find(opt => opt === sampleRushTesting);

  return (
    <PanelContainer
      style={{ backgroundColor: colors.blueHawkes, border: `solid 1px ${colors.blueColumbia}` }}
      width={250}
    >
      <Block>
        <LabeledInput
          label={translate('shipping.jiraTicket', 'JIRA Ticket Number')}
          name="jiraTicketNumber"
          onChange={changeMethod(fieldUpdate)}
          style={{ display: 'block' }}
          value={jiraTicketNumber}
        />
      </Block>
      <ShipToday>
        <input checked={mustShipToday} name="mustShipToday" onChange={changeMethod(fieldUpdate)} type="checkbox" />
        {translate('checkout.mustShipToday', 'Must Ship Today')}
      </ShipToday>
      <Block>
        <LabeledTextarea
          label={translate('shipping.specialInstructions', 'Special Instructions')}
          name="specialInstructions"
          onChange={changeMethod(fieldUpdate)}
          style={{ display: 'block' }}
          value={specialInstructions}
        />
      </Block>
      <Block>
        <LabeledSelect
          clearable={false}
          label={translate('checkout.defaultLabelLanguage', 'Default Label Language')}
          onChange={changeMethod(fieldUpdate, 'labelLanguage')}
          options={languages}
          value={labelLanguage || initialLabelLanguage}
        />
        <Alert hidden={!isLangOverridden} warning style={{ marginTop: '5px' }}>
          One or more kits in this order contain a label language override
        </Alert>
      </Block>
      <Block>
        <LabeledSelect
          clearable={false}
          label={translate('checkout.returnLabLocation', 'Return Lab Location')}
          onChange={changeMethod(fieldUpdate, 'returnLabLocation')}
          options={locations}
          value={returnLabLocation || initialReturnLabLocation}
        />
        <Alert hidden={!isLabLocationOverridden} warning style={{ marginTop: '5px' }}>
          One or more kits in this order contain a lab location override
        </Alert>
      </Block>
      <Block>
        <LabeledInput
          label={translate('checkout.prefillComponentId', 'Prefill Component ID')}
          name="prefillComponentId"
          onChange={changeMethod(fieldUpdate)}
          style={{ display: 'block' }}
          value={prefillComponentId}
        />
      </Block>
      <Block>
        <Info>
          <span>{isStandardOption ? 'Rush Testing' : 'Approval Required From'}</span>
          <span>{sampleRushTesting}</span>
        </Info>
      </Block>
      <Block>
        <Info>
          {sampleRushTesting === 'Invoice' && (
            <>
              <span>Open Invoice Level</span>
              <span>{`${accountLevel} [${accountMask}]`}</span>
            </>
          )}
        </Info>
      </Block>
    </PanelContainer>
  );
};

CsrPanel.propTypes = {
  csr: PropTypes.shape({
    labelLanguage: PropTypes.string,
    jiraTicketNumber: PropTypes.string,
    prefillComponentId: PropTypes.string,
    returnLabLocation: PropTypes.string,
    shipToday: PropTypes.bool,
    specialInstructions: PropTypes.string,
  }).isRequired,
  csrUpdate: PropTypes.func.isRequired,
};

export default CsrPanel;
