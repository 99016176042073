import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import { DEVELOPMENT } from 'config';
import { rootReducer, rootSaga } from 'modules/root';

/*
 * Middleware & enhancers
 */
const middleware = [];

// middleware: Redux Saga
const sagaMiddleware = createSagaMiddleware();
middleware.push(sagaMiddleware);

// middleware history for react-router-redux
export const history = createBrowserHistory();
middleware.push(routerMiddleware(history));

// middleware: Redux Logger
if (DEVELOPMENT) middleware.push(createLogger({ collapsed: true }));

const enhancers = [];

// enhancer: middleware chain
enhancers.push(applyMiddleware(...middleware));

// enhancer: Redux DevTools
if (DEVELOPMENT) {
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f);
}

// export the created store
const store = createStore(rootReducer(history), compose(...enhancers));

// run the root saga
sagaMiddleware.run(rootSaga);

export default store;
