import camelCase from 'lodash/camelCase';
import zipObject from 'lodash/zipObject';
import { createActions, handleActions } from 'redux-actions';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import * as api from 'lib/api';
import { actions as detailsActions } from 'modules/account/details/actions';
import { selectors as details } from 'modules/account/details/selectors';

import { selectors } from './selectors';

const defaultState = {
  data: {},
  loading: false,
};

const decorateSettings = settings => {
  const keys = Object.keys(settings).map(camelCase);

  const values = Object.keys(settings).map(key => {
    const value = settings[key];

    if (!value) return null;

    const lowerValue = value.toLowerCase();

    if (lowerValue === 'yes') return true;
    if (lowerValue === 'no') return false;
    if (lowerValue === 'none') return null;
    return value;
  });

  return zipObject(keys, values);
};

selectors.decorated = createSelector([selectors.allSettings], settings => decorateSettings(settings));

export const actions = createActions('FETCH_ACCOUNT_SETTINGS', 'ACCOUNT_SETTINGS_RESPONSE');

function* fetchSettingsSaga({ payload: types }) {
  let accountNumber = yield select(details.accountNumber);
  if (!accountNumber) yield take(detailsActions.accountDetailsResponse);

  accountNumber = yield select(details.accountNumber);
  if (!accountNumber) throw Error('Account number invalid');

  try {
    const { success, settings, message } = yield call(api.accountSettings, accountNumber, types);

    if (success) {
      yield put(actions.accountSettingsResponse({ settings }));
    } else {
      throw Error(message);
    }
  } catch (e) {
    yield put(actions.accountSettingsResponse(e));
  }
}

export function* accountSettingsSaga() {
  yield all([takeLatest(actions.fetchAccountSettings, fetchSettingsSaga)]);
}

export { selectors } from './selectors';

// reducer
export default handleActions(
  {
    [detailsActions.fetchAccountDetails]() {
      return { ...defaultState };
    },
    [actions.fetchAccountSettings](state) {
      return { ...state, loading: true };
    },
    [actions.accountSettingsResponse]: {
      next(state, { payload }) {
        const { settings } = payload;
        return { ...state, loading: false, data: settings };
      },
      throw(state) {
        return { ...state, loading: false };
      },
    },
  },
  defaultState
);
