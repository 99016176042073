import camelCase from 'lodash/camelCase';
import filter from 'lodash/filter';
import get from 'lodash/get';
import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import { createActions, handleActions } from 'redux-actions';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import * as api from 'lib/api';

const defaultState = {
  loading: false,
  invalid: false,
  data: {},
  showDialog: false,
  dialogNoteId: null,
  dialogNotebook: null,
  saving: false,
  saveError: false,
};

export const notebookTypes = { ORDER: 'ORDER', SHIP: 'SHIP' };

export const actions = createActions(
  'ADD_NOTE',
  'FETCH_NOTES',
  'FETCH_NOTES_RESPONSE',
  'SAVE_NOTE',
  'SAVE_NOTE_RESPONSE',
  'DELETE_NOTE',
  'DELETE_NOTE_RESPONSE',
  'OPEN_ACCOUNT_NOTE_DIALOG',
  'CLOSE_ACCOUNT_NOTE_DIALOG'
);

export const selectors = {
  data: state => get(state, 'account.notes.data', {}),
  loading: state => get(state, 'account.notes.loading'),
  invalid: state => get(state, 'account.notes.invalid'),
  showDialog: state => get(state, 'account.notes.showDialog'),
  dialogNoteId: state => get(state, 'account.notes.dialogNoteId'),
  dialogNotebook: state => get(state, 'account.notes.dialogNotebook'),
  saving: state => get(state, 'account.notes.saving'),
  saveError: state => get(state, 'account.notes.saveError'),
  notebookType: (_, props) => props.notebook,
};

selectors.note = createSelector([selectors.data, selectors.dialogNoteId], (notes, id) => notes[id]);

const sortedNotesForType = (notes, type) => sortBy(filter(notes, { notebook: type }), 'noteMask');

selectors.dialogNotes = createSelector([selectors.data, selectors.dialogNotebook], sortedNotesForType);

export const makeNotesSelector = () => createSelector([selectors.data, selectors.notebookType], sortedNotesForType);

selectors.orderNotes = createSelector([selectors.data], notes => sortedNotesForType(notes, notebookTypes.ORDER));
selectors.shipNotes = createSelector([selectors.data], notes => sortedNotesForType(notes, notebookTypes.SHIP));

const noteDecorator = note => mapKeys(note, (v, k) => camelCase(k));

export function* fetchNotesSaga({ payload: accountNumber }) {
  if (!accountNumber) throw Error('payload required for fetchNotes action');

  try {
    const notesArr = yield call(api.accountNotes, accountNumber);

    const data = notesArr.reduce((obj, note) => {
      obj[note.id] = noteDecorator(note);
      return obj;
    }, {});

    yield put(actions.fetchNotesResponse(data));
  } catch (e) {
    yield put(actions.fetchNotesResponse(e));
  }
}

export function* saveNoteSaga({ payload: { noteId, params } }) {
  let note = { ...params };
  let endpoint;

  if (noteId) {
    endpoint = api.updateNote;
  } else {
    endpoint = api.createNote;
    note['notebook'] = yield select(selectors.dialogNotebook);
  }

  try {
    const { accountNote } = yield call(endpoint, note, noteId);
    yield put(actions.saveNoteResponse(noteDecorator(accountNote)));
  } catch (e) {
    yield put(actions.saveNoteResponse(e));
  }
}

export function* deleteNoteSaga({ payload: noteId }) {
  try {
    yield call(api.deleteNote, noteId);
    yield put(actions.deleteNoteResponse(noteId));
  } catch (e) {
    yield put(actions.deleteNoteResponse(e));
  }
}

export function* notesSaga() {
  yield all([
    takeLatest(actions.fetchNotes, fetchNotesSaga),
    takeLatest(actions.saveNote, saveNoteSaga),
    takeLatest(actions.deleteNote, deleteNoteSaga),
  ]);
}

export default handleActions(
  {
    [actions.fetchNotes]() {
      return { ...defaultState, loading: true };
    },
    [actions.fetchNotesResponse]: {
      next(state, { payload }) {
        return { ...state, loading: false, data: payload };
      },
      throw(state, { payload }) {
        return { ...state, loading: false, invalid: true };
      },
    },
    [actions.addNote](state, { payload: dialogNotebook }) {
      return { ...state, showDialog: true, dialogNoteId: null, dialogNotebook };
    },
    [actions.openAccountNoteDialog](state, { payload: { id: dialogNoteId = null, type: dialogNotebook } }) {
      return { ...state, showDialog: true, dialogNoteId, dialogNotebook };
    },
    [actions.closeAccountNoteDialog](state) {
      return {
        ...state,
        showDialog: false,
        dialogNoteId: null,
        dialogNotebook: null,
      };
    },
    [actions.saveNote](state) {
      return { ...state, saving: true, saveError: false };
    },
    [actions.saveNoteResponse]: {
      next(state, { payload }) {
        return {
          ...state,
          saving: false,
          showDialog: false,
          dialogNoteId: null,
          dialogNotebook: null,
          data: { ...state.data, [payload.id]: payload },
        };
      },
      throw(state) {
        return { ...state, saving: false, saveError: true };
      },
    },
    [actions.deleteNoteResponse]: {
      next(state, { payload: noteId }) {
        return { ...state, data: omit(state.data, noteId) };
      },
      throw() {},
    },
  },
  defaultState
);
