import React, { Fragment } from 'react';
import styled from 'styled-components';

import Currency from 'components/Currency/index';

const TemplateContent = styled.label`
  display: flex;
  padding: 10px;
  font-size: 12px;
`;

const Thing = styled.div`
  flex: 0 0 auto;
  margin-right: 10px;

  &:last-child {
    margin: 0;
  }
`;

const ShippingMethodItem = ({ id, label, price, onSelect, selected, showRates, currency }) => (
  <TemplateContent>
    <Thing>
      <input type="radio" name="shippingMethod" onChange={onSelect} checked={selected} value={id} />
    </Thing>
    <Thing>
      {showRates ? (
        <Fragment>
          <Currency quantity={price} currency={currency} /> - {label}
        </Fragment>
      ) : (
        label
      )}
    </Thing>
  </TemplateContent>
);

export default ShippingMethodItem;
