import { get } from 'lodash';
import { createSelector } from 'reselect';

// selectors
export const selectors = {
  billingInfo: state => get(state, 'account.details.billingInfo', {}),
  defaultAddressId: state => get(state, 'account.details.defaultAddressId'),
  defaultAddressLoading: state => get(state, 'account.details.defaultAddressLoading'),
  details: state => get(state, 'account.details.data', {}),
  isOrderEnabled: state => get(state, 'account.details.data.order', false),
  error: state => get(state, 'account.details.error'),
  loading: state => get(state, 'account.details.loading'),
  reportAddress: state => get(state, 'account.details.reportAddress', {}),
};

selectors.accountNumber = createSelector([selectors.details], details => {
  if (!details) return null;
  return details.number;
});

selectors.program = createSelector([selectors.details], details => {
  if (!details) return null;
  return details.program;
});

selectors.id = createSelector([selectors.details], details => {
  if (!details) return null;
  return details.id;
});

selectors.isInvoiceAccount = createSelector([selectors.details, selectors.billingInfo], (details, billingInfo) => {
  if (!details || !billingInfo.invoiceAccount) return null;

  const accountNumber = get(details, 'number');
  const invoiceAccountNumber = get(billingInfo, 'invoiceAccount.number');

  if (!accountNumber || !invoiceAccountNumber) return null;

  return accountNumber === invoiceAccountNumber;
});
