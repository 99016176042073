import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Currency from 'components/Currency';
import Input from 'components/Input';
import ListTable from 'components/ListTable';
import OneColumnRow from 'components/OneColumnRow';
import { changeMethod } from 'utils/common';
import { renderDescription } from 'utils/display';
import { translate } from 'utils/translations';

const DollarPrefix = styled.div`
  white-space: nowrap;

  &:before {
    content: '$ ';
  }
`;

const Item = styled.tr`
  .Item__title,
  .Item__number,
  .Item__subtitle {
    font-size: 14px;
  }

  .Item__title {
    font-weight: 700;
  }

  .Item__kit-contents,
  .Item__content {
    margin: 10px 0 10px;

    &:last-child {
      margin: 0;
    }
  }
`;

const OverrideTable = ({
  currency,
  editable = false,
  items,
  language,
  onPriceChange,
  reportAddress,
  showOverridePrice,
}) => {
  return (
    <ListTable>
      <thead>
        <tr>
          <th>{translate('cart.description', 'Description')}</th>
          <th>{translate('cart.price', 'Price')}</th>
          <th>{translate('cart.quantity', 'Quantity')}</th>
          <th style={{ width: 150 }}>{translate('cart.totalPrice', 'Total Price')}</th>
          {showOverridePrice && <th style={{ width: 150 }}>{translate('shipping.overrideTotal', 'Override Total')}</th>}
        </tr>
      </thead>
      <tbody>
        {!items.length && <OneColumnRow colSpan="5" condensed text={translate('cart.emptyOrder', 'Order is Empty')} />}
        {items.length > 0 &&
          sortBy(items, 'id').map((item, i) => {
            const { csrOverride } = item;
            return (
              <Item key={item.id}>
                <td>
                  {item.items && item.items.length > 0 ? (
                    <>
                      <div className="Item__title">{item.name}</div>
                      <div className="Item__number">{item.actual_item_number}</div>
                      <div className="Item__subtitle">
                        ({item.packSize})&nbsp;{item.actual_description}
                      </div>
                      <div className="Item__kit-contents">
                        {item.items.map(kitItem => (
                          <div key={kitItem.inventory_id}>
                            ({kitItem.quantity}) {renderDescription(kitItem, language, kitItem.category, item.category)}
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="Item__title">{item.actual_description}</div>
                      <div className="Item__subtitle">{item.name}</div>
                      <div className="Item__content">
                        <div key={`${item.productListItemId}-${i}`}>
                          ({item.quantity}) {item.description}
                        </div>
                      </div>
                    </>
                  )}
                  {item.prefillLabel && (
                    <div>
                      <strong>{translate('cart.prefillLabel')}:&nbsp;</strong>
                      {reportAddress.attention && <span>{reportAddress.attention},&nbsp;</span>}
                      <span>{reportAddress.address1},&nbsp;</span>
                      {reportAddress.address2 && <span>{reportAddress.address2},&nbsp;</span>}
                      <span>
                        {reportAddress.city}, {reportAddress.region} {reportAddress.post_code}
                      </span>
                    </div>
                  )}
                  {item.labelsOnly && (
                    <div>
                      <strong>Labels Only</strong>
                    </div>
                  )}
                </td>
                <td>
                  <Currency quantity={item.price} currency={currency} />
                </td>
                <td>{item.quantity}</td>
                <td style={{ width: 150 }}>
                  <strong>
                    <Currency quantity={item.totalPrice} currency={currency} />
                  </strong>
                </td>
                {showOverridePrice && (
                  <td style={{ width: 150 }}>
                    {editable ? (
                      <DollarPrefix>
                        <Input
                          name={item.id}
                          style={{ width: 75, textAlign: 'right', fontSize: '12px' }}
                          value={isNil(csrOverride.price) ? item.totalPrice : csrOverride.price}
                          onBlur={changeMethod(onPriceChange, false, 'blur')}
                          onChange={changeMethod(onPriceChange, false, 'change')}
                        />
                      </DollarPrefix>
                    ) : (
                      csrOverride.price && (
                        <strong>
                          <Currency quantity={parseFloat(csrOverride.price)} currency={currency} />
                        </strong>
                      )
                    )}
                  </td>
                )}
              </Item>
            );
          })}
      </tbody>
    </ListTable>
  );
};

OverrideTable.propTypes = {
  currency: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  language: PropTypes.string.isRequired,
  summary: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        description: PropTypes.string,
        quantity: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        totalPrice: PropTypes.number.isRequired,
      })
    ).isRequired,
  }),
};

export default OverrideTable;
