import { Tabs } from '@polarislabs/polaris-ui-components';
import React from 'react';
import { connect } from 'react-redux';

import { selectors } from 'modules/user';
import { translate } from 'utils/translations';

const BaseTabs = ({ isCsr }) => {
  const data = [
    { url: '/accounts', label: translate('selectAccount'), exact: true },
    { url: '/accounts/orders', label: translate('previousOrders', 'Previous Orders'), exact: true },
  ];

  if (isCsr) {
    data.push({ url: '/accounts/reports', label: translate('reports'), isCsr: true });
    data.push({ url: '/accounts/sample-lookup', label: translate('sampleLookup', 'Sample Lookup'), isCsr: true });
  }

  return <Tabs data={data} />;
};

const mapState = state => ({
  isCsr: selectors.isCsr(state),
});

export default connect(mapState)(BaseTabs);
