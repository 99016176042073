import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dashPosition = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
`;

const colorChanging = keyframes`
  100%, 0% {
    stroke: #feb000;
  }
  40% {
    stroke: #d0011b;
  }
  80%, 90% {
    stroke: #1194f6;
  }
`;

export const LoaderRoot = styled.div`
  padding: 20px;
  ${props => props.small && 'padding: 15px'};
  ${props => props.tiny && 'padding: 3px'};

  .Loader__spinner {
    position: relative;
    margin: 0 auto;
    width: 75px;
    height: 75px;

    ${props => props.small && 'width: 45px'};
    ${props => props.small && 'height: 45px'};

    ${props => props.tiny && 'width: 20px'};
    ${props => props.tiny && 'height: 20px'};
  }

  svg {
    animation: ${rotate} 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    margin: auto;
  }

  circle {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: ${dashPosition} 1.5s ease-in-out infinite, ${colorChanging} 6s ease-in-out infinite;
    stroke-linecap: round;
  }
`;
