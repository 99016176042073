import React from 'react';
import { Switch, Route } from 'react-router-dom';

import BaseLayout from 'components/BaseLayout';
import { Breadcrumbs, Navigation, Tabs } from 'components/ProductLists';

import DetailLayout from './Detail.layout';
import ListLayout from './List.layout';

const ProductListLayout = ({ match }) => (
  <BaseLayout>
    <Breadcrumbs />
    <Tabs />
    <div className="Layout__content">
      <div className="Layout__navigation">
        <Navigation />
      </div>
      <div className="Layout__wrapper">
        <Switch>
          <Route exact path={`${match.path}/`} component={ListLayout} />
          <Route path={`${match.path}/:productListItemId`} component={DetailLayout} />
        </Switch>
      </div>
    </div>
  </BaseLayout>
);

export default ProductListLayout;
