import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Payment from 'components/Account/RushSample/Checkout/Payment';
import CsrPanel from 'components/Account/RushSample/CsrPanel';
import RushOrderSummary from 'components/Account/RushSample/RushOrderSummary';
import { DEFAULT_PAYMENT } from 'constants/defaults';
import { selectors as userSelectors } from 'modules/user';
import { translate } from 'utils/translations';

import ReferenceInfo from '../ReferenceInfo';

const StyledCheckout = styled.div`
  display: flex;
  flex-flow: column;

  .Checkout__header {
    display: flex;
    margin: 0 0 30px;

    &-item {
      flex: 1 1 auto;
      margin-right: 30px;

      &:last-child {
        margin: 0;
      }
    }
  }

  .Checkout__summary {
  }
`;

const Checkout = ({ loading, order, onPlace }) => {
  const isCsr = useSelector(userSelectors.isCsr);

  const [jiraTicketNumber, setJiraTicketNumber] = useState('');
  const [referenceInfo, setReferenceInfo] = useState(order.referenceInfo || {});
  const [paymentInfo, setPaymentInfo] = useState({ ...DEFAULT_PAYMENT, ...order.payment });
  const [validation, setValidation] = useState({ payment: false });

  const onPaymentValidation = useCallback(valid => {
    setValidation(validation => ({
      ...validation,
      payment: valid,
    }));
  }, []);

  const prepOrder = useCallback(() => {
    onPlace({
      csr: { jiraTicketNumber },
      payment: paymentInfo,
      referenceInfo,
    });
  }, [jiraTicketNumber, paymentInfo, referenceInfo, onPlace]);

  return (
    <StyledCheckout>
      <div className="Checkout__header">
        <div className="Checkout__header-item">
          <Payment
            onChange={setPaymentInfo}
            onValidate={onPaymentValidation}
            value={paymentInfo}
            total={order.totals.total}
          />
        </div>
        <div className="Checkout__header-item">
          <ReferenceInfo onChange={setReferenceInfo} value={referenceInfo} />
        </div>
        {isCsr && (
          <div className="Checkout__header-item" style={{ flex: '0 0 auto' }}>
            <CsrPanel jiraTicketNumber={jiraTicketNumber} updateJiraTicketNumber={setJiraTicketNumber} />
          </div>
        )}
      </div>
      <div className="Checkout__breakdown">
        <RushOrderSummary
          data={order.items}
          currency={order.currency}
          loading={loading}
          isTaxable={order.isTaxable}
          totals={order.totals}
          textSubmit={translate('placeOrder', 'Place Order')}
          onSubmit={prepOrder}
          disabled={!validation.payment}
        />
      </div>
    </StyledCheckout>
  );
};

export default Checkout;
