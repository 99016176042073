import React from 'react';

import { PreviousOrders, Tabs } from 'components/Base';

const PreviousOrdersLayout = ({ match }) => {
  return (
    <>
      <Tabs />
      <PreviousOrders />
    </>
  );
};

export default PreviousOrdersLayout;
