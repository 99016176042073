import moment from 'moment/moment';
import { connect } from 'react-redux';

import OrderHeader from 'components/Order/OrderHeader';
import { SHIPPED } from 'constants/orderStatuses';
import selectors from 'modules/checkout/selectors';

const mapState = state => {
  const data = selectors.confirmationData(state);
  const {
    id,
    shipped_at,
    shippingPreference: { shippingMethod, custom_ship_date, estimated_ship_date },
    status,
    ...order
  } = data;
  const {
    name: methodName,
    shippingOption: { name: carrierName },
  } = shippingMethod;

  return {
    ...order,
    orderNumber: id,
    orderDate: moment(order.ordered_at).format('MM/DD/YYYY, h:mm:ss a'),
    status: status,
    shipDate:
      status === SHIPPED
        ? moment(shipped_at).format('MM/DD/YYYY')
        : moment(custom_ship_date || estimated_ship_date)
            .utc(false)
            .format('MM/DD/YYYY'),
    shipMethod: `${carrierName} ${methodName}`,
  };
};

export default connect(mapState)(OrderHeader);
