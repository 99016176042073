import isNil from 'lodash/isNil';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import CsrPanel from 'components/Checkout/CsrPanel';
import { actions, selectors } from 'modules/checkout';
import { selectors as orderSelectors } from 'modules/order';

class CsrPanelContainer extends PureComponent {
  componentDidMount() {
    const { fetchSettingsOptions, csrUpdate, labelLanguage, returnLabLocation } = this.props;
    csrUpdate({ labelLanguage, returnLabLocation });
    fetchSettingsOptions();
  }

  fieldUpdate = (field, val) => {
    this.props.csrUpdate({ [field]: val });
  };

  render() {
    return <CsrPanel {...this.props} fieldUpdate={this.fieldUpdate} />;
  }
}

const mapStateToProps = state => ({
  labelLanguage: orderSelectors.labelLanguageCode(state),
  returnLabLocation: orderSelectors.labLocationCode(state),
  csr: selectors.csr(state),
  isLangOverridden: orderSelectors.kits(state).some(kit => !isNil(kit.label_language_id)),
  isLabLocationOverridden: orderSelectors.kits(state).some(kit => !isNil(kit.lab_location_id)),
});

const mapDispatch = {
  csrUpdate: actions.csrUpdate,
  fetchSettingsOptions: actions.fetchSettingsOptions,
};

export default connect(mapStateToProps, mapDispatch)(CsrPanelContainer);
