import { allCountries as countryRegionData } from 'country-region-data';

const COUNTRIES = [];
const REGIONS = {};

countryRegionData.forEach(([countryName, countrySlug, regions]) => {
  // Put US at the top
  if (countrySlug === 'US') {
    COUNTRIES.unshift({ label: countryName, value: countrySlug });
  } else {
    COUNTRIES.push({ label: countryName, value: countrySlug });
  }

  REGIONS[countrySlug] = regions.map(([regionName, regionSlug]) => ({
    label: regionName,
    value: regionSlug,
  }));
});

export { COUNTRIES, REGIONS };
