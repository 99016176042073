import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import OrderDetails from 'components/Order/OrderDetails';
import { actions as addressActions, selectors as addressSelectors } from 'modules/account/shippingAddresses';
import { actions, selectors } from 'modules/previousOrder';

const mapState = state => {
  const data = selectors.data(state);
  const { address = {}, paymentDetails } = data;

  return {
    userRoles: state.user.details.roles,
    order: data,
    summary: {
      address: {
        id: address.id,
        company_name: address.company_name,
        attention: address.attention,
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        region: address.region,
        post_code: address.post_code,
        country: address.country,
        phone_number: address.phone_number,
      },
      emails: data.notificationEmails,
      payment: {
        poNumber: data.purchase_order_number,
        method: data.payment_method,
        number: paymentDetails && `${paymentDetails.cardType} ${paymentDetails.cardNumber}`,
      },
      placedBy: {
        name: data.order_placed_by,
      },
      placedFor: {
        name: data.order_placed_for,
        poNumber: data.reference_po,
      },
      shipping: {
        trackingNumber: data.tracking_number,
        shippingAccountNumber: data.shippingPreference.shipping_account_number,
        shippingCost: data.shippingPreference ? data.shippingPreference.customer_cost || 0 : null,
      },
    },
    showCandidatesDialog: addressSelectors.showCandidatesDialog(state),
    showDialog: addressSelectors.showDialog(state),
  };
};

class OrderDetailsContainer extends PureComponent {
  constructor(props) {
    super(props);

    const { reference_po, purchase_order_number, order_placed_for } = this.props.order;
    const { trackingNumber } = this.props.summary.shipping;

    this.state = {
      editable: false,
      placedFor: order_placed_for,
      purchaseOrderNumber: purchase_order_number,
      referencePo: reference_po,
      trackingNumber: trackingNumber,
      editingTracking: false,
    };
  }

  fieldUpdate = (field, val) => {
    return this.setState({ [field]: val });
  };

  onEditingToggle = () => {
    this.setState({ editable: !this.state.editable });
  };

  toggleEditingTracking = () => {
    const { trackingNumber } = this.props.summary.shipping;
    const { editingTracking } = this.state;
    this.setState({ editingTracking: !editingTracking, trackingNumber });
  };

  onEditShipping = address => this.props.openAddressDialog({ address });

  onSave = () => {
    const { placedFor, purchaseOrderNumber, referencePo, editingTracking, trackingNumber } = this.state;
    const { id } = this.props.order;

    if (editingTracking) {
      this.props.updateTrackingNumber({ orderId: id, trackingNumber });
      this.setState({ editingTracking: false });
    } else {
      this.props.modifyOrder({
        purchaseOrderNumber,
        referencePo,
        placedFor,
        isDetails: true,
        orderId: id,
      });
      this.setState({ editable: false });
    }
  };

  render() {
    return (
      <OrderDetails
        {...this.props}
        {...this.state}
        editable={this.state.editable}
        fieldUpdate={this.fieldUpdate}
        onEditingToggle={this.onEditingToggle}
        onEditShipping={this.onEditShipping}
        save={this.onSave}
        status={this.props.order.status}
        userRoles={this.props.userRoles}
        editingTracking={this.state.editingTracking}
        toggleEditingTracking={this.toggleEditingTracking}
        trackingNumber={this.state.trackingNumber}
      />
    );
  }
}

const mapDispatch = {
  closeAddressDialog: addressActions.closeAddressDialog,
  modifyOrder: actions.modifyOrder,
  updateTrackingNumber: actions.updateTrackingNumber,
  openAddressDialog: addressActions.openAddressDialog,
};

export default connect(mapState, mapDispatch)(OrderDetailsContainer);
