import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import { colors } from 'style/theme';
import { translate } from 'utils/translations';

const StyledRow = styled.tr`
  color: ${props => (props.enabled ? colors.grayMineShaft : colors.whiteAlto)};

  cursor: ${props => (props.enabled ? 'pointer' : 'not-allowed')};
  td {
    color: ${props => (props.suspended ? colors.redCarmine : 'inherit')};
    background-color: ${props => (props.suspended ? colors.redLightSalmon : 'inherit')};
  }
`;

const LinkRow = ({ enabled, children, to, history, suspended, ...props }) => (
  <StyledRow
    onClick={() => {
      if (enabled) history.push(to);
    }}
    title={suspended ? translate('accountSuspended', 'Account Suspended. Contact Customer Service') : null}
    enabled={enabled}
    suspended={suspended}
    {...props}
  >
    {children}
  </StyledRow>
);

export default withRouter(LinkRow);
