import React from 'react';
import styled from 'styled-components';

import { colors } from 'style/theme';
import { translate } from 'utils/translations';

const StyledDetails = styled.div`
  padding: 15px;
  background: ${colors.bluePajamas};
  border: 1px solid ${colors.blueSalt};
  font-size: 12px;
  margin: 0 0 25px;

  &:last-child {
    margin: 0;
  }

  .Details__section {
    margin: 0 0 15px;

    &:last-child {
      margin: 0;
    }

    &-label {
      font-weight: 700;
    }
  }
`;

const OrderShippingMaterials = ({ shippingMaterials, ...props }) => {
  return (
    <StyledDetails {...props}>
      <div className="Details__section">
        <div className="Details__section-label">{translate('store.shippingMaterials', 'Shipping Materials')}</div>
        {shippingMaterials.length === 0 && <div>No Shipping Materials</div>}
        {shippingMaterials.length > 0 &&
          shippingMaterials.map((material, i) => (
            <div key={i}>
              {`
                ${material.quantity}x 
                ${material.bundle ? material.bundle : material.item_number} 
                (${material.length}x${material.width}x${material.height}) 
                ${material.bundle ? `(${material.description} bundled as one)` : ''}
              `}
            </div>
          ))}
      </div>
    </StyledDetails>
  );
};

export default OrderShippingMaterials;
