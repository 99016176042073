import { createAction } from 'redux-actions';

export const actions = {
  fetchRequest: createAction('PREVIOUS_ORDER_REQUEST'),
  fetchResponse: createAction('PREVIOUS_ORDER_RESPONSE'),
  modifyOrder: createAction('MODIFY_ORDER'),
  updateTrackingNumber: createAction('UPDATE_TRACKING'),
  modifyOrderResponse: createAction('MODIFY_ORDER_RESPONSE'),
  modifyAddress: createAction('MODIFY_ADDRESS'),
  modifyAddressResponse: createAction('MODIFY_ADDRESS_RESPONSE'),
};
