import PropTypes from 'prop-types';
import React from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import styled from 'styled-components';

import Input from 'components/Input';
import { colors } from 'style/theme';
import { translate } from 'utils/translations';

export const validateLength = (text = '', len) =>
  !!text.length && text.length < len && translate('validationMinLength', '{len} character minimum', { len });

const Root = styled.div`
  vertical-align: bottom;
  position: relative;
  width: ${props => (props.inline ? '100%' : 'auto')};
  margin-bottom: 15px;

  &:last-child {
    margin: 0;
  }

  .labelText {
    font-size: 12px;
    color: ${colors.grayEmperor};
    margin-bottom: 5px;
  }

  .LabeledInput__input,
  .LabeledInput__country,
  .LabeledInput__region {
    border: 1px solid ${colors.whiteAlto};

    ${props =>
      props.invalid &&
      `
        transition: border-color 333ms, outline-color 333ms;
        border-color: red;
        outline-color: red;
    `};
  }

  .LabeledInput__country,
  .LabeledInput__region {
    width: 100%;
    border-radius: 3px;
    background: transparent;
    font-size: 14px;
    padding: 6px;
    height: 30px;
  }

  .LabeledInput__region {
    width: 230px;
  }

  .LabeledInput__validation {
    font-size: 12px;
    position: absolute;
    background: darkred;
    margin-top: -1px;
    min-width: 100%;
    white-space: nowrap;
    color: white;
    padding: 0 8px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 100;
  }
`;

const InlineLabel = styled.label`
  display: flex;
  width: 100%;
  align-items: center;

  .labelText {
    margin-right: 11px;
    margin-bottom: 0;
  }
`;

const Required = styled.span`
  color: red;
`;

const LabeledInput = ({
  countryInput,
  hasButton,
  invalid,
  inline,
  label,
  regionInput,
  required,
  secondary,
  style,
  width,
  labelStyle,
  ...props
}) => {
  const LabelComponent = inline ? InlineLabel : 'label';

  return (
    <Root invalid={invalid} inline={inline} style={style}>
      <LabelComponent onClick={e => e.stopPropagation()}>
        <div className="labelText">
          {label}
          {secondary && <span>&nbsp;{secondary}</span>}
          {required && <Required>&nbsp;*</Required>}
        </div>
        {!countryInput && !regionInput && (
          <Input
            type="text"
            className="LabeledInput__input"
            style={{ width }}
            inline={inline}
            hasButton={hasButton}
            invisible={!props.onChange ? true : undefined}
            {...props}
          />
        )}
        {countryInput && <CountryDropdown classes="LabeledInput__input LabeledInput__country" {...props} />}
        {regionInput && <RegionDropdown classes="LabeledInput__input LabeledInput__region" {...props} />}
        {invalid && typeof invalid === 'string' && <div className="LabeledInput__validation">{invalid}</div>}
      </LabelComponent>
    </Root>
  );
};

LabeledInput.propTypes = {
  invalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.node.isRequired,
  style: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default LabeledInput;
