import find from 'lodash/find';
import get from 'lodash/get';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { PREPAID_LABEL } from 'constants/categories';
import { KITS } from 'constants/strings';
import withAccountSettings from 'hoc/withAccountSettings';
import socket, { checkForSocket } from 'lib/socket';
import { selectors as accountSelectors } from 'modules/account/details';
import { actions, selectors } from 'modules/order';
import { actions as inventoryActions, selectors as inventorySelectors } from 'modules/order/inventory';
import { selectors as substituteSelectors, actions as substituteActions } from 'modules/order/substitutes';
import { selectors as user } from 'modules/user';

import ProductDetails from './ProductDetails';

const createSubstitutedItem = (item, prevId, existingSubstitute) => {
  const substitutedItem = {
    ...existingSubstitute,
    id: item.id,
    item_number: item.item_number,
  };

  if (!existingSubstitute) {
    substitutedItem.original_item_id = prevId;
  }

  return substitutedItem;
};

const parseData = (product, shouldPrefillLabel) => {
  const originalLabel = get(product, 'originalLabel', null);
  const prefillLabel = product.item_type === KITS ? shouldPrefillLabel : false;

  const substitutedItems = {};

  if (originalLabel) {
    const currentLabelIndex = product.items.findIndex(item => item.category === PREPAID_LABEL);
    if (currentLabelIndex !== -1) {
      const currentLabel = product.items[currentLabelIndex];
      substitutedItems[currentLabelIndex] = {
        id: currentLabel.id,
        item_number: currentLabel.item_number,
        original_item_id: originalLabel.id,
      };
    }
  }

  return {
    prefillLabel,
    productListItemId: product.product_list_item_id,
    isAdded: false,
    labelsOnly: false,
    quantity: 1,
    substitutedItems,
  };
};

const ProductDetailsContainer = props => {
  const [data, setData] = useState({});
  const [product, setProduct] = useState();
  const [hasAddedToCart, setHasAddedToCart] = useState(false);

  const {
    accountId,
    accountNumber,
    currency,
    language,
    orderId,
    product: initialProduct,
    productId,
    productList,
    reportAddress,
    settings,
    substituteOptions,
    isAddingToCart,
    onFetch,
    onFetchSubstituteItems,
    onAddItem: propsOnAddItem,
  } = props;

  const registerSocketEvent = useCallback(() => {
    checkForSocket();
    socket.off('pricesUpdated');
    socket.on('pricesUpdated', data => {
      setProduct(data);
    });
  }, [setProduct]);

  useEffect(() => {
    registerSocketEvent();
    return () => socket.off('pricesUpdated');
  }, [registerSocketEvent]);

  useEffect(() => {
    setHasAddedToCart(false);
    onFetch(productId);
  }, [productId, onFetch]);

  useEffect(() => {
    onFetchSubstituteItems();
  }, [productList.id, onFetchSubstituteItems]);

  useEffect(() => {
    if (initialProduct.loading) return;
    const updatedData = parseData(initialProduct.data, settings.labelPrefill);

    setData(updatedData);
    setProduct(initialProduct.data);
  }, [initialProduct, settings.labelPrefill]);

  const handleAddItem = useCallback(() => {
    setHasAddedToCart(true);
    propsOnAddItem({
      ...data,
      accountId,
      orderId,
    });
  }, [data, propsOnAddItem, accountId, orderId]);

  const onQuantityChange = e => {
    const quantity = e.target.value.length > 0 ? parseInt(e.target.value, 10) : '';
    setData(data => ({ ...data, quantity }));
  };

  const onSubstituteItemChange = (itemNumber, prevId, prevItemNumber, uniqueElementKey) => {
    const { labelsOnly } = data;

    const isSelectionUnchanged = itemNumber === prevItemNumber;
    const substituteItemsExist = substituteOptions && substituteOptions.length > 0;

    if (substituteItemsExist && !isSelectionUnchanged) {
      const newItem = find(substituteOptions, item => {
        return item.item_number === itemNumber;
      });

      const items = product.items.map(item => {
        const isMatchingItem = item.id === prevId;

        if (isMatchingItem) {
          return { ...newItem, quantity: item.quantity };
        }

        return item;
      });

      const updatedKitWithItems = { ...product, items };

      sendSocketUpdatePricesMessage(updatedKitWithItems, currency, labelsOnly);

      const existingSubstitute = data.substitutedItems[uniqueElementKey];
      const updates = {
        substitutedItems: {
          ...data.substitutedItems,
          [uniqueElementKey]: createSubstitutedItem(newItem, prevId, existingSubstitute),
        },
      };

      if (updates) {
        setData(data => ({ ...data, ...updates }));
      }
    }
  };

  const onToggleLabelsOnly = () => {
    setData(data => {
      const labelsOnly = !data.labelsOnly;
      sendSocketUpdatePricesMessage(product, currency, labelsOnly);
      return { ...data, labelsOnly };
    });
  };

  const onTogglePrefillLabel = () => {
    setData(data => ({ ...data, prefillLabel: !data.prefillLabel }));
  };

  const sendSocketUpdatePricesMessage = (kit, currency, labelsOnly) => {
    socket.emit('updatePrices', { kit, currency, labelsOnly });
  };

  const isCartAddDisabled = hasAddedToCart && isAddingToCart;
  const isCartMessageVisible = hasAddedToCart && !isAddingToCart;
  const { loading, error } = initialProduct;

  const getChildProps = () => ({
    ...data,
    accountNumber,
    currency,
    error,
    language,
    loading,
    product,
    reportAddress,
    substituteOptions,
    isCartAddDisabled,
    isCartMessageVisible,
    onAddItem: handleAddItem,
    onQuantityChange,
    onSubstituteItemChange,
    onToggleLabelsOnly,
    onTogglePrefillLabel,
  });

  return <ProductDetails {...getChildProps()} />;
};

const mapStateToProps = (state, { match }) => ({
  accountNumber: match.params.accountNumber,
  productId: match.params.productListItemId,
  accountId: accountSelectors.id(state),
  currency: selectors.currency(state),
  language: user.language(state),
  orderId: selectors.id(state),
  product: inventorySelectors.item(state),
  productList: selectors.productList(state),
  reportAddress: accountSelectors.reportAddress(state),
  substituteOptions: substituteSelectors.data(state),
  isAddingToCart: selectors.isAddingToCart(state),
});

const mapDispatch = {
  onAddItem: actions.addItem,
  onFetch: inventoryActions.fetchProductListItem,
  onFetchSubstituteItems: substituteActions.fetchSubstituteItems,
};

const enhance = compose(withRouter, withAccountSettings(['label_prefill']), connect(mapStateToProps, mapDispatch));
export default enhance(ProductDetailsContainer);
