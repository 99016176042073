import find from 'lodash/find';
import moment from 'moment/moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import OrderCSR from 'components/Order/OrderCSR';
import { fetchSettingsOptions } from 'lib/api';
import { actions, selectors } from 'modules/previousOrder';

class OrderCsrContainer extends PureComponent {
  constructor(props) {
    super(props);

    const {
      labelLanguage,
      labLocation,
      csrInformation: { jira_ticket_number, prefill_component_id, special_instructions },
      must_ship_today,
    } = this.props.data;

    this.state = {
      jiraTicket: jira_ticket_number || '',
      labelLang: labelLanguage,
      mustShipToday: must_ship_today,
      returnLabLocation: labLocation,
      prefillComponentId: prefill_component_id || '',
      specialInstructions: special_instructions || '',
      editable: false,
    };
  }

  componentDidMount() {
    this.fetchSettingsOptions();
  }

  fetchSettingsOptions = async () => {
    try {
      const { languages, locations } = await fetchSettingsOptions();
      this.setState({
        languages: languages.map(lang => ({ label: lang.name, value: lang.code })),
        locations: locations.map(loc => ({ label: loc.name, value: loc.code })),
      });
    } catch (e) {
      console.error('Failed to fetch settings options.');
    }
  };

  fieldUpdate = (field, val) => {
    const { languages, locations } = this.state;
    if (field === 'labelLang') {
      const lang = find(languages, { value: val });
      return this.setState({ labelLang: { name: lang.label, code: lang.value } });
    }
    if (field === 'returnLabLocation') {
      const loc = find(locations, { value: val });
      return this.setState({ returnLabLocation: { name: loc.label, code: loc.value } });
    }
    return this.setState({ [field]: val });
  };

  onEditingToggle = () => {
    this.setState({ editable: !this.state.editable });
  };

  onSave = () => {
    const { jiraTicket, labelLang, prefillComponentId, returnLabLocation } = this.state;

    this.props.modifyOrder({
      jiraTicket,
      labelLang,
      prefillComponentId,
      returnLabLocation,
      orderId: this.props.data.id,
    });
  };

  render() {
    return (
      <OrderCSR
        {...this.state}
        fieldUpdate={this.fieldUpdate}
        onEditingToggle={this.onEditingToggle}
        orderDate={moment(this.props.data.ordered_at).format('MM/DD/YYYY')}
        packedBy={this.props.data.packed_by}
        save={this.onSave}
        status={this.props.data.status}
      />
    );
  }
}

const mapState = state => ({
  data: selectors.data(state),
});

const mapDispatch = {
  modifyOrder: actions.modifyOrder,
};

export default connect(mapState, mapDispatch)(OrderCsrContainer);
