import get from 'lodash/get';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import OrderCSR from 'components/Order/OrderCSR';
import { actions, selectors } from 'modules/rushSample';

const OrderCsrContainer = ({ modifyRushOrder, order }) => {
  const {
    data: { csrInformation, id, status },
  } = order;

  const jiraTicket = get(csrInformation, 'jiraTicketNumber', '');

  const [edits, setEdits] = useState({ jiraTicket });
  const [editing, setEditing] = useState(false);

  const onChange = (key, value) => {
    setEdits(edits => ({ ...edits, [key]: value }));
  };

  const onToggleEdit = () => {
    setEditing(isEditing => {
      if (isEditing) setEdits({ jiraTicket });
      return !isEditing;
    });
  };

  const onSave = () => {
    const { jiraTicket } = edits;

    modifyRushOrder({
      jiraTicket,
      orderId: id,
    });

    setEditing(false);
  };

  return (
    <OrderCSR
      jiraTicket={edits.jiraTicket}
      status={status}
      editable={editing}
      fieldUpdate={onChange}
      onEditingToggle={onToggleEdit}
      save={onSave}
    />
  );
};

const mapState = state => ({
  order: selectors.currentOrder(state),
});

const mapDispatch = {
  modifyRushOrder: actions.modifyRushOrder,
};

export default connect(mapState, mapDispatch)(OrderCsrContainer);
