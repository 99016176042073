import React from 'react';
import BaseSelect from 'react-select';
import styled from 'styled-components';

import { colors } from 'style/theme';

import 'react-select/dist/react-select.css';

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  flex-flow: ${props => (props.inline ? 'row nowrap' : 'column nowrap')};
  font-size: 12px;

  .Select {
    flex: 1 1 auto;
    &.full {
      width: 100%;
    }
  }

  .Select__label {
    flex: 0 0 auto;
    font-size: 12px;
    padding: 6px 10px 6px 6px;
  }

  .Select-control {
    border: 1px solid ${colors.whiteAlto};
    border-radius: 3px;
    min-width: 100px;
  }

  .Select-placeholder {
    color: ${colors.grayWarm};
  }

  .has-value.Select--single > .Select-control .Select-value .Select-value-label,
  .has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
    color: ${colors.black};
  }

  .is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
    border-color: ${colors.black};
  }
`;

const Select = ({ fullWidth, inline, label, onChange, errors, style, containerStyle, ...props }) => (
  <SelectContainer inline={inline} style={containerStyle}>
    {label && <div className="Select__label">{label}</div>}
    <BaseSelect
      style={style}
      className={fullWidth ? 'full' : ''}
      clearable={true}
      {...props}
      onChange={onChange ? payload => onChange(payload ? payload.value : '') : undefined}
    />
  </SelectContainer>
);

export default Select;
