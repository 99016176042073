import React, { useCallback, useEffect, useState } from 'react';

import Button from 'components/Buttons';
import Errors from 'components/Errors';
import Hider from 'components/Hider';
import Input from 'components/Input';
import { translate } from 'utils/translations';

import SearchForm from './SearchForm';

const INVALID_FORMAT = 'Order # Must Be Positive';

const InputWrapper = ({ errors, touched, ...props }) => (
  <div style={{ position: 'relative' }}>
    <Input type="text" invalid={touched && errors.length > 0} inline {...props} />
    {touched && errors.length > 0 && <Errors data={errors} />}
  </div>
);

const isPositive = num => Number(num) > 0;

const OrderNumberSearch = ({ data, loading, selected, onChange, onSearch, onSelect }) => {
  const [touched, setTouched] = useState(false);
  const [validation, setValidation] = useState({ valid: false, errors: [] });

  useEffect(() => {
    setTouched(false);
    setValidation({ valid: false, errors: [] });
  }, [selected]);

  const validate = useCallback(orderNumber => {
    const errors = [];

    if (!orderNumber.length) return { valid: false, errors };

    if (!isPositive(orderNumber)) errors.push(INVALID_FORMAT);

    return { valid: errors.length === 0, errors };
  }, []);

  useEffect(() => {
    const currentValidation = validate(data);
    setValidation(currentValidation);
  }, [data, validate]);

  const onSubmit = useCallback(() => {
    onSearch({ type: 'orderNumber', data });
  }, [data, onSearch]);

  const onBlur = useCallback(
    e => {
      const currentValidation = validate(e.target.value);
      setValidation(currentValidation);
      setTouched(true);
    },
    [validate]
  );

  const onNumberChange = useCallback(
    e => {
      const { value } = e.target;
      onChange({ type: 'orderNumber', data: value });
    },
    [onChange]
  );

  return (
    <SearchForm onSubmit={onSubmit} noValidate>
      <label className="Search__label">
        <div className="Search__input">
          <input type="radio" checked={selected === 'orderNumber'} onChange={() => onSelect('orderNumber')} />
        </div>
        <div className="Search__input-label">{translate('orderNumber', 'Order #')}</div>
      </label>
      <Hider hidden={selected !== 'orderNumber'}>
        <div className="Search__content">
          <InputWrapper
            errors={validation.errors}
            name="orderNumber"
            touched={touched}
            value={data || ''}
            onBlur={onBlur}
            onChange={onNumberChange}
          />
        </div>
        <div className="Search__action">
          <Button success icon="search" disabled={loading || !validation.valid} type="submit" />
        </div>
      </Hider>
    </SearchForm>
  );
};

export default OrderNumberSearch;
