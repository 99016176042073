import { createActions } from 'redux-actions';

export const actions = createActions(
  {
    CREATE_TOAST: (type, { id, ...options } = {}) => ({
      id,
      type,
      options,
    }),
  },
  'CLOSE_TOAST'
);
