import { createActions } from 'redux-actions';

export const actions = createActions(
  'ADD_ITEM',
  'CANCEL_ORDER',
  'CANCEL_ORDER_RESPONSE',
  'CANCEL_RECURRING_ORDER',
  'CANCEL_RECURRING_ORDER_RESPONSE',
  'CHECK_FOR_EXISTING_ORDER',
  'CSR_OVERRIDE_RESPONSE',
  'DELETE_RECURRING_ORDER',
  'DUPLICATE_ORDER',
  'DUPLICATE_ORDER_RESPONSE',
  'FETCH_ORDERS',
  'FETCH_ORDERS_RESPONSE',
  'LOAD_ORDER',
  'LOAD_ORDER_RESPONSE',
  'NO_EXISTING_ORDER',
  'PLACE_ORDER',
  'PLACE_ORDER_RESPONSE',
  'RECURRING_ORDER',
  'RECURRING_ORDER_RESPONSE',
  'REMOVE_ITEM',
  'STUB_ORDER',
  'STUB_ORDER_RESPONSE',
  'UPDATE_ORDER_ADDRESS',
  'UPDATE_ORDER_ADDRESS_RESPONSE',
  'UPDATE_SUMMARY_TOTAL_PRICES'
);

export default actions;
