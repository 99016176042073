import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { colors } from 'style/theme';

export const validateLength = (text, len) => !!text.length && text.length < len && `${len} character minimum`;

const Root = styled.div`
  display: block;
  position: relative;

  label {
    cursor: pointer;
  }

  .LabeledCheckbox__label {
    display: inline-flex;
    font-size: 12px;
    color: ${colors.grayEmperor};
  }

  .LabeledCheckbox__input {
    margin-right: 8px;
  }

  .LabeledCheckbox__validation {
    color: darkred;
    font-size: 12px;
    position: absolute;
    bottom: -1.2rem;
  }
`;

const LabeledCheckbox = ({ invalid, label, style, ...props }) => {
  return (
    <Root invalid={invalid} style={style}>
      <label>
        <input className="LabeledCheckbox__input" type="checkbox" {...props} />
        <div className="LabeledCheckbox__label">{label}</div>
        {invalid && typeof invalid === 'string' && <div className="LabeledCheckbox__validation">{invalid}</div>}
      </label>
    </Root>
  );
};

LabeledCheckbox.propTypes = {
  invalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export default LabeledCheckbox;
