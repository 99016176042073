import React from 'react';

import { Breadcrumbs, Tabs } from 'components/Account';
import { RushSample } from 'components/Account/RushSample';

const RushSampleLayout = ({ match }) => {
  return (
    <>
      <Breadcrumbs />
      <Tabs />
      <RushSample />
    </>
  );
};

export default RushSampleLayout;
