import { connect } from 'react-redux';

import { selectors, actions } from 'modules/account/notes';

import NoteFormDialog from './NoteFormDialog';

const mapState = state => ({
  newNote: !!selectors.dialogNoteId(state),
  showDialog: selectors.showDialog(state),
  type: selectors.dialogNotebook(state),
});

const mapDispatch = { closeDialog: actions.closeAccountNoteDialog };

export default connect(mapState, mapDispatch)(NoteFormDialog);
