import styled from 'styled-components';

const BaseLayout = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;

  .Layout__content {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
  }

  .Layout__navigation {
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 auto;
    margin-right: 15px;
  }

  .Layout__wrapper {
    flex: 1 1 auto;
  }

  .Layout__tabs {
    flex: 0 0 auto;
  }
`;

export default BaseLayout;
