import { handleActions } from 'redux-actions';
import { v4 as uuid } from 'uuid';

import { replaceItem, removeItem } from 'utils/array';

import { actions } from './actions';

// default state
const defaultState = {
  toasts: [],
};

// actions

const updateOrCreateToast = (toasts, toast) => {
  const id = toast.id ? toast.id : uuid();
  return replaceItem(toasts, { ...toast, id });
};

export { actions } from './actions';

// reducer
export default handleActions(
  {
    [actions.createToast](state, { payload: toast }) {
      return {
        ...state,
        toasts: updateOrCreateToast(state.toasts, toast),
      };
    },
    [actions.closeToast](state, { payload: id }) {
      return { ...state, toasts: removeItem(state.toasts, { id }) };
    },
  },
  defaultState
);
