import React from 'react';
import styled from 'styled-components';

import { colors } from 'style/theme';

const StyledErrors = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  background-color: ${colors.redLightSalmon};
  border: 1px solid ${colors.redFlamingo};
  position: absolute;
  left: 0;
  top: calc(100% - 1px);
  font-size: 11px;
`;

const Errors = ({ data }) => {
  return (
    <StyledErrors>
      {data.map(error => (
        <div key={error}>{error}</div>
      ))}
    </StyledErrors>
  );
};

export default Errors;
