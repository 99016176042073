import isUndefined from 'lodash/isUndefined';
import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from 'style/theme';

const StyledItem = styled.div`
  .Item__label {
    display: block;
    padding: 5px;
    color: #333;
    padding-left: ${props => props.depth * 16}px;
    transition: background 300ms, color 300ms;
    cursor: pointer;

    &:hover {
      background: ${colors.whiteGallery};
      color: #333;
    }
  }

  .Item__link {
    text-decoration: none;
    ${props => props.active && `background: ${colors.blueBahama};`};
    ${props => props.active && 'color: white;'};

    &:hover {
      background: ${colors.whiteGallery};
      color: #333;

      ${props => props.active && `background: ${colors.blueBahama};`};
      ${props => props.active && 'color: white;'};
    }
  }
`;

class NavItem extends PureComponent {
  state = {
    collapsed: !isUndefined(this.props.collapsed) ? this.props.collapsed : this.props.depth === 1,
  };

  toggle = () => {
    this.setState(state => ({ collapsed: !state.collapsed }));
  };

  componentDidUpdate(prevProps) {
    // Open Up Nav When Child Viewed
    if (prevProps.collapsed && !this.props.collapsed) {
      this.setState({ collapsed: false });
    }
  }

  render() {
    const { collapsed } = this.state;
    const { active, depth, children, title, to, baseUrl, ...rest } = this.props;

    const itemUrl = (baseUrl, to) => (to === '/' ? baseUrl : `${baseUrl}${to}`);

    return (
      <StyledItem active={active} depth={depth} {...rest}>
        {to ? (
          <NavLink className="Item__label Item__link" to={itemUrl(baseUrl, to)}>
            {title}
          </NavLink>
        ) : (
          <div className="Item__label" onClick={this.toggle}>
            {title}
          </div>
        )}
        {!collapsed ? children : null}
      </StyledItem>
    );
  }
}

export default NavItem;
