import noop from 'lodash/noop';
import React from 'react';
import styled from 'styled-components';

import Button from 'components/Buttons';
import Input from 'components/Input';
import JiraLink from 'components/JiraLink';
import { PACKED, PENDING, SHIPPED, RUSH } from 'constants/orderStatuses';
import { colors } from 'style/theme';
import { changeMethod } from 'utils/common';
import { translate } from 'utils/translations';

import { LabeledSelect } from '../LabeledInput';

const StyledDetails = styled.div`
  padding: 15px;
  background: ${colors.bluePajamas};
  border: 1px solid ${colors.blueSalt};
  font-size: 12px;
  margin: 0 0 25px;

  &:last-child {
    margin: 0;
  }

  .Details__section {
    margin: 0 0 15px;

    &:last-child {
      margin: 0;
    }

    &-label {
      font-weight: 700;
    }
  }

  .Details__edit {
    display: flex;
    justify-content: flex-end;
    color: ${colors.blueWater};
    cursor: pointer;
  }
  .Details__buttons {
    display: flex;
    justify-content: space-between;
  }
`;

const OrderCSR = ({
  editable,
  fieldUpdate,
  isEditing = noop,
  jiraTicket,
  labelLang,
  languages,
  locations,
  mustShipToday,
  onEditingToggle = noop,
  orderDate,
  packedBy,
  prefillComponentId,
  returnLabLocation,
  save,
  specialInstructions,
  status,
  ...props
}) => {
  return (
    <StyledDetails {...props}>
      {!editable && [PENDING, RUSH].includes(status) && (
        <div className="Details__edit" onClick={onEditingToggle}>
          <i className="fa fa-pencil" />
        </div>
      )}
      <div className="Details__section">
        <div className="Details__section-label">{translate('store.jiraTicketNumber', 'JIRA Ticket Number')} </div>
        {editable ? (
          <Input name="jiraTicket" onChange={changeMethod(fieldUpdate)} value={jiraTicket} />
        ) : (
          <div>
            <JiraLink issueNumber={jiraTicket} />
          </div>
        )}
      </div>
      {mustShipToday && ![RUSH].includes(status) && (
        <div className="Details__section">
          <div className="Details__section-label">{translate('checkout.mustShipToday', 'Must Ship Today')} </div>
          <div>{`Ordered: ${orderDate}`}</div>
        </div>
      )}
      {![RUSH].includes(status) && (
        <div className="Details__section">
          <div className="Details__section-label">
            {translate('checkout.defaultLabelLanguage', 'Default Label Language')}{' '}
          </div>
          {editable ? (
            <LabeledSelect
              clearable={false}
              label={null}
              onChange={changeMethod(fieldUpdate, 'labelLang')}
              options={languages}
              value={labelLang.code}
            />
          ) : (
            <div>{`${labelLang.code} - ${labelLang.name}`}</div>
          )}
        </div>
      )}
      {![RUSH].includes(status) && (
        <div className="Details__section">
          <div className="Details__section-label">{translate('checkout.returnLabLocation', 'Return Lab Location')}</div>
          {editable ? (
            <LabeledSelect
              clearable={false}
              label={null}
              onChange={changeMethod(fieldUpdate, 'returnLabLocation')}
              options={locations}
              value={returnLabLocation.code}
            />
          ) : (
            <div>{returnLabLocation.name}</div>
          )}
        </div>
      )}
      {![RUSH].includes(status) && (
        <div className="Details__section">
          <div className="Details__section-label">
            {translate('checkout.prefillComponentId', 'Prefill Component ID')}{' '}
          </div>
          {editable ? (
            <Input name="prefillComponentId" onChange={changeMethod(fieldUpdate)} value={prefillComponentId} />
          ) : (
            <div>{prefillComponentId}</div>
          )}
        </div>
      )}
      <div className="Details__section">
        <div className="Details__section-label">{translate('store.specialInstructions', 'Special Instructions')} </div>
        <div>{specialInstructions}</div>
      </div>
      {[PACKED, SHIPPED].includes(status) && (
        <div className="Details__section">
          <div className="Details__section-label">{translate('store.packedBy', 'Packed By')} </div>
          <div>{packedBy}</div>
        </div>
      )}
      {editable && (
        <div className="Details__buttons">
          <Button onClick={onEditingToggle} icon="times" alert inline>
            {translate('shipping.cancel', 'Cancel')}
          </Button>
          <Button onClick={save} icon="print" inline success>
            {translate('store.save', 'Save')}
          </Button>
        </div>
      )}
    </StyledDetails>
  );
};

export default OrderCSR;
