import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/Buttons';
import ListTable from 'components/ListTable';
import OneColumnRow from 'components/OneColumnRow';
import { RECURRENCE_TYPES } from 'constants/recurrenceTypes';
import { colors } from 'style/theme';
import { translate } from 'utils/translations';

const Root = styled.div`
  .RecurringOrders__result {
    td {
      vertical-align: top;
    }

    a {
      &,
      :visited,
      :hover,
      :active,
      :focus {
        color: ${colors.blueWater};
        text-decoration: none;
      }
    }
  }
`;

const translateInterval = interval => {
  if (interval === RECURRENCE_TYPES.monthly) return translate('store.recurrenceTypeMonthly', 'Monthly');
  if (interval === RECURRENCE_TYPES.quarterly) return translate('store.recurrenceTypeQuarterly', 'Quarterly');
  if (interval === RECURRENCE_TYPES.volume) return translate('store.recurrenceTypeVolume', 'By Volume');
  return translate(interval, interval);
};

const RecurringOrders = ({ error, loading, account, orders, onCancel, onDelete }) => {
  return (
    <Root>
      <ListTable>
        <thead>
          <tr>
            <th>{translate('store.recurrenceId', 'Recurring')} #</th>
            <th>{translate('store.originalOrderNumber', 'Original Order')} #</th>
            <th>{translate('store.firstShipmentDate', 'First Shipment Date')}</th>
            <th>{translate('store.nextShipmentDate', 'Next Shipment Date')}</th>
            <th>{translate('store.recurrenceInterval', 'Interval')}</th>
            <th>{translate('store.status', 'Status')}</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {error && <OneColumnRow colSpan="10" error text={translate('anErrorOccurred')} />}
          {loading && <OneColumnRow colSpan="10" text={`${translate('loading')}...`} />}
          {orders &&
            orders.length > 0 &&
            orders.map(order => (
              <tr key={order.id} className="RecurringOrders__result">
                <td>{order.id}</td>
                <td>
                  <Link to={`/accounts/${account.number}/orders/${order.order_id}`}>{order.order_id}</Link>
                </td>
                <td>{moment(order.start_date).format('MM/DD/YYYY')}</td>
                <td>{moment(order.next_recurrence_date).format('MM/DD/YYYY')}</td>
                <td>{translateInterval(order.recurrence_type)}</td>
                <td>{translate(order.status, order.status)}</td>
                <td>
                  {order.status === 'active' && (
                    <Button style={{ padding: 0, height: 'auto' }} alert transparent onClick={() => onCancel(order)}>
                      {translate('store.cancelRecurOrderTitle', 'Cancel Recurring Order')}
                    </Button>
                  )}
                  {order.status === 'unprocessable' && (
                    <Button style={{ padding: 0, height: 'auto' }} alert transparent onClick={() => onDelete(order)}>
                      {translate('delete', 'Delete')}
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          {!error && orders.length === 0 && <OneColumnRow colSpan="10" text={translate('noRecordsFound')} />}
        </tbody>
      </ListTable>
    </Root>
  );
};

export default RecurringOrders;
