import { createAction } from 'redux-actions';

import { createRecurringOrderSearch } from 'modules/helpers/createRecurringOrderSearch';

const recurringOrderSearch = createRecurringOrderSearch({
  requestAction: createAction('RECURRING_ORDER_SEARCH_REQUEST'),
  responseAction: createAction('RECURRING_ORDER_SEARCH_RESPONSE'),
  baseSelector: state => state.recurringOrderSearch,
});

export const { actions, reducer, saga, selectors } = recurringOrderSearch;
