import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import Loader from 'components/Loader';
import { actions, selectors } from 'modules/rushSample';
import { colors } from 'style/theme';

import BarcodeSearch from './BarcodeSearch';
import LabcodeSearch from './LabcodeSearch';

const StyledLookup = styled.div`
  .Lookup__result {
    display: flex;
    padding: 10px;
    align-items: center;
    font-size: 12px;
    background-color: ${colors.whiteAlabaster};

    &.center {
      justify-content: center;
    }
  }
`;

const SearchRow = styled.label`
  display: flex;
  align-items: center;
  color: ${colors.grayEmperor};
  font-size: 12px;
  min-height: 30px;
  margin: 0 0 10px;

  &:last-child {
    margin: 0;
  }

  .Search__input {
    display: flex;
    margin-right: 8px;
  }

  .Search__label {
    margin-right: 8px;
  }

  .Search__content {
    display: flex;
    align-items: center;

    &-label {
      margin: 0 8px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .Search__action {
    margin-left: 10px;
  }
`;

const SampleLookup = ({ fetchRushSamples, rushSampleLookup }) => {
  const [selected, setSelected] = useState('barcode');

  const renderResult = () => {
    const { data, loading, hasSearched } = rushSampleLookup;
    if (loading) return <Loader />;
    if (!hasSearched) return null;

    const { accountNumber } = data;

    if (!accountNumber) return <div className="Lookup__result center">No result found</div>;

    return (
      <div className="Lookup__result">
        <NavLink to={`${accountNumber}/rush-sample`}>{accountNumber}</NavLink>
      </div>
    );
  };

  return (
    <StyledLookup>
      <SearchRow>
        <BarcodeSearch selected={selected} setSelected={setSelected} onSearch={fetchRushSamples} />
      </SearchRow>
      <SearchRow>
        <LabcodeSearch selected={selected} setSelected={setSelected} onSearch={fetchRushSamples} />
      </SearchRow>
      {renderResult()}
    </StyledLookup>
  );
};

const mapState = state => ({
  rushSampleLookup: selectors.rushSampleLookup(state),
});

const mapDispatch = {
  fetchRushSamples: actions.fetchRushSampleLookup,
};

export default connect(mapState, mapDispatch)(SampleLookup);
