import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { colors } from 'style/theme';
import { translate } from 'utils/translations';

const MAX_PAGES = 15;

const PaginationContainer = styled.div`
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const PaginationItem = styled.button`
  display: inline-flex;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
  border: 1px solid ${colors.whiteGallery};
  border-right: none;
  align-items: center;
  justify-content: center;
  outline: none;
  transition: background 0.5s;

  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: ${props => (props.disabled ? colors.whiteAlto : colors.grayMineShaft)};
  background: ${props => (props.disabled ? colors.whiteAlabaster : colors.whiteLight)};

  &:hover:not(:disabled) {
    background: ${colors.whiteGallery};
  }

  &:last-child {
    border-right: 1px solid ${colors.whiteGallery};
  }
`;

const createPageInfo = (size, total, current) => {
  let pages = [];
  let hasPrefix = false;
  let hasSuffix = false;

  const totalPages = Math.ceil(total / size);
  const median = Math.ceil(MAX_PAGES / 2);

  if (totalPages <= MAX_PAGES) {
    pages = [...Array(totalPages)].map((_, i) => i + 1);
  } else if (current <= median) {
    hasSuffix = true;
    pages = [...Array(MAX_PAGES)].map((_, i) => i + 1);
  } else if (totalPages - current <= median) {
    hasPrefix = true;
    pages = [...Array(MAX_PAGES)].map((_, i) => totalPages - MAX_PAGES + i + 1);
  } else {
    hasPrefix = true;
    hasSuffix = true;
    pages = [...Array(MAX_PAGES)].map((_, i) => i + 1 - median + current);
  }

  return { hasPrefix, hasSuffix, pages, totalPages };
};

const renderPageItem = (label = '', { ...props }) => <PaginationItem {...props}>{label}</PaginationItem>;

const Pagination = ({ currentPage = 1, pageSize = '100', totalResults = 0, onPageChange = noop }) => {
  const { hasPrefix, hasSuffix, pages, totalPages } = createPageInfo(parseInt(pageSize, 10), totalResults, currentPage);
  const firstPage = pages[0];
  const lastPage = pages[pages.length - 1];

  if (totalResults === 0) {
    return null;
  }

  return (
    <PaginationContainer>
      {renderPageItem(translate('firstPage'), {
        disabled: currentPage === 1,
        onClick: () => onPageChange(1),
      })}
      {renderPageItem(translate('previousPage'), {
        disabled: currentPage === 1,
        onClick: () => onPageChange(currentPage - 1),
      })}

      {hasPrefix &&
        renderPageItem('...', {
          onClick: () => onPageChange(firstPage - 1),
        })}

      {pages.map((p, i) =>
        renderPageItem(p, {
          key: `${p}-${i}`,
          disabled: currentPage === p,
          onClick: () => onPageChange(p),
        })
      )}

      {hasSuffix &&
        renderPageItem('...', {
          onClick: () => onPageChange(lastPage + 1),
        })}

      {renderPageItem(translate('nextPage'), {
        disabled: currentPage === totalPages,
        onClick: () => onPageChange(currentPage + 1),
      })}
      {renderPageItem(translate('lastPage'), {
        disabled: currentPage === totalPages,
        onClick: () => onPageChange(totalPages),
      })}
    </PaginationContainer>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  pageSize: PropTypes.string.isRequired,
  totalResults: PropTypes.number.isRequired,
};

export default Pagination;
