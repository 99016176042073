import React from 'react';

import BaseLayout from 'components/BaseLayout';
import { Breadcrumbs, Confirmation } from 'components/Confirmation';

const ConfirmationLayout = () => (
  <BaseLayout>
    <Breadcrumbs />
    <div className="Layout__content">
      <div className="Layout__wrapper">
        <Confirmation />
      </div>
    </div>
  </BaseLayout>
);

export default ConfirmationLayout;
