import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { selectors as userSelectors } from 'modules/user/selectors';

import { AccountDetails } from './Account';
import { AccountList, PreviousOrders, Reports, SampleLookup } from './Base';

const AccountsLayout = ({ isCsr }) => (
  <>
    <Switch>
      <Route exact path="/accounts/orders" component={PreviousOrders} />
      <Route exact path="/accounts" component={AccountList} />
      {isCsr && <Route exact path="/accounts/reports" component={Reports} />}
      {isCsr && <Route exact path="/accounts/sample-lookup" component={SampleLookup} />}
      <Route path="/accounts/:accountNumber" component={AccountDetails} />
    </Switch>
  </>
);

const mapState = state => ({
  isCsr: userSelectors.isCsr(state),
});

const enhance = connect(mapState);
export default enhance(AccountsLayout);
