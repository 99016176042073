import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

import Image from 'components/Image';
import { LightboxGallery } from 'components/Lightbox';
import { VIDEO_PLAY, GALLERY_VIDEO } from 'constants/strings';

const StyledGallery = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;

  .Gallery__featured {
    margin: 0 0 10px;
  }

  .Gallery__media {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }

  .Gallery__thumbnail {
    cursor: pointer;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

const ProductGallery = ({ data }) => {
  const [lightbox, setLightbox] = useState(false);
  const [selected, setSelected] = useState();
  const [defaultImage, ...media] = data;

  const openLightbox = index => {
    setLightbox(true);
    setSelected(index);
  };

  const closeLightbox = () => {
    setLightbox(false);
    setSelected();
  };

  return (
    <>
      <StyledGallery>
        <div className="Gallery__featured">
          <Image
            className="Gallery__image"
            src={defaultImage.url}
            alt={defaultImage.alt}
            onClick={() => openLightbox(0)}
          />
        </div>
        <div className="Gallery__media">
          {media.map((item, i) => (
            <Image
              key={item.id}
              className="Gallery__thumbnail"
              src={item.type === GALLERY_VIDEO ? VIDEO_PLAY : item.url}
              alt={item.alt}
              onClick={() => openLightbox(i + 1)}
            />
          ))}
        </div>
      </StyledGallery>
      {lightbox && <LightboxGallery data={data} selected={selected} onBackdropClick={closeLightbox} />}
    </>
  );
};

ProductGallery.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string,
    })
  ).isRequired,
};

export default ProductGallery;
