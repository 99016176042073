import React from 'react';

import { SampleLookup, Tabs } from 'components/Base';

const ReportsLayout = () => {
  return (
    <>
      <Tabs />
      <SampleLookup />
    </>
  );
};

export default ReportsLayout;
