import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import ReferenceInfo from 'components/Checkout/ReferenceInfo';
import { selectors as detailsSelectors } from 'modules/account/details';
import { selectors as settingsSelectors } from 'modules/account/settings';
import { actions, selectors } from 'modules/checkout';
import { selectors as userSelectors } from 'modules/user';

class ReferenceInfoContainer extends PureComponent {
  componentDidMount() {
    const { accountNumber, fetchNotificationEmails, userDetails } = this.props;
    if (accountNumber) fetchNotificationEmails();
    this.props.referenceInfoUpdate({ orderPlacedBy: userDetails.name });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.accountNumber !== nextProps.accountNumber) {
      this.props.fetchNotificationEmails();
    }
  }

  actions = {
    onEdit: id => this.props.editEmail(id),
    onDelete: id => this.props.deleteEmail({ id }),
  };

  addEmail = () => {
    this.props.referenceInfoUpdate({ showAddEmail: true });
  };

  createEmail = () => {
    const { referenceInfo, saveEmail } = this.props;

    saveEmail({ email: referenceInfo.newEmail });
  };

  saveEmail = (email, id) => {
    this.props.saveEmail({ email, id });
  };

  fieldUpdate = (field, val) => {
    this.props.referenceInfoUpdate({ [field]: val });
  };

  render() {
    return (
      <ReferenceInfo
        {...this.state}
        {...this.props}
        actions={this.actions}
        addEmail={this.addEmail}
        createEmail={this.createEmail}
        fieldUpdate={this.fieldUpdate}
        saveEmail={this.saveEmail}
      />
    );
  }
}

const mapStateToProps = state => ({
  accountNumber: detailsSelectors.accountNumber(state),
  allSettings: settingsSelectors.allSettings(state),
  isCsr: userSelectors.isCsr(state),
  referenceInfo: selectors.referenceInfo(state),
  userDetails: userSelectors.details(state),
});

const mapDispatch = {
  deleteEmail: actions.deleteEmail,
  editEmail: actions.editEmail,
  fetchNotificationEmails: actions.fetchNotificationEmails,
  referenceInfoUpdate: actions.referenceInfoUpdate,
  saveEmail: actions.saveEmail,
};

export default connect(mapStateToProps, mapDispatch)(ReferenceInfoContainer);
