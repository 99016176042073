const {
  REACT_APP_AUTH_REDIRECT_URL,
  REACT_APP_ENV,
  REACT_APP_ROLLBAR_ENV,
  REACT_APP_ROLLBAR_SHA,
  REACT_APP_SOCKET_ENDPOINT,
  REACT_APP_THEME_URL,
} = process.env;

export const ENVIRONMENT = REACT_APP_ENV || 'local';
export const AUTH_REDIRECT_URL =
  REACT_APP_AUTH_REDIRECT_URL || 'http://localhost:17100/dev/login?referrer=https://localhost:17001';
export const SOCKET_ENDPOINT = REACT_APP_SOCKET_ENDPOINT || '/';
export const THEME_URL = REACT_APP_THEME_URL || 'https://dev.eoilreports.com';
export const ROLLBAR_ENV = REACT_APP_ROLLBAR_ENV || 'local';
export const ROLLBAR_SHA = REACT_APP_ROLLBAR_SHA || '';

export const DEVELOPMENT = ['local', 'development'].includes(ENVIRONMENT);
