import React from 'react';
import styled from 'styled-components';

import { colors } from 'style/theme';

const StyledPanel = styled.div`
  background: ${colors.whiteAlabaster};
  font-size: 12px;
  margin: 0 0 25px;
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
  ${props => props.csr && `background: ${colors.blueHawkes}`};
  ${props => props.raised && 'box-shadow: 0 0 5px rgba(0,0,0,0.3)'};

  &:last-child {
    margin: 0;
  }

  .Panel__header {
    display: flex;
    font-size: 14px;
    font-weight: 700;
    border-bottom: 2px solid ${colors.whiteGallery};
    padding: ${props => (props.collapseTitle ? 0 : '15px')};
    ${props => props.toggleable && 'cursor: pointer'};
    ${props => props.csr && `border-color: ${colors.bluePajamas}`};

    ${props => props.csrDark && `background: ${colors.blueBahama}`};
    ${props => props.csrDark && `color: ${colors.white}`};
    ${props => props.csrDark && 'border: none'};

    &-title {
      margin-right: 10px;
    }

    &-actions {
      margin-left: auto;
      ${props => props.csrDark && `color: ${colors.grayBenjamin}`};
    }
  }

  .Panel__content {
    padding: ${props => (props.collapse ? 0 : '15px')};
    ${props => props.csrDark && `background: ${colors.blueHawkes}`};

    ${props =>
      props.maxHeight &&
      `
      max-height: ${props.maxHeight}px;
      overflow: auto;
    `};

    height: ${props => props.height && `${props.height}px`};

    hr {
      border-color: ${colors.whiteGallery};
    }
  }
`;

const Panel = props => {
  const { children, title, toggleable, toggled, onToggle, ...rest } = props;
  return (
    <StyledPanel toggleable={toggleable} {...rest}>
      {title && (
        <div className="Panel__header" onClick={onToggle}>
          <div className="Panel__header-title">{title}</div>
          {toggleable && (
            <div className="Panel__header-actions">
              {toggled ? <i className="fa fa-minus" /> : <i className="fa fa-plus" />}
            </div>
          )}
        </div>
      )}
      {toggled && <div className="Panel__content">{children}</div>}
    </StyledPanel>
  );
};

export default Panel;
