import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import Input from 'components/Input';
import { changeMethod } from 'utils/common';

const AddEmailInput = styled.div`
  display: flex;
  width: 100%;

  input {
    margin-right: 
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
  }

  button {
    padding: 2px 10px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;

const TemplateContent = styled.label`
  display: flex;
  padding: 10px;
  font-size: 12px;

  button {
    margin-right: 8px;

    &:last-child {
      margin: 0;
    }
  }
`;

const Thing = styled.div`
  flex: 1 1 auto;
  margin-right: 10px;

  &:last-child {
    margin: 0;
  }
`;

const Action = styled(Thing)`
  cursor: pointer;
  flex: 0 0 auto;
`;

const Icon = styled.i`
  &.fa-pencil {
    color: #1976d2;
  }
  &.fa-times {
    color: #e53935;
  }
`;

const ShippingInfoItem = ({ id, value, onDelete, onSave }) => {
  const [email, setEmail] = useState(value);
  const [editing, setEditing] = useState(value.length === 0);

  useEffect(() => {
    setEmail(value);
  }, [value]);

  const onDeleteEmail = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  const onEdit = useCallback(() => {
    setEditing(true);
  }, []);

  const onUpdateEmail = useCallback((_, value) => {
    setEmail(value);
  }, []);

  const onSaveEmail = useCallback(() => {
    onSave(id, email);
    setEditing(false);
  }, [id, email, setEditing, onSave]);

  return (
    <div>
      {editing ? (
        <AddEmailInput>
          <Input inline onChange={changeMethod(onUpdateEmail)} value={email} />
          <Button icon="save" onClick={onSaveEmail} disabled={email.length < 3} />
        </AddEmailInput>
      ) : (
        <TemplateContent>
          <Thing>{email}</Thing>
          <Action onClick={onEdit}>
            <Icon className="fa fa-pencil" />
          </Action>
          <Action onClick={onDeleteEmail}>
            <Icon className="fa fa-times" />
          </Action>
        </TemplateContent>
      )}
    </div>
  );
};

export default ShippingInfoItem;
