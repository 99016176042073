import omit from 'lodash/omit';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import addressValidations from 'constants/addressValidations';
import { fetchCityRegion } from 'lib/api';
import { actions as billingActions } from 'modules/account/billingAddress';
import { selectors as detailsSelectors } from 'modules/account/details';
import { actions, selectors } from 'modules/account/shippingAddresses';
import { actions as confirmationActions } from 'modules/checkout';
import orderActions from 'modules/order/actions';
import { actions as previousOrderActions } from 'modules/previousOrder';
import { allValid } from 'utils/validation';

import AddressForm from './AddressForm';

const VALID_POSTAL_COUNTRIES = ['US', 'CA'];

class AddressFormContainer extends PureComponent {
  validations = addressValidations;

  constructor(props) {
    super(props);

    const { account, address } = props;
    const existingAddress = address || {};

    this.state = {
      address: {
        id: existingAddress.id || undefined,
        account_id: existingAddress.account_id || account.id,
        address1: existingAddress.address1 || '',
        address2: existingAddress.address2 || '',
        address_type: existingAddress.address_type || '',
        attention: existingAddress.attention || '',
        city: existingAddress.city || '',
        company_name: existingAddress.company_name || '',
        country: existingAddress.country || '',
        phone_number: existingAddress.phone_number || '',
        post_code: existingAddress.post_code || '',
        region: existingAddress.region || '',
      },
      saveToAccount: true,
      touched: {},
    };
  }

  fetchCityRegion = async () => {
    const { country, post_code, address1, address2, city, region } = this.state.address;

    // Bail If Required Fields Not Filled
    if (!country || !post_code || !address1) return;

    // Attempt to Find City and Region
    try {
      const { data } = await fetchCityRegion({ address1, address2, country, post_code });
      const { city: cityResult, region: regionResult } = data;
      if (this.didMount) {
        this.setState(state => ({
          address: {
            ...state.address,
            city: city || cityResult,
            region: region || regionResult,
          },
        }));
      }
    } catch (e) {
      // ignored
    }
  };

  handleBlur = name => {
    if (!['country', 'post_code', 'address1'].includes(name)) return;
    this.fetchCityRegion();
  };

  handleAddressChange = (name, value) => {
    if (name === 'country') {
      this.setState(state => ({
        fetchedCityRegion: false,
        address: {
          ...state.address,
          city: '',
          post_code: '',
          region: '',
        },
        touched: { ...state.touched, [name]: true },
      }));
    }

    this.setState(state => ({
      address: { ...state.address, [name]: value },
      touched: { ...state.touched, [name]: true },
    }));
  };

  handleChange = (name, value) => {
    this.setState(() => ({
      [name]: value,
    }));
  };

  placeNewOrder = () => {
    const { address, saveToAccount } = this.state;
    this.props.stubOrder({ address, saveToAccount });
  };

  placeOrder = () => {
    const { address } = this.state;

    this.props.placeOrder();
    this.props.closeAddressDialog();
    this.props.emailInvoiceAddress({ ...address });
  };

  saveAddress = () => {
    const { address } = this.state;
    const {
      isBilling,
      isCheckout,
      isInvoiceAddressAdd,
      isPreviousOrder,
      isConfirmation,
      saveAddress,
      updateBillingAddress,
      updateConfirmationOrderAddress,
      updateOrderAddress,
      updatePreviousOrderAddress,
    } = this.props;

    if (isInvoiceAddressAdd) return;
    if (isBilling) return updateBillingAddress({ address });
    if (isCheckout) return updateOrderAddress({ address });
    if (isConfirmation) return updateConfirmationOrderAddress({ address });
    if (isPreviousOrder) return updatePreviousOrderAddress({ address });

    return saveAddress({ address, saveToAccount: true });
  };

  getValidations = () => {
    const { address } = this.state;
    const validatePostal = VALID_POSTAL_COUNTRIES.includes(address.country);
    return validatePostal ? this.validations : omit(this.validations, 'post_code');
  };

  validateField = field => {
    const { showValidationErrors } = this.props;
    const { address, touched } = this.state;
    const validations = this.getValidations();

    // No Validation Exists for Field
    if (!validations[field]) return true;

    // Field Hasn't Been Edited Yet
    if (!touched[field] && !showValidationErrors) return true;

    // Actually Validate
    return allValid(address, { [field]: validations[field] });
  };

  validate = () => {
    const { address } = this.state;
    const validations = this.getValidations();
    return allValid(address, validations);
  };

  componentDidMount() {
    this.didMount = true;
  }

  componentWillUnmount() {
    // Easiest way to cancel our setState on addressLookup
    // since blurs can fire as user leaves
    this.didMount = false;
  }

  getChildProps = () => ({
    ...this.props,
    ...this.state,
    handleBlur: this.handleBlur,
    handleChange: this.handleChange,
    handleAddressChange: this.handleAddressChange,
    onSaveAddressToAccount: this.onSaveAddressToAccount,
    placeNewOrder: this.placeNewOrder,
    placeOrder: this.placeOrder,
    saveAddress: this.saveAddress,
    validateField: this.validateField,
    validations: this.getValidations(),
    valid: this.validate(),
  });

  render() {
    return <AddressForm {...this.getChildProps()} />;
  }
}

const mapState = (state, { address }) => ({
  account: detailsSelectors.details(state),
  address: address || selectors.dialogAddress(state),
  billingInfo: detailsSelectors.billingInfo(state),
  error: selectors.error(state),
  errorMessage: selectors.errorMessage(state),
  isInvoiceAddressAdd: selectors.isInvoiceAddressAdd(state),
  isOrderEnabled: detailsSelectors.isOrderEnabled(state),
  loading: selectors.loading(state),
  showValidationErrors: selectors.showValidationErrors(state),
});

const mapDispatch = {
  closeAddressDialog: actions.closeAddressDialog,
  emailInvoiceAddress: confirmationActions.emailInvoiceAddress,
  placeOrder: confirmationActions.placeOrder,
  saveAddress: actions.saveAddress,
  stubOrder: orderActions.stubOrder,
  updateBillingAddress: billingActions.saveBillingAddress,
  updateOrderAddress: orderActions.updateOrderAddress,
  updatePreviousOrderAddress: previousOrderActions.modifyAddress,
  updateConfirmationOrderAddress: confirmationActions.updateConfirmationOrderAddress,
};

export default connect(mapState, mapDispatch)(AddressFormContainer);
