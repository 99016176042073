import { handleActions } from 'redux-actions';
import { all, takeLatest, put, call, select, take } from 'redux-saga/effects';

import * as config from 'config';
import * as api from 'lib/api';
import rollbar from 'lib/rollbar';
import { actions as checkoutActions } from 'modules/checkout/actions';
import { actions as toastActions } from 'modules/toast/actions';
import { fetchTranslationsSaga } from 'modules/translations/fetchTranslationsSaga';
import { httpRedirect } from 'utils/navigation';
import { getTokenFromUrl } from 'utils/token';

import { actions } from './actions';
import { selectors } from './selectors';

const DELETE_CARD_TOAST = 'DELETE_CARD_TOAST';

// state
const defaultState = {
  loading: false,
  invalid: false,
  hasSession: false,
  details: undefined,
  createCreditCard: {
    error: null,
    loading: false,
  },
  creditCards: {
    data: [],
    loading: false,
  },
};

export const errors = {
  TOKEN_MISSING: Error('token missing'),
};

const parseCreditCards = (paymentProfiles = []) => {
  return paymentProfiles.map(profile => ({
    ...profile.payment.creditCard,
    id: profile.customerPaymentProfileId,
  }));
};

// sagas
function* createCreditCardSaga({ payload }) {
  try {
    const { distPointCode, creditCardInfo, customerInfo } = payload;
    const { authorize_net_customer_ids } = yield select(selectors.details);

    let customerId;
    if (authorize_net_customer_ids) {
      const { authorize_net_us_customer_id, authorize_net_ca_customer_id } = authorize_net_customer_ids;
      customerId = distPointCode === 'CA' ? authorize_net_ca_customer_id : authorize_net_us_customer_id;
    }

    if (customerId) {
      yield call(api.addCreditCard, customerId, { creditCardInfo, customerInfo, distPointCode });

      // ReAuth User
      yield put(actions.userAuth());
      yield take(actions.userAuthResponse);
      yield put(actions.fetchUserProfile(distPointCode));
    } else {
      yield call(api.createUserProfile, { creditCardInfo, customerInfo, distPointCode });

      // ReAuth User
      yield put(actions.userAuth());
      yield take(actions.userAuthResponse);
      yield put(actions.fetchUserProfile(distPointCode));
    }

    // Allows for Clearing of Form
    yield take(actions.fetchUserProfileResponse);
    yield put(actions.createCreditCardResponse());
  } catch (e) {
    console.log(e.message);
    yield put(actions.createCreditCardResponse(e));
  }
}

function* deleteCreditCardSaga({ payload }) {
  try {
    const { authorize_net_customer_ids } = yield select(selectors.details);
    const { creditCardId, distPointCode } = payload;

    const { authorize_net_us_customer_id, authorize_net_ca_customer_id } = authorize_net_customer_ids;
    const customerId = distPointCode === 'CA' ? authorize_net_ca_customer_id : authorize_net_us_customer_id;
    yield call(api.deleteCreditCard, customerId, creditCardId, distPointCode);
    yield put(actions.userAuth());
    yield put(actions.fetchUserProfile(distPointCode));

    yield put(
      toastActions.createToast('success', {
        id: DELETE_CARD_TOAST,
        translation: true,
        message: 'DELETE_CARD_SUCCESS',
        title: 'DELETE_CARD_TITLE',
      })
    );
  } catch (e) {
    console.log(e.message);
    yield put(
      toastActions.createToast('alert', {
        id: DELETE_CARD_TOAST,
        translation: true,
        message: e.message,
        timeout: false,
        title: 'store.error',
      })
    );
  }
}

function* fetchUserProfileSaga({ payload: distPointCode }) {
  try {
    const { authorize_net_customer_ids } = yield select(selectors.details);

    let customerId;
    if (authorize_net_customer_ids) {
      const { authorize_net_us_customer_id, authorize_net_ca_customer_id } = authorize_net_customer_ids;
      customerId = distPointCode === 'CA' ? authorize_net_ca_customer_id : authorize_net_us_customer_id;
    }

    if (customerId) {
      const { customerProfile } = yield call(api.fetchUserProfile, customerId, distPointCode);
      const creditCards = parseCreditCards(customerProfile.paymentProfiles);
      yield put(actions.fetchUserProfileResponse(creditCards));

      const [defaultCard] = creditCards;
      const creditCardId = defaultCard ? defaultCard.id : 'create';
      yield put(checkoutActions.paymentUpdate({ creditCardId }));
    } else {
      yield put(checkoutActions.paymentUpdate({ creditCardId: 'create' }));
    }
  } catch (e) {
    console.log(e);
    yield put(actions.fetchUserProfileResponse(e));
  }
}

export function* userAuthSaga() {
  try {
    const token = yield call(getTokenFromUrl);

    let response;
    if (token) {
      const { details } = yield call(api.verifyToken, token);
      response = details;
    } else {
      const { details } = yield call(api.fetchSession);
      response = details;
    }

    yield call(fetchTranslationsSaga);
    yield put(actions.userAuthResponse(response));
  } catch (e) {
    yield put(actions.userAuthResponse(e));
  }
}

export function* authResponseSaga() {
  const invalid = yield select(selectors.invalid);
  if (invalid) {
    yield call(httpRedirect, config.AUTH_REDIRECT_URL);
  } else {
    const { id, email, name: username } = yield select(selectors.details);
    rollbar.configure({ payload: { person: { id, email, username } } });
  }
}

export function* userSaga() {
  yield all([
    takeLatest(actions.createCreditCard, createCreditCardSaga),
    takeLatest(actions.deleteCreditCard, deleteCreditCardSaga),
    takeLatest(actions.fetchUserProfile, fetchUserProfileSaga),
    takeLatest(actions.userAuth, userAuthSaga),
    takeLatest(actions.userAuthResponse, authResponseSaga),
  ]);
}

export { actions } from './actions';
export { selectors } from './selectors';

// reducer
export default handleActions(
  {
    [actions.createCreditCard](state) {
      return {
        ...state,
        createCreditCard: { loading: true, error: null },
      };
    },
    [actions.createCreditCardResponse]: {
      throw(state, { payload: e }) {
        return { ...state, createCreditCard: { loading: false, error: e.message } };
      },
    },
    [actions.fetchUserProfile](state) {
      return {
        ...state,
        createCreditCard: defaultState.createCreditCard,
        creditCards: defaultState.creditCards,
      };
    },
    [actions.fetchUserProfileResponse]: {
      next(state, { payload: data }) {
        return {
          ...state,
          createCreditCard: { loading: false },
          creditCards: { data, loading: false },
        };
      },
      throw(state, { payload: e }) {
        return { ...state, createCreditCard: { loading: false, error: e.message } };
      },
    },
    [actions.userAuth](state) {
      return { ...state, loading: true };
    },
    [actions.userAuthResponse]: {
      next(state, { payload: details }) {
        return { ...state, loading: false, hasSession: true, details };
      },
      throw(state) {
        return { ...state, loading: false, invalid: true };
      },
    },
  },
  defaultState
);
