import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import React from 'react';
import styled from 'styled-components';

import { SearchButton, ClearButton } from 'components/Buttons';
import LinkRow from 'components/LinkRow';
import ListTable from 'components/ListTable';
import OneColumnRow from 'components/OneColumnRow';
import Pagination from 'components/Pagination';
import SearchSortLabel from 'components/SearchSortLabel';
import SearchSubmit from 'components/SearchSubmit';
import ShowSelect from 'components/ShowNumberSelect';
import UserSearchDialog from 'components/UserSearchDialog';
import { colors } from 'style/theme';
import { translate } from 'utils/translations';

const LookupLinkRoot = styled.a`
  background: none;
  border: none;
  padding: 0;
  margin-left: 5px;
  color: ${colors.blueWater};
  cursor: pointer;
`;

const Root = styled.div`
  position: static;
`;

const ShowSearchAnyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 15px;
`;

const LookupLink = ({ onClick, ...props }) => (
  <LookupLinkRoot
    onClick={e => {
      e.preventDefault();
      onClick();
    }}
    {...props}
  >
    <i className="fa fa-info-circle" /> {translate('lookup')}
  </LookupLinkRoot>
);

const AccountList = ({
  accounts = [],
  emailSearch,
  error,
  hasSearched,
  loading,
  params = {},
  resetSearch = noop,
  searchData = {},
  searchSubmit = noop,
  showEmail,
  toggleEmailSearch = noop,
  updateParams = noop,
  updateSort = noop,
  validInputLength = {},
  validSearch,
  isValid,
}) => {
  return (
    <Root>
      <form onSubmit={searchSubmit}>
        <ShowSearchAnyWrapper>
          <ShowSelect
            label={translate('show')}
            value={params.pageSize || '100'}
            clearable={false}
            onChange={val => updateParams('pageSize', val, true)}
            width={200}
          />
          <SearchSubmit
            disabled={!isValid}
            invalidLength={validInputLength.any}
            label={translate('search')}
            searchQuery={params.any}
            searchHandler={val => updateParams('any', val)}
          />
        </ShowSearchAnyWrapper>
        <ListTable>
          <thead>
            <tr>
              <th style={{ width: 275 }}>
                <SearchSortLabel
                  invalidLength={validInputLength.name}
                  label={translate('accountName')}
                  sortDirection={params.sortDirection}
                  searchQuery={params.name}
                  searchHandler={val => updateParams('name', val)}
                  toggleHandler={direction => updateSort('name', direction)}
                  toggleIsActive={params.sort === 'name'}
                  autoFocus
                />
              </th>
              <th>
                <SearchSortLabel
                  invalidLength={validInputLength.number}
                  label={translate('accountNumber')}
                  sortDirection={params.sortDirection}
                  searchQuery={params.number}
                  searchHandler={val => updateParams('number', val)}
                  toggleHandler={direction => updateSort('number', direction)}
                  toggleIsActive={params.sort === 'number'}
                />
              </th>
              {showEmail && (
                <th>
                  <SearchSortLabel
                    label={
                      <span>
                        {translate('email')}
                        <LookupLink onClick={() => toggleEmailSearch(true)} />
                      </span>
                    }
                    sortDirection={params.sortDirection}
                    searchQuery={params.email}
                    searchHandler={val => updateParams('email', val)}
                    toggleHandler={direction => updateSort('email', direction)}
                    toggleIsActive={params.sort === 'email'}
                  />
                </th>
              )}
              <th>
                <SearchSortLabel
                  invalidLength={validInputLength.city}
                  label={translate('city')}
                  sortDirection={params.sortDirection}
                  searchQuery={params.city}
                  searchHandler={val => updateParams('city', val)}
                  toggleHandler={direction => updateSort('city', direction)}
                  toggleIsActive={params.sort === 'city'}
                />
              </th>
              <th style={{ width: 150 }}>
                <SearchSortLabel
                  invalidLength={validInputLength.state}
                  label={translate('state')}
                  sortDirection={params.sortDirection}
                  searchQuery={params.state}
                  searchHandler={val => updateParams('state', val)}
                  toggleHandler={direction => updateSort('state', direction)}
                  toggleIsActive={params.sort === 'state'}
                />
              </th>
              <th style={{ width: 100 }}>
                <SearchSortLabel
                  invalidLength={validInputLength.country}
                  label={translate('country')}
                  sortDirection={params.sortDirection}
                  searchQuery={params.country}
                  searchHandler={val => updateParams('country', val)}
                  toggleHandler={direction => updateSort('country', direction)}
                  toggleIsActive={params.sort === 'country'}
                  maxLength="2"
                />
              </th>
              <th style={{ width: 30, verticalAlign: 'bottom', padding: '10px 5px' }}>
                <SearchButton type="submit" disabled={!isValid} />
              </th>
              <th style={{ width: 30, verticalAlign: 'bottom', padding: '10px 15px 10px 5px' }}>
                <ClearButton onClick={() => resetSearch()} icon="close" disabled={!isValid} />
              </th>
            </tr>
          </thead>
          <tbody>
            {error && <OneColumnRow colSpan="8" error text={translate('anErrorOccurred')} />}
            {!validSearch && <OneColumnRow colSpan="8" error text={translate('invalidSearch')} />}
            {loading && <OneColumnRow colSpan="8" text={`${translate('loading')}...`} />}
            {accounts.length > 0 &&
              accounts.map(acc => (
                <LinkRow key={acc.id} suspended={acc.suspended} enabled={!acc.suspended} to={`/accounts/${acc.number}`}>
                  <td style={{ width: 275 }}>{acc.name}</td>
                  <td>{acc.number}</td>
                  {showEmail && <td>{acc.email}</td>}
                  <td>{acc.city}</td>
                  <td style={{ width: 150 }}>{acc.state}</td>
                  <td style={{ width: 100 }}>{acc.country}</td>
                  <td />
                  <td>{acc.suspended && <i className="fa fa-exclamation-triangle" />}</td>
                </LinkRow>
              ))}
            {!error && validSearch && hasSearched && accounts.length === 0 && (
              <OneColumnRow colSpan="8" text={translate('noRecordsFound')} />
            )}
            {!error && !loading && isEmpty(accounts) && !hasSearched && (
              <OneColumnRow colSpan="8" text={translate('accountSearchNoSearchYet')} />
            )}
          </tbody>
        </ListTable>
      </form>
      {emailSearch && (
        <UserSearchDialog
          onClose={() => toggleEmailSearch(false)}
          selectEmail={email => {
            updateParams('email', email);
            toggleEmailSearch(false);
          }}
        />
      )}
      <Pagination
        currentPage={(searchData && searchData.page) || 1}
        pageSize={params.pageSize || '100'}
        totalResults={(searchData && searchData.totalResults) || 0}
        onPageChange={val => updateParams('page', val, true)}
      />
    </Root>
  );
};

export default AccountList;
