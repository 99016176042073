import find from 'lodash/find';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { createSelector } from 'reselect';

import { CC_REQUIRED, CREDIT_CARD, INVOICE } from 'constants/strings';
import { selectors as settingsSelectors } from 'modules/account/settings/selectors';
import { selectors as orderSelectors } from 'modules/order/selectors';
import { selectors as shippingOptionsSelectors } from 'modules/shipping/options/selectors';
import { selectors as userSelectors } from 'modules/user/selectors';

const VALID_CREDIT_COUNTRIES = ['CA', 'MX', 'US'];

const selectors = {
  accountDetails: state => get(state, 'account.details.data', {}),
  confirmation: state => get(state, 'checkout.confirmation', {}),
  confirmationData: state => get(state, 'checkout.confirmation.data', {}),
  confirmationLoading: state => get(state, 'checkout.confirmation.loading'),
  csr: state => get(state, 'checkout.csr', {}),
  override: state => get(state, 'checkout.override', {}),
  overrideItems: state => get(state, 'checkout.override.items'),
  payment: state => get(state, 'checkout.payment', {}),
  referenceInfo: state => get(state, 'checkout.referenceInfo', {}),
  shipping: state => get(state, 'checkout.shipping', {}),
  shippingAddress: state => get(state, 'checkout.shippingAddress', {}),
  showOverrideDialog: state => get(state, 'checkout.override.showDialog'),
};

selectors.purchaseOrderNumber = createSelector(
  [settingsSelectors.allSettings, selectors.payment],
  (settings, payment) => {
    const { blanket_po } = settings;
    return blanket_po && blanket_po !== 'None' ? blanket_po : payment.purchaseOrderNumber;
  }
);

selectors.paymentMethod = createSelector([settingsSelectors.allSettings, selectors.payment], (settings, payment) => {
  const { billing_option } = settings;
  return billing_option === 'CC Required' ? 'credit_card' : payment.paymentMethod;
});

selectors.paymentMethods = createSelector(
  [
    selectors.accountDetails,
    settingsSelectors.allSettings,
    shippingOptionsSelectors.carriers,
    orderSelectors.address,
    selectors.shipping,
  ],
  (accountDetails, settings, carriers, address, shipping) => {
    const paymentMethods = [];
    if (carriers.loading) return { data: paymentMethods, loading: true };

    const canUseInvoice = settings.billing_option !== CC_REQUIRED;
    if (canUseInvoice) paymentMethods.push(INVOICE);

    const canUseCreditCard = () => {
      const isValidShipping = !shipping.isFreight && !shipping.freightSelected;
      const isValidCarriers = !carriers.error;
      const isValidAddress = VALID_CREDIT_COUNTRIES.includes(address.country);
      const isValidAccount = !accountDetails.number.startsWith('USLUBE');

      return isValidAddress && isValidCarriers && isValidShipping && isValidAccount;
    };
    if (canUseCreditCard()) paymentMethods.push(CREDIT_CARD);

    if (paymentMethods.length === 0) return { data: paymentMethods, loading: false, error: true };
    return { data: paymentMethods, loading: false };
  }
);

selectors.selectedShippingCarrier = createSelector(
  [selectors.shipping, shippingOptionsSelectors.carriers],
  (shipping, carriers) => {
    const shipPref = shipping.shipperPreference;
    if (!shipPref || carriers.data.length === 0) return null;
    return find(carriers.data, { id: shipPref });
  }
);

selectors.shippingMethods = createSelector(selectors.selectedShippingCarrier, carrier => {
  if (!carrier) return null;

  return carrier.methods.map(m => ({
    id: m.id,
    label: m.name,
    price: m.price,
    defaultCost: m.defaultCost,
  }));
});

selectors.shippingMethod = createSelector([selectors.shippingMethods, selectors.shipping], (methods, shipping) => {
  const baseMethod = { id: null, price: 0, defaultCost: 0 };

  if (methods) {
    const method = find(methods, { id: shipping.shippingMethod });
    const defaultMethod = find(methods, { default: true });
    const selectedMethod = method || defaultMethod || methods[0];
    return { ...baseMethod, ...selectedMethod };
  }

  return baseMethod;
});

selectors.all = createSelector(
  selectors.csr,
  selectors.overrideItems,
  selectors.payment,
  selectors.referenceInfo,
  selectors.shipping,
  selectors.shippingAddress,
  selectors.shippingMethod,
  (state, props) => userSelectors.isCsr(state, props),
  (csr, overrideItems, payment, referenceInfo, shipping, shippingAddress, shippingMethod, isCsr) => {
    const selection = {
      isCsr,
      overrideItems,
      payment,
      referenceInfo,
      shipping,
      shippingAddress,
      shippingPrice: shippingMethod.price,
      shippingDefaultCost: shippingMethod.defaultCost,
    };

    if (isCsr) {
      selection.csr = omit(csr, ['loading', 'languages', 'locations']);
      selection.payment = omit(selection.payment, ['creditCardId', 'valid']);
    } else {
      selection.payment = omit(selection.payment, ['creditCardInfo', 'customerInfo', 'valid']);
    }

    return selection;
  }
);

selectors.valid = createSelector(selectors.shipping, shipping => {
  return shipping.valid;
});

const confirmationSelector = getter => createSelector([selectors.confirmationData], getter);

selectors.confirmationCurrency = confirmationSelector(data => (data.productList ? data.productList.currency : 'USD'));
selectors.confirmationItems = confirmationSelector(data => data.items);
selectors.confirmationAddress = confirmationSelector(data => data.address);
selectors.confirmationOverrides = createSelector([selectors.confirmationItems], items =>
  items.some(item => item.csrOverride && item.csrOverride.price)
);

export default selectors;
