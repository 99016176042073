import get from 'lodash/get';
import { connect } from 'react-redux';

import { selectors as accountSelectors } from 'modules/account/details';
import orderSelectors from 'modules/order/selectors';
import { selectors as userSelectors } from 'modules/user';

import Header from './Header';

const mapStateToProps = state => ({
  accountNumber: get(accountSelectors.details(state), 'number', ''),
  order: orderSelectors.hasOrder(state),
  itemCount: orderSelectors.hasOrder(state) ? orderSelectors.itemCount(state) : null,
  user: userSelectors.details(state),
});

export default connect(mapStateToProps)(Header);
