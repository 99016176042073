import get from 'lodash/get';
import moment from 'moment/moment';
import { connect } from 'react-redux';

import OrderHeader from 'components/Order/OrderHeader';
import { SHIPPED } from 'constants/orderStatuses';
import { selectors } from 'modules/previousOrder';

const mapState = state => {
  const data = selectors.data(state);
  const {
    cancelled_reason,
    cancelled_username,
    id,
    shippingPreference: { shippingMethod, custom_ship_date, estimated_ship_date },
    status,
    ...order
  } = data;
  const methodName = get(shippingMethod, 'name');
  const carrierName = get(shippingMethod, 'shippingOption.name');

  return {
    ...order,
    cancelledReason: cancelled_reason,
    cancelledUser: cancelled_username,
    orderNumber: id,
    status,
    orderDate: moment(order.ordered_at).format('MM/DD/YYYY, h:mm:ss a'),
    shipDate:
      status === SHIPPED
        ? moment(order.shipped_at).format('MM/DD/YYYY')
        : moment(custom_ship_date || estimated_ship_date)
            .utc(false)
            .format('MM/DD/YYYY'),
    shipMethod: `${carrierName} ${methodName}`,
  };
};

export default connect(mapState)(OrderHeader);
