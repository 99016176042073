import isEqual from 'lodash/isEqual';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Payment from 'components/Checkout/Payment';
import { CREDIT_CARD, CC_REQUIRED, PO_REQUIRED, INVOICE } from 'constants/strings';
import { checkPurchaseOrder } from 'lib/api';
import { selectors as detailsSelectors } from 'modules/account/details';
import { selectors as settingsSelectors } from 'modules/account/settings';
import { actions, selectors, selectors as checkoutSelectors } from 'modules/checkout';
import { actions as modalActions } from 'modules/modal';
import { selectors as orderSelectors } from 'modules/order';
import { actions as userActions, selectors as userSelectors } from 'modules/user';
import { translate } from 'utils/translations';
import { allValid } from 'utils/validation';

class PaymentContainer extends PureComponent {
  state = {
    existingPurchaseOrder: '',
  };

  validations = {
    default: {},
    address: {
      companyName: { validation: ['minLength:1'] },
      firstName: { validation: ['minLength:1'] },
      lastName: { validation: ['minLength:1'] },
      address: { validation: ['minLength:1'] },
      city: { validation: ['minLength:1'] },
      state: { validation: ['minLength:1'] },
      zip: { validation: ['minLength:3'] },
    },
    amexCreditCard: {
      cardNumber: { validation: ['minLength:15'] },
      expirationDate: { validation: ['minLength:5', 'expirationDate:MM/YY:M'] },
      cvv: { validation: ['minLength:4', 'numbersOnly'] },
    },
    creditCard: {
      cardNumber: { validation: ['minLength:16'] },
      expirationDate: { validation: ['minLength:5', 'expirationDate:MM/YY:M'] },
      cvv: { validation: ['minLength:3', 'numbersOnly'] },
    },
    customerInfo: {
      companyName: { validation: ['minLength:1'] },
      country: { validation: ['minLength:1'] },
      firstName: { validation: ['minLength:1'] },
      lastName: { validation: ['minLength:1'] },
      address: { validation: ['minLength:1'] },
      city: { validation: ['minLength:1'] },
      state: { validation: ['minLength:1'] },
      zip: { validation: ['minLength:3'] },
    },
  };

  onCreateCreditCard = e => {
    e.preventDefault();
    const { distributionPoint, payment } = this.props;
    const { creditCardInfo, customerInfo } = payment;
    this.props.onCreateCreditCard({
      distPointCode: distributionPoint.code,
      creditCardInfo,
      customerInfo,
    });
  };

  onDeleteCreditCard = creditCardId => {
    const { distributionPoint, showModal, onDeleteCreditCard } = this.props;
    showModal('confirm', {
      type: 'alert',
      title: translate('deleteCreditCard', 'Delete Credit Card'),
      closeText: translate('yesDeleteCard', 'Yes, Delete Card'),
      cancelText: translate('noCancel', 'No, Cancel'),
      locked: true,
      message: <p>{translate('confirmDelete', "Are you sure you'd like to delete this credit card?")}</p>,
      onClose: () => onDeleteCreditCard({ distPointCode: distributionPoint.code, creditCardId }),
    });
  };

  onPurchaseOrderChange = async (_, val) => {
    const setPurchaseOrder = existingPurchaseOrder => {
      this.setState({ existingPurchaseOrder });
    };

    if (!this.props.isCsr) return setPurchaseOrder();
    if (val.length === 0 || val.toLowerCase() === 'none') return setPurchaseOrder();

    const { id: account_id } = this.props.account;
    const { orders } = await checkPurchaseOrder({ account_id, poNumber: val });

    if (orders.length !== 1) return setPurchaseOrder();

    const [existingPurchaseOrder] = orders;
    setPurchaseOrder(existingPurchaseOrder);
  };

  onAddressUpdate = (field, val) => {
    const { payment } = this.props;
    this.paymentUpdate({
      customerInfo: {
        ...payment.customerInfo,
        [field]: val,
      },
    });
  };

  onAddressBlurUpdate = field => {
    const { payment } = this.props;
    this.paymentUpdate({
      touched: { ...payment.touched, [field]: true },
    });
  };

  onCreditCardBlurUpdate = field => {
    const { payment } = this.props;
    this.paymentUpdate({
      touched: { ...payment.touched, [field]: true },
    });
  };

  onCardUpdate = (field, val) => {
    const { payment } = this.props;

    if (field === 'cardNumber') val = val.replace(/\s/g, '');

    this.paymentUpdate({
      creditCardInfo: {
        ...payment.creditCardInfo,
        [field]: val,
      },
    });
  };

  onFieldUpdate = (field, val) => {
    this.paymentUpdate({ [field]: val });
  };

  isAmex = (number = '') => number.startsWith('34') || number.startsWith('37');

  getValidations = field => {
    const creditCardFields = ['cardNumber', 'expirationDate', 'cvv'];
    const {
      allSettings: settings,
      payment: {
        paymentMethod,
        creditCardInfo: { cardNumber },
      },
    } = this.props;

    const customerInfoValidations = this.validations.customerInfo;
    const creditCardInfoValidations = this.validations.creditCard;
    const amexCreditCardInfoValidations = this.validations.amexCreditCard;
    let validations = { ...this.validations.default };

    if (settings.billing_option === PO_REQUIRED && paymentMethod === INVOICE) {
      validations.purchaseOrderNumber = { validation: ['minLength:1', 'isNotEmpty'] };
    }

    if (paymentMethod === CREDIT_CARD && field && field !== 'purchaseOrderNumber') {
      validations[field] = customerInfoValidations[field];
    }

    if (creditCardFields.includes(field)) {
      const cardValidation = this.isAmex(cardNumber) ? amexCreditCardInfoValidations : creditCardInfoValidations;
      validations[field] = cardValidation[field];
    }

    return validations;
  };

  isValidCreditCard = ({ customerInfo, creditCardInfo }) => {
    const { cardNumber } = creditCardInfo;
    const [NUMBER_LENGTH, CVV_LENGTH] = this.isAmex(cardNumber) ? [15, 4] : [16, 3];

    const isValidAddress = allValid(customerInfo, this.validations.address);
    const isValidCard = allValid(creditCardInfo, {
      ...this.validations.creditCard,
      cardNumber: { validation: [`minLength:${NUMBER_LENGTH}`] },
      cvv: { validation: [`minLength:${CVV_LENGTH}`] },
    });

    return isValidAddress && isValidCard;
  };

  isValidField = (field, val) => {
    const {
      payment: { touched },
    } = this.props;

    if (!touched[field] && field !== 'purchaseOrderNumber') return true;

    const validations = this.getValidations(field);
    return allValid({ [field]: val }, validations);
  };

  isValid = update => {
    const { isCsr } = this.props;
    const validations = this.getValidations();

    let valid = allValid(update, validations);
    if (update.paymentMethod === CREDIT_CARD) {
      if (isCsr) {
        valid = valid && this.isValidCreditCard(update);
      } else {
        valid = valid && update.creditCardId !== 'create';
      }
    }

    return valid;
  };

  paymentUpdate = update => {
    const { payment } = this.props;
    const nextState = { ...update };

    if (nextState.purchaseOrderNumber && nextState.purchaseOrderNumber.toLowerCase() === 'none') {
      nextState.purchaseOrderNumber = '';
    }

    this.props.paymentUpdate({
      ...nextState,
      valid: this.isValid({ ...payment, ...nextState }),
    });
  };

  updateValidity = () => {
    const { payment } = this.props;
    this.props.paymentUpdate({
      valid: this.isValid(payment),
    });
  };

  componentDidMount() {
    const { distributionPoint, isCsr, paymentMethods, fetchUserProfile, allSettings: settings } = this.props;
    if (paymentMethods.data.includes(CREDIT_CARD) && !isCsr) fetchUserProfile(distributionPoint.code);
    if (settings.billing_option === CC_REQUIRED) {
      this.paymentUpdate({ paymentMethod: CREDIT_CARD });
    } else {
      this.paymentUpdate({ purchaseOrderNumber: settings.blanket_po });
      this.updateValidity();
    }
  }

  componentDidUpdate(prevProps) {
    const { payment, allSettings: settings } = this.props;
    if (!isEqual(prevProps.allSettings.billing_option, settings.billing_option)) {
      if (settings.billing_option === CC_REQUIRED) this.paymentUpdate({ paymentMethod: CREDIT_CARD });
    }
    if (!isEqual(prevProps.payment, payment)) this.updateValidity();
  }

  getChildProps = () => ({
    ...this.state,
    ...this.props,
    onAddressBlurUpdate: this.onAddressBlurUpdate,
    onCreditCardBlurUpdate: this.onCreditCardBlurUpdate,
    onAddressUpdate: this.onAddressUpdate,
    onCardUpdate: this.onCardUpdate,
    onCreateCreditCard: this.onCreateCreditCard,
    onDeleteCreditCard: this.onDeleteCreditCard,
    onFieldUpdate: this.onFieldUpdate,
    onPaymentUpdate: this.paymentUpdate,
    onPurchaseOrderChange: this.onPurchaseOrderChange,
    isAmex: this.isAmex,
    isValidCreditCard: this.isValidCreditCard,
    isValidField: this.isValidField,
  });

  render() {
    return <Payment {...this.getChildProps()} />;
  }
}

const mapStateToProps = state => ({
  allSettings: settingsSelectors.allSettings(state),
  account: detailsSelectors.details(state),
  createCreditCard: userSelectors.createCreditCard(state),
  creditCards: userSelectors.creditCards(state),
  isCsr: userSelectors.isCsr(state),
  distributionPoint: orderSelectors.distributionPoint(state),
  payment: selectors.payment(state),
  paymentMethod: checkoutSelectors.paymentMethod(state),
  paymentMethods: checkoutSelectors.paymentMethods(state),
  purchaseOrderNumber: checkoutSelectors.purchaseOrderNumber(state),
  orderAddress: orderSelectors.address(state),
});

const mapDispatch = {
  fetchUserProfile: userActions.fetchUserProfile,
  onCreateCreditCard: userActions.createCreditCard,
  onDeleteCreditCard: userActions.deleteCreditCard,
  paymentUpdate: actions.paymentUpdate,
  showModal: modalActions.showModal,
};

export default connect(mapStateToProps, mapDispatch)(PaymentContainer);
