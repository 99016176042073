import React from 'react';
import styled from 'styled-components';

import { PlaceNewOrderButton, SaveButton } from 'components/Buttons';
import Dialog from 'components/Dialog';
import LabeledInput, { LabeledSelect } from 'components/LabeledInput';
import { COUNTRIES } from 'constants/countryRegions';
import { colors } from 'style/theme';
import { changeMethod } from 'utils/common';
import { translate } from 'utils/translations';

const Message = styled.div`
  font-size: 14px;
  padding: 3px 0;
  margin-top: 1rem;
  ${props => props.color && `color: ${props.color};`};
`;

const SpaceBetweenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ValidationError = styled.div`
  background-color: ${colors.redCinnabar};
  color: ${colors.white};
  text-align: center;
  padding: 30px 55px 30px 40px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 5px;
`;

const DenotesRequired = styled.div`
  margin-bottom: 15px;
  span {
    color: red;
  }
`;

const AddressForm = ({
  address,
  billingInfo,
  closeAddressDialog,
  error,
  errorMessage,
  handleAddressChange,
  handleBlur,
  handleChange,
  isBilling,
  isCheckout,
  isConfirmation,
  isInvoiceAddressAdd,
  isOrderEnabled,
  isPreviousOrder,
  placeOrder,
  placeNewOrder,
  saveAddress,
  saveToAccount,
  showValidationErrors,
  valid,
  validateField,
  validations,
}) => {
  const isExistingAddress = address.id && address.id !== 'unvalidated';
  const { hasValidInvoiceAddress } = billingInfo;

  const showPlaceOrder =
    ((!isCheckout && !isPreviousOrder && !isConfirmation && !isBilling) || isInvoiceAddressAdd) &&
    hasValidInvoiceAddress;

  const showPlaceOrderOnCheckout = isCheckout && isInvoiceAddressAdd && !hasValidInvoiceAddress;

  const showDefaultErrorMessage = !errorMessage && !showValidationErrors && error;
  const header = isInvoiceAddressAdd
    ? 'Add An Invoice Address To Complete Order'
    : isExistingAddress
    ? translate('editAddress')
    : translate('addNewAddress');

  return (
    <Dialog
      error={showValidationErrors || errorMessage}
      header={header}
      modalStyle={{ width: 430, maxWidth: '90vw' }}
      onClose={closeAddressDialog}
      locked
    >
      {showValidationErrors && (
        <ValidationError>
          The address you have selected is invalid. The highlighted fields are required.
        </ValidationError>
      )}
      {errorMessage && <ValidationError>{translate(errorMessage)}</ValidationError>}
      <LabeledInput
        autoComplete="organization"
        autoFocus
        id="companyName"
        name="company_name"
        label={translate('companyName')}
        onChange={changeMethod(handleAddressChange)}
        style={{ width: '100%' }}
        value={address.company_name}
        invalid={!validateField('company_name')}
        width={'100%'}
        required
      />
      <LabeledInput
        label={translate('attention')}
        id="attention"
        name="attention"
        onChange={changeMethod(handleAddressChange)}
        style={{ width: '100%' }}
        value={address.attention}
        width={'100%'}
      />
      <LabeledInput
        autoComplete="address-line1"
        label={translate('address')}
        id="address1"
        name="address1"
        onBlur={changeMethod(handleBlur)}
        onChange={changeMethod(handleAddressChange)}
        style={{ width: '100%' }}
        value={address.address1}
        invalid={!validateField('address1')}
        width={'100%'}
        required
      />
      <LabeledInput
        autoComplete="address-line2"
        label={translate('address2')}
        id="address2"
        name="address2"
        onChange={changeMethod(handleAddressChange)}
        secondary={translate('address2Helper')}
        style={{ width: '100%' }}
        value={address.address2}
        width={'100%'}
      />
      <LabeledSelect
        autoComplete="country"
        label={translate('country')}
        id="country"
        name="country"
        onBlur={changeMethod(handleBlur, 'country')}
        onChange={changeMethod(handleAddressChange, 'country')}
        style={{ width: '100%', marginBottom: 15 }}
        options={COUNTRIES}
        invalid={!validateField('country')}
        value={address.country}
        width={'100%'}
        required
      />
      <LabeledInput
        autoComplete="postal-code"
        invalid={!validateField('post_code')}
        label={translate('zipPostalCode')}
        id="zipPostalCode"
        name="post_code"
        onBlur={changeMethod(handleBlur)}
        onChange={changeMethod(handleAddressChange)}
        required={validations.post_code}
        value={address.post_code}
        width={'96px'}
      />
      <LabeledInput
        autoComplete="address-level2"
        invalid={!validateField('city')}
        label={translate('city')}
        id="city"
        name="city"
        onBlur={changeMethod(handleBlur)}
        onChange={changeMethod(handleAddressChange)}
        style={{ width: '100%' }}
        value={address.city}
        width={'100%'}
        required
      />
      <LabeledInput
        autoComplete="region"
        invalid={!validateField('region')}
        country={address.country}
        countryValueType="short"
        id="stateProvidenceRegion"
        name="region"
        label={translate('stateProvidenceRegion')}
        onBlur={changeMethod(handleBlur, 'region')}
        onChange={changeMethod(handleAddressChange, 'region')}
        regionInput
        value={address.region}
        valueType="short"
        required
      />
      <LabeledInput
        autoComplete="tel"
        label={translate('phone')}
        id="phoneNumber"
        name="phone_number"
        onChange={changeMethod(handleAddressChange)}
        style={{ width: '100%' }}
        value={address.phone_number}
        width={'100%'}
      />
      <DenotesRequired>
        <span>*&nbsp;</span>Indicates required field
      </DenotesRequired>
      {!isExistingAddress && !isInvoiceAddressAdd && (
        <div style={{ marginBottom: '15px' }}>
          <label>
            <StyledCheckbox name="saveToAccount" checked={saveToAccount} onChange={changeMethod(handleChange)} />
            {translate('saveAddress')}
          </label>
        </div>
      )}
      <SpaceBetweenWrapper>
        {!isInvoiceAddressAdd && <SaveButton disabled={!valid} onClick={saveAddress} />}
        {(showPlaceOrder || showPlaceOrderOnCheckout) && (
          <PlaceNewOrderButton
            isInvoiceAddressAdd
            disabled={!valid || !isOrderEnabled}
            onClick={isInvoiceAddressAdd ? placeOrder : placeNewOrder}
            style={{ marginLeft: isExistingAddress ? 0 : 15 }}
          />
        )}
      </SpaceBetweenWrapper>
      {showDefaultErrorMessage && <Message color="darkred">{translate('anErrorOccurred')}</Message>}
    </Dialog>
  );
};

export default AddressForm;
