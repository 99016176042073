import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import { actions } from 'modules/user/actions';
import { selectors } from 'modules/user/selectors';

class AuthGate extends PureComponent {
  componentDidMount() {
    const { user } = this.props;
    if (!user) this.props.userAuth();
  }

  render() {
    const { user, loading, children } = this.props;
    const showLoading = loading || !user;

    if (showLoading) return null;

    return children;
  }
}

const mapState = state => ({
  user: selectors.details(state),
  loading: selectors.loading(state),
});

const mapDispatch = { userAuth: actions.userAuth };

export default compose(withRouter, connect(mapState, mapDispatch))(AuthGate);
