import React from 'react';

import AddressCandidatesForm from 'components/AddressCandidatesForm';
import AddressForm from 'components/AddressForm';
import Button, { PlaceNewOrderButton } from 'components/Buttons';
import ItemManager from 'components/ItemManager';
import PanelContainer from 'components/Panel';
import { translate } from 'utils/translations';

import ShippingInfoItem from './ShippingInfoItem';

const ShippingInfo = ({
  actions,
  addresses,
  billingInfo,
  defaultAddressId,
  isCsr,
  isCustomer,
  isOrderEnabled,
  openAddressDialog,
  noValidAddressToast,
  placeNewOrder,
  selectedAddressId,
  hasOrder,
  continueOrder,
  showCandidatesDialog,
  showDialog,
}) => {
  const { hasValidInvoiceAddress } = billingInfo;

  const verifyNewOrderIsPlaceable = () => {
    if (!isCustomer || (isCsr && !hasValidInvoiceAddress)) {
      noValidAddressToast();
      return;
    }

    if (isCustomer || (isCsr && hasValidInvoiceAddress)) {
      placeNewOrder();
    }
  };

  return (
    <PanelContainer title={translate('shippingInfo')}>
      <ItemManager
        items={addresses}
        defaultAddressId={defaultAddressId}
        actions={actions}
        component={ShippingInfoItem}
        selectedItem={selectedAddressId}
        addItem={() => openAddressDialog()}
        addItemText={translate('addNewAddress', 'Add New Address')}
      />
      <PlaceNewOrderButton inline onClick={verifyNewOrderIsPlaceable} disabled={!isOrderEnabled} />
      <Button style={{ marginLeft: 10 }} inline onClick={continueOrder} disabled={!hasOrder || !isOrderEnabled}>
        <i className="fa fa-arrow-right" /> {translate('resumeOrder', 'Resume Order')}
      </Button>
      {showDialog && <AddressForm />}
      {showCandidatesDialog && <AddressCandidatesForm />}
    </PanelContainer>
  );
};

export default ShippingInfo;
