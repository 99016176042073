import React from 'react';
import styled from 'styled-components';

import { colors } from 'style/theme';

import { BarcodesSearch, LabcodesSearch, OrderNumberSearch } from './SearchFields';

const StyledSearch = styled.div`
  margin: 0 0 20px;

  .Search--csr-only {
    padding: 10px;
    width: fit-content;
    background-color: ${colors.blueHawkes};
    border: 1px solid ${colors.blueColumbia};
  }

  .Search--padding {
    padding-left: 10px;
    margin-bottom: 10px;
  }
`;

const RushSampleSearch = ({ data, isCsr, loading, selected, onChange, onSearch, onSelect }) => {
  const sharedProps = { isCsr, loading, selected, onChange, onSearch, onSelect };

  return (
    <StyledSearch>
      <div className="Search--padding">
        <BarcodesSearch {...sharedProps} data={data.barcodes || {}} />
      </div>
      <div className="Search--padding">
        <LabcodesSearch {...sharedProps} data={data.labcodes || {}} />
      </div>
      {isCsr && (
        <div className="Search--csr-only">
          <OrderNumberSearch {...sharedProps} data={data.orderNumber || ''} />
        </div>
      )}
    </StyledSearch>
  );
};

export default RushSampleSearch;
