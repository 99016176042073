import get from 'lodash/get';
import { createActions, handleActions } from 'redux-actions';
import { all, takeLatest, call, put, select } from 'redux-saga/effects';

import { BILLING_TYPE } from 'constants/strings';
import * as api from 'lib/api';
import { actions as detailsActions } from 'modules/account/details/actions';
import { selectors as detailsSelectors } from 'modules/account/details/selectors';
import { actions as toastActions } from 'modules/toast/actions';

const ERROR_TOAST = 'ERROR_TOAST';

// default state
const defaultState = {
  showDialog: false,
};

// actions
export const actions = createActions(
  'OPEN_BILLING_ADDRESS_DIALOG',
  'CLOSE_ADDRESS_DIALOG',
  'SAVE_BILLING_ADDRESS',
  'SAVE_BILLING_ADDRESS_RESPONSE'
);

// selectors
export const selectors = {
  showDialog: state => get(state, 'account.billingAddress.showDialog'),
};

// sagas
function* saveAddressSaga({ payload: { address: saveAddress } }) {
  try {
    const { company_name, phone_number, ...addressDetails } = saveAddress;
    const { number } = yield select(detailsSelectors.details);

    const endpoint = saveAddress.id ? api.updateAddress : api.createAddress;

    const { address } = yield call(endpoint, { ...addressDetails, company_name, address_type: BILLING_TYPE });
    yield call(api.updateBillingInfo, { id: number, name: company_name, phone: phone_number });

    yield put(actions.saveBillingAddressResponse(address));
    yield put(detailsActions.fetchBillingInfo(number));
  } catch (e) {
    yield put(actions.saveBillingAddressResponse(e));
    yield put(
      toastActions.createToast('alert', {
        id: ERROR_TOAST,
        message: e.message,
        timeout: false,
        title: 'store.error',
      })
    );
  }
}

export function* billingAddressSaga() {
  yield all([takeLatest(actions.saveBillingAddress, saveAddressSaga)]);
}

// reducer
export default handleActions(
  {
    [actions.openBillingAddressDialog](state) {
      return {
        ...state,
        showDialog: true,
      };
    },
    [actions.closeAddressDialog](state) {
      return {
        ...state,
        showDialog: false,
      };
    },
    [actions.saveBillingAddressResponse]: {
      next(state) {
        return {
          ...state,
          showDialog: false,
        };
      },
      throw() {
        return { ...defaultState };
      },
    },
  },
  defaultState
);
