import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import { handleActions } from 'redux-actions';
import { all, takeLatest, call, put, select } from 'redux-saga/effects';

import { CATEGORY_TITLES } from 'constants/categories';
import * as api from 'lib/api';
import { createStateObject } from 'modules/helpers';
import ordersSelectors from 'modules/order/selectors';

import { actions } from './actions';
import { selectors } from './selectors';

// default state
const defaultState = {
  ...createStateObject('item', {}, { categories: [] }),
  ...createStateObject('productsMap', {}),
  ...createStateObject('products', []),
};

const mapProductListItems = items => {
  return groupBy(items, item => {
    return item.listable_type === 'kits' ? item.unitType.label : CATEGORY_TITLES[item.category];
  });
};

// sagas
function* fetchProductListItemSaga({ payload: id }) {
  try {
    const orderId = yield select(ordersSelectors.id);
    const { productListItem } = yield call(api.fetchProductListItem, id, orderId);
    yield put(actions.fetchProductListItemResponse(productListItem));
  } catch (e) {
    yield put(actions.fetchProductListItemResponse(e));
  }
}

function* fetchProductListItemsSaga({ payload: categories }) {
  try {
    const orderId = yield select(ordersSelectors.id);
    const products = yield select(selectors.products);

    let data = {};
    if (!isEqual(products.categories, categories)) {
      const { productListItems } = yield call(api.fetchProductListItems, orderId, categories);
      data = mapProductListItems(productListItems);
    } else {
      data = products.data;
    }

    yield put(actions.fetchProductListItemsResponse({ categories, data }));
  } catch (e) {
    yield put(actions.fetchProductListItemsResponse(e));
  }
}

function* fetchProductListItemsSimpleSaga({ payload: categories }) {
  try {
    const orderId = yield select(ordersSelectors.id);
    const { productListItems } = yield call(api.fetchProductListItemsSimple, orderId, categories);
    const response = mapProductListItems(productListItems);
    yield put(actions.fetchProductListItemsSimpleResponse(response));
  } catch (e) {
    yield put(actions.fetchProductListItemsSimpleResponse(e));
  }
}

export function* inventorySaga() {
  yield all([
    takeLatest(actions.fetchProductListItem, fetchProductListItemSaga),
    takeLatest(actions.fetchProductListItems, fetchProductListItemsSaga),
    takeLatest(actions.fetchProductListItemsSimple, fetchProductListItemsSimpleSaga),
  ]);
}

export { actions } from './actions';
export { selectors } from './selectors';

// reducers
export default handleActions(
  {
    // Fetch Product List Items
    [actions.fetchProductListItems](state) {
      return {
        ...state,
        products: {
          ...defaultState.products,
          categories: state.products.categories,
          data: state.products.data,
        },
      };
    },
    [actions.fetchProductListItemsResponse]: {
      next(state, { payload }) {
        const { categories, data } = payload;
        return {
          ...state,
          products: {
            ...defaultState.products,
            categories: categories,
            data: data,
            loading: false,
          },
        };
      },
      throw(state) {
        return {
          ...state,
          products: {
            ...defaultState.products,
            error: true,
            loading: false,
          },
        };
      },
    },

    // Fetch Product List Items (Simple Sidebar)
    [actions.fetchProductListItemsSimple](state) {
      return {
        ...state,
        productsMap: defaultState.productsMap,
      };
    },
    [actions.fetchProductListItemsSimpleResponse]: {
      next(state, { payload }) {
        return {
          ...state,
          productsMap: {
            ...defaultState.productsMap,
            data: payload,
            loading: false,
          },
        };
      },
      throw(state) {
        return {
          ...state,
          productsMap: {
            ...defaultState.productsMap,
            error: true,
            loading: false,
          },
        };
      },
    },

    // Fetch Product List Item
    [actions.fetchProductListItem](state) {
      return {
        ...state,
        item: defaultState.item,
      };
    },
    [actions.fetchProductListItemResponse]: {
      next(state, { payload }) {
        return {
          ...state,
          item: {
            ...defaultState.item,
            data: payload,
            loading: false,
          },
        };
      },
      throw(state) {
        return {
          ...state,
          item: {
            ...defaultState.item,
            error: true,
            loading: false,
          },
        };
      },
    },

    // Clear Product Lists
    [actions.clearProductLists]() {
      return {
        ...defaultState,
      };
    },
  },
  defaultState
);
