import React from 'react';

import { Breadcrumbs, PreviousOrder, Tabs } from 'components/Account';

const PreviousOrderLayout = ({ match }) => {
  return (
    <>
      <Breadcrumbs />
      <Tabs />
      <PreviousOrder />
    </>
  );
};

export default PreviousOrderLayout;
