import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';

import Button from 'components/Buttons';
import Errors from 'components/Errors';
import Hider from 'components/Hider';
import Input from 'components/Input';
import { colors } from 'style/theme';
import { translate } from 'utils/translations';

import SearchForm from './SearchForm';

const CsrBox = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  background-color: ${colors.blueHawkes};
  border: 1px solid ${colors.blueColumbia};
`;

const VALID_LENGTH = 11;
const VALID_LETTER_POSITIONS = [0, 5];
const INVALID_LETTER = 'Only 1st & 6th can be letters';
const INVALID_FORMAT = 'Invalid Barcode Format';
const MISMATCH_FORMAT = 'Barcode formats must match';
const START = 'barcodeStart';
const END = 'barcodeEnd';

const REGEX_FORMAT_ONE = /^\d{5}(?![io])[a-z]{1}\d{5}$/i;
const REGEX_FORMAT_TWO = /^\d{11}$/i;
const REGEX_FORMAT_THREE = /^[a-z]{1}\d{10}$/i;

const validFormatOne = (barcode = '') => REGEX_FORMAT_ONE.test(barcode);
const validFormatTwo = (barcode = '') => REGEX_FORMAT_TWO.test(barcode);
const validFormatThree = (barcode = '') => REGEX_FORMAT_THREE.test(barcode);
const validLength = barcode => barcode.length === VALID_LENGTH;
const validLetters = (barcode = '') => {
  return !barcode.split('').some((char, i) => {
    const isNumberPosition = !VALID_LETTER_POSITIONS.includes(i);
    const isLetter = char.match(/[a-z]/i);

    if (isNumberPosition && isLetter) return true;
    return false;
  });
};

const InputWrapper = ({ errors, touched, ...props }) => (
  <div style={{ position: 'relative' }}>
    <Input type="text" invalid={touched && errors.length > 0} inline {...props} />
    {touched && errors.length > 0 && <Errors data={errors} />}
  </div>
);

const BarcodesSearch = ({ data, isCsr, loading, selected, onChange, onSearch, onSelect }) => {
  const [touched, setTouched] = useState({ [START]: false, [END]: false });
  const [validation, setValidation] = useState({
    [START]: [],
    [END]: [],
    valid: false,
  });

  useEffect(() => {
    setTouched({ [START]: false, [END]: false });
    setValidation({
      [START]: [],
      [END]: [],
      valid: false,
    });
  }, [selected]);

  const validateSingle = useCallback(barcode => {
    const errors = [];

    if (!barcode.length) return { valid: false, errors: [] };

    if (!validLetters(barcode)) errors.push(translate('onlyOneAndSixthAreLetters', INVALID_LETTER));

    if (!validLength(barcode)) {
      errors.push(translate('validationMinLength', '{len} character minimum', { len: VALID_LENGTH }));
    }

    const validFormats = validFormatOne(barcode) || validFormatTwo(barcode) || validFormatThree(barcode);
    if (!validFormats) errors.push(translate('invalidBarcodeFormat', INVALID_FORMAT));

    return { valid: errors.length === 0, errors };
  }, []);

  const validate = useCallback(
    barcodes => {
      const startBarcode = barcodes[START] || '';
      const endBarcode = barcodes[END] || '';

      const startValidation = validateSingle(startBarcode);
      const endValidation = validateSingle(endBarcode);

      const errors = {
        [START]: startValidation.errors,
        [END]: [],
      };

      if (endBarcode.length > 0) errors[END] = endValidation.errors;

      // Combo Validation
      let validCombo = true;
      if (startBarcode.length > 0 && endBarcode.length > 0) {
        validCombo = false;
        if (validFormatOne(startBarcode) && validFormatOne(endBarcode)) validCombo = true;
        if (validFormatTwo(startBarcode) && validFormatTwo(endBarcode)) validCombo = true;
        if (validFormatThree(startBarcode) && validFormatThree(endBarcode)) validCombo = true;
        if (!validCombo) errors[END].push(translate('barcodeFormatMustMatch', MISMATCH_FORMAT));
      }

      const startValid = startValidation.valid;
      const endValid = endBarcode.length > 0 ? endValidation.valid : startValid;
      const valid = startValid && endValid && validCombo;
      return { ...errors, valid };
    },
    [validateSingle]
  );

  useEffect(() => {
    const currentValidation = validate(data);
    setValidation(currentValidation);
  }, [data, validate]);

  const onSubmit = useCallback(() => {
    const dataSubmit = {
      [START]: data[START],
      [END]: data[END] || data[START],
    };
    onSearch({ type: 'barcodes', data: dataSubmit });
  }, [data, onSearch]);

  const onBlur = useCallback(
    e => {
      const { name } = e.target;
      const currentValidation = validate(data);
      setValidation(currentValidation);
      setTouched(t => ({ ...t, [name]: true }));
    },
    [data, validate]
  );

  const onBarcodesChange = useCallback(
    e => {
      const { name, value } = e.target;
      onChange({ type: 'barcodes', data: { ...data, [name]: value } });
    },
    [data, onChange]
  );

  return (
    <SearchForm onSubmit={onSubmit} noValidate>
      <label className="Search__label">
        <div className="Search__input">
          <input type="radio" checked={selected === 'barcodes'} onChange={() => onSelect('barcodes')} />
        </div>
        <div className="Search__input-label">
          {isCsr ? translate('barcodes', 'Barcodes') : translate('barcode', 'Barcode')}
        </div>
      </label>
      <Hider hidden={selected !== 'barcodes'}>
        <div className="Search__content">
          <div className="Search__content-label">{isCsr && translate('from', 'from')}</div>
          <InputWrapper
            name={START}
            errors={validation[START]}
            touched={touched[START]}
            value={data[START] || ''}
            maxLength={VALID_LENGTH}
            onBlur={onBlur}
            onChange={onBarcodesChange}
          />
          {isCsr && (
            <CsrBox>
              <div className="Search__content-label">to</div>
              <InputWrapper
                name={END}
                errors={validation[END]}
                touched={touched[END]}
                value={data[END] || ''}
                maxLength={VALID_LENGTH}
                onBlur={onBlur}
                onChange={onBarcodesChange}
              />
            </CsrBox>
          )}
        </div>
        <div className="Search__action">
          <Button success icon="search" disabled={loading || !validation.valid} type="submit" />
        </div>
      </Hider>
    </SearchForm>
  );
};

export default BarcodesSearch;
