import io from 'socket.io-client';

import * as config from 'config';

const socket = io(config.SOCKET_ENDPOINT, { autoConnect: false });

const checkForSocket = () => (!socket || !socket.connected) && socket.open();

socket.on('connect', () => {
  console.log('connecting to socket');
});

export { checkForSocket };
export default socket;
