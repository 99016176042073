import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Button from 'components/Buttons';
import { OverrideDialog } from 'components/Checkout';
import { OverrideTable } from 'components/Order';
import PanelContainer from 'components/Panel';
import { translate } from 'utils/translations';

const Title = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 15px;

  .Title__name {
    font-size: 14px;
    font-weight: 700;
    margin-right: 10px;
  }

  .Title__actions {
    margin-left: auto;
  }
`;

const Summary = ({ openOverrideDialog = noop, showOverrideDialog, isCsr }) => (
  <PanelContainer
    title={
      <Title>
        <div className="Title__name">{translate('checkout.orderSummary', 'Order Summary')}</div>
        {isCsr && (
          <div className="Title__actions">
            <Button icon="refresh" info onClick={openOverrideDialog}>
              {translate('checkout.costOverride', 'Cost Override')}
            </Button>
          </div>
        )}
      </Title>
    }
    style={{ margin: '0 0 15px' }}
    collapse
    collapseTitle
  >
    <OverrideTable showOverridePrice={isCsr} />
    {isCsr && showOverrideDialog && <OverrideDialog />}
  </PanelContainer>
);

Summary.propTypes = {
  openOverrideDialog: PropTypes.func,
};

export default Summary;
