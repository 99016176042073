import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import AddressCandidatesForm from 'components/AddressCandidatesForm';
import AddressForm from 'components/AddressForm';
import { ActionButton } from 'components/Buttons';
import Input from 'components/Input';
import PanelContainer from 'components/Panel';
import RowItem from 'components/RowItem';
import { changeMethod } from 'utils/common';
import { translate } from 'utils/translations';

const Title = styled.div`
  display: flex;
  align-items: center;

  .Title__action {
    margin-left: 10px;
  }
`;

const ShippingAddress = ({ address, fieldUpdate, onEdit = noop, orderAddress, showCandidatesDialog, showDialog }) => {
  return (
    <PanelContainer
      title={
        <Title>
          <div className="Title__base">{translate('store.shippingAddress', 'Shipping Address')}</div>
          <div className="Title__action">
            <ActionButton onClick={() => onEdit(orderAddress)}>
              <i className="fa fa-pencil" />
            </ActionButton>
          </div>
        </Title>
      }
    >
      {!orderAddress && (
        <div style={{ textAlign: 'center', padding: '15px' }}>
          {translate('store.noAddressDetails', 'Address Details Not Available')}
        </div>
      )}
      {orderAddress && (
        <div>
          <RowItem title={translate('store.companyName', 'Company Name')}>{orderAddress.company_name}</RowItem>
          <RowItem title={translate('store.attention', 'Attention')}>{orderAddress.attention}</RowItem>
          <RowItem title={translate('store.address', 'Address')}>
            <div>{orderAddress.address1}</div>
            {orderAddress.address2 && <div>{orderAddress.address2}</div>}
            <div>
              {orderAddress.city}, {orderAddress.region} {orderAddress.post_code}
            </div>
            <div>{orderAddress.country}</div>
          </RowItem>
          <RowItem title={translate('phone', 'Phone')}>
            <Input
              name="phone_number"
              onChange={changeMethod(fieldUpdate)}
              value={address.phone_number || orderAddress.phone_number || ''}
            />
          </RowItem>
        </div>
      )}
      {showDialog && <AddressForm isCheckout />}
      {showCandidatesDialog && <AddressCandidatesForm isCheckout />}
    </PanelContainer>
  );
};

ShippingAddress.propTypes = {
  address: PropTypes.shape({
    attention: PropTypes.string,
    company_name: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    post_code: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default ShippingAddress;
