import React from 'react';
import styled from 'styled-components';

import { colors } from 'style/theme';

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  color: ${colors.grayEmperor};
  font-size: 12px;
  min-height: 30px;
  margin: 0 0 10px;

  &:last-child {
    margin: 0;
  }

  .Search__label {
    display: flex;
    align-items: center;
  }

  .Search__input {
    display: flex;
    margin-right: 8px;

    &-label {
      margin-right: 8px;
    }
  }

  .Search__content {
    display: flex;
    align-items: center;

    &-label {
      margin: 0 8px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .Search__action {
    margin-left: 10px;
  }
`;

const SearchForm = ({ onSubmit, ...props }) => {
  const submitEvent = e => {
    onSubmit();
    e.preventDefault();
  };

  return <StyledForm onSubmit={submitEvent} {...props} />;
};

export default SearchForm;
