const outboundRushSampleMapper = jarLabels => {
  return jarLabels.map(jarLabel => ({
    barcode: jarLabel.barcode,
    countryId: jarLabel.countryId,
    fee: jarLabel.fee,
    id: jarLabel.id,
    waived: jarLabel.waived,
  }));
};

export default outboundRushSampleMapper;
