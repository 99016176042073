import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ClearButton, SearchButton } from 'components/Buttons';
import Dialog from 'components/Dialog';
import LabeledInput, { validateLength } from 'components/LabeledInput';
import { colors } from 'style/theme';
import { translate } from 'utils/translations';

const Form = styled.form`
  display: flex;
  align-items: flex-end;
`;

const Message = styled.div`
  font-size: 14px;
  padding: 3px 0;
  margin-top: 1rem;
  ${props => props.color && `color: ${props.color};`};
`;

const ResultsTable = styled.table`
  background: ${colors.whiteAlabaster};
  width: 100%;
  font-size: 12px;

  th {
    text-align: left;
    color: ${colors.grayEmperor};
    padding: 15px;
  }

  td {
    padding: 5px 15px;
  }

  > tbody {
    > tr {
      &:nth-child(odd) {
        background: ${colors.whiteGallery};
      }

      > td {
        &:first-child {
          color: ${colors.blueWater};

          > span {
            cursor: pointer;
          }
        }

        &:nth-child(2) {
          text-transform: uppercase;
        }
      }
    }
  }
`;

const UserSearchDialog = ({
  error,
  loading,
  onClose,
  onReset,
  users = [],
  searchText,
  searchUsers,
  setSearchText,
  selectEmail,
  showResults,
}) => (
  <Dialog onClose={onClose} locked modalStyle={{ width: 1150, maxWidth: '90vw' }} header={translate('emailLookup')}>
    <div>
      <Form onSubmit={searchUsers}>
        <LabeledInput
          label={translate('searchText')}
          autoFocus
          width={200}
          style={{ width: '200px', margin: 0 }}
          value={searchText}
          onChange={setSearchText}
          invalid={validateLength(searchText, 3)}
        />
        <SearchButton type="submit" style={{ marginLeft: 10 }} />
        <ClearButton onClick={onReset} icon="close" style={{ marginLeft: 10 }} />
      </Form>
    </div>
    {error && <Message color="darkred">{translate('anErrorOccurred')}</Message>}
    {loading && <Message>{translate('searching')}...</Message>}
    {showResults && (
      <ResultsTable style={{ marginTop: 25 }}>
        <thead>
          <tr>
            <th>{translate('emailAddress')}</th>
            <th>{translate('contactName')}</th>
            <th>{translate('assignedAccountMask')}</th>
            <th>{translate('profileStatus')}</th>
            <th>{translate('lastLoggedIn')}</th>
            <th>{translate('termsAcceptanceDate')}</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 &&
            users.map(user => (
              <tr key={user.id}>
                <td>
                  <span onClick={() => selectEmail(user.username)}>{user.username}</span>
                </td>
                <td>{user.name}</td>
                <td>
                  {user.masks.map(mask => (
                    <div key={mask}>{mask}</div>
                  ))}
                </td>
                <td>{user.status}</td>
                <td>{user.lastLoginDate}</td>
                <td>{user.termsAcceptanceDate}</td>
              </tr>
            ))}
        </tbody>
      </ResultsTable>
    )}
  </Dialog>
);

UserSearchDialog.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  selectEmail: PropTypes.func.isRequired,
  searchUsers: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  showResults: PropTypes.bool,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      lastLoginDate: PropTypes.string,
      termsAcceptanceDate: PropTypes.string,
      masks: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};

export default UserSearchDialog;
