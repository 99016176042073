import { LOCATION_CHANGE, push } from 'connected-react-router';
import differenceBy from 'lodash/differenceBy';
import find from 'lodash/find';
import pick from 'lodash/pick';
import some from 'lodash/some';
import sortBy from 'lodash/sortBy';
import unionBy from 'lodash/unionBy';
import { combineReducers } from 'redux';
import { combineActions, handleActions } from 'redux-actions';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import * as api from 'lib/api';
import { selectors as accountSelectors } from 'modules/account/details/selectors';
import { selectors as settingsSelectors } from 'modules/account/settings/selectors';
import { actions as shippingAddressesActions } from 'modules/account/shippingAddresses/actions';
import { actions as orderActions } from 'modules/order/actions';
import { selectors as orderSelectors } from 'modules/order/selectors';
import { actions as toastActions } from 'modules/toast/actions';
import { actions as userActions } from 'modules/user/actions';
import simplifyItem from 'utils/simplifyItem';
import { allValid } from 'utils/validation';

import { actions } from './actions';
import selectors from './selectors';

const CONFIRMATION_ORDER_ADDRESS_TOAST = 'CONFIRMATION_ORDER_ADDRESS_TOAST';

// state
const defaultState = {
  confirmation: {
    data: {},
    error: false,
    loading: false,
  },
  csr: {
    labelLanguage: null,
    loading: false,
    jiraTicketNumber: '',
    languages: [],
    locations: [],
    prefillComponentId: '',
    returnLabLocation: null,
    mustShipToday: false,
    specialInstructions: '',
  },
  override: {
    discount: 0,
    error: false,
    items: null,
    loading: false,
    shippingPrice: 0,
    showDialog: false,
    subtotal: 0,
    tax: 0,
    total: 0,
  },
  payment: {
    creditCardId: 'create',
    paymentMethod: 'invoice',
    purchaseOrderNumber: '',
    customerInfo: {
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    },
    creditCardInfo: {
      cardNumber: '',
      expirationDate: '',
      cvv: '',
    },
    touched: {},
    valid: false,
  },
  referenceInfo: {
    editedEmail: '',
    editedEmailId: null,
    loading: false,
    newEmail: '',
    notificationEmails: [],
    orderPlacedBy: '',
    orderPlacedFor: '',
    referencePO: '',
    showAddEmail: false,
  },
  shipping: {
    carrierAccount: '',
    customShipDate: null,
    freightSelected: false,
    isFreight: false,
    isSplitBill: false,
    shipDate: null,
    shipperPreference: null,
    shippingMethod: null,
    valid: false,
  },
  shippingAddress: {
    phone_number: '',
  },
};

// Sagas
function* createOverrideItemsSaga() {
  const summary = yield select(orderSelectors.summary);
  if (summary) yield put(actions.setOverrideItems(summary));
}

function* deleteEmailSaga({ payload: { id } }) {
  try {
    const data = yield call(api.deleteEmailNotification, id);
    if (data) {
      yield put(actions.deleteEmailResponse(id));
    }
  } catch (e) {
    yield put(actions.deleteEmailResponse(e));
  }
}

function* fetchNotificationEmailsSaga() {
  try {
    const { email_notification } = yield select(settingsSelectors.allSettings);
    const orderId = yield select(orderSelectors.id);
    const data = yield call(api.fetchNotificationEmails, orderId);

    if (data) {
      yield put(actions.notificationEmailsResponse(data));

      const emailAdded = some(data.emails, { email: email_notification });
      if (email_notification && email_notification.toLowerCase() !== 'none' && !emailAdded) {
        yield put(actions.saveEmail({ email: email_notification }));
      }
    }
  } catch (e) {
    yield put(actions.notificationEmailsResponse(e));
  }
}

function* fetchSettingsOptionsSaga() {
  try {
    const data = yield call(api.fetchSettingsOptions);
    if (data) {
      yield put(actions.settingsOptionsResponse(data));
    }
  } catch (e) {
    yield put(actions.settingsOptionsResponse(e));
  }
}

function* placeOrderSaga() {
  try {
    const orderId = yield select(orderSelectors.id);
    const orderData = yield select(selectors.all);
    const accountNumber = yield select(accountSelectors.accountNumber);
    const data = yield call(api.placeOrder, orderId, orderData);
    if (data) {
      yield put(actions.placeOrderResponse(data));
      yield put(orderActions.checkForExistingOrder());
      yield put(push(`/accounts/${accountNumber}/order/confirmation`));
    }
  } catch (e) {
    yield put(actions.placeOrderResponse(e));
  }
}

function* emailInvoiceAddressSaga({ payload }) {
  const orderId = yield select(orderSelectors.id);

  try {
    yield call(api.emailInvoiceAddress, orderId, payload);
  } catch (e) {
    console.log(e);
  }
}

function* saveEmailSaga({ payload: { email, id } }) {
  const orderId = yield select(orderSelectors.id);
  const endpoint = id ? api.editEmailNotification : api.createEmailNotification;

  try {
    const data = yield call(endpoint, { email, orderId }, id);
    if (data) {
      yield put(actions.saveEmailResponse(data));
    }
  } catch (e) {
    yield put(actions.saveEmailResponse(e));
  }
}

function* saveOverrideSaga() {
  const { items: overrideItems } = yield select(selectors.override);
  const { items, orderId } = yield select(orderSelectors.summary);
  const accountNumber = yield select(accountSelectors.accountNumber);

  const changedItems = differenceBy(overrideItems, items, 'csrOverride');

  if (changedItems.length > 0) {
    try {
      const formattedItems = changedItems.map(item => pick(item, ['id', 'csrOverride']));
      const data = yield call(api.saveOverride, accountNumber, orderId, { items: formattedItems });
      if (data) {
        yield put(orderActions.csrOverrideResponse(data));
        yield put(actions.saveOverrideResponse(data));
      }
    } catch (e) {
      yield put(actions.saveOverrideResponse(e));
    }
  } else {
    yield put(actions.saveOverrideResponse({}));
  }
}

const modifyOrderSaga = function* ({ payload: { isDetails = false, orderId, ...payload } }) {
  try {
    const { order } = yield call(isDetails ? api.modifyOrder : api.modifyCsrInfo, orderId, payload);
    const { language } = yield select(accountSelectors.details);
    yield put(actions.modifyConfirmationOrderResponse({ response: order, language }));
  } catch (e) {
    console.log(e);
    yield put(actions.modifyConfirmationOrderResponse(e));
  }
};

function* updateConfirmationOrderAddressSaga({ payload: { address, skipValidation } }) {
  try {
    const { language } = yield select(accountSelectors.details);
    const { id: orderId } = yield select(selectors.confirmationData);

    const response = yield call(api.updateOrderAddress, orderId, address.id, address, skipValidation);
    if (!response.showCandidates) {
      yield put(actions.updateConfirmationOrderAddressResponse({ response: response.order, language }));
      yield put(shippingAddressesActions.closeAllAddressDialogs());
    } else {
      yield put(shippingAddressesActions.validateAddressResponse(response));
    }
  } catch (e) {
    console.error(e.message);
    yield put(
      toastActions.createToast('alert', {
        id: CONFIRMATION_ORDER_ADDRESS_TOAST,
        translation: true,
        message: e.message,
        timeout: false,
        title: 'store.error',
      })
    );
    yield put(shippingAddressesActions.closeAllAddressDialogs());
  }
}

export function* checkoutSaga() {
  yield all([
    takeLatest(actions.createOverrideItems, createOverrideItemsSaga),
    takeLatest(actions.deleteEmail, deleteEmailSaga),
    takeLatest(actions.emailInvoiceAddress, emailInvoiceAddressSaga),
    takeLatest(actions.fetchNotificationEmails, fetchNotificationEmailsSaga),
    takeLatest(actions.fetchSettingsOptions, fetchSettingsOptionsSaga),
    takeLatest(actions.modifyConfirmationOrder, modifyOrderSaga),
    takeLatest(actions.placeOrder, placeOrderSaga),
    takeLatest(actions.saveEmail, saveEmailSaga),
    takeLatest(actions.saveOverride, saveOverrideSaga),
    takeLatest(actions.updateConfirmationOrderAddress, updateConfirmationOrderAddressSaga),
  ]);
}

// Reducers
const confirmation = handleActions(
  {
    [LOCATION_CHANGE](state) {
      return { ...state, error: false };
    },
    [actions.placeOrder](state) {
      return { ...state, error: false, loading: true };
    },
    [actions.placeOrderResponse]: {
      next(state, { payload }) {
        const { order } = payload;
        const { items, productList } = order;

        return {
          ...state,
          data: {
            ...payload.order,
            items: items && items.map(item => simplifyItem(item, payload.language, productList)),
          },
          loading: false,
        };
      },
      throw(state, { payload: e }) {
        return { ...state, loading: false, error: e.message };
      },
    },
    [combineActions(actions.modifyConfirmationOrder, actions.updateConfirmationOrderAddress)](state) {
      return { ...state, loading: true };
    },
    [combineActions(actions.modifyConfirmationOrderResponse, actions.updateConfirmationOrderAddressResponse)]: {
      next(state, { payload }) {
        const { items, productList } = payload.response;
        const data = {
          ...payload.response,
          items: items && items.map(item => simplifyItem(item, payload.language, productList)),
        };

        return { ...defaultState, data, loading: false };
      },
      throw() {
        return { ...defaultState, invalid: true };
      },
    },
  },
  defaultState.confirmation
);

const csr = handleActions(
  {
    [actions.csrUpdate]: {
      next(state, { payload: update }) {
        return { ...state, ...update };
      },
      throw(state) {
        return { ...state, error: true };
      },
    },
    [actions.fetchSettingsOptions]: {
      next(state) {
        return { ...state, loading: true };
      },
      throw(state) {
        return { ...state, error: true };
      },
    },
    [actions.settingsOptionsResponse]: {
      next(state, { payload }) {
        const { languages, locations } = payload;
        return {
          ...state,
          languages: languages.map(lang => ({ label: lang.name, value: lang.code })),
          loading: false,
          locations: locations.map(loc => ({ label: loc.name, value: loc.code })),
        };
      },
      throw(state) {
        return { ...state, error: true };
      },
    },
    [actions.placeOrderResponse]: {
      next() {
        return defaultState.csr;
      },
    },
  },
  defaultState.csr
);

const override = handleActions(
  {
    [actions.openOverrideDialog](state) {
      return { ...state, showDialog: true };
    },
    [actions.closeOverrideDialog](state) {
      return { ...defaultState.override, showDialog: false };
    },
    [actions.saveOverride](state) {
      return { ...state, loading: true };
    },
    [actions.saveOverrideResponse]: {
      next() {
        return { ...defaultState.override, showDialog: false, loading: false };
      },
      throw(state) {
        return { ...state, error: true };
      },
    },
    [actions.setOverrideItems](state, { payload: orderSummary }) {
      const { discount, items, shippingPrice, subtotal, tax, total } = orderSummary;
      return { ...state, discount, items, shippingPrice, subtotal, tax, total };
    },
    [actions.overrideUpdate](state, { payload: update }) {
      return { ...state, ...update };
    },
    [actions.updateOverrideSummaryPrices]: {
      next(state, { payload }) {
        return {
          ...state,
          ...payload,
        };
      },
      throw() {
        return { ...defaultState, loading: false, error: true };
      },
    },
    [actions.placeOrderResponse]: {
      next() {
        return defaultState.override;
      },
    },
  },
  defaultState.override
);

const payment = handleActions(
  {
    [actions.paymentUpdate]: {
      next(state, { payload: update }) {
        return { ...state, ...update };
      },
      throw(state) {
        return { ...state, error: true };
      },
    },
    [actions.placeOrderResponse]: {
      next() {
        return defaultState.payment;
      },
    },
    [userActions.createCreditCardResponse]: {
      next(state) {
        return {
          ...state,
          creditCardInfo: defaultState.payment.creditCardInfo,
          customerInfo: defaultState.payment.customerInfo,
        };
      },
    },
  },
  defaultState.payment
);

const referenceInfo = handleActions(
  {
    [actions.deleteEmail](state) {
      return { ...state, loading: true };
    },
    [actions.deleteEmailResponse](state, { payload: id }) {
      return { ...state, notificationEmails: state.notificationEmails.filter(e => e.id !== id) };
    },
    [actions.editEmail](state, { payload }) {
      const { email } = find(state.notificationEmails, { id: payload });
      return { ...state, editedEmail: email, editedEmailId: payload };
    },
    [actions.fetchNotificationEmails](state) {
      return { ...state, loading: true };
    },
    [actions.notificationEmailsResponse](state, { payload }) {
      return { ...state, notificationEmails: payload.emails, loading: false };
    },
    [actions.referenceInfoUpdate](state, { payload: update }) {
      return { ...state, ...update };
    },
    [actions.saveEmail](state) {
      return { ...state, loading: true };
    },
    [actions.saveEmailResponse](state, { payload }) {
      return {
        ...state,
        editedEmailId: null,
        editedEmail: '',
        loading: false,
        newEmail: '',
        notificationEmails: sortBy(unionBy([payload.notificationEmail, ...state.notificationEmails], 'id'), 'id'),
        showAddEmail: false,
      };
    },
    [actions.placeOrderResponse]: {
      next() {
        return defaultState.referenceInfo;
      },
    },
  },
  defaultState.referenceInfo
);

const shipping = handleActions(
  {
    [actions.shippingUpdate]: {
      next(state, { payload: update }) {
        const nextState = { ...state, ...update };
        const valid = allValid(nextState, {
          shippingMethod: { validation: ['exists'] },
          shipperPreference: { validation: ['exists'] },
        });
        return { ...nextState, valid };
      },
      throw(state) {
        return { ...state, invalid: true };
      },
    },
    [actions.placeOrderResponse]: {
      next() {
        return defaultState.shipping;
      },
    },
  },
  defaultState.shipping
);

const shippingAddress = handleActions(
  {
    [actions.shippingAddressUpdate]: {
      next(state, { payload: update }) {
        return { ...state, ...update };
      },
      throw(state) {
        return { ...state, error: true };
      },
    },
    [actions.placeOrderResponse]: {
      next() {
        return defaultState.shippingAddress;
      },
    },
  },
  defaultState.shippingAddress
);

export { selectors };

const checkoutReducer = combineReducers({
  confirmation,
  csr,
  override,
  payment,
  referenceInfo,
  shipping,
  shippingAddress,
});

const rootCheckoutReducer = (state, action) => {
  if (action.type === 'FETCH_ACCOUNT_DETAILS') {
    state = defaultState;
  }

  return checkoutReducer(state, action);
};

export { actions } from './actions';
export default rootCheckoutReducer;
