import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Loader from 'components/Loader';
import { selectors as accountSelectors } from 'modules/account/details';
import { actions, selectors } from 'modules/order';

import Cart from './Cart';

class CartContainer extends PureComponent {
  render() {
    const { account, hasOrder, accountLoading, loading } = this.props;
    if (accountLoading || loading) return <Loader />;
    if (!hasOrder && !account) return <Redirect to={'/accounts'} />;
    if (!hasOrder) return <Redirect to={`/accounts/${account.number}`} />;
    return <Cart {...this.props} />;
  }
}

const mapStateToProps = state => ({
  account: accountSelectors.details(state),
  currency: selectors.currency(state),
  hasOrder: selectors.hasOrder(state),
  items: selectors.items(state),
  loading: selectors.loading(state),
  accountLoading: accountSelectors.loading(state),
  summary: selectors.summary(state),
});

const mapDispatch = {
  removeItem: actions.removeItem,
};

export default connect(mapStateToProps, mapDispatch)(CartContainer);
