import React from 'react';

import { Breadcrumbs, Tabs } from 'components/Account';
import { RushSampleCheckout } from 'components/Account/RushSample/Checkout';

const RushSampleCheckoutLayout = ({ match }) => {
  return (
    <>
      <Breadcrumbs />
      <Tabs />
      <RushSampleCheckout />
    </>
  );
};

export default RushSampleCheckoutLayout;
