import get from 'lodash/get';
import slice from 'lodash/slice';
import React from 'react';
import styled from 'styled-components';

import Label from 'components/Label';
import PanelContainer from 'components/Panel';
import RowItem from 'components/RowItem';
import ShowAll from 'components/ShowAll';
import { colors } from 'style/theme';
import { translate } from 'utils/translations';

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const AccountInfo = ({ details, isCsr }) => {
  const accountLevel = get(details, 'accountLevel', '');
  const name = get(details, 'name', '');
  const reportAddress = get(details, 'reportAddress');
  const userAccess = get(details, 'userAccess', []);

  const userAccessLength = userAccess && userAccess.length;
  return (
    <Container>
      <PanelContainer
        title={
          <Title>
            {translate('accountInfo')}
            {isCsr && (
              <Label style={{ marginLeft: 8, fontSize: 13 }} large csr>
                Account Tier: {accountLevel}
              </Label>
            )}
          </Title>
        }
        style={{ marginBottom: 0, flex: '1 1 auto' }}
      >
        <RowItem title={translate('companyName')}>{name}</RowItem>
        {reportAddress && (
          <>
            {reportAddress.attention && <RowItem title={translate('attention')}>{reportAddress.attention}</RowItem>}
            <RowItem title={translate('address')}>
              {reportAddress.address1 && <div>{reportAddress.address1}</div>}
              {reportAddress.address2 && <div>{reportAddress.address2}</div>}
              <div>
                {reportAddress.city && <span>{reportAddress.city},&nbsp;</span>}
                {reportAddress.region && <span>{reportAddress.region}&nbsp;</span>}
                {reportAddress.post_code && <span>{reportAddress.post_code}</span>}
              </div>
              {reportAddress.country && <div>{reportAddress.country}</div>}
            </RowItem>
          </>
        )}
        {reportAddress && (
          <RowItem title={translate('phone')}>
            {reportAddress.phone_number && <div>{reportAddress.phone_number}</div>}
          </RowItem>
        )}
      </PanelContainer>

      {isCsr && (
        <PanelContainer style={{ background: colors.blueHawkes }}>
          <RowItem title={translate('userAccess')}>
            {userAccessLength > 3 ? (
              <div>
                {slice(userAccess, 0, 3).map(u => (
                  <div key={u}>{u}</div>
                ))}
                <ShowAll>
                  {slice(userAccess, 3, userAccessLength).map(u => (
                    <div key={u}>{u}</div>
                  ))}
                </ShowAll>
              </div>
            ) : (
              <div>
                {userAccess.map(u => (
                  <div key={u}>{u}</div>
                ))}
              </div>
            )}
          </RowItem>
        </PanelContainer>
      )}
    </Container>
  );
};

export default AccountInfo;
