import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import noop from 'lodash/noop';
import moment from 'moment';

const validatorMethods = {
  date: string => isEmpty(string) || moment(string).isValid(),
  dateBefore: (dateString, dateToCheck, dateFormat) => {
    if (!dateString) return true;

    const dateAsMoment = !moment.isMoment(dateString) ? moment(dateString, dateFormat) : dateString;
    return moment(dateAsMoment).isSameOrBefore(moment(dateToCheck, dateFormat));
  },
  dateAfter: (dateString, dateToCheck, dateFormat) => {
    if (!dateString) return true;

    const dateAsMoment = !moment.isMoment(dateString) ? moment(dateString, dateFormat) : dateString;
    return moment(dateAsMoment).isSameOrAfter(moment(dateToCheck, dateFormat));
  },
  expirationDate: (expirationString, format) => !moment(expirationString, format).endOf('M').isBefore(moment()),
  exists: string => (isString(string) ? !isEmpty(string) : !isNil(string)),
  isNotEmpty: value => isString(value) && !isEmpty(value.trim()),
  maxlength: (string, length) => !isEmpty(string) && `${string}`.length <= length,
  minLength: (string, length) => !isEmpty(string) && `${string}`.length >= length,
  numbersOnly: string => isEmpty(string) || /^\d+$/.test(string),
};

export const checkSingleValidity = (obj, validators) => {
  const validFields = validators.filter(v => {
    const [type, ...params] = v.split(':');
    const validator = validatorMethods[type] || noop;
    return validator(obj, ...params);
  });
  return validFields.length === validators.length;
};

export const checkValidity = (obj, validations) => {
  return Object.keys(validations).reduce((prev, cur) => {
    prev[cur] = { ...validations[cur], valid: checkSingleValidity(obj[cur], validations[cur].validation) };
    return prev;
  }, {});
};

export const allValid = (obj, validations) => {
  const validity = checkValidity(obj, validations);
  return !Object.keys(validity).some(x => !validity[x].valid);
};
