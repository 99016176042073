import React from 'react';

import { Breadcrumbs, RecurringOrders, Tabs } from 'components/Account';

const RecurringOrdersLayout = ({ match }) => {
  return (
    <>
      <Breadcrumbs />
      <Tabs />
      <RecurringOrders />
    </>
  );
};

export default RecurringOrdersLayout;
