import qs from 'qs';

import { translate } from './translations';

// base url
export const baseURL = '/api/v1';

// default headers
export const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const getErrorJson = async response => {
  const defaultError = translate('somethingWentWrongRetry', 'Looks like something went wrong, please retry!');

  try {
    return await response.json();
  } catch (e) {
    return { error: response.statusText || defaultError };
  }
};

export const urlWithParams = (url, params) => {
  if (!params || Object.keys(params).length === 0) return url;
  return `${url}${url.indexOf('?') > -1 ? '&' : '?'}${qs.stringify(params)}`;
};

// fetch wrapper
export default async function apiFetch(path, opts = {}) {
  if (typeof path !== 'string') throw new Error('expected url to be a string');

  const { method = 'GET', body, params, headers } = opts;

  const init = {
    ...opts,
    method: method.toUpperCase(),
    headers: { ...defaultHeaders, ...headers },
    credentials: 'include',
  };

  if (body) {
    init.headers['Content-Type'] = 'application/json';
    init.body = JSON.stringify(body);
  }

  const url = `${baseURL}/${path.replace(/^\/+/g, '')}`;

  const request = new Request(urlWithParams(url, params), init);
  const response = await fetch(request);

  if (!response.ok) {
    const payload = await getErrorJson(response);
    throw Error(payload.error);
  }

  const payload = await response.json();
  return payload;
}
