import { Breadcrumbs } from '@polarislabs/polaris-ui-components';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { renderMask } from 'utils/display';
import { translate } from 'utils/translations';

const CheckoutBreadcrumbs = ({ match }) => {
  const { accountNumber } = match.params;

  const routes = [
    { url: '/accounts', label: translate('accounts', 'Accounts') },
    { url: `/accounts/${accountNumber}/details`, label: renderMask(accountNumber) },
    { url: `/accounts/${accountNumber}/order`, label: translate('shipping.order', 'Order') },
    { url: `/accounts/${accountNumber}/order/cart`, label: translate('store.cart', 'Cart') },
    { label: translate('store.checkout', 'Checkout') },
  ];

  return <Breadcrumbs routes={routes} />;
};

export default withRouter(CheckoutBreadcrumbs);
