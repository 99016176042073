import find from 'lodash/find';
import unionBy from 'lodash/unionBy';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import OverrideTable from 'components/Order/OverrideTable';
import socket, { checkForSocket } from 'lib/socket';
import { selectors as accountSelectors } from 'modules/account/details';
import { actions, selectors } from 'modules/checkout';
import { selectors as orderSelectors } from 'modules/order';
import { selectors as userSelectors } from 'modules/user';

class OverrideTableContainer extends PureComponent {
  state = {};

  componentDidMount() {
    this.registerSocketEvent();
  }

  componentWillUnmount() {
    socket.off('overrideTotalSummaryUpdated');
  }

  registerSocketEvent = () => {
    checkForSocket();
    socket.on('overrideTotalSummaryUpdated', data => {
      this.props.updateOverrideSummaryPrices(data);
    });
  };

  onPriceChange = (id, val, eventType) => {
    const {
      currency,
      override: { items },
      overrideUpdate,
      summary: { address, orderId, shippingPrice },
    } = this.props;
    const updatedItem = find(items, { id: parseInt(id, 10) });

    const price = eventType === 'blur' ? parseFloat(val) : val;

    const overrideItems = unionBy(
      [{ ...updatedItem, csrOverride: { ...updatedItem.csrOverride, price } }, ...items],
      'id'
    );

    overrideUpdate({
      items: overrideItems,
    });

    if (eventType === 'blur') {
      socket.emit('updateTotalSummary', {
        address,
        currency,
        orderId,
        shippingPrice,
        emitTo: 'overrideTotalSummaryUpdated',
      });
    }
  };

  render() {
    const {
      override: { items },
      summary,
    } = this.props;

    return <OverrideTable {...this.props} items={items || summary.items} onPriceChange={this.onPriceChange} />;
  }
}

const mapStateToProps = state => ({
  currency: orderSelectors.currency(state),
  language: userSelectors.language(state),
  override: selectors.override(state),
  reportAddress: accountSelectors.reportAddress(state),
  summary: orderSelectors.summary(state),
});

const mapDispatch = {
  overrideUpdate: actions.overrideUpdate,
  updateOverrideSummaryPrices: actions.updateOverrideSummaryPrices,
};

export default connect(mapStateToProps, mapDispatch)(OverrideTableContainer);
