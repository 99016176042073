import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Currency from 'components/Currency';
import Image from 'components/Image';
import ListTable from 'components/ListTable';
import OneColumnRow from 'components/OneColumnRow';
import { colors } from 'style/theme';
import { renderDescription } from 'utils/display';
import { translate } from 'utils/translations';

const Item = styled.tr`
  .Item__image {
    display: inline-flex;
    align-items: center;
    flex: 0 0 auto;
    width: 100px;
    min-height: 100px;
    background: ${colors.whiteAlto};

    img {
      width: 100px;
      height: auto;
    }
  }

  .Item__title,
  .Item__number,
  .Item__subtitle {
    font-size: 14px;
  }

  .Item__title {
    font-weight: 700;
  }

  .Item__kit-contents {
    margin: 10px 0 10px;

    &:last-child {
      margin: 0;
    }
  }
`;

const SummaryTable = ({ currency, items, language, hasOverrides, reportAddress }) => {
  const renderImage = ({ featuredImage }) => {
    if (!featuredImage) return null;

    return (
      <div className="Item__image">
        <Image src={featuredImage.url} alt={featuredImage.alt} />
      </div>
    );
  };

  return (
    <ListTable>
      <thead>
        <tr>
          <th style={{ width: 100 }}>&nbsp;</th>
          <th>{translate('cart.description', 'Description')}</th>
          <th>{translate('cart.price', 'Price')}</th>
          <th>{translate('cart.quantity', 'Quantity')}</th>
          <th style={{ width: 150, textAlign: 'right' }}>{translate('cart.totalPrice', 'Total Price')}</th>
          {hasOverrides && <th style={{ width: 150 }}>{translate('shipping.overrideTotal', 'Override Total')}</th>}
        </tr>
      </thead>
      <tbody>
        {!items.length && <OneColumnRow colSpan="4" condensed text={translate('cart.emptyOrder', 'Order is Empty')} />}
        {items.length > 0 &&
          sortBy(items, 'id').map((item, i) => (
            <Item key={item.id}>
              <td>{renderImage(item)}</td>
              <td>
                {item.items && item.items.length > 0 ? (
                  <>
                    <div className="Item__title">{item.name}</div>
                    <div className="Item__number">{item.actual_item_number}</div>
                    <div className="Item__subtitle">
                      ({item.packSize})&nbsp;{item.actual_description}
                    </div>
                    <div className="Item__kit-contents">
                      {item.items.map(kitItem => (
                        <div key={kitItem.inventory_id}>
                          ({kitItem.quantity}) {renderDescription(kitItem, language, kitItem.category, item.category)}
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="Item__title">{item.actual_description}</div>
                    <div className="Item__subtitle">{item.name}</div>
                    <div className="Item__content">
                      <div key={`${item.productListItemId}-${i}`}>
                        ({item.quantity}) {item.description}
                      </div>
                    </div>
                  </>
                )}
                {item.prefillLabel && (
                  <div>
                    <strong>{translate('cart.prefillLabel')}:&nbsp;</strong>
                    {reportAddress.attention && <span>{reportAddress.attention},&nbsp;</span>}
                    <span>{reportAddress.address1},&nbsp;</span>
                    {reportAddress.address2 && <span>{reportAddress.address2},&nbsp;</span>}
                    <span>
                      {reportAddress.city}, {reportAddress.region} {reportAddress.post_code}
                    </span>
                  </div>
                )}
                {item.labelsOnly && (
                  <div>
                    <strong>Labels Only</strong>
                  </div>
                )}
              </td>
              <td>
                <Currency quantity={item.price} currency={currency} />
              </td>
              <td>{item.quantity}</td>
              <td style={{ width: 150, textAlign: 'right' }}>
                <strong>
                  <Currency quantity={item.totalPrice} currency={currency} />
                </strong>
              </td>
              {hasOverrides && (
                <td style={{ width: 150 }}>
                  {item.csrOverride.price && (
                    <strong>
                      <Currency quantity={parseFloat(item.csrOverride.price)} currency={currency} />
                    </strong>
                  )}
                </td>
              )}
            </Item>
          ))}
      </tbody>
    </ListTable>
  );
};

SummaryTable.propTypes = {
  currency: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string,
      quantity: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      totalPrice: PropTypes.number.isRequired,
      csrOverride: PropTypes.shape({
        price: PropTypes.number,
      }),
    })
  ).isRequired,
  language: PropTypes.string.isRequired,
  reportAddress: PropTypes.object,
};

export default SummaryTable;
