import { createActions } from 'redux-actions';

export const actions = createActions(
  'CREATE_CREDIT_CARD',
  'CREATE_CREDIT_CARD_RESPONSE',
  'DELETE_CREDIT_CARD',
  'DELETE_CREDIT_CARD_RESPONSE',
  'FETCH_USER_PROFILE',
  'FETCH_USER_PROFILE_RESPONSE',
  'USER_AUTH',
  'USER_AUTH_RESPONSE'
);
