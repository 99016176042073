import React from 'react';

import { AccountList, Tabs } from 'components/Base';

const AccountListLayout = ({ match }) => {
  return (
    <>
      <Tabs />
      <AccountList />
    </>
  );
};

export default AccountListLayout;
