import React from 'react';
import styled from 'styled-components';

import ModalWrapper from 'components/Modals/ModalWrapper';
import { colors } from 'style/theme';
import { translate } from 'utils/translations';

const StyledContent = styled.div`
  .Content__red {
    color: ${colors.redCarmine};
  }
`;

const DuplicatePOModal = props => {
  const { purchaseOrderNumber, ...optionProps } = props;

  const options = {
    cancelIcon: 'pencil',
    cancelText: translate('store.editPO', 'Edit PO#'),
    closeIcon: 'arrow-right',
    closeText: translate('store.continuePO', 'Continue with Duplicate PO#'),
    type: 'alert',
    width: 525,
  };

  return (
    <ModalWrapper {...options} {...optionProps} locked>
      <StyledContent>
        <p>
          <strong>
            <span className="Content__red">{translate('store.notice', 'Notice')}:</span>{' '}
            {translate(
              'store.duplicatePOMessage',
              'Purchase order #{purchaseOrderNumber} is already in use on a different order.',
              {
                purchaseOrderNumber,
              }
            )}
          </strong>
        </p>
      </StyledContent>
    </ModalWrapper>
  );
};

export default DuplicatePOModal;
