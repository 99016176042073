import React from 'react';
import styled from 'styled-components';

import changeMethod from 'utils/common/changeMethod';

const TemplateContent = styled.label`
  display: flex;
  padding: 10px;
  font-size: 12px;
  align-items: center;
  .no-wrap {
    flex: 0 0 auto;
  }
  .wrap {
    flex: 1 1 auto;
  }
`;

const Thing = styled.div`
  flex: 0 0 auto;
  margin-right: 10px;

  &:last-child {
    margin: 0;
  }
`;

const Action = styled(Thing)`
  cursor: pointer;
`;

const Icon = styled.i`
  &.fa-pencil {
    color: #1976d2;
  }
`;

const ShippingInfoItem = ({
  address1,
  address2,
  attention,
  city,
  company_name,
  country,
  editable,
  id,
  onEdit,
  onSelect,
  phone_number,
  post_code,
  region,
  selected,
}) => {
  return (
    <TemplateContent>
      <Thing className="no-wrap">
        <input type="radio" onChange={changeMethod(onSelect)} checked={selected} value={id} />
      </Thing>
      <Thing className="wrap">
        {company_name && <span>{company_name},&nbsp;</span>}
        {attention && <span>{attention},&nbsp;</span>}
        {address1 && <span>{address1},&nbsp;</span>}
        {address2 && <span>{address2},&nbsp;</span>}
        {city && <span>{city},&nbsp;</span>}
        {region && <span>{region},&nbsp;</span>}
        {post_code && <span>{post_code},&nbsp;</span>}
        {country && <span>{country},&nbsp;</span>}
        {phone_number && <span>{phone_number}</span>}
      </Thing>
      {editable && (
        <Action className="no-wrap" onClick={e => e.preventDefault() || onEdit(id)}>
          <Icon className="fa fa-pencil" />
        </Action>
      )}
    </TemplateContent>
  );
};

export default ShippingInfoItem;
