import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { actions, selectors } from 'modules/userSearch';

import UserSearchDialog from './UserSearchDialog';

class UserSearchDialogContainer extends PureComponent {
  static propTypes = {
    error: PropTypes.bool,
    loading: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    selectEmail: PropTypes.func.isRequired,
    showResults: PropTypes.bool,
    userSearch: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        username: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        lastLoginDate: PropTypes.string,
        termsAcceptanceDate: PropTypes.string,
        masks: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  };

  state = { searchText: '' };

  setSearchText = e => {
    const searchText = e.target.value;
    this.setState(() => ({ searchText }));
  };

  searchUsers = e => {
    e.preventDefault();

    const { searchText } = this.state;

    if (searchText.length >= 3) {
      this.props.userSearch(searchText);
    }
  };

  resetSearch = () => {
    this.setState(() => ({ searchText: '' }));
    this.props.resetSearch();
  };

  componentDidMount() {
    this.resetSearch();
  }

  render() {
    return (
      <UserSearchDialog
        error={this.props.error}
        loading={this.props.loading}
        onClose={this.props.onClose}
        onReset={this.resetSearch}
        searchText={this.state.searchText}
        searchUsers={this.searchUsers}
        selectEmail={this.props.selectEmail}
        setSearchText={this.setSearchText}
        showResults={this.props.showResults}
        users={this.props.users}
      />
    );
  }
}

const mapState = state => ({
  error: selectors.error(state),
  loading: selectors.loading(state),
  showResults: selectors.showResults(state),
  users: selectors.users(state),
});

export default connect(mapState, {
  resetSearch: actions.resetUserSearch,
  userSearch: actions.userSearch,
})(UserSearchDialogContainer);
