import React from 'react';
import styled from 'styled-components';

import { colors } from 'style/theme';

const THEMES = {
  default: {
    bg1: '#E6E6E6',
    bg2: colors.white,
    border: '#D1D1D1',
    color: colors.black,
  },
  primary: {
    bg1: '#1565C0',
    bg2: '#1E88E5',
    border: '#0060B9',
    color: colors.whiteAlabaster,
  },
  success: {
    bg1: colors.greenSpinach,
    bg2: colors.greenMantis,
    border: colors.greenApple,
    color: colors.white,
  },
  danger: {
    bg1: '#E53935',
    bg2: '#EF5350',
    border: '#D32F2F',
    color: colors.whiteAlabaster,
  },
};

const getTheme = themeName => THEMES[themeName] || THEMES.default;

const ButtonRoot = styled.button`
  background-image: linear-gradient(
    to top,
    ${props => getTheme(props.theme).bg1},
    ${props => getTheme(props.theme).bg2 || getTheme(props.theme).bg1}
  );
  border: solid 1px ${props => getTheme(props.theme).border};
  border-radius: 3px;
  color: ${props => getTheme(props.theme).color};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')} !important;
  font-size: 12px;
  line-height: 1em;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  padding: 2px 3px 1px;
  vertical-align: middle;

  .fa,
  .label {
    vertical-align: middle;
  }

  .fa {
    font-size: 14px;
  }

  .label {
    ${props => props.hasIcon && 'margin-left: 10px;'};
  }
`;

const Button = ({ icon, label, ...props }) => (
  <ButtonRoot {...props} hasIcon={!!icon} hasLabel={!!label}>
    {icon && <i className={`fa fa-${icon}`} />}
    {label && <span className="label">{label}</span>}
  </ButtonRoot>
);

Button.defaultProps = { type: 'button', theme: 'default', size: 'medium' };

export default Button;
