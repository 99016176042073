import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import EmailNotificationItem from 'components/Checkout/EmailNotificationItem';
import Input from 'components/Input';
import ItemManager from 'components/ItemManager';
import PanelContainer from 'components/Panel';
import RowItem from 'components/RowItem';
import { changeMethod } from 'utils/common';
import { translate } from 'utils/translations';

import Button from '../Button';

const AddEmailInput = styled.div`
  display: flex;
  width: 100%;
  input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
  }
  button {
    padding: 2px 10px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  .Title__action {
    margin-left: auto;
  }
`;

const ReferenceInfo = ({
  actions,
  addEmail,
  allSettings: { email_notification },
  createEmail,
  fieldUpdate,
  referenceInfo: {
    editedEmail,
    editedEmailId,
    newEmail,
    notificationEmails,
    orderPlacedBy,
    orderPlacedFor,
    referencePO,
    showAddEmail,
  },
  saveEmail,
}) => {
  return (
    <PanelContainer
      title={
        <Title>
          <div className="Title__base">{translate('checkout.referenceInformation', 'Reference Information')}</div>
        </Title>
      }
    >
      <RowItem center title={translate('shipping.orderPlacedBy', 'Order Placed By')}>
        <span>{orderPlacedBy}</span>
      </RowItem>
      <RowItem center title={translate('shipping.orderPlacedFor', 'Order Placed For')}>
        <Input name="orderPlacedFor" onChange={changeMethod(fieldUpdate)} value={orderPlacedFor} />
      </RowItem>
      <RowItem center title={translate('checkout.referencePO', 'Reference PO')}>
        <Input name="referencePO" onChange={changeMethod(fieldUpdate)} value={referencePO} />
      </RowItem>

      <RowItem titleStyle={{ marginTop: '5px' }} title={translate('shipping.sendNotification', 'Send Notification')}>
        <ItemManager
          addItem={() => addEmail()}
          addItemText={translate('addEmailAddress', 'Add email address')}
          actions={actions}
          component={EmailNotificationItem}
          items={notificationEmails}
          otherOptions={{
            primaryEmail: email_notification || null,
            editedEmail,
            editedEmailId,
            fieldUpdate,
            saveEmail,
          }}
          showAddItem={!showAddEmail && notificationEmails && notificationEmails.length < 3}
        >
          {showAddEmail && (
            <AddEmailInput>
              <Input name="newEmail" inline onChange={changeMethod(fieldUpdate)} value={newEmail} />
              <Button icon="save" onClick={() => createEmail()} />
            </AddEmailInput>
          )}
        </ItemManager>
      </RowItem>
    </PanelContainer>
  );
};

ReferenceInfo.propTypes = {
  referenceInfo: PropTypes.shape({
    notificationEmails: PropTypes.array,
    orderPlacedBy: PropTypes.string.isRequired,
    orderPlacedFor: PropTypes.string.isRequired,
    primaryNotification: PropTypes.object,
    referencePO: PropTypes.string.isRequired,
  }).isRequired,
  addEmail: PropTypes.func.isRequired,
  fieldUpdate: PropTypes.func.isRequired,
};

export default ReferenceInfo;
