import values from 'lodash/values';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/Button';
import { notebookTypes } from 'modules/account/notes';

const AddNoteButton = ({ notebook, addNote, disabled }) => (
  <Button
    icon="plus"
    theme="success"
    notebook={notebook}
    disabled={disabled}
    onClick={() => !disabled && addNote(notebook)}
  />
);

AddNoteButton.propTypes = {
  notebook: PropTypes.oneOf(values(notebookTypes)).isRequired,
  addNote: PropTypes.func.isRequired,
};

export default AddNoteButton;
